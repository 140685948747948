import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

class setupTaxRatesAmounts extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:20,ratesamounts:'',Resp:'',Lyear:'',
			total00:'',total01:'',total02:'',total03:'',total04:'',total10:'',total11:'',total12:'',total13:'',total14:'',			
			employeeUpdate:'',yearclaimcode:'',Provs:[],indexvalue:'',indexyear:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);						
		this.handleYearChange=this.handleYearChange.bind(this)
		this.handleManageClaimChange=this.handleManageClaimChange.bind(this)		
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Provs:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});			
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
		this.setState({Lyear:""});		
	}	
	handleYearChange = event=>{		
		this.setState({Resp:""});
		this.setState({indexvalue:""});
		this.setState({Lyear:""});		
		this.setState({total00:""}); this.setState({total01:""}); this.setState({total02:""});this.setState({total03:""});this.setState({total04:""});			
		this.setState({total10:""}); this.setState({total11:""}); this.setState({total12:""});this.setState({total13:""});this.setState({total14:""});				
		const {name, value} = event.target				
		this.setState({[name]: value})			
		const userObject={			
				year:event.target.value,					
				amounts: this.state.ratesamounts}
		axios.post(process.env.REACT_APP_API_ENV+'api/updateeis.php', userObject)
			.then(result => {
				if((this.state.ratesamounts==2 || this.state.ratesamounts==3) && result.data[0][0] == 'No')
					{this.setState({indexvalue:'No'})}
				else if(this.state.ratesamounts==1 && result.data[0][0] == 'YEAR')
					{this.setState({indexvalue:'No'})}
				else if(result.data[0][0] != 'No' && result.data[0][0] != 'YEAR')	{				
					this.setState({total00:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[2]).[0]});
					this.setState({total01:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[3]).[0]}); 
					this.setState({total02:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[4]).[0]});
					this.setState({total03:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[5]).[0]});
					this.setState({total04:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[6]).[0]});													
					this.setState({total10:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[2]).[0]});
					this.setState({total11:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[3]).[0]}); 
					this.setState({total12:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[4]).[0]});
					this.setState({total13:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[5]).[0]});
					this.setState({total14:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[6]).[0]});
					this.setState({Lyear:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[0]).[0]});	
				} 
			})	
		this.setState({Resp:""});				
	}
	
	handleManageClaimChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({yearclaimcode:''})		
		this.setState({Resp:""});		
		this.setState({indexvalue:""});
		this.setState({Lyear:""});		
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {ratesamounts,total00,total01,total02,total03,total04,total10,total11,total12,total13,total14,yearclaimcode} = this.state			
		if (this.state.ratesamounts == "") {
			alert('Please select manage EI Add, Update or Delete')
			return false
		}
		if(this.state.ratesamounts==1)  {					
			if(!yearclaimcode) {
				alert('Please select year for the EI')
				return false
			}			
			if(!total00) {
					alert('Please enter annual Max insurable earnings')
					return false
				}	
			if(!total01) {
					alert('Please enter employee contribution rate')
					return false
				}
			if(!total02) {
					alert('Please enter employer contribution rate')
					return false
				}
			if(!total03) {
					alert('Please enter annual max employee premium')
					return false
				}
			if(!total04) {
					alert('Please enter annual max employer premium')
					return false
				}															
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,					
				yearclaimcode: this.state.yearclaimcode,						
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,
					total03:this.state.total03,total04:this.state.total04,
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,
					total13:this.state.total13,total14:this.state.total14
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.ratesamounts==2)  {						
			if(!yearclaimcode) {
				alert('Please select year for the CPP updating')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,					
				yearclaimcode: this.state.yearclaimcode,
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,
					total03:this.state.total03,total04:this.state.total04,
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,
					total13:this.state.total13,total14:this.state.total14
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.ratesamounts==3)  {						
			if(!yearclaimcode) {
				alert('Please select year')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,					
				yearclaimcode: this.state.yearclaimcode
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (					
			<div>      					
				<CommonHeader titles="Employment Insurance"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage EI</h2></label>							
						<select name="ratesamounts" id="ratesamounts" style={{width:'150px'}} value={this.state.ratesamounts} onChange={this.handleManageClaimChange}>
							<option value="">--Manage Type--</option>
							<option value="1">Add New EI</option>															
							<option value="2">Update EI</option>												
							<option value="3">Remove EI</option>		
						</select><br/><br/>	
					</div>	
					{(()=>{
						{/*add/update/delete EI values*/}
						if(this.state.ratesamounts != "") {return(<div>							
							<label><h2>Select Year</h2></label>							
							<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleYearChange}>
								<option value="">--Select Year--</option>									
								{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}																		
							</select><br/><br/>										
							<h2>{(this.state.indexvalue !="No" && this.state.ratesamounts==1 && this.state.yearclaimcode !="") ? "These values are obtained from "+this.state.Lyear+", please update for current year":""}</h2>
							<h2>{(this.state.indexvalue == "No" && this.state.ratesamounts==1 && this.state.yearclaimcode !="") ? "Please enter the values":""}</h2>							
							<h2>{(this.state.indexvalue=="No" && this.state.ratesamounts==2 && this.state.yearclaimcode !="") ? "Sorry, No EI in the system, please add them first":((this.state.ratesamounts==2 && this.state.yearclaimcode !="") ? "Please modify EI and update":"")}</h2>
							<h2>{(this.state.indexvalue=="No" && this.state.ratesamounts==3 && this.state.yearclaimcode !="") ? "Sorry, No EI in the system":((this.state.ratesamounts==3 && this.state.yearclaimcode !="") ? "Please look at these values carefully and determine the removing":"")}</h2><br/><br/>
						</div>)} 
					})()}
					{/*Add/update/remove EI*/}
					{(()=>{if(this.state.ratesamounts != "" && this.state.yearclaimcode !="") {return(<div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'150px'}}>EI</h2>
								<h2 style={{width:'150px'}}>Annual Max Insurable Earnings</h2>
								<h2 style={{width:'150px'}}>Employee Contribution Rate</h2>
								<h2 style={{width:'150px'}}>Employer Contribution Rate</h2>
								<h2 style={{width:'150px'}}>Annual Max Employee Premium</h2>
								<h2 style={{width:'150px'}}>Annual Max employer Premium</h2>								
							</div><br/><br/>
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'150px'}}>Canada except QC</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'120px'}} name="total00" value={this.state.total00} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'120px'}} name="total01" value={this.state.total01} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'120px'}} name="total02" value={this.state.total02} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'120px'}} name="total03" value={this.state.total03} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'120px'}} name="total04" value={this.state.total04} onChange={this.handleChange}/></h2>								
							</div><br/><br/><br/><br/>		
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'150px'}}>QC</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'120px'}} name="total10" value={this.state.total10} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'120px'}} name="total11" value={this.state.total11} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'120px'}} name="total12" value={this.state.total12} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'120px'}} name="total13" value={this.state.total13} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'120px'}} name="total14" value={this.state.total14} onChange={this.handleChange}/></h2>								
							</div><br/><br/>			
						</div><br/><br/>													
						<h1>{this.state.Resp}</h1><br/><br/>
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupTaxRatesAmounts;