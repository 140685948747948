import React from 'react'
import axios from 'axios'


class ResourceForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {CompName:''}
		
	}
	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
	}
	render() {    		
		return(
			<div style={{textAlign:'left'}}>
				<p>This Privacy Policy applies to the website or service owned and operated by {this.state.CompName} and 
					its subsidiaries and affiliates. {this.state.CompName} respects your right to the privacy and adhere to the privacy 
					principles to handle all your personally identifiable information carefully and strictly.					
				</p><br/>
				<p>We collect the necessary and required information for the service you have with us and provide better service 
				to you. We may require you to provide business information, name, address, phone number, financial institution 
				information, credit card information, or e-mail address. We may collect your employee information under your 
				instruction to fulfill your requested services. The information we collected is limited to the related service 
				you request and we are committed to safeguard and protect your business information and personal information.</p><br/>
				<p>We use the information we collect from you or under your instruction to offer tailored service and more personalized 
				and value-added experience. We use the information to communiate with you about our current or future services or promotions.  
				The information is under the strict control by our designated individuals for the company compliance.</p><br/>
				<p>We identify the purpose of the information and communiate it at or before the information is collected and obtain 
				the consent and knowledge before we collect the information for the use and disclosure. All the information you 
				are asked to provide will be stored on limited access servers and is dealt with strictly in accordance with this policy.</p><br/>
				<p>We will not use or disclose the confidential information for other purposes unrelated to the services without your consent 
				except required by law.</p><br/>
				<p>We regularly review our compliance with our Privacy Policy. we strictly maintain and implement procedures to protect the information. 
				Our website may contain links to other sites that are not owned by {this.state.CompName}. We remind you to be aware when you 
				leave our site. This privacy policy applies only to information collected on this website. When you visit this website, 
				you consent to this Privacy Policy. {this.state.CompName} is not responsible for the privacy practices of websites that are linked from our website.</p><br/>
				<p>We have the right to modify our Privacy Policy at any time without notice, you consent to accept these changes when you use this website.
				We will post any updated privacy policy on this website when updated/modified.</p><br/>
				<p>We will comply fully with all laws and regulations applicable with our privacy policy.</p>			
			</div>	
		);
	}
}



export default ResourceForm