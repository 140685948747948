import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupTD1FormYear extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:8,taxyear:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);				
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {assignment,taxyear} = this.state		
		if(!taxyear) {
				alert('Please select the tax year')
				return false
		}			
		const userObject={
			user:this.state.user,
			names:this.state.names,
			adminitem:this.state.adminitem,				
			taxyear:this.state.taxyear				
		}
		axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
			.then((res) => {						
			this.setState({Resp:res.data});											
		}).catch((error) => {
			console.log(error)
		});		
	}
	
	render() {						
		return (		
			<div>      			
				<CommonHeader titles="TD1 Form Year"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Select Year</h2></label>							
						<select name="taxyear" id="taxyear" style={{width:'150px'}} value={this.state.taxyear} onChange={this.handleChange}>
							<option value="">--Select Year--</option>
							{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 300 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}
						</select>
						<p>New employee will fill out TD1 Form for this year</p>
						<p>{dayjs().diff(dayjs(lastyear).endOf('year'),'day') > 300 ? "Please check Government new TD1 Form for year: "+nextyear:""}</p>
						<br/><br/>		
						<h1>{this.state.Resp}</h1><br/><br/>
					</div>					
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupTD1FormYear;