import React from "react";
import '../main.css'
import axios from 'axios'
import CommonHeader from '../clients/commons/Head'

class contactForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {oldpassword:'',password:'', password2:'',Resp:'',
					user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};		
		this.handleChange=this.handleChange.bind(this);			
		this.handleSubmit=this.handleSubmit.bind(this);	
	}

	
	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})		
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {password,password2,oldpassword} = this.state	
		if(!oldpassword){
			alert('Please enter your old password')
			return false
		}
		if(!password){
			alert('Please enter your new password')
			return false
		} else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(password)) {
			alert('Password must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters')
			return false
		}
		if(!password2){
			alert('Sorry, please confirm your new password')
			return false
		} else if (password2 !== password){
			alert('Sorry, your new password is not matched, pleare re-enter your new password' )
			return false
		}			
		const userObject={
				user:this.state.user,
				names:this.state.names,
				password:this.state.password,	
				password2: this.state.password2,
				oldpassword: this.state.oldpassword
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/changepwd.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});
	}
	
	render() {							
		return (
			<div>			
				<CommonHeader titles="Password"/>
				<form onSubmit={this.handleSubmit}>																										
					<label><h2 >Old Password</h2></label>	
					<input type="password" name="oldpassword" value = {this.state.oldpassword} onChange={this.handleChange} /><br/><br/>
					<label><h2 >New Password</h2></label>	
					<input type="password" name="password" value = {this.state.password} onChange={this.handleChange} /><br/><br/>
					<label><h2 >New Password Again</h2></label>											
					<input type="password" name="password2" value = {this.state.password2} onChange={this.handleChange} /><br/><br/>
					<br/><br/>	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> &nbsp;&nbsp;<br/><br/>
					<h2>{this.state.Resp=="" ? "":this.state.Resp}</h2>
				</form>
			</div>
		)
	}
}

export default contactForm;