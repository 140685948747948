import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupTaxRatesAmounts extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:17,ratesamounts:'',Resp:'',
			total00:'',total01:'',total02:'',total03:'',total04:'',total05:'',total06:'',total07:'',total08:'',total09:'',
			total10:'',total11:'',total12:'',total13:'',total14:'',total15:'',total16:'',total17:'',total18:'',total19:'',
			total20:'',total21:'',total22:'',total23:'',total24:'',total25:'',total26:'',total27:'',total28:'',total29:'',
			employeeUpdate:'',yearclaimcode:'',Provs:[],indexvalue:'',indexyear:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);		
		this.handleIndexChange=this.handleIndexChange.bind(this)
		this.handleEmployeeChange=this.handleEmployeeChange.bind(this)
		this.handleYearChange=this.handleYearChange.bind(this)
		this.handleManageClaimChange=this.handleManageClaimChange.bind(this)
		this.handleUpClaimChange=this.handleUpClaimChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Provs:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});			
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
	}	
	handleYearChange = event=>{		
		this.setState({Resp:""});
		this.setState({total00:""}); this.setState({total01:""}); this.setState({total02:""});this.setState({total03:""});this.setState({total04:""});this.setState({total05:""});this.setState({total06:""});this.setState({total07:""});this.setState({total08:""});this.setState({total09:""});
		this.setState({total10:""}); this.setState({total11:""}); this.setState({total12:""});this.setState({total13:""});this.setState({total14:""});this.setState({total15:""});this.setState({total16:""});this.setState({total17:""});this.setState({total18:""});this.setState({total19:""});
		this.setState({total20:""}); this.setState({total21:""}); this.setState({total22:""});this.setState({total23:""});this.setState({total24:""});this.setState({total25:""});this.setState({total26:""});this.setState({total27:""});this.setState({total28:""});this.setState({total29:""});
		this.setState({indexvalue:""})				
		const {name, value} = event.target				
		this.setState({[name]: value})	
		if (event.target.value==thisyear){
			this.setState({indexyear:lastyear})
		} else if (event.target.value==nextyear){
			this.setState({indexyear:thisyear})
		}	
	}

	
	handleManageClaimChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({employeeUpdate:''})
		
		this.setState({Resp:""});		
	}
	handleEmployeeChange = event => {				
		this.setState({employeeUpdate: event.target.value})	
		this.setState({yearclaimcode:""})
		this.setState({total00:""}); this.setState({total01:""}); this.setState({total02:""});this.setState({total03:""});this.setState({total04:""});this.setState({total05:""});this.setState({total06:""});this.setState({total07:""});this.setState({total08:""});this.setState({total09:""});
		this.setState({total10:""}); this.setState({total11:""}); this.setState({total12:""});this.setState({total13:""});this.setState({total14:""});this.setState({total15:""});this.setState({total16:""});this.setState({total17:""});this.setState({total18:""});this.setState({total19:""});
		this.setState({total20:""}); this.setState({total21:""}); this.setState({total22:""});this.setState({total23:""});this.setState({total24:""});this.setState({total25:""});this.setState({total26:""});this.setState({total27:""});this.setState({total28:""});this.setState({total29:""});
		this.setState({Resp:""});
	}
	handleIndexChange = event=>{	
		this.setState({total00:""}); this.setState({total01:""}); this.setState({total02:""});this.setState({total03:""});this.setState({total04:""});this.setState({total05:""});this.setState({total06:""});this.setState({total07:""});this.setState({total08:""});this.setState({total09:""});
		this.setState({total10:""}); this.setState({total11:""}); this.setState({total12:""});this.setState({total13:""});this.setState({total14:""});this.setState({total15:""});this.setState({total16:""});this.setState({total17:""});this.setState({total18:""});this.setState({total19:""});
		this.setState({total20:""}); this.setState({total21:""}); this.setState({total22:""});this.setState({total23:""});this.setState({total24:""});this.setState({total25:""});this.setState({total26:""});this.setState({total27:""});this.setState({total28:""});this.setState({total29:""});
		const {name, value} = event.target				
		this.setState({[name]: value})		
		const userObject={
				STATE:event.target.value,
				lastYEAR: this.state.indexyear,
				thisYEAR: this.state.yearclaimcode}
		axios.post(process.env.REACT_APP_API_ENV+'api/indexupdateratesamounts.php', userObject) 
			.then(result => {	
				if(result.data[0][0] != 'No')	{
					this.setState({total00:result.data.filter(sub=>sub[2]=='A').map(sub=>sub[3])[0]});this.setState({total01:result.data.filter(sub=>sub[2]=='A').map(sub=>sub[4])[0]}); this.setState({total02:result.data.filter(sub=>sub[2]=='A').map(sub=>sub[5])[0]});
					this.setState({total03:result.data.filter(sub=>sub[2]=='A').map(sub=>sub[6])[0]});this.setState({total04:result.data.filter(sub=>sub[2]=='A').map(sub=>sub[7])[0]});this.setState({total05:result.data.filter(sub=>sub[2]=='A').map(sub=>sub[8])[0]});
					this.setState({total06:result.data.filter(sub=>sub[2]=='A').map(sub=>sub[9])[0]});this.setState({total07:result.data.filter(sub=>sub[2]=='A').map(sub=>sub[10])[0]});this.setState({total08:result.data.filter(sub=>sub[2]=='A').map(sub=>sub[11])[0]});this.setState({total09:result.data.filter(sub=>sub[2]=='A').map(sub=>sub[12])[0]});
					this.setState({total10:result.data.filter(sub=>sub[2]=='R').map(sub=>sub[3])[0]});this.setState({total11:result.data.filter(sub=>sub[2]=='R').map(sub=>sub[4])[0]}); this.setState({total12:result.data.filter(sub=>sub[2]=='R').map(sub=>sub[5])[0]});
					this.setState({total13:result.data.filter(sub=>sub[2]=='R').map(sub=>sub[6])[0]});this.setState({total14:result.data.filter(sub=>sub[2]=='R').map(sub=>sub[7])[0]});this.setState({total15:result.data.filter(sub=>sub[2]=='R').map(sub=>sub[8])[0]});
					this.setState({total16:result.data.filter(sub=>sub[2]=='R').map(sub=>sub[9])[0]});this.setState({total17:result.data.filter(sub=>sub[2]=='R').map(sub=>sub[10])[0]});this.setState({total18:result.data.filter(sub=>sub[2]=='R').map(sub=>sub[11])[0]});this.setState({total19:result.data.filter(sub=>sub[2]=='R').map(sub=>sub[12])[0]});
					this.setState({total20:result.data.filter(sub=>sub[2]=='K').map(sub=>sub[3])[0]});this.setState({total21:result.data.filter(sub=>sub[2]=='K').map(sub=>sub[4])[0]}); this.setState({total22:result.data.filter(sub=>sub[2]=='K').map(sub=>sub[5])[0]});
					this.setState({total23:result.data.filter(sub=>sub[2]=='K').map(sub=>sub[6])[0]});this.setState({total24:result.data.filter(sub=>sub[2]=='K').map(sub=>sub[7])[0]});this.setState({total25:result.data.filter(sub=>sub[2]=='K').map(sub=>sub[8])[0]});
					this.setState({total26:result.data.filter(sub=>sub[2]=='K').map(sub=>sub[9])[0]});this.setState({total27:result.data.filter(sub=>sub[2]=='K').map(sub=>sub[10])[0]});this.setState({total28:result.data.filter(sub=>sub[2]=='K').map(sub=>sub[11])[0]});this.setState({total29:result.data.filter(sub=>sub[2]=='K').map(sub=>sub[12])[0]});
				} else {this.setState({indexvalue:'No'})}
			})	
		this.setState({Resp:""});		
	}
	
	handleUpClaimChange = event=>{		
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({Resp:""});
		this.setState({indexvalue:''})
		const userObject={		
			YEAR:event.target.value,
			STATE:this.state.employeeUpdate}
		axios.post(process.env.REACT_APP_API_ENV+'api/updateratesandamounts.php', userObject)
			.then(result => {					
				console.log(result.data)
				if(result.data[0][0] != 'No')	{
					this.setState({total00:result.data.filter(sub=>sub[0]=='A').map(sub=>sub[1])[0]}); this.setState({total01:result.data.filter(sub=>sub[0]=='A').map(sub=>sub[2])[0]}); this.setState({total02:result.data.filter(sub=>sub[0]=='A').map(sub=>sub[3])[0]}); 
					this.setState({total03:result.data.filter(sub=>sub[0]=='A').map(sub=>sub[4])[0]}); this.setState({total04:result.data.filter(sub=>sub[0]=='A').map(sub=>sub[5])[0]});this.setState({total05:result.data.filter(sub=>sub[0]=='A').map(sub=>sub[6])[0]});
					this.setState({total06:result.data.filter(sub=>sub[0]=='A').map(sub=>sub[7])[0]});this.setState({total07:result.data.filter(sub=>sub[0]=='A').map(sub=>sub[8])[0]});this.setState({total08:result.data.filter(sub=>sub[0]=='A').map(sub=>sub[9])[0]});this.setState({total09:result.data.filter(sub=>sub[0]=='A').map(sub=>sub[10])[0]});
					this.setState({total10:result.data.filter(sub=>sub[0]=='R').map(sub=>sub[1])[0]}); this.setState({total11:result.data.filter(sub=>sub[0]=='R').map(sub=>sub[2])[0]}); this.setState({total12:result.data.filter(sub=>sub[0]=='R').map(sub=>sub[3])[0]}); 
					this.setState({total13:result.data.filter(sub=>sub[0]=='R').map(sub=>sub[4])[0]}); this.setState({total14:result.data.filter(sub=>sub[0]=='R').map(sub=>sub[5])[0]});this.setState({total15:result.data.filter(sub=>sub[0]=='R').map(sub=>sub[6])[0]});
					this.setState({total16:result.data.filter(sub=>sub[0]=='R').map(sub=>sub[7])[0]});this.setState({total17:result.data.filter(sub=>sub[0]=='R').map(sub=>sub[8])[0]});this.setState({total18:result.data.filter(sub=>sub[0]=='R').map(sub=>sub[9])[0]});this.setState({total19:result.data.filter(sub=>sub[0]=='R').map(sub=>sub[10])[0]});
					this.setState({total20:result.data.filter(sub=>sub[0]=='K').map(sub=>sub[1])[0]}); this.setState({total21:result.data.filter(sub=>sub[0]=='K').map(sub=>sub[2])[0]}); this.setState({total22:result.data.filter(sub=>sub[0]=='K').map(sub=>sub[3])[0]}); 
					this.setState({total23:result.data.filter(sub=>sub[0]=='K').map(sub=>sub[4])[0]}); this.setState({total24:result.data.filter(sub=>sub[0]=='K').map(sub=>sub[5])[0]});this.setState({total25:result.data.filter(sub=>sub[0]=='K').map(sub=>sub[6])[0]});
					this.setState({total26:result.data.filter(sub=>sub[0]=='K').map(sub=>sub[7])[0]});this.setState({total27:result.data.filter(sub=>sub[0]=='K').map(sub=>sub[8])[0]});this.setState({total28:result.data.filter(sub=>sub[0]=='K').map(sub=>sub[9])[0]});this.setState({total29:result.data.filter(sub=>sub[0]=='K').map(sub=>sub[10])[0]});
				} else {this.setState({indexvalue:'No'})}
				
			})		
	}
	handleSubmit = event => {		
		event.preventDefault()		
		const {ratesamounts,total00,total01,total02,total03,total04,total05,total06,total07,total08,total09,total10,total11,total12,total13,
				total14,total15,total16,total17, total18, total19,total20,total21,total22,total23,total24,total25,total26,total27, total28,total29,
				employeeUpdate,yearclaimcode,indexvalue} = this.state			
		if (this.state.ratesamounts == "") {
			alert('Please select manage rates and amounts type, Add, Update or Delete')
			return false
		}
		if(this.state.ratesamounts==1)  {
			if(!employeeUpdate) {
				alert('Please select province for the rates and amounts')
				return false
			}			
			if(!yearclaimcode) {
				alert('Please select year for the rates and amounts')
				return false
			}
			if(!indexvalue) {
				alert('Please select whether index applied or not')
				return false
			}		
			if(!total10) {
				alert('Please enter value for rate in first income range')
				return false
			}						
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,	
				employeeUpdate:this.state.employeeUpdate,
				yearclaimcode: this.state.yearclaimcode,
				indexvalue:this.state.indexvalue,				
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,total03:this.state.total03,total04:this.state.total04,total05:this.state.total05,total06:this.state.total06,total07:this.state.total07,total08:this.state.total08,total09:this.state.total09,
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,total13:this.state.total13,total14:this.state.total14,total15:this.state.total15,total16:this.state.total16,total17:this.state.total17,total18:this.state.total18,total19:this.state.total19,
				total20:this.state.total20,total21:this.state.total21,total22:this.state.total22,total23:this.state.total23,total24:this.state.total24,total25:this.state.total25,total26:this.state.total26,total27:this.state.total27,total28:this.state.total28,total29:this.state.total29
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.ratesamounts==2)  {
			if(!employeeUpdate) {
				alert('Please select province for the claim code updating')
				return false
			}			
			if(!yearclaimcode) {
				alert('Please select year for the claim code updating')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,	
				employeeUpdate:this.state.employeeUpdate,
				yearclaimcode: this.state.yearclaimcode,
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,total03:this.state.total03,total04:this.state.total04,total05:this.state.total05,total06:this.state.total06,total07:this.state.total07,total08:this.state.total08,total09:this.state.total09,
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,total13:this.state.total13,total14:this.state.total14,total15:this.state.total15,total16:this.state.total16,total17:this.state.total17,total18:this.state.total18,total19:this.state.total19,
				total20:this.state.total20,total21:this.state.total21,total22:this.state.total22,total23:this.state.total23,total24:this.state.total24,total25:this.state.total25,total26:this.state.total26,total27:this.state.total27,total28:this.state.total28,total29:this.state.total29
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.ratesamounts==3)  {
			if(!employeeUpdate) {
				alert('Please select province')
				return false
			}			
			if(!yearclaimcode) {
				alert('Please select year')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,	
				employeeUpdate:this.state.employeeUpdate,
				yearclaimcode: this.state.yearclaimcode,
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (					
			<div>      			
				<CommonHeader titles="Tax Rates"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Rates and Amounts</h2></label>							
						<select name="ratesamounts" id="ratesamounts" style={{width:'150px'}} value={this.state.ratesamounts} onChange={this.handleManageClaimChange}>
							<option value="">--Manage Type--</option>
							<option value="1">Add New Rates and Amounts</option>															
							<option value="2">Update Rates and Amounts</option>												
							<option value="3">Remove Rates and Amounts</option>		
						</select><br/><br/>	
					</div>	
					{(()=>{
						{/*add new Rates and Amounts*/}
						if(this.state.ratesamounts == "1") {return(<div>
							<label><h2>Select Province</h2></label>							
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Province--</option>									
									{this.state.Provs.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
								</select>
							<br/><br/>
							{(()=>{if(this.state.employeeUpdate != "") {return(<div>
									<label><h2>Select Year</h2></label>							
									<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleYearChange}>
										<option value="">--Select Year--</option>																			
										{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}									
									</select>
									<br/><br/>	
									{(()=>{if(this.state.yearclaimcode != "") {return(<div>
											<label><h2>Apply Index</h2></label>							
											<select id="indexvalue" name="indexvalue" value = {this.state.indexvalue} style={{width:'160px'}} onChange={this.handleIndexChange}>
												<option value="">--Apply Index--</option>									
												<option value="No">No Index</option>									
												{this.state.Provs.filter(item=>item[0]==this.state.employeeUpdate).map((sub,index) => (<option value={sub[0]} key={index}>{'Apply '+sub[1]+' Index'}</option>))}
											</select>
											<h2>{this.state.indexvalue=="No"? "No Index Applied, please enter rates and amounts by yourself":""}</h2><br/><br/>
											<label><h2>Rates(R,V), Income Threshold (A) and Constants (K,KP)</h2></label><br/><br/>
										</div>)}
									})()}	
								</div>)}
							})()}								
						{/*update claim code*/}
						</div>)} else if(this.state.ratesamounts == "2") {return(<div>
							<label><h2>Select Province</h2></label>							
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Province--</option>									
									{this.state.Provs.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
								</select>
							<br/><br/>
							{(()=>{if(this.state.employeeUpdate != "") {return(<div>
									<label><h2>Select Year</h2></label>							
										<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleUpClaimChange}>
											<option value="">--Select Year--</option>									
											{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}																		
										</select>
									<br/><br/>											
								</div>)}
							})()}				
						<h2>{(this.state.employeeUpdate !="" && this.state.yearclaimcode !="" && this.state.indexvalue =="No") ? this.state.yearclaimcode +" Tax rates and amounts are not entered yet":""}</h2><br/><br/>
						<h2>{(this.state.employeeUpdate !="" && this.state.yearclaimcode !="" && this.state.indexvalue !="No") ? "Please modify tax rates and amounts and update":""}</h2><br/><br/>
						{/*remove claim code*/}
						</div>)} else if(this.state.ratesamounts == "3") {return(<div>
							<label><h2>Select Province</h2></label>							
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Province--</option>									
									{this.state.Provs.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
								</select>
							<br/><br/>
							{(()=>{if(this.state.employeeUpdate != "") {return(<div>
									<label><h2>Select Year</h2></label>							
										<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleUpClaimChange}>
											<option value="">--Select Year--</option>									
											<option value={lastyear}>{lastyear}</option>									
											<option value={thisyear}>{thisyear}</option>									
											<option value={nextyear}>{nextyear}</option>									
										</select>
									<br/><br/>											
								</div>)}
							})()}
						<h2>{(this.state.ratesamounts==3 && this.state.yearclaimcode=="") ? "":"Please look at the claim code values carefully and determine the removing"}</h2><br/><br/>						
						</div>)} 
					})()}
					{/*Add/update/remove rates and amounts*/}
					{(()=>{if(this.state.ratesamounts != "" && this.state.employeeUpdate !="" && this.state.yearclaimcode !="") {return(<div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'120px'}}>Parameters</h2>
								<h2 style={{width:'80px'}}>1st</h2>
								<h2 style={{width:'80px'}}>2nd</h2>
								<h2 style={{width:'80px'}}>3rd</h2>
								<h2 style={{width:'80px'}}>4th</h2>
								<h2 style={{width:'80px'}}>5th</h2>
								<h2 style={{width:'80px'}}>6th</h2>
								<h2 style={{width:'80px'}}>7th</h2>
								<h2 style={{width:'80px'}}>8th</h2>
								<h2 style={{width:'80px'}}>9th</h2>
								<h2 style={{width:'80px'}}>10th</h2>
							</div><br/><br/>
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'120px'}}>A</h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total00" value={this.state.total00} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total01" value={this.state.total01} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total02" value={this.state.total02} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total03" value={this.state.total03} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total04" value={this.state.total04} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total05" value={this.state.total05} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total06" value={this.state.total06} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total07" value={this.state.total07} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total08" value={this.state.total08} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total09" value={this.state.total09} onChange={this.handleChange}/></h2>
							</div><br/><br/>			
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'120px'}}>{this.state.employeeUpdate=='FD' ? 'R':'V'}</h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total10" value={this.state.total10} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total11" value={this.state.total11} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total12" value={this.state.total12} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total13" value={this.state.total13} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total14" value={this.state.total14} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total15" value={this.state.total15} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total16" value={this.state.total16} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total17" value={this.state.total17} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total18" value={this.state.total18} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total19" value={this.state.total19} onChange={this.handleChange}/></h2>
							</div><br/><br/>			
						</div>				
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'120px'}}>{this.state.employeeUpdate=='FD' ? 'K':(this.state.employeeUpdate=='NT'?'KP0':'KP')}</h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total20" value={this.state.total20} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total21" value={this.state.total21} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total22" value={this.state.total22} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total23" value={this.state.total23} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total24" value={this.state.total24} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total25" value={this.state.total25} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total26" value={this.state.total26} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total27" value={this.state.total27} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total28" value={this.state.total28} onChange={this.handleChange}/></h2>
								<h2 style={{width:'80px'}}><input type="text" style={{width:'70px'}} name="total29" value={this.state.total29} onChange={this.handleChange}/></h2>
							</div><br/><br/>			
						</div>										
						<h2>{(this.state.indexvalue!="No" && this.state.indexvalue!="" && this.state.ratesamounts==1)? "These values are obtained by "+this.state.indexyear+" rates and amounts multiplied by "+this.state.yearclaimcode+" index values. If no changes required, please click Submit button to add them to the system":""}</h2><br/><br/>
						<h1>{this.state.Resp}</h1><br/><br/>
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupTaxRatesAmounts;