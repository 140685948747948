import React from 'react'
import '../main.css'
import axios from 'axios'
import CommonHeader from '../clients/commons/Head'

class setupBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:28,Resp:'', Nodata:'',BName: '', passwords:'',delaydays:'',
					user:localStorage.getItem('tokens'),ProgName:'',names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);				
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {															
				this.setState({BName:result.data[0]})												
				this.setState({ProgName:result.data[1]})												
				this.setState({delaydays:result.data[2]})
		})		
	}	
		
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
		
	handleSubmit = event => {		
		event.preventDefault()		
		const {ProgName,BName,delaydays} = this.state
		if(!BName) {
			alert('Please enter new company name')
			return false
		}		
		if(!ProgName) {
			alert('Please enter how many months the existing passwords will be expired')
			return false
		}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,	
				BName:this.state.BName,
				ProgName:this.state.ProgName,
				delaydays:this.state.delaydays
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});				
	}
	
	render() {				
		return (		
			<div>      	
				<CommonHeader titles="System"/>
				<form onSubmit={this.handleSubmit}>										
					<label><h2>New Company Name</h2></label>
					<input id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>								
					<label><h2>Password Expired Months </h2></label>
					<input id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>					
					<label><h2>Delay Payment Days</h2></label>
					<input id="delaydays" name="delaydays" value = {this.state.delaydays} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>															
					<h1>{this.state.Resp}</h1><br/><br/>								
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupBanking;