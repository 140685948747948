import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  history,
  withRouter
} from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import { AuthContext } from "./context/auth";
import Home from "./pages/home";
import Registration from './pages/registration'
import Contact from './pages/contact'
import Login from "./login/Login";
import Logout from './login/logout';
import Thank_You from './thankyou'
import RegSub from './pages/registrationsub'
import NotYet from './pages/notregistration'
import Already from './pages/alreadyregistration'
import Failure from './submitregistration'
import Clients from "./clients/Timesheet";
import Profile from "./clients/Profile";
import Report from "./clients/Report";
import Approval from "./clients/Approval";
import Admin from "./clients/Admin";
import Management from "./clients/Management";
import Accountant from "./clients/Accountant";
import manage from "./admin/index";
import GetQuote from "./admin/quoteform";
import ForgotPWD from "./pages/forgotpwd";
import Resource from "./pages/resource";
import Privacy from "./pages/privacy";
import CreatePDF from "./pages/CreatePDF";
import SetupTable from "./pages/setupTables";
import UCSLogin from "./RegistrationClients/UCSLogin";
import UCSPassword from "./RegistrationClients/UCSPassword";
import UCSLogout from './RegistrationClients/UCSLogout';


export default function App(props) {
	const [authTokens, setAuthTokens] = useState();
    
	const setTokens = (data) => {			
		if(!data) {
			setAuthTokens(data);
		} else {						
			localStorage.setItem("tokens", JSON.stringify(data[13]));							
			localStorage.setItem("names", JSON.stringify(data[0]));	
			setAuthTokens(data);
		}
	}	
	
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>	
      <Router>		
        <div>			
			<Switch>								
				<Route exact path="/" component={Home} />
				<Route exact path="/Registration" component={Registration} />
				<Route exact path="/Contact" component={Contact} />
				<Route exact path="/login" component={Login} />								
				<Route exact path="/RegSub" component={RegSub} />	
				<Route exact path="/NotYet" component={NotYet} />	
				<Route exact path="/Already" component={Already} />				
				<Route exact path="/manage" component={manage} />	
				<Route exact path="/GetQuote" component={GetQuote} />					
				<Route exact path="/ForgotPWD" component={ForgotPWD} />					
				<Route exact path="/Resource" component={Resource} />	
				<Route exact path="/Privacy" component={Privacy} />						
				<Route exact path="/CreatePDF" component={CreatePDF} />		
				<Route exact path="/UCSLogin" component={UCSLogin} />		
				<Route exact path="/UCSPassword" component={UCSPassword} />						
				<Route exact path="/UCSLogout" component={UCSLogout} />	
				<PrivateRoute path="/Admin" component={Admin} />
				<PrivateRoute path="/Logout" component={Logout} />
				<PrivateRoute path="/Clients" component={Clients} />
				<PrivateRoute path="/Profile" component={Profile} />
				<PrivateRoute path="/Report" component={Report} />	
				<PrivateRoute path="/Management" component={Management} />	
				<PrivateRoute path="/Approval" component={Approval} />	
				<PrivateRoute path="/Accountant" component={Accountant} />
				<PrivateRoute path="/SetupTable" component={SetupTable} />	
			</Switch>
        </div>
      </Router>
    </AuthContext.Provider>
  );
}

