import React from 'react'
import {Link} from "react-router-dom";

class regSub extends React.Component {
	render() {    		
		return (<div>
			<h2><b>Registration Completed</b></h2>			
			<br/>
			<p>Your company has been registered in our system already</p><br/>
			<p>Please go to <Link to = "/Login" style={{color:'blue'}}><b>Login page</b></Link> to login to the system</p><br/>			
			<p>Thank you for your cooperation!</p><br/>					
		</div>)
	}
}


export default regSub