import CommonHeader from '../clients/commons/Head'
import React from 'react'
const axios = require("axios");

class ReactUploadImage extends React.Component {
    constructor(props) {
        super(props);
        this.state ={file: null,msg:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onFormSubmit(e){		
        e.preventDefault();		
        const formData = new FormData();		
        formData.append('myImage',this.state.file);
		formData.append('email',this.state.user);	
		formData.append('names',this.state.names);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        axios.post(process.env.REACT_APP_API_ENV+"api/upload.php",formData,config)
            .then((response) => {
				this.setState({msg:response.data});
            }).catch((error) => {
        });
    }
    onChange(e) {
        this.setState({file:e.target.files[0]});
    }

    render() {
        return (<div>
				<CommonHeader titles="Logo"/>
				<form onSubmit={this.onFormSubmit}>                                
					<input type="file" name="pictures" style={{width:'160px'}} onChange= {this.onChange} /><br/><br/>				
					<h2>{this.state.msg}</h2><br/><br/>
					<input type="submit" name="submit" value="Upload" />
				</form>
			</div>
        )
    }
}

export default ReactUploadImage

