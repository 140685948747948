import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupTaxAmounts extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:13,managementitem:'', Resp:'',Pro:[],employeeUpdate:'', basicbottom:'', Lyear:'',
		basichigh:'',nsadditional:'',incomerange:'',incomerangede:'',cppmax:'',cppexemption:'',cppee:'',cpper:'',
		eimax:'',eiee:'',eier:'',qpipmax:'',qpipee:'',qpiper:'',yearclaimcode:'',taxcomments:'',infirmamt:'',
		infirmre:'',infirmex:'',edupart:'',edufull:'',textpart:'',textfull:'',amtsenior:'',childamt:'',OptionTD1:'',
		cea:'',agesup:'',LineNumbers:'', DLines:'',
		user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);
		this.handleManageChange=this.handleManageChange.bind(this)
		this.handleUpdateChange=this.handleUpdateChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {				
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {					
				this.setState({Pro:result.data});				
		}).catch((error) => {
			console.log('error ' + error);   
		});			
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
		this.setState({Lyear:""})
	}	
	handleManageChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({employeeUpdate:""});	
		this.setState({yearclaimcode:''})
		this.setState({basicamount:''})
		this.setState({basicbottom:''})
		this.setState({basichigh:''})
		this.setState({nsadditional:''})
		this.setState({incomerange:''})
		this.setState({incomerangede:''})
		this.setState({cppmax:''})
		this.setState({cppexemption:''})
		this.setState({cppee:''})
		this.setState({cpper:''})
		this.setState({eimax:''})
		this.setState({eiee:''})
		this.setState({eier:''})
		this.setState({qpipmax:''})
		this.setState({qpipee:''})
		this.setState({qpiper:''})				
		this.setState({infirmamt:''})
		this.setState({infirmre:''})
		this.setState({infirmex:''})
		this.setState({edupart:''})
		this.setState({edufull:''})
		this.setState({amtsenior:''})
		this.setState({childamt:''})
		this.setState({textfull:''})
		this.setState({textpart:''})
		this.setState({cea:''})
		this.setState({agesup:''})	
		this.setState({Lyear:""})
	}
	handleUpdateChange = event=>{		
		const {name, value} = event.target		
		if(event.target.value == 'FD' || event.target.value == 'YT')  {
			this.setState({LineNumbers:3})
			this.setState({DLines:6})
		} else if(event.target.value == 'AB' || event.target.value == 'ON') {
			this.setState({LineNumbers:2})
			this.setState({DLines:4})
		} else {
			this.setState({LineNumbers:2})
			this.setState({DLines:5})
		}		
		this.setState({[name]: value})			
		this.setState({basicamount:''})
		this.setState({basicbottom:''})
		this.setState({basichigh:''})
		this.setState({nsadditional:''})
		this.setState({incomerange:''})
		this.setState({incomerangede:''})
		this.setState({cppmax:''})
		this.setState({cppexemption:''})
		this.setState({cppee:''})
		this.setState({cpper:''})
		this.setState({eimax:''})
		this.setState({eiee:''})
		this.setState({eier:''})
		this.setState({qpipmax:''})
		this.setState({qpipee:''})
		this.setState({qpiper:''})
		this.setState({infirmamt:''})
		this.setState({infirmre:''})
		this.setState({infirmex:''})
		this.setState({edupart:''})
		this.setState({edufull:''})
		this.setState({amtsenior:''})
		this.setState({childamt:''})
		this.setState({textfull:''})
		this.setState({textpart:''})
		this.setState({cea:''})
		this.setState({agesup:''})
		this.setState({taxcomments:""})		
		this.setState({Lyear:""})
		const userObject={state:event.target.value,
						  year:this.state.yearclaimcode,
						  OptionTD1:this.state.managementitem}
		axios.post(process.env.REACT_APP_API_ENV+'api/updatetd1formamounts.php',userObject)
			.then(result => {
				if (!result.data || result.data==0) {
					this.setState({taxcomments:'Sorry, no TD1 Form claim amounts in the system, please add amounts first'})
				} else  {						
					this.setState({basicamount:result.data[3]})
					this.setState({basicbottom:result.data[4]})
					this.setState({basichigh:result.data[5]})
					this.setState({nsadditional:result.data[2]})
					this.setState({incomerange:result.data[8]})
					this.setState({incomerangede:result.data[9]})
					this.setState({cppmax:result.data[10]})
					this.setState({cppexemption:result.data[6]})
					this.setState({cppee:result.data[7]})
					this.setState({cpper:result.data[12]})
					this.setState({eimax:result.data[13]})
					this.setState({eiee:result.data[14]})
					this.setState({eier:result.data[15]})
					this.setState({qpipmax:result.data[11]})
					this.setState({qpipee:result.data[16]})
					this.setState({qpiper:result.data[17]})							
					this.setState({infirmamt:result.data[18]})		
					this.setState({infirmre:result.data[19]})		
					this.setState({infirmex:result.data[20]})		
					this.setState({edupart:result.data[21]})		
					this.setState({edufull:result.data[22]})		
					this.setState({textfull:result.data[28]})		
					this.setState({textpart:result.data[29]})		
					this.setState({amtsenior:result.data[26]})		
					this.setState({childamt:result.data[27]})		
					this.setState({cea:result.data[30]})
					this.setState({agesup:result.data[31]})		
					this.setState({Lyear:result.data[0]})	
				} 
			})				
		this.setState({Resp:""});		
	}
	handleYearChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({employeeUpdate:''})
		this.setState({Resp:""});
	}
	handleSubmit = event => {		
		event.preventDefault()		
		const {managementitem,basicamount,employeeUpdate,basicbottom,basichigh,nsadditional,incomerange,incomerangede,
				cppmax,cppexemption,cppee, cpper,eimax, eiee,eier,qpipmax,qpipee,qpiper,yearclaimcode,infirmamt,
				infirmre,infirmex,edupart,edufull,textpart,textfull,amtsenior,childamt,cea,agesup} = this.state			
		if (this.state.managementitem == "") {
			alert('Please select action type for the tax amounts: Add, Update or Delete')
			return false
		}		
		if(this.state.managementitem==1)  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}
			if(!basicamount ) {
				alert('Please enter age amount')
				return false
			}						
			if(!basicbottom) {
				alert('Please enter threshold when net income exceeds, age amount will be reduced')
				return false
			}						
			if(!basichigh) {
				alert('Please enter threshold when net income exceeds, age amount will be eliminated')
				return false
			}	
			if(!nsadditional && (this.state.employeeUpdate=='FD'||this.state.employeeUpdate=='YT')) {
				alert('Please enter Canada Caregive Credit for age 18 and below')
				return false
			}				
			if(!incomerange && (this.state.employeeUpdate=='FD' || this.state.employeeUpdate=='YT'|| this.state.employeeUpdate=='ON'|| this.state.employeeUpdate=='BC') ) {
				alert('Please enter Canada Caregive Credit for age 18 and over ')
				return false
			}
			if(!incomerangede && (this.state.employeeUpdate=='FD' || this.state.employeeUpdate=='YT'|| this.state.employeeUpdate=='ON'|| this.state.employeeUpdate=='BC') ) {			
				alert('Please enter threshold amount when net income exceeds, Canada Caregive Credit amount will be reduced')
				return false
			}
			if(!qpipmax && (this.state.employeeUpdate=='FD' || this.state.employeeUpdate=='YT'|| this.state.employeeUpdate=='ON'|| this.state.employeeUpdate=='BC') ) {			
				alert('Please enter threshold amount when net income exceeds, Canada Caregive Credit amount will be eliminated')
				return false
			}
			if(!cppmax && (this.state.employeeUpdate=='FD'||this.state.employeeUpdate=='YT')) {			
				alert('Please enter threshold amount in TD1 Form Line 10, Credit could be claimed if net income is below this threshold')
				return false
			}
			if(!cppexemption) {
				alert('Please enter pension income amount')
				return false
			}			
			if(!cppee) {
				alert('Please enter disability amount')
				return false
			}
			if(!cpper) {
				alert('Please enter Spouse or others maximum amount')
				return false
			}
			if(!eimax) {
				alert('Please enter threshold when net income exceeds, spouse amount will be reduced')
				return false
			}
			if(!eiee) {
				alert('Please enter threshold when net income exceeds, spouse amount will be eligible')
				return false
			}
			if(this.state.employeeUpdate != 'FD' && this.state.employeeUpdate != 'YT' && this.state.employeeUpdate != 'ON' && this.state.employeeUpdate !='BC') {						
				if(!eier) {						
					alert('Please enter Caregiver amount for in-home care of parent or grandparent age 65+, or of infirm adult relative')
					return false
				}						
				if(!qpipee) {									
					alert('Please enter threshold when net income exceeds, Caregive credit will be reduced')
					return false
				}
				if(!qpiper) {									
					alert('Please enter threshold when net income exceeds, Caregive credit will be eliminated')
					return false
				}	
				if(!infirmamt) {						
					alert('Please enter Infirm Dependant Amount (age 18+)')
					return false
				}						
				if(!infirmre) {									
					alert('Please enter threshold when net income exceeds, Infirm dependant amount will be reduced')
					return false
				}
				if(!infirmex) {									
					alert('Please enter threshold when net income exceeds, infirm dependant amount will be eliminated')
					return false
				}	
			}
			if(this.state.employeeUpdate == 'NL' || this.state.employeeUpdate == 'PE' || this.state.employeeUpdate == 'NS' || this.state.employeeUpdate =='MB' || this.state.employeeUpdate =='NT' || this.state.employeeUpdate =='NU') {						
				if(!edupart) {									
					alert('Please enter enducation amount, part time per month')
					return false
				}	
				if(!edufull) {									
					alert('Please enter enducation amount, full time per month')
					return false
				}	
			}
			if(this.state.employeeUpdate == 'NU') {						
				if(!textpart) {									
					alert('Please enter textbooks amount, part time per month')
					return false
				}	
				if(!textfull) {									
					alert('Please enter textbooks amount, full time per month')
					return false
				}	
			}
			if(this.state.employeeUpdate == 'SK') {										
				if(!amtsenior) {									
					alert('Please enter Senior Supplementary Amount (age 65+)')
					return false
				}	
				if(!childamt) {									
					alert('Please enter Child Amount for Dependant Children Under 19')
					return false
				}	
			}			
			if(this.state.employeeUpdate == 'FD'||this.state.employeeUpdate=='YT') {										
				if(!cea) {									
					alert('Please enter Canada Employment Amount')
					return false
				}					
			}		
			if(!agesup && this.state.employeeUpdate=='NS') {									
				alert('Please enter Age Amount Supplement')
				return false
			}	
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				basicamount:this.state.basicamount,
				basicbottom:this.state.basicbottom,
				basichigh:this.state.basichigh,
				nsadditional:this.state.nsadditional,
				incomerange:this.state.incomerange,
				incomerangede:this.state.incomerangede,
				cppmax:this.state.cppmax,
				cppexemption:this.state.cppexemption,
				cppee:this.state.cppee,
				cpper:this.state.cpper,
				eimax:this.state.eimax,
				eiee:this.state.eiee,
				eier:this.state.eier,
				qpipmax:this.state.qpipmax,
				qpipee:this.state.qpipee,
				qpiper:this.state.qpiper,
				infirmamt:this.state.infirmamt,
				infirmre:this.state.infirmre,
				infirmex:this.state.infirmex,
				edufull:this.state.edufull,
				edupart:this.state.edupart,
				textpart:this.state.textpart,
				textfull:this.state.textfull,
				amtsenior:this.state.amtsenior,
				childamt:this.state.childamt,
				cea:this.state.cea,
				agesup:this.state.agesup
				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.managementitem==2)  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}
			if(!basicamount ) {
				alert('Please enter age amount')
				return false
			}						
			if(!basicbottom) {
				alert('Please enter threshold when net income exceeds, age amount will be reduced')
				return false
			}						
			if(!basichigh) {
				alert('Please enter threshold when net income exceeds, age amount will be eliminated')
				return false
			}	
			if(!nsadditional && (this.state.employeeUpdate=='FD'||this.state.employeeUpdate=='YT')) {
				alert('Please enter Canada Caregive Credit for age 18 and below')
				return false
			}				
			if(!incomerange && (this.state.employeeUpdate=='FD' || this.state.employeeUpdate=='YT'|| this.state.employeeUpdate=='ON'|| this.state.employeeUpdate=='BC') ) {
				alert('Please enter Canada Caregive Credit for age 18 and over ')
				return false
			}
			if(!incomerangede && (this.state.employeeUpdate=='FD' || this.state.employeeUpdate=='YT'|| this.state.employeeUpdate=='ON'|| this.state.employeeUpdate=='BC') ) {			
				alert('Please enter threshold amount when net income exceeds, Canada Caregive Credit amount will be reduced')
				return false
			}
			if(!qpipmax && (this.state.employeeUpdate=='FD' || this.state.employeeUpdate=='YT'|| this.state.employeeUpdate=='ON'|| this.state.employeeUpdate=='BC') ) {			
				alert('Please enter threshold amount when net income exceeds, Canada Caregive Credit amount will be eliminated')
				return false
			}
			if(!cppmax && (this.state.employeeUpdate=='FD'||this.state.employeeUpdate=='YT')) {			
				alert('Please enter threshold amount in TD1 Form Line 10, Credit could be claimed if net income is below this threshold')
				return false
			}
			if(!cppexemption) {
				alert('Please enter pension income amount')
				return false
			}			
			if(!cppee) {
				alert('Please enter disability amount')
				return false
			}
			if(!cpper) {
				alert('Please enter Spouse or others maximum amount')
				return false
			}
			if(!eimax) {
				alert('Please enter threshold when net income exceeds, spouse amount will be reduced')
				return false
			}
			if(!eiee) {
				alert('Please enter threshold when net income exceeds, spouse amount will be eligible')
				return false
			}
			if(this.state.employeeUpdate != 'FD' && this.state.employeeUpdate != 'YT' && this.state.employeeUpdate != 'ON' && this.state.employeeUpdate !='BC') {						
				if(!eier) {						
					alert('Please enter Caregiver amount for in-home care of parent or grandparent age 65+, or of infirm adult relative')
					return false
				}						
				if(!qpipee) {									
					alert('Please enter threshold when net income exceeds, Caregive credit will be reduced')
					return false
				}
				if(!qpiper) {									
					alert('Please enter threshold when net income exceeds, Caregive credit will be eliminated')
					return false
				}	
				if(!infirmamt) {						
					alert('Please enter Infirm Dependant Amount (age 18+)')
					return false
				}						
				if(!infirmre) {									
					alert('Please enter threshold when net income exceeds, Infirm dependant amount will be reduced')
					return false
				}
				if(!infirmex) {									
					alert('Please enter threshold when net income exceeds, infirm dependant amount will be eliminated')
					return false
				}	
			}
			if(this.state.employeeUpdate == 'NL' || this.state.employeeUpdate == 'PE' || this.state.employeeUpdate == 'NS' || this.state.employeeUpdate =='MB' || this.state.employeeUpdate =='NT' || this.state.employeeUpdate =='NU') {						
				if(!edupart) {									
					alert('Please enter enducation amount, part time per month')
					return false
				}	
				if(!edufull) {									
					alert('Please enter enducation amount, full time per month')
					return false
				}	
			}
			if(this.state.employeeUpdate == 'NU') {						
				if(!textpart) {									
					alert('Please enter textbooks amount, part time per month')
					return false
				}	
				if(!textfull) {									
					alert('Please enter textbooks amount, full time per month')
					return false
				}	
			}
			if(this.state.employeeUpdate == 'SK') {										
				if(!amtsenior) {									
					alert('Please enter Senior Supplementary Amount (age 65+)')
					return false
				}	
				if(!childamt) {									
					alert('Please enter Child Amount for Dependant Children Under 19')
					return false
				}	
			}			
			if(this.state.employeeUpdate == 'FD'||this.state.employeeUpdate=='YT') {										
				if(!cea) {									
					alert('Please enter Canada Employment Amount')
					return false
				}					
			}		
			if(!agesup && this.state.employeeUpdate=='NS') {									
				alert('Please enter Age Amount Supplement')
				return false
			}	
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				basicamount:this.state.basicamount,
				basicbottom:this.state.basicbottom,
				basichigh:this.state.basichigh,
				nsadditional:this.state.nsadditional,
				incomerange:this.state.incomerange,
				incomerangede:this.state.incomerangede,
				cppmax:this.state.cppmax,
				cppexemption:this.state.cppexemption,
				cppee:this.state.cppee,
				cpper:this.state.cpper,
				eimax:this.state.eimax,
				eiee:this.state.eiee,
				eier:this.state.eier,
				qpipmax:this.state.qpipmax,
				qpipee:this.state.qpipee,
				qpiper:this.state.qpiper,
				infirmamt:this.state.infirmamt,
				infirmre:this.state.infirmre,
				infirmex:this.state.infirmex,
				edufull:this.state.edufull,
				edupart:this.state.edupart,
				textpart:this.state.textpart,
				textfull:this.state.textfull,
				amtsenior:this.state.amtsenior,
				childamt:this.state.childamt,
				cea:this.state.cea,
				agesup:this.state.agesup
				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.managementitem==3)  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}	
			const userObject={			
				adminitem:this.state.adminitem,	
				names:this.state.names,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {				
		return (		
			<div>      					
				<CommonHeader titles="TD1 Claim Amount"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>TD1 Form Claim Amounts</h2></label>							
						<select name="managementitem" id="managementitem" style={{width:'150px'}} value={this.state.managementitem} onChange={this.handleManageChange}>
							<option value="">--TD1 Form Claim Amount--</option>
							<option value="1">Set up TD1Form Claim Amounts</option>															
							<option value="2">Update TD1Form Claim Amounts</option>												
							<option value="3">Remove TD1Form Claim Amounts</option>		
						</select><br/><br/>	
						{(()=>{if(this.state.managementitem != "") {return(<div>						
								<label><h2>Select Year</h2></label>							
								<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleYearChange}>
									<option value="">--Select Year--</option>									
									{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}									
								</select><br/><br/>
								{(()=>{						
									if(this.state.yearclaimcode !="" ) {return(<div>
										<label><h2>Choose Federal or Province</h2></label>							
										<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleUpdateChange}>
											<option value="">--Federal or Province--</option>									
											{this.state.Pro.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
										</select><br/><br/>
									</div>)}
								})()}
							</div>)} 							
						})()}												
					</div>	
					{(()=>{
						{/*set up/update/remove claim amounts*/}
						if(this.state.managementitem != "" && this.state.employeeUpdate != "") {return(<div>														
							<h1>{this.state.managementitem == 2 || this.state.managementitem == 3 ? this.state.taxcomments:""}</h1><br/><br/>
							<h1>{this.state.managementitem == 1 && this.state.Lyear != "" ? "These values are obtained from "+this.state.Lyear+", please update them for current year":(this.state.managementitem == 1 ? "Please enter the values":"")}</h1><br/><br/>
							{(()=>{	{/*Only FD and YT*/}
								if(this.state.employeeUpdate == "FD"||this.state.employeeUpdate == "YT") {return(<div>											
									<div className="forinput">
										<label className="labelname">2. Caregive Amount-18-: </label>
										<input type="text" name ="nsadditional" id="nsadditional" style={{width:'150px'}} value={this.state.nsadditional} onChange={this.handleChange} />
									</div><br/>							
								</div>)} 							
							})()}	
							<div className="forinput">
								<label className="labelname">{this.state.LineNumbers}. Age Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>					
							<div className="forinput">
								<label className="labelname">{this.state.LineNumbers}.1. Income: </label>
								<input type="text" name ="basicbottom" id="basicbottom" style={{width:'150px'}} value={this.state.basicbottom} onChange={this.handleChange} />
							</div><br/>	
							<div className="forinput">
								<label className="labelname">{this.state.LineNumbers}.2. Income: </label>
								<input type="text" name ="basichigh" id="basichigh" style={{width:'150px'}} value={this.state.basichigh} onChange={this.handleChange} />
							</div><br/>	
							{(()=>{
								{/*Only NS*/}
								if(this.state.employeeUpdate == "NS") {return(<div>											
									<div className="forinput">
										<label className="labelname">2-1 Age Amount Supplement: </label>
										<input type="text" name ="agesup" id="agesup" style={{width:'150px'}} value={this.state.agesup} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">2-1.1 Income: </label>
										<input type="text" name ="textpart" id="textpart" style={{width:'150px'}} value={this.state.textpart} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">2-1.2 Income: </label>
										<input type="text" name ="textfull" id="textfull" style={{width:'150px'}} value={this.state.textfull} onChange={this.handleChange} />
									</div><br/>	
								</div>)} 							
							})()}
							{/*Only SK*/}
							{(()=>{if(this.state.employeeUpdate == "SK") {return(<div>
									<div className="forinput">
										<label className="labelname">3. Senior Amount: </label>
										<input type="text" name ="amtsenior" id="amtsenior" style={{width:'150px'}} value={this.state.amtsenior} onChange={this.handleChange} />
									</div><br/>																															
							</div>)}})()}
							<div className="forinput">
								<label className="labelname">{this.state.employeeUpdate == "SK" ? this.state.LineNumbers+2:this.state.LineNumbers+1}. Pension Income Amount: </label>
								<input type="text" name ="cppexemption" id="cppexemption" style={{width:'150px'}} value={this.state.cppexemption} onChange={this.handleChange} />
							</div><br/>		
							{(()=>{	{/*FD and YT*/}
								if(this.state.employeeUpdate == "FD" || this.state.employeeUpdate == "YT" || this.state.employeeUpdate == "BC" || this.state.employeeUpdate == "NB") {return(<div>
									<div className="forinput">
										<label className="labelname">{this.state.employeeUpdate == "BC" || this.state.employeeUpdate == "NB" ? 4:5}. Tuition: </label>										
										<input type="text" name ="cea" id="cea" style={{width:'150px'}} value={this.state.cea} onChange={this.handleChange} />
									</div><br/>	
								</div>)}
							})()}	
							{(()=>{
								{/*MB*/}
								if(this.state.employeeUpdate == "MB" || this.state.employeeUpdate == "NL" || this.state.employeeUpdate == "NS" || this.state.employeeUpdate == 'NT' || this.state.employeeUpdate == 'NU' || this.state.employeeUpdate == 'PE') {return(<div>
									<div className="forinput">
										<label className="labelname">4. Tuition: </label>										
										<input type="text" name ="cea" id="cea" style={{width:'150px'}} value={this.state.cea} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">4.1. Part Time Amount: </label>
										<input type="text" name ="edupart" id="edupart" style={{width:'150px'}} value={this.state.edupart} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">4.2. Full Time Amount: </label>
										<input type="text" name ="edufull" id="edufull" style={{width:'150px'}} value={this.state.edufull} onChange={this.handleChange} />
									</div><br/>			
								</div>)} 							
							})()}	
							{(()=>{
								{/*NU*/}
								if(this.state.employeeUpdate == "NU") {return(<div>
									<div className="forinput">
										<label className="labelname">4.3. Part Time Textbooks: </label>
										<input type="text" name ="textpart" id="textpart" style={{width:'150px'}} value={this.state.textpart} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">4.4. Full Time Textbooks: </label>
										<input type="text" name ="textfull" id="textfull" style={{width:'150px'}} value={this.state.textfull} onChange={this.handleChange} />
									</div><br/>								
								</div>)} 							
							})()}	
							<div className="forinput">
								<label className="labelname">{this.state.DLines}. Disability Amount: </label>
								<input type="text" name ="cppee" id="cppee" style={{width:'150px'}} value={this.state.cppee} onChange={this.handleChange} />
							</div><br/>	
							{(()=>{{/*AB now */}
								if(this.state.employeeUpdate == "AB" || this.state.employeeUpdate == "ON"){return(<div>
									<div className="forinput">
										<label className="labelname">5. Spouse Amount: </label>
										<input type="text" name ="cpper" id="cpper" style={{width:'150px'}} value={this.state.cpper} onChange={this.handleChange} />
									</div><br/>
									<div className="forinput">
										<label className="labelname">5.1. Income: </label>
										<input type="text" name ="eimax" id="eimax" style={{width:'150px'}} value={this.state.eimax} onChange={this.handleChange} />
									</div><br/>																			
									<div className="forinput">
										<label className="labelname">5.2. Income: </label>
										<input type="text" name ="eiee" id="eiee" style={{width:'150px'}} value={this.state.eiee} onChange={this.handleChange} />
									</div><br/>	
								</div>)} 							
							})()}
							{(()=>{{/*FD and YT*/}
								if(this.state.employeeUpdate == "FD"||this.state.employeeUpdate == "YT") {return(<div>
									<div className="forinput">
										<label className="labelname">7, 8 & 9. Suspport Amount: </label>
										<input type="text" name ="cpper" id="cpper" style={{width:'150px'}} value={this.state.cpper} onChange={this.handleChange} />
									</div><br/>
									<div className="forinput">
										<label className="labelname">7.1. Income: </label>
										<input type="text" name ="eimax" id="eimax" style={{width:'150px'}} value={this.state.eimax} onChange={this.handleChange} />
									</div><br/>																			
									<div className="forinput">
										<label className="labelname">7.2. Income: </label>
										<input type="text" name ="eiee" id="eiee" style={{width:'150px'}} value={this.state.eiee} onChange={this.handleChange} />
									</div><br/>									
									<div className="forinput">
										<label className="labelname">10. Caregive Amount-18+: </label>
										<input type="text" name ="incomerange" id="incomerange" style={{width:'150px'}} value={this.state.incomerange} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">10.1. Income: </label>
										<input type="text" name ="incomerangede" id="incomerangede" style={{width:'150px'}} value={this.state.incomerangede} onChange={this.handleChange} />
									</div><br/>								
									<div className="forinput">
										<label className="labelname">10.2. Income: </label>
										<input type="text" name ="qpipmax" id="qpipmax" style={{width:'150px'}} value={this.state.qpipmax} onChange={this.handleChange} />
									</div><br/>		
									<div className="forinput">
										<label className="labelname">10.3. Income: </label>
										<input type="text" name ="cppmax" id="cppmax" style={{width:'150px'}} value={this.state.cppmax} onChange={this.handleChange} />
									</div><br/>	
								</div>)} 							
							})()}								
							{(()=>{{/*BC, MB, NB, NL, NS, NT, NU, PE AND SK*/}
								if(this.state.employeeUpdate != "FD" && this.state.employeeUpdate != "YT" && this.state.employeeUpdate != "AB" && this.state.employeeUpdate != "ON" && this.state.employeeUpdate !="QC"){return(<div>
									<div className="forinput">
										<label className="labelname">6 & 7. Support Amount: </label>
										<input type="text" name ="cpper" id="cpper" style={{width:'150px'}} value={this.state.cpper} onChange={this.handleChange} />
									</div><br/>
									<div className="forinput">
										<label className="labelname">6.1. Income: </label>
										<input type="text" name ="eimax" id="eimax" style={{width:'150px'}} value={this.state.eimax} onChange={this.handleChange} />
									</div><br/>																			
									<div className="forinput">
										<label className="labelname">6.2. Income: </label>
										<input type="text" name ="eiee" id="eiee" style={{width:'150px'}} value={this.state.eiee} onChange={this.handleChange} />
									</div><br/>									
								</div>)} 							
							})()}
							{/*BC only*/}
							{(()=>{if(this.state.employeeUpdate == "BC"){return(<div>
								<div className="forinput">
									<label className="labelname">8. Caregive Amount: </label>
									<input type="text" name ="qpipmax" id="qpipmax" style={{width:'150px'}} value={this.state.qpipmax} onChange={this.handleChange} />
								</div><br/>
							</div>)}})()}
							{/*SK only*/}
							{(()=>{if(this.state.employeeUpdate == "SK") {return(<div>
								<div className="forinput">
									<label className="labelname">8. Child Amount: </label>
									<input type="text" name ="childamt" id="childamt" style={{width:'150px'}} value={this.state.childamt} onChange={this.handleChange} />
								</div><br/>	
							</div>)}})()}							
							{/*NS only*/}
							{(()=>{if(this.state.employeeUpdate == "NS") {return(<div>
								<div className="forinput">
									<label className="labelname">6 & 7-1. Supplement: </label>
									<input type="text" name ="qpipmax" id="qpipmax" style={{width:'150px'}} value={this.state.qpipmax} onChange={this.handleChange} />
								</div><br/>
								<div className="forinput">
									<label className="labelname">6-1.1 Income: </label>
									<input type="text" name ="amtsenior" id="amtsenior" style={{width:'150px'}} value={this.state.amtsenior} onChange={this.handleChange} />
								</div><br/>																						
								<div className="forinput">
									<label className="labelname">6-1.2. Income: </label>
									<input type="text" name ="childamt" id="childamt" style={{width:'150px'}} value={this.state.childamt} onChange={this.handleChange} />
								</div><br/>	
							</div>)}})()}					
							{(()=>{
								{/*not in FD, BC, ON, YT*/}
								if(this.state.employeeUpdate != "FD" && this.state.employeeUpdate != "BC" && this.state.employeeUpdate != "ON" && this.state.employeeUpdate != "YT") {return(<div>
									<div className="forinput">
										<label className="labelname">{this.state.employeeUpdate == "SK" ? this.state.DLines+4:this.state.DLines+3}. Caregive Amount: </label>
										<input type="text" name ="eier" id="eier" style={{width:'150px'}} value={this.state.eier} onChange={this.handleChange} />
									</div><br/>														
									<div className="forinput">
										<label className="labelname">{this.state.employeeUpdate == "SK" ? this.state.DLines+4:this.state.DLines+3}.1. Income:</label>
										<input type="text" name ="qpipee" id="qpipee" style={{width:'150px'}} value={this.state.qpipee} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">{this.state.employeeUpdate == "SK" ? this.state.DLines+4:this.state.DLines+3}.2. Income: </label>
										<input type="text" name ="qpiper" id="qpiper" style={{width:'150px'}} value={this.state.qpiper} onChange={this.handleChange} />
									</div><br/>											
									<div className="forinput">
										<label className="labelname">{this.state.employeeUpdate == "SK" ? this.state.DLines+5:this.state.DLines+4}. Caregive Amount-18+: </label>
										<input type="text" name ="infirmamt" id="infirmamt" style={{width:'150px'}} value={this.state.infirmamt} onChange={this.handleChange} />
									</div><br/>
									<div className="forinput">
										<label className="labelname">{this.state.employeeUpdate == "SK" ? this.state.DLines+5:this.state.DLines+4}.1. Income: </label>
										<input type="text" name ="infirmre" id="infirmre" style={{width:'150px'}} value={this.state.infirmre} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">{this.state.employeeUpdate == "SK" ? this.state.DLines+5:this.state.DLines+4}.2. Income: </label>
										<input type="text" name ="infirmex" id="infirmex" style={{width:'150px'}} value={this.state.infirmex} onChange={this.handleChange} />
									</div><br/>
								</div>)} 							
							})()}	
							<h1>{this.state.Resp}</h1><br/><br/>						
						</div>)} 
					})()}					
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupTaxAmounts;