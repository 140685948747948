import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";

function PrivateRoute({ component: Component, ...rest }) {
  let authTokens = useAuth();

  if (authTokens === undefined || authTokens.authTokens === undefined){
	console.log('Login token is missing');
	authTokens = localStorage.getItem("tokens");
  }
  
  return (
    <Route
      {...rest}
      render={props =>
        authTokens ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;