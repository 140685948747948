/* eslint-disable import/first */
import React from 'react'
import '../main.css'
import MenuBar from '../common/Menu'
import PrivacyForm from '../common/privacyform'
import PrimaryFooter from '../common/PrimaryFooter'
import BusinessName from '../common/BusinessName'
import BusinessBanner from '../common/BusinessBanner'


class RegistrationTitle extends React.Component {	  
	constructor(props) {
		super(props);	
	}

	render() {				
		return(
			<div className="title" style={{textAlign:'center'}}>
				<h1>Privacy Policy</h1>
			</div>
		)
	}
}


function GenericPage() {
	return(		
		<div className="generic">
			<div className="panel">	  
				<RegistrationTitle />											
				<PrivacyForm />
			</div>
		</div>		
	)
}

function MainPage(){
	return(
		<div className="page">
			<GenericPage />
			
		</div>		
	)
}

export default function EmployeeLogin() {
	return(
		<div>
			<MenuBar />
			<BusinessName />			
			<BusinessBanner />
			<MainPage />
			<PrimaryFooter />
		</div>		
	)
}