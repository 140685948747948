import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import CommonHeader from '../clients/commons/Head'

class setupBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:25,program:'', ProgName:'', ProjName:'', Resp:'', Nodata:'',freqs:[],selectedLName:'',
			LName: '', BName: '', defaultpay:'',payfreqs:'',starting:'',payperiod:[], monthlyprice:[],
			mid:'',mservice:'',memp:'',mfee:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);		
		this.handleCampusChange=this.handleCampusChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/frequency.php', para)
			.then(result => {												
				this.setState({freqs:result.data})								
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/deletepaymentperiod.php')
			.then(result => {				
				this.setState({Nodata:result.data})
				this.setState({payperiod:result.data})										
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/paytypeprice.php')
			.then(result => {				
				this.setState({monthlyprice:result.data})													
		})	
		
	}			
	
	handleCampusChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({BName:''})
		if(event.target.value !=="") {
			axios.post(process.env.REACT_APP_API_ENV+'api/setupsystemprice.php')
				.then(result => {		
					this.setState({BName:result.data[0]})
					this.setState({ProgName:result.data[1]})
					this.setState({LName:result.data[2]})
					this.setState({ProjName:result.data[3]})
					this.setState({payfreqs:result.data[4]})					
				})			
		} 
		this.setState({Resp:""});
	}	
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	
	

	StartingPoint =(Freq) =>{			
		const LB=[]	
		if(Freq==1||Freq==2) {			
			for(let i=0;i<10;i++) {
				LB[i]=<option value={dayjs().day(0).add(i+1,'week').format("YYYY-MM-DD")}>{dayjs().day(0).add(i+1,'week').format("YYYY-MM-DD")}</option>;
			}
		} else if(Freq==3||Freq==4) {
			for(let i=0;i<10;i++) {
				LB[i]=<option value={dayjs().date(1).add(i+1,'month').format("YYYY-MM-DD")}>{dayjs().date(1).add(i+1,'month').format("YYYY-MM-DD")}</option>
			}
		} 			
		return LB
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {program,ProgName, ProjName, LName, BName, payfreqs, mid, mservice, memp,mfee} = this.state					
		if (this.state.program == "") {
				alert('Please select manage rate type, Add or Update?')
				return false
		}
		if(this.state.program == "1" || this.state.program=="2")  {			
			if(!BName) {
				alert('Please enter rate for each payment each employee')
				return false
			}
			if(!ProgName) {
				alert('Please enter least charging rate for each payment')
				return false
			}					
			if(!LName) {
				alert('Please enter coupon amount, if no coupon, please enter 1')
				return false
			}									
			if(!ProjName) {
				alert('Please enter coupon starting date, if no coupon, please leave the date without any change')
				return false
			}					
			if(!payfreqs) {
				alert('Please enter coupon ending date, if no coupon, please leave the date without any change')
				return false
			}
			if(mid != "") {
				if(!mservice) {
					alert('Please select service payment type')
					return false
				}
				if(!memp) {
					alert('Please enter employe number')
					return false
				}
				if(!mfee) {
					alert('Please enter service fee')
					return false
				}
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				LName: this.state.LName,
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				BName: this.state.BName,
				payfreqs:this.state.payfreqs,
				mid:this.state.mid,
				mservice:this.state.mservice,
				memp:this.state.memp,
				mfee:this.state.mfee
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.program == "3")  {						
			if(!mid) {
				alert('Please select service payment type')
				return false				
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,	
				program:this.state.program,				
				mid:this.state.mid
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} 
	}
	
	render() {				
		return (		
			<div>      	
				<CommonHeader titles="System Price"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage System Price</h2></label>							
						<select name="program" id="program" style={{width:'150px'}} value={this.state.program} onChange={this.handleCampusChange}>
							<option value="">--Manage Payment Periods--</option>
							<option value="1">Set Up New Price</option>						
							<option value="2">Update System Price</option>																														
							<option value="3">Delete Monthly System Price</option>
						</select><br/>
						<h2>{this.state.program == "1" ? "If price is existing in the system, new price will update the existing one":""}</h2>
						<br/><br/>		
					</div>
					{(()=>{if(this.state.program !== "" && this.state.program !== "3") {return(<div>
						<label><h2>Per Employee Per Payment Rate</h2></label>
						<input id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} onChange={this.handleChange} />
						<br/><br/>								
						<label><h2>At Least Charging Fee Per Payment</h2></label>
						<input id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange} />
						<br/><br/>																	
						<label><h2>Coupon</h2></label>
						<input id="LName" name="LName" value = {this.state.LName} style={{width:'160px'}} onChange={this.handleChange} />
						<br/><p>If no coupon, set to 1</p><br/>	
						<label><h2>Coupon Starting Date <small>(YYYY-MM-DD)</small></h2></label>
						<input id="ProjName" name="ProjName" value = {this.state.ProjName} style={{width:'160px'}} onChange={this.handleChange} maxLength="10" />
						<br/><br/>								
						<label><h2>Coupon Ending Date<small>(YYYY-MM-DD)</small></h2></label>
						<input id="payfreqs" name="payfreqs" value = {this.state.payfreqs} style={{width:'160px'}} onChange={this.handleChange}  maxLength="10"/>
						<br/><br/>
						<div className="label-wrap" style={{textAlign:'center'}}>
							<div className="label">	
								<table>
									<thead>
										<tr>											
											<th style={{width:'200px'}}>No.</th>
											<th style={{width:'200px'}}>Service Type</th>
											<th style={{width:'200px'}}>Employee</th>
											<th style={{width:'200px'}}>Service Fee</th>											
										</tr>
									</thead><br/>
									<tbody>																			
									{this.state.monthlyprice.slice(0, this.state.monthlyprice.length).map((item, index) => {
										return (
											<tr><td>{item[0]}</td>
												<td>{item[1]}</td>
												<td>&le;{item[2]}</td>
												<td>${item[3]}</td>												
											</tr>
									)})}
								
									</tbody>
								</table>
							</div>				
						</div><br/>		
						<div>
							<label style={{width: '200px',display:'inline-block',textAlign:'center'}}><b>No.</b></label>
							<label style={{width: '200px',display:'inline-block',textAlign:'center'}}><b>Servce Type</b></label>
							<label style={{width: '200px',display:'inline-block',textAlign:'center'}}><b>Employee Number</b></label>
							<label style={{width: '200px',display:'inline-block',textAlign:'center'}}><b>Service Fee</b></label>
							</div>
						<div>
							<input type="text" name="mid" style={{width: '200px',border:'1px solid #4195fc',marginLeft:'5px'}} value={this.state.mid} onChange={this.handleChange}/>
							
							<select name="mservice" style={{width: '200px',border:'1px solid #4195fc',marginLeft:'5px'}} value={this.state.mservice} onChange={this.handleChange}>
								<option value="">--Select Service Type--</option>
								<option value="Monthly">--Monthly--</option>						
								<option value="Employee">--Employee--</option>																														
							</select>
							<input type="text" name="memp" style={{width: '200px',border:'1px solid #4195fc',marginLeft:'5px'}} value={this.state.memp} onChange={this.handleChange}/>
							<input type="text" name="mfee" style={{width: '200px',border:'1px solid #4195fc',marginLeft:'5px'}} value={this.state.mfee} onChange={this.handleChange}/>
							</div><br/><br/>												
					</div>)}})()}	
					{(()=>{if(this.state.program == "3") {return(<div>
						<h2>Select Payment Category</h2>
							<select name="mid" style={{width: '200px',border:'1px solid #4195fc'}} value={this.state.mid} onChange={this.handleChange}>
								<option value="">--Select--</option>
								{this.state.monthlyprice.map((sub,index) => (<option value={sub[0]} key={index}>{sub[0]+ ' - ' +sub[1]+' - ' + sub[2] + ' - ' + sub[3]}</option>))}																																
							</select><br/><br/>
					</div>)}})()}	
					<h1>{this.state.Resp}</h1><br/><br/>
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupBanking;