import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import CommonHeader from '../clients/commons/Head'

class profileForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {checkid:'', Comments:[], user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
	}

	componentDidMount() {
		const para={user:this.state.user,names:this.state.names}
		axios.post(process.env.REACT_APP_API_ENV+'api/currentProfile.php', para)
			.then(result => {				
			if (result.status === 200) {							
				this.setState({Comments:result.data})						
				this.setState({checkid:(result.data[12]).substring(8,12)})							
			}
		})
	}	
	
	
	render() {		
		return (
			<div>
				<CommonHeader titles="Profile"/>
				<h2 ><b>Name:</b> {'   '}{this.state.Comments[0]} {' '} {this.state.Comments[1]}</h2><br/><br/>				
				<h2 ><b>SIN:</b> {'   '}{this.state.Comments[2]}</h2><br/><br/>				
				<h2 ><b>Address:</b> {'  '}{this.state.Comments[4]}{' '}{this.state.Comments[5]} {' '}{this.state.Comments[6]} {' '} {this.state.Comments[7]}{' '} {this.state.Comments[8]}</h2><br/><br/>
				<h2 ><b>Contact Phone:</b> {'  '}{this.state.Comments[3]}</h2><br/><br/>						
				<h2 ><b>Contact Email:</b>{'  '}{this.state.Comments[13]}</h2><br/><br/>						
				<h2 ><b>Bank:</b> {'   '}{this.state.Comments[9]}</h2><br/><br/>
				<h2 ><b>Branch Transit Number:</b>{' '}{this.state.Comments[10]}</h2><br/><br/>
				<h2 ><b>Checking Account Number:</b>{' '}{'xxxxxxxx'+this.state.checkid}</h2><br/><br/>
				{(()=>{if(this.state.Comments[16] != 0) {return(<div>									
					<h2 ><b>Pension Plan Memeber Rate:</b>{' '}{this.state.Comments[16]}</h2><br/><br/>
				</div>)}})()}
				{(()=>{if(this.state.Comments[17] != 0) {return(<div>									
					<h2 ><b>Union Member Rate:</b>{' '}{this.state.Comments[17]}</h2><br/><br/>
				</div>)}})()}
				<h2 ><b>Salary Rate:</b>{' $'}{this.state.Comments[18]}</h2>
				<h2><small>{this.state.Comments[18] ==0 ? "Please check with your manager to update your $0 salary rate":""}</small></h2><br/><br/>								
			</div>
		)
	}
}

export default profileForm;