import React from 'react'
import '../main.css'
import axios from 'axios'
import CommonHeader from '../clients/commons/Head'

class setupBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:3,program:'', ProgName:'', ProjName:'', Industry:'', Resp:'', Nodata:'',SubC:[],selectedLName:'',
			LName: '', SName: props.SName, BName: '', FINID: '', BTransit: '', CNumber: '', singleBank:'',programLists:[],
			defaultpay:'',projLists:[],UpdatingBank:[],UpdatingProgram:[], selectedUpdate:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);		
		this.handleCampusChange=this.handleCampusChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}		
		this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
		axios.post(process.env.REACT_APP_API_ENV+'api/branchupdates.php', para)
			.then(result => {												
				this.setState({SubC:result.data})								
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/bankupdates.php', para)
			.then(result => {							
				this.setState({UpdatingBank:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/findindustry.php', para)
			.then(result => {							
				this.setState({Industry:result.data})								
		})	
		axios.post(process.env.REACT_APP_API_ENV+'api/programlists.php', para)
			.then(result => {						
				this.setState({programLists:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/projectlists.php', para)
			.then(result => {						
				this.setState({projLists:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/programupdate.php', para)
			.then(result => {						
				this.setState({UpdatingProgram:result.data})								
		})	
	}	
	componentWillUnmount() {
		clearInterval(this.interval);
	}
	handleBankNameChange = event => {
		this.setState({BName: event.target.value})	
		this.setState({BTransit:this.state.UpdatingBank.filter(sub=>sub[0] == event.target.value).map(sub=>sub[5])})
		this.setState({CNumber:this.state.UpdatingBank.filter(sub=>sub[0] == event.target.value).map(sub=>sub[6])})
	}
	handleBankChange = event => {		
		this.setState({singleBank: event.target.value});	
		axios.post(process.env.REACT_APP_API_ENV+'api/singlebank.php?ID='+ event.target.value)
			.then(result => {							
				this.setState({Nodata:result.data})
				this.setState({singBName:result.data})								
				this.setState({BName:result.data[2]})								
				this.setState({BTransit:result.data[3]})	
				this.setState({CNumber:result.data[4]})
				this.setState({LName:result.data[5]})					
			})
		this.setState({Resp:""});
	}
	
	handleCampusChange = event => {
		this.setState({ProgName:""})
		this.setState({ProjName:""})
		this.setState({selectedLName:""})
		this.setState({singleBank:""})
		this.setState({LName: ""})	
		this.setState({BName: ""})
		this.setState({SName: ""})
		this.setState({program: event.target.value})	
		this.setState({Resp:""});
	}	
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	
	handleSubsidyChange = event => {
		this.setState({LName: event.target.value})	
		this.setState({BName:""})
	}
	
	handleLNameChange = event => {		
		this.setState({selectedLName: event.target.value});			
		axios.post(process.env.REACT_APP_API_ENV+'api/singlebranch.php?BID='+event.target.value)
			.then(result => {			
				this.setState({Nodata:result.data})
				this.setState({singlebranch:result.data})								
				this.setState({SName:result.data[3]})								
				this.setState({CName:result.data[5]})	
				this.setState({ISIC:result.data[7]})	
				this.setState({CArea:(result.data[6]).substring(0,3)})	
				this.setState({Cthree:(result.data[6]).substring(4,7)})	
				this.setState({CPhone:(result.data[6]).substring(8,12)})	
				this.setState({CAddress:result.data[8]})	
				this.setState({CCity:result.data[9]})	
				this.setState({CProvince:result.data[10]})	
				this.setState({postcode1:(result.data[11]).substring(0,3)})	
				this.setState({postcode2:(result.data[11]).substring(4,7)})	
			})			
		this.setState({Resp:""});
	}
		
	handleSubmit = event => {		
		event.preventDefault()		
		const {program,ProgName, ProjName, LName, BName, BTransit,SName,CName, CNumber,defaultpay} = this.state					
		if (this.state.program == "") {
				alert('Please select manage program type, Add, Update or Delete?')
				return false
		}
		if(this.state.program == "0")  {			
			if(!ProgName) {
				alert('Please enter your '+((this.state.Industry=='O') ? 'Campus': 'Program')+' name here')
				return false
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,			
				ProgName:this.state.ProgName,				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.program == "1")  {			
			if(!ProgName) {
				alert('Please select '+((this.state.Industry=='O') ? 'Campus': 'Program'))
				return false
			}
			if(!ProjName) {
				alert('Please select '+((this.state.Industry=="O") ? 'Program': 'Project')+' which belongs to the '+((this.state.Industry=="O") ? 'Campus': 'Program'))
				return false
			}		
			if(this.state.selectedLName == "") {
				alert('Please select your subsidiary for the selected '+ ((this.state.Industry=='O') ? 'Campus': 'Program'))
				return false
			}
			if(this.state.singleBank == "") {
				alert('Please select your charging bank account for this new ' + ((this.state.Industry=="O") ? 'Campus and ': 'Program and')  + ((this.state.Industry=="O") ? 'Program': 'Project'))
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				LName: this.state.selectedLName,
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				BName: this.state.singleBank								
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.program == "2")  {			
			if(!ProgName) {
				alert('Please select '+((this.state.Industry=='O') ? 'Campus': 'Program'))
				return false
			}
			if(!ProjName) {
				alert('Please select '+((this.state.Industry=="O") ? 'Program': 'Project')+' which belongs to the '+((this.state.Industry=="O") ? 'Campus': 'Program'))
				return false
			}		
			if(this.state.LName == "") {
				alert('Please select your subsidiary for this updating')
				return false
			}
			if(!BName) {
				alert('Please select your bank accout for this updating')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				SName:this.state.selectedUpdate,				
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				LName: this.state.LName,
				BName: this.state.BName
								
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.program == "3")  {
			if(this.state.SName == "") {
				alert('Please select your your '+ ((this.state.Industry=='O') ? 'Campus': 'Program') + ' and ' + ((this.state.Industry=='O') ? 'Program': 'Project'))
				return false
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				SName:this.state.SName,				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.program == "4")  {			
			if(!ProgName) {
				alert('Please select '+((this.state.Industry=='O') ? 'Campus': 'Program')+' for the new '+ ((this.state.Industry=='O') ? 'Program': 'Project'))
				return false
			}			
			if(!ProjName) {
				alert('Please enter new '+ ((this.state.Industry=='O') ? 'Program': 'Project'))
				return false
			}	
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,			
				ProgName:this.state.ProgName,				
				ProjName:this.state.ProjName,				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.program == "5")  {			
			if(!defaultpay) {
				alert('Please select '+((this.state.Industry=='O') ? 'Campus': 'Program')+' or ' +((this.state.Industry=='O') ? 'Program': 'Project')+ ' to delete')
				return false
			}	
			if (this.state.defaultpay == 1) {
				if(!ProgName) {
						alert('Please select '+((this.state.Industry=='O') ? 'Campus': 'Program')+' to delete')
						return false
				}			
			} else if (this.state.defaultpay == 2) {
				if(!ProgName) {
						alert('Please select '+((this.state.Industry=='O') ? 'Campus': 'Program')+' to delete')
						return false
				}			
				if(!ProjName) {
					alert('Please select '+ ((this.state.Industry=='O') ? 'Program': 'Project') + ' to delete')
					return false
				}	
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,			
				ProgName:this.state.ProgName,				
				ProjName:this.state.ProjName,
				defaultpay:this.state.defaultpay
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {				
		const indname = (this.state.Industry == "O") ? "Campus": "Program"	
		const projname = (this.state.Industry == "O") ? "Program": "Project"			
		const BankTr =(this.state.program == "1" && this.state.adminitem =="3") ? ((this.state.singleBank=="") ? "":"Branch Transit Number: " + this.state.BTransit):
					   ((this.state.program == "2" && this.state.adminitem =="3") ? ((this.state.BName==""||this.state.LName=="") ? "":"Branch Transit Number: " + this.state.BTransit):(""))
		const BankCh =(this.state.program == "1" && this.state.adminitem =="3") ? ((this.state.singleBank=="") ? "":"Checking Account Number: xxxxxxxx" + JSON.stringify(this.state.CNumber).slice(-6,-2)):
					   ((this.state.program == "2" && this.state.adminitem =="3") ? ((this.state.BName==""||this.state.LName=="") ? "":"Checking Account Number: xxxxxxxx" + JSON.stringify(this.state.CNumber).slice(-6,-2)):(""))					   			
		return (		
			<div>      					
				<CommonHeader titles={indname}/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage {indname} and {projname}</h2></label>							
						<select name="program" id="program" style={{width:'150px'}} value={this.state.program} onChange={this.handleCampusChange}>
							<option value="">--Manage {indname} and {projname}--</option>
							<option value="0">Add New {indname}</option>		
							<option value="4">Add New {projname}</option>	
							<option value="5">Remove {indname} or {projname}</option>	
							<option value="1">Set Up Cost Center</option>						
							<option value="2">Update Cost Center</option>												
							<option value="3">Delete Cost Center</option>												
						</select><br/><br/>							
					</div>
					{(()=>{
					{/*to set up new program*/}
						if(this.state.program == "0") {return(<div>
							<label><h2>Add New {indname}</h2></label>
							<p>You can add as many as {indname} here and you can set up the link among your 
							subsidiary, {indname}, {projname} and bank account</p><br/>							
							<label><h2>New {indname} Name:</h2></label>
							<input type="text" name="ProgName" id="ProgName" value = {this.state.ProgName} onChange={this.handleChange} />
							<br/><br/>							
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*to add new project*/}
						</div>)} else if(this.state.program == "4") {return(<div>
							<label><h2>Add New {projname}</h2></label>
							<p>You can add as many as {projname} for the selected {indname} here</p><br/>							
							<label><h2>Choose {indname} for new {projname}:</h2></label>							
							<select id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select {indname} --</option>									
								{this.state.programLists.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}			
							</select><br/><br/>								
							<label><h2>New {projname} Name:</h2></label>
							<input type="text" name="ProjName" id="ProjName" value = {this.state.ProjName} onChange={this.handleChange} />
							<br/><br/>
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*to remove added program and project*/}
						</div>)} else if(this.state.program == "5") {return(<div>
							<label><h2>Delete {indname} or {projname}:</h2></label>
							<select id="defaultpay" name="defaultpay" value = {this.state.defaultpay} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									<option value="1">{indname}</option>
									<option value="2">{projname}</option>								
							</select><br/><br/>											
						{/*to set up cost center*/}
						</div>)} else if(this.state.program == "1") {return(<div>
							<label><h2>Set up Cost Center</h2></label>
							<p>Connection among added Subsidiary, Bank account, {indname} and {projname} will be set up here</p><br/>
							<label><h2>Choose {indname}:</h2></label>
							<select id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select {indname} --</option>									
								{this.state.programLists.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}			
							</select><br/><br/>								
							<label><h2>Choose {projname}:</h2></label>							
							<select id="ProjName" name="ProjName" value = {this.state.ProjName} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select {projname} --</option>									
								{this.state.projLists.filter(item=>item[0]==this.state.ProgName).map((sub,index) => (<option value={sub[3]} key={index}>{sub[2]}</option>))}			
							</select><br/>
							<br/><br/>
							<label><h2>This selected {indname}/{projname} belongs to</h2></label>
							<select id="LName" name="LName" value = {this.state.selectedLName} style={{width:'160px'}} onChange={this.handleLNameChange}>
								<option value="">--Select Subsidiary--</option>									
								{this.state.SubC.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}																											
							</select><br/><br/>							
							<label><h2>This selected {indname} and {projname} will be charged to the following Bank Account:</h2></label>
							<select id="BName" name="BName" value = {this.state.singleBank} style={{width:'160px'}} onChange={this.handleBankChange}>
								<option value="">--Select Bank--</option>									
								{this.state.UpdatingBank.filter(sub=> sub[1] == this.state.selectedLName).map((sub,index) => (<option value={sub[0]} key={index}>{sub[4]} - Account={sub[6]}</option>))}			
							</select><br/><br/>										
							<h2>{this.state.UpdatingBank.filter(sub=> sub[1] == this.state.selectedLName).map(sub => sub[3]) == "TEST" ? "Sorry, your banking information is not set up appropriately, please update it now":""}</h2><br/>
							<label><h2>{BankTr}</h2></label><br/>
							<label><h2>{BankCh}</h2></label><br/>															
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*to update cost center*/}
						</div>)} else if(this.state.program == "2") {return(<div>
							<label><h2>Choose {indname}:</h2></label>
							<select id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select {indname} --</option>									
								{this.state.programLists.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}			
							</select><br/><br/>	
							<label><h2>Choose {projname}</h2></label>							
							<select id="ProjName" name="ProjName" value = {this.state.ProjName} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select {projname} --</option>																	
								{this.state.UpdatingProgram.filter(item=>item[11]==this.state.ProgName).map((id,index) => (<option value={id[0]} key={index}>{id[4]}</option>))}			
							</select><br/><br/>						
							<label><h2>Currently The {indname} and {projname} Belongs To</h2></label>
							<select id="LName" name="LName" value = {this.state.LName} style={{width:'160px'}} onChange={this.handleSubsidyChange}>
								<option value="">--Select Subsidiary--</option>									
								{this.state.SubC.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}											
							</select><br/><br/>							
							<label><h2>The charging Bank Account:</h2></label>
							<select id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} onChange={this.handleBankNameChange}>
								<option value="">--Select Bank--</option>									
								{this.state.UpdatingBank.filter(sub=> sub[1] == this.state.LName).map((sub,index) => (<option value={sub[0]} key={index}>{sub[4]} - Account={sub[6]}</option>))}			
							</select><br/><br/>									
							<label><h2>{BankTr}</h2></label><br/>
							<label><h2>{BankCh}</h2></label><br/>															
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*to delete program*/}
						</div>)} else if(this.state.program == "3") {return(<div>
							<label><h2>Choose {indname} and {projname} to delete</h2></label>
							<select id="SName" name="SName" value = {this.state.SName} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select {indname} and {projname}--</option>									
								{this.state.UpdatingProgram.map((id,index) => (<option value={id[0]} key={index}>{id[3]}- {id[4]}</option>))}			
							</select><br/><br/>
							<h1>{(this.state.SName == "") ? "Please select " + indname + " and " + projname + " to delete":""}</h1>							
							<h1>{this.state.Resp}</h1><br/><br/>						
						</div>)}
					})()}	
					{(()=>{if(this.state.program == "5" && this.state.defaultpay =="1") {return(<div>							
							<label><h2>Choose {indname}:</h2></label>							
							<select id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select {indname} --</option>									
								{this.state.programLists.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}			
							</select><br/>
							<p>Note: if you delete the selected {indname}, all related {projname} will be deleted as well</p><br/><br/>							
							<h1>{this.state.Resp}</h1><br/><br/>
						</div>)} else if(this.state.program == "5" && this.state.defaultpay =="2") {return(<div>							
							<label><h2>Choose {indname}:</h2></label>							
							<select id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select {indname} --</option>									
								{this.state.programLists.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}			
							</select><br/><br/>
							<label><h2>Choose {projname}:</h2></label>							
							<select id="ProjName" name="ProjName" value = {this.state.ProjName} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select {indname} --</option>									
								{this.state.projLists.filter(item=>item[0]==this.state.ProgName).map((sub,index) => (<option value={sub[3]} key={index}>{sub[2]}</option>))}			
							</select><br/>
							<p>Note: only selected {projname} will be deleted</p><br/><br/>							
							<h1>{this.state.Resp}</h1><br/><br/>
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupBanking;