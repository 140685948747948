import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import CommonHeader from '../clients/commons/Head'
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

class setupEmployees extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:5,employee:'', ProgName:'', ProjName:'', Resp:'', SubC:[], selectedLName:'',CampProg:'',ProgProj:'',
			LName: '', defaultpay:'',UpdatingProgram:[], firstname:'',lastname:'',cemail:'', manager:'',employeeRole:'', employeeType:'', 
			salaryrate:'', salarytype:'', salarytax:'', employeeUpdate:'', employeeStatus:'',Industry:'',FindManager:[],empRole:[],
			empType:[],UpdatingEmployee:[],vacationpay:4,childcare:0,alimonypay:0,roleid:'',eyear:'',emonth:'',eday:'',
			otherpay:'',otherpayrate:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);		
		this.handleProjDependChange=this.handleProjDependChange.bind(this)
		this.handleManageEmployeeChange=this.handleManageEmployeeChange.bind(this)
		this.handleLNameChange=this.handleLNameChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/branchupdates.php', para)
			.then(result => {												
				this.setState({SubC:result.data})								
		})									
		axios.post(process.env.REACT_APP_API_ENV+'api/programupdate.php', para)
			.then(result => {						
				this.setState({UpdatingProgram:result.data})								
		})	
		axios.post(process.env.REACT_APP_API_ENV+'api/findindustry.php', para)
			.then(result => {							
				this.setState({Industry:result.data})								
				if(result.data=='O') {
					this.setState({CampProg:"Campus"})
					this.setState ({ProgProj: "Program"})
				} else {
					this.setState({CampProg:"Program"})
					this.setState ({ProgProj: "Project"})
				}
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/findmanager.php', para)
			.then(result => {							
				this.setState({FindManager:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/employeerole.php')			
			.then((result) => {	
				this.setState({empRole:result.data});
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/employeetype.php')			
			.then((result) => {	
				this.setState({empType:result.data});
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/employeeupdate.php', para)
			.then(result => {							
				this.setState({UpdatingEmployee:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php', para)
			.then(result => {
				this.setState({roleid:result.data[5]})																	
		})
	}	
	handleProjDependChange = event => {
		this.setState({ProgName: event.target.value})			
		this.setState({ProjName:this.state.UpdatingProgram.filter(item=>item[0] == event.target.value).map(item=>item[4])})
		this.setState({Resp:""});
	}
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	handleManageEmployeeChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({firstname:""}) 
		this.setState({lastname:""}) 
		this.setState({cemail:""}) 
		this.setState({LName:""}) 
		this.setState({employeeRole:""})			
		this.setState({employeeType:""})
		this.setState({employeeStatus:""})
		this.setState({salarytype:""})
		this.setState({salarytax:""})			
		this.setState({selectedLName:""})
		this.setState({ProgName:""})
		this.setState({ProjName:""})
		this.setState({manager:""})
		this.setState({salaryrate:""})
		this.setState({defaultpay:""})
		this.setState({employeeUpdate:""})		
		this.setState({vacationpay:""})		
		this.setState({childcare:""})		
		this.setState({alimonypay:""})		
		this.setState({Resp:""});
	}
	
	handleLNameChange = event => {		
		this.setState({selectedLName: event.target.value});								
		this.setState({Resp:""});
	}
	handleEmployeeChange = event => {		
		this.setState({selectedUpdate:""})
		this.setState({employeeUpdate: event.target.value})	
		this.setState({LName:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[1])})
		this.setState({manager:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[6])})
		this.setState({employeeRole:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[4])})
		this.setState({employeeType:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[5])})
		this.setState({employeeStatus:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[3])})
		this.setState({salaryrate:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[9])})
		
		this.setState({otherpay:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[18])})
		this.setState({otherpayrate:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[19])})
		this.setState({eyear:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[20])})
		this.setState({emonth:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[21])})
		this.setState({eday:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[22])})
		
		this.setState({salarytype:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[10])})
		this.setState({salarytax:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[11])})				
		this.setState({defaultpay:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[15])})				
		this.setState({vacationpay:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[12])})				
		this.setState({childcare:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[13])})				
		this.setState({alimonypay:this.state.UpdatingEmployee.filter(item=>item[0] == event.target.value).map(item=>item[14])})				
		if(this.state.adminitem == 6) {			
			if(this.state.assignment == 2) {				
				this.setState({manager:this.state.AssignmentUpdate.filter(item=>item[14] == event.target.value).map(item=>item[8])})
				this.setState({salaryrate:this.state.AssignmentUpdate.filter(item=>item[14] == event.target.value).map(item=>item[13])})
				this.setState({defaultpay:this.state.AssignmentUpdate.filter(item=>item[14] == event.target.value).map(item=>item[12])})
			}
		} 
		if(this.state.adminitem == 9) {			
			this.setState({yearclaimcode:""})						
		} 
		this.setState({Resp:""});
	}
	handleSubmit = event => {		
		event.preventDefault()		
		const {employee,firstname,lastname,cemail, LName,ProgName,ProjName,manager,employeeRole, employeeType, 
				salaryrate,otherpay,otherpayrate,eyear,emonth,eday,salarytype, salarytax, defaultpay, employeeUpdate, employeeStatus,vacationpay,
				childcare,alimonypay} = this.state					
		if (this.state.employee == "") {
				alert('Please select manage employee type, Add, Update or Delete?')
				return false
		}
		if(this.state.employee == "1")  {
			if(!firstname) {
				alert('Please enter the new employee first name')
				return false
			}
			if(!lastname) {
				alert('Please enter the new employee last name')
				return false
			}
			if(!cemail){
				alert('Please enter the new employee email address as the loginID for the future use')
				return false
			} else if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(cemail))) {
				alert('Sorry, please enter correct email format')
				return false
			}
			if(this.state.selectedLName == "") {
				alert('Please select the subsidiary which the new employee belongs to')
				return false
			}
			if(!ProgName) {
				alert('Please select the '+this.state.CampProg+' which the new employee will work for')
				return false
			}	
			if(!manager) {
				alert('Please assign this new employee reporting manager')
				return false
			}	
			if(!employeeRole) {
				alert('Please select role for this new employee')
				return false
			}	
			if(this.state.employeeType == "") {
				alert('Please select employment type for this new employee')
				return false
			} 
			if(!salaryrate) {
				alert('Please set up the salary rate for this new employee')
				return false
			}	
			if(!salarytype) {
				alert('Please select salary type for this new employee')
				return false
			}	
			if(this.state.employeeType == "3") {
				if(!salarytax) {
					alert('Please determine whether salary tax is required for this new employee')
					return false
				}	
			}
			if(this.state.employeeType != "3") {
				if(!defaultpay) {
					alert('Please determine whether EI is exempt or not')
					return false
				}	
				if(!vacationpay) {
					alert('Please enter vacation pay rate for this employee')
					return false
				}
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				employee:this.state.employee,
				firstname:this.state.firstname,
				lastname:this.state.lastname,
				cemail:this.state.cemail,
				LName: this.state.selectedLName,
				ProgName:this.state.ProgName,
				manager: this.state.manager,
				employeeRole:this.state.employeeRole,
				employeeType: this.state.employeeType,				
				salaryrate: this.state.salaryrate,
				salarytype: this.state.salarytype,
				salarytax: this.state.salarytax,
				defaultpay: this.state.defaultpay,
				vacationpay:this.state.vacationpay,
				childcare:this.state.childcare,
				alimonypay:this.state.alimonypay,
				roleid:this.state.roleid,
				otherpay:this.state.otherpay,
				otherpayrate:this.state.otherpayrate
				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.employee == "2")  {			
			if(this.state.employeeUpdate == "") {
				alert('Please select the employee for updating')
				return false
			}
			if(!LName) {
				alert('Please select the subsidiary which the employee belongs to')
				return false
			}
			if(!manager) {
				alert('Please assign reporting manager to this selected employee')
				return false
			}	
			if(!employeeRole) {
				alert('Please assign role for this selected employee')
				return false
			}	
			if(this.state.employeeType == "") {
				alert('Please select employment type for this employee')
				return false
			}	
			if(!employeeStatus) {
				alert('Please select employment status for this employee')
				return false
			}	
			if(!salaryrate) {
				alert('Please set up salary rate for this employee')
				return false
			}	
			if(!eyear) {
				alert('Please select Effective year')
				return false
			}	
			if(!emonth) {
				alert('Please select Effective month')
				return false
			}	
			if(!eday) {
				alert('Please select Effective day')
				return false
			}	
			if(!salarytype) {
				alert('Please select employment type for this employee')
				return false
			}	
			if(this.state.employeeType == "3") {
				if(!salarytax) {
					alert('Please determine whether the salary tax is required for this employee')
					return false
				}	
			}
			if(this.state.employeeType != "3") {
				if(!defaultpay) {
					alert('Please determine whether EI is exempt or not')
					return false
				}
				if(!vacationpay) {
					alert('Please enter vacation pay rate for this employee')
					return false
				}
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				employee:this.state.employee,
				employeeUpdate:this.state.employeeUpdate,
				LName: this.state.LName,				
				manager: this.state.manager,
				employeeRole:this.state.employeeRole,
				employeeType: this.state.employeeType,
				employeeStatus:this.state.employeeStatus,
				salaryrate:this.state.salaryrate,				
				eyear:this.state.eyear,
				emonth:this.state.emonth,
				eday:this.state.eday,				
				salarytype:this.state.salarytype,
				salarytax:this.state.salarytax,
				defaultpay:this.state.defaultpay,
				vacationpay:this.state.vacationpay,
				childcare:this.state.childcare,
				alimonypay:this.state.alimonypay,
				roleid:this.state.roleid,
				otherpay:this.state.otherpay,
				otherpayrate:this.state.otherpayrate
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}					
		else if(this.state.employee == "3")  {			
			if(this.state.employeeUpdate == "") {
				alert('Please select the employee for updating')
				return false
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				employee:this.state.employee,
				employeeUpdate:this.state.employeeUpdate,			
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {									
		return (		
			<div>      				
				<CommonHeader titles="Employee"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Employee</h2></label>							
						<select name="employee" id="employee" style={{width:'150px'}} value={this.state.employee} onChange={this.handleManageEmployeeChange}>
							<option value="">--Manage Employee--</option>
							<option value="1">Add New Employee</option>						
							<option value="2">Update Employee Information</option>												
							<option value="3">Remove Employee</option>												
						</select><br/><br/>
					</div>
					{(()=>{
					{/*to add new employee*/}
						if(this.state.employee == "1") {return(<div>
							<label><h2>Add New Employee</h2></label>
							<p>You can add as many as employees here</p><br/>
							<div className="forinput">
								<label><h2>First Name:</h2></label>
								<input type="text" name="firstname" id="firstname" value = {this.state.firstname} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><h2>Last Name</h2></label>
								<input type="text" name="lastname" id="lastname" value = {this.state.lastname} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><h2>Email (loginID)</h2></label>
								<input type="text" name="cemail" id="cemail" value = {this.state.cemail} onChange={this.handleChange} />
							</div>
								<p>Emaill will be sent to tell the new employee what to do for the next step</p>
							<br/>
							<div className="forinput">
								<label><h2>Assign the new employee to:</h2></label>
								<select id="LName" name="LName" value = {this.state.selectedLName} style={{width:'160px'}} onChange={this.handleLNameChange}>
									<option value="">--Select Subsidiary--</option>									
									{this.state.SubC.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}			
								</select>
							</div><br/>
							<div className="forinput">
								<label><h2>and {this.state.CampProg}:</h2></label>								
								<select id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleProjDependChange}>
									<option value="">--Select --</option>
									{this.state.UpdatingProgram.filter(item=>item[1] == this.state.selectedLName).map(item=>(<option value={item[0]}>{item[3]} - {item[4]}</option>))}
								</select>
							</div><br/>
							<div className="forinput">
								<label><h2>and {this.state.ProgProj}</h2></label>
								<input type="text" name="ProjName" id="ProjName" value = {this.state.ProjName} readOnly />								
							</div><br/>	
							<div className="forinput">
								<label><h2>and Reporting Manager:</h2></label>								
								<select id="manager" name="manager" value = {this.state.manager} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									{this.state.FindManager.map(item=>(<option value={item[0]} key={item[0]}>{item[3]} {item[4]}</option>))}
								</select>
							</div><br/>
							<div className="forinput">
								<label><h2>Employee Role</h2></label>								
								<select id="employeeRole" name="employeeRole" value = {this.state.employeeRole} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									{this.state.empRole.map(item=>(<option value={item[0]} key={item[0]}>{item[1]}</option>))}
								</select>
							</div><br/>	
							<div className="forinput">
								<label><h2>Employment Type</h2></label>								
								<select id="employeeType" name="employeeType" value = {this.state.employeeType} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									{this.state.empType.map(item=>(<option value={item[0]} key={item[0]}>{item[1]}</option>))}
								</select>
							</div><br/>		
							{(()=>{if(this.state.roleid == "1") {return(<div>
								<div className="forinput">
									<label><h2>Base Salary</h2></label>
									<input type="text" name="salaryrate" id="salaryrate" value = {this.state.salaryrate} style={{width:'160px'}} onChange={this.handleChange} />								
								</div>	
								<p>Salary information need to be coordinated together with Salary type</p>
								<br/>
								<div className="forinput">
									<label><h2>Other Payment</h2></label>
									<input type="text" name="otherpay" id="otherpay" value = {this.state.otherpay} style={{width:'160px'}} onChange={this.handleChange} />								
								</div><br/>	
								<div className="forinput">
									<label><h2>Other Payment Rate</h2></label>
									<input type="text" name="otherpayrate" id="otherpayrate" value = {this.state.otherpayrate} style={{width:'160px'}} onChange={this.handleChange} />								
								</div><br/>	
								<div className="forinput">
									<label><h2>Salary Type</h2></label>								
									<select id="salarytype" name="salarytype" value = {this.state.salarytype} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Select --</option>
										{this.state.employeeType == "3" ? "":(this.state.employeeType == "1" || this.state.employeeType == "2" ? <option value="Yearly">Yearly</option>:"")}
										{this.state.employeeType != "1" ? <option value="Hourly">Hourly</option> :""}
									</select>
								</div><br/>	
								{(()=>{if(this.state.employeeType == "3") {return(<div>
									<div className="forinput">
										<label><h2>Salary + Sales Tax </h2></label>								
										<select id="salarytax" name="salarytax" value = {this.state.salarytax} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--Select --</option>
											<option value="Yes">Yes tax required</option>
											<option value="No">No tax</option>								
										</select>
									</div>	
										<p>Generally, employer pay contract Salary plus GST/HST if possible. E.g. 13% in Ontario</p>
										<br/>
								</div>)}})()}
							</div>)}})()}	
							{(()=>{if(this.state.employeeType !== "3") {return(<div>
								<div className="forinput">
									<label><h2>EI Exempt ? </h2></label>								
									<select id="defaultpay" name="defaultpay" value = {this.state.defaultpay} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Select --</option>
										<option value="1">Yes</option>
										<option value="0">No</option>								
									</select>
								</div>
									<p>Owner can request EI exempt from the Salary pay</p>
								<br/>	
								<div className="forinput">
									<label><h2>Entitled Vacation Pay Rate</h2></label>								
									<input type="text" id="vacationpay" name="vacationpay" value = {this.state.vacationpay} style={{width:'160px'}} onChange={this.handleChange} />									
								</div>
									<p>Basically vacation pay rate is 4%, if employee takes vacations, then no vacation pay, please enter 0 here</p>
								<br/>
								<div className="forinput">
									<label><h2>Child Care Expense</h2></label>								
									<input type="text" id="childcare" name="childcare" value = {this.state.childcare} style={{width:'160px'}} onChange={this.handleChange} />									
								</div>
									<p>Annual deductions such as child care expenses and support payments, requested by an employee or pensioner and authorized by a tax services office or tax centre</p>
								<br/>
								<div className="forinput">
									<label><h2>Alimony or Maintenance Payments</h2></label>								
									<input type="text" id="alimonypay" name="alimonypay" value = {this.state.alimonypay} style={{width:'160px'}} onChange={this.handleChange} />									
								</div>
									<p>Alimony or maintenance payments required by a legal document dated before May 1, 1997, to be payroll-deducted authorized by a tax services office or tax centre</p>
								<br/>
							</div>)}})()}
							<h1>{this.state.Resp}</h1><br/><br/>						
						{/*update employee information*/}
						</div>)} else if(this.state.employee == "2") {return(<div>
							<label><h2>Choose Employee to Update</h2></label>																						
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Employee--</option>									
									{this.state.UpdatingEmployee.map((sub,index) => (<option value={sub[0]} key={index}>{sub[7]} {sub[8]}</option>))}			
								</select><br/>
							<label>Newly added employees must set up their profiles first and then can be updated here by their managers</label><br/>
							<label>Here updating only applies to employee base salary, if you need change rate to a specific program, please go to section "Manage Assignment"</label>
							<br/><br/>
							<div className="forinput">
								<label><h2>The employee belongs to:</h2></label>								
								<select id="LName" name="LName" value = {this.state.LName} style={{width:'160px'}} onChange={this.handleChange}>
									{/*<option value="">--Select Subsidiary--</option> no option to chose currently*/}
									{this.state.SubC.filter(sub=>sub[0]==this.state.LName).map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}			
								</select>
							</div><br/>							
							<div className="forinput">
								<label><h2>Reporting Manager:</h2></label>								
								<select id="manager" name="manager" value = {this.state.manager} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									{this.state.FindManager.map(item=>(<option value={item[0]} key={item[0]}>{item[3]} {item[4]}</option>))}
								</select>
							</div><br/>
							<div className="forinput">
								<label><h2>Employee Role</h2></label>								
								<select id="employeeRole" name="employeeRole" value = {this.state.employeeRole} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									{this.state.empRole.map(item=>(<option value={item[0]} key={item[0]}>{item[1]}</option>))}
								</select>
							</div><br/>	
							<div className="forinput">
								<label><h2>Employment Type</h2></label>								
								<select id="employeeType" name="employeeType" value = {this.state.employeeType} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									{this.state.empType.map(item=>(<option value={item[0]} key={item[0]}>{item[1]}</option>))}
								</select>
							</div><br/>	
							<div className="forinput">
								<label><h2>Employment Status</h2></label>								
								<select id="employeeStatus" name="employeeStatus" value = {this.state.employeeStatus} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select --</option>
									<option value="0">Inactive</option>
									<option value="1">Active</option>
								</select>
							</div><br/>	
							{/*management job*/}
							{(()=>{if(this.state.roleid == "1") {return(<div>
								<div className="forinput">
									<label><h2>Base Salary</h2></label>
									<input type="text" name="salaryrate" id="salaryrate" value = {this.state.salaryrate} style={{width:'160px'}} onChange={this.handleChange} />								
								</div><p>Salary information need to be coordinated together with Salary type</p>
								<br/>
								<div className="forinput">
									<label><h2>Other Payment</h2></label>
									<input type="text" name="otherpay" id="otherpay" value = {this.state.otherpay} style={{width:'160px'}} onChange={this.handleChange} />								
								</div><br/>	
								<div className="forinput">
									<label><h2>Other Payment Rate</h2></label>
									<input type="text" name="otherpayrate" id="otherpayrate" value = {this.state.otherpayrate} style={{width:'160px'}} onChange={this.handleChange} />								
								</div><br/>	
								<div className="forinput">
									<label><h2>Effective Date</h2></label>
									<select name="eyear" id="eyear" value = {this.state.eyear} style={{width:'60px'}} onChange={this.handleChange}>
										<option value="">--Select --</option>
										<option value={thisyear}>{thisyear}</option>
										<option value={nextyear}>{nextyear}</option>	
									</select>
									<select name="emonth" id="emonth" value = {this.state.emonth} style={{width:'50px'}} onChange={this.handleChange}>
										<option value="">--Select --</option>
										<option value="01">January</option>
										<option value="02">February</option>
										<option value="03">March</option>
										<option value="04">April</option>
										<option value="05">May</option>
										<option value="06">June</option>
										<option value="07">July</option>
										<option value="08">August</option>
										<option value="09">September</option>
										<option value="10">October</option>
										<option value="11">November</option>
										<option value="12">December</option>
									</select>									
									<select type="text" name="eday" id="eday" value = {this.state.eday} style={{width:'50px'}} onChange={this.handleChange}>
										<option value="">--Select --</option>
										<option value="01">1</option>
										<option value="02">2</option>
										<option value="03">3</option>
										<option value="04">4</option>
										<option value="05">5</option>
										<option value="06">6</option>
										<option value="07">7</option>
										<option value="08">8</option>
										<option value="09">9</option>
										<option value="10">10</option>
										<option value="11">11</option>
										<option value="12">12</option>
										<option value="13">13</option>
										<option value="14">14</option>
										<option value="15">15</option>
										<option value="16">16</option>
										<option value="17">17</option>
										<option value="18">18</option>
										<option value="19">19</option>
										<option value="20">20</option>
										<option value="21">21</option>
										<option value="22">22</option>
										<option value="23">23</option>
										<option value="24">24</option>
										<option value="25">25</option>
										<option value="26">26</option>
										<option value="27">27</option>
										<option value="28">28</option>
										<option value="29">29</option>
										<option value="30">30</option>
										<option value="31">31</option>
									</select>
								</div>
								<p>All corresponding assignment rates will be updated as well and Effective date will be the start payment date. If you want to update assignment rate, please go to Manage Assignment section for the update</p>
								<br/>	
								<div className="forinput">
									<label><h2>Salary Type</h2></label>								
									<select id="salarytype" name="salarytype" value = {this.state.salarytype} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Select --</option>
										<option value="Yearly">Yearly</option>
										<option value="Hourly">Hourly</option>								
									</select>
								</div><br/>	
								{(()=>{if(this.state.employeeType == "3") {return(<div>
									<div className="forinput">
										<label><h2>Salary Sales Tax </h2></label>								
										<select id="salarytax" name="salarytax" value = {this.state.salarytax} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--Select --</option>
											<option value="Yes">Yes tax required</option>
											<option value="No">No tax</option>								
										</select>
									</div><br/>	
								</div>)}})()}
							</div>)}})()}
							{(()=>{if(this.state.employeeType !== "3") {return(<div>
								<div className="forinput">
									<label><h2>EI Exemption</h2></label>								
									<select id="defaultpay" name="defaultpay" value = {this.state.defaultpay} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Select --</option>
										<option value="1">Yes</option>
										<option value="0">No</option>								
									</select>
								</div><br/>
								<div className="forinput">
									<label><h2>Entitled Vacation Pay Rate</h2></label>								
									<input type="text" id="vacationpay" name="vacationpay" value = {this.state.vacationpay} style={{width:'160px'}} onChange={this.handleChange} />									
								</div><br/>
								<div className="forinput">
									<label><h2>Child Care Expense</h2></label>								
									<input type="text" id="childcare" name="childcare" value = {this.state.childcare} style={{width:'160px'}} onChange={this.handleChange} />									
								</div>
									<p>Annual deductions such as child care expenses and support payments, requested by an employee or pensioner and authorized by a tax services office or tax centre</p>
								<br/>
								<div className="forinput">
									<label><h2>Alimony or Maintenance Payments</h2></label>								
									<input type="text" id="alimonypay" name="alimonypay" value = {this.state.alimonypay} style={{width:'160px'}} onChange={this.handleChange} />									
								</div>
									<p>Alimony or maintenance payments required by a legal document dated before May 1, 1997, to be payroll-deducted authorized by a tax services office or tax centre</p>
								<br/>
							</div>)}})()}
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*to delete employee*/}
						</div>)} else if(this.state.employee == "3") {return(<div>
							<label><h2>Delete Employee Information from System</h2></label>																						
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Employee--</option>									
									{this.state.UpdatingEmployee.map((sub,index) => (<option value={sub[0]} key={index}>{sub[7]} {sub[8]}</option>))}			
								</select>
							<br/><br/>							
							<h1>{this.state.Resp}</h1><br/><br/>												
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupEmployees;