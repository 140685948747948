import React, {setState} from "react";
import { useAuth } from "../context/auth";
import Login from "../login/Login";


function Logout(props) {
	const { setAuthTokens } = useAuth();
	setAuthTokens(); 	
	localStorage.clear(); 	
	sessionStorage.clear(); 
	window.location.reload()
	window.location.href='/Login'	
	return false
}

export default Logout;