import React from "react";
import axios from 'axios';
import { saveAs } from 'file-saver';
import CommonHeader from '../clients/commons/Head'

class reportForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CID:'',COMP:[], chargingfee:[],commentComp:'', Resp:'', RespRoll:'',ftp:[],ftpprocess:'',
					ftpfile:'', CIDProcess:'', EIDProcess:'', STARTProcess:'', PERIODProcess:'',updatestatusforpay:'',
					rCID:'',rCIDResp:'',regComp:[], rCIDPayFreq:[],paymentperiod:'', PayStatus:'',actions:'', 
					numofclient:'',latestday:'', EmailSend:'',
					user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};				
		this.handleCompChange=this.handleCompChange.bind(this)
		this.handleStatusChange = this.handleStatusChange.bind(this)
		this.handlerCIDChange = this.handlerCIDChange.bind(this)
		this.handlePayPeriodChange = this.handlePayPeriodChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)		
					
	}

	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}			
		axios.post(process.env.REACT_APP_API_ENV+'api/findCompany.php', para)
			.then(result => {
				if(result.data[0][0] !=="No") {
					this.setState({COMP:result.data})									
				}
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/findftps.php', para)
			.then(result => {
				if(result.data[0][0] !=="No") {
					this.setState({ftp:result.data})									
				} 
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/registeredcompany.php', para)
			.then(result => {
				if(result.data[0][0] == "NO") {
					this.setState({rCIDResp:"Sorry, No registered company yet"})
				} else {
					this.setState({regComp:result.data})
					this.setState({rCIDResp:""})
				} 
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/approvaldeadline.php')
			.then(result => {
				this.setState({numofclient:result.data[0]})					
				this.setState({latestday:result.data[1]})
			})
	}
	
	handleChange = event => {					
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({Resp:''})
	}
	handleStatusChange = event => {					
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({Resp: ''})	
		this.setState({RespRoll: ''})			
		if(event.target.value === "1") {			
			const userObject={				
					user:this.state.user,
					names:this.state.names,
					CID:this.state.CIDProcess,
					EID:this.state.EIDProcess,
					PERIOD: this.state.PERIODProcess,
					START: this.state.STARTProcess
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/updatepayrollstatus.php', userObject)
				.then(result => {	
					this.setState({Resp:result.data})										
				})	
		} 	
		this.setState({EIDProcess: ''})	
		
	}
	
	handleCompChange = event => {					
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({Resp:''})
		this.setState({commentComp:''})
		if(event.target.value !=="") {			
			const userObject={				
					user:this.state.user,							
					CID:event.target.value				
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/servicefeeamount.php', userObject)
				.then(result => {	
					if(result.data[0][0] === "No"||result.data[0][0]==="") {
						this.setState({commentComp:'No company approved timesheet yet'})
					} else {
						this.setState({chargingfee:result.data})											
					}
				})		
		} 		
	}
	
	handlerCIDChange = event => {					
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({Resp:''})
		if(event.target.value !=="") {	
			const userObject={				
					user:this.state.user,							
					rCID:event.target.value				
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/cidpayfreq.php', userObject)
				.then(result => {	
					this.setState({rCIDPayFreq:result.data})
				})		
		}
	}
	
	handlePayPeriodChange = event => {					
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({Resp:''})
		if(event.target.value !=="") {	
			const userObject={				
					user:this.state.user,							
					rCID:this.state.rCID,
					paymentperiod:event.target.value
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/cidpaymentstate.php', userObject)
				.then(result => {	
					this.setState({PayStatus:result.data})
				})		
		}
	}
	
	handleSubmit = event => {
		event.preventDefault()				
		const {CID, ftpprocess,ftpfile} = this.state		
		if(this.state.ftpprocess === "") {
			alert('Please select generate or download FTP file')
			return false
		} else if(this.state.ftpprocess === "0") {	
			const userObject={
				user:this.state.user,
				names:this.state.names,
				ftpprocess: this.state.ftpprocess
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/paryrollcalculation.php',userObject)
				.then((res) => {	
					if(res.data[1] !== "") {
						this.setState({RespRoll:res.data[0]});
						this.setState({EIDProcess:res.data[1]});
						this.setState({CIDProcess:res.data[2]});
						this.setState({PERIODProcess:res.data[3]});
						this.setState({STARTProcess:res.data[4]});
						this.setState({Resp:''});						
					} else {
						this.setState({RespRoll:res.data[0]});
						this.setState({Resp:''});		
					}
			})
		} else if(this.state.ftpprocess === "1") {
			if(this.state.CID === "") {
				alert('Please select all corporations or one single corporate to display charging fees')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				CID: this.state.CID
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/generatebankingfile.php',userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
			})
		} else if(this.state.ftpprocess === "2") {
			if(this.state.ftpfile === "") {
				alert('Please select ftp file to download')
				return false
			}			
			/*
			const userObject={
				user:this.state.user,
				names:this.state.names,
				ftpprocess: this.state.ftpprocess,
				ftpfile: this.state.ftpfile
			}			
			axios.post(process.env.REACT_APP_API_ENV+'api/downloadFTP.php',)
				.then((res) => {						
					this.setState({Resp:res.data});											
			})*/
			fetch(process.env.REACT_APP_API_ENV+"api/downloadFTP.php?ftpfile="+this.state.ftpfile)
				.then(res => res.blob())
				.then(blob => saveAs(blob, this.state.ftpfile.slice(-15)))	
		} else if(this.state.ftpprocess === "6") {
			const userObject={
				user:this.state.user,
				names:this.state.names,
				CID: this.state.rCID,
				paymentperiod: this.state.paymentperiod,
				actions: this.state.actions
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/clientpayrollchecking.php',userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
			})
		} else if(this.state.ftpprocess === "7") {
			const userObject={
				user:this.state.user,
				names:this.state.names,				
				actions: this.state.actions,
				EmailSend:1				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/approvaldeadline.php',userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
			})
		}
	}
	
	render() {				
		return (
			<div>      
				<CommonHeader titles="FTP Process"/>
				<form onSubmit={this.handleSubmit}>	
					<h2>Payroll Process</h2>					
					<select name="ftpprocess" style={{width:'150px'}} value={this.state.ftpprocess} onChange={this.handleChange}>
						<option value="">--select--</option>
						<option value="7">Reminding Email Regarding Timesheet and Approval</option>
						<option value="6">Client Payroll Submit Checking</option>
						<option value="0">Calculate Payroll</option>						
						{/*<option value="3">Scheduled Payroll</option>
						<option value="1">Generate FTP File</option>						
						<option value="2">Download FTP File</option>
						Generate and Download FTP file functions can be combined together with another businesses. We can recover the functions here just removing comments if needed
						*/}
					</select><br/><br/>	
					{(()=>{if(this.state.ftpprocess ==="1") {return (<div>						
						<h2>Generate FTP Files<br/><small>(All companies FTP files generated, but show all or single company)</small></h2><br/>
						<select name="CID" style={{width:'150px'}} value={this.state.CID} onChange={this.handleCompChange}>
							<option value="">--select--</option>						
							<option value="0">All Corporations</option>
							{this.state.COMP.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}						
						</select><br/><br/>	
						<h2>{this.state.commentComp !=="" ? this.state.commentComp:""}</h2>	
					</div>)} else if(this.state.ftpprocess ==="2") {return (<div>						
						<select name="ftpfile" style={{width:'150px'}} value={this.state.ftpfile} onChange={this.handleChange}>
							<option value="">--select--</option>													
							{this.state.ftp.map((sub,index) => (<option value={sub[0]} key={index}>{sub[0]}</option>))}						
						</select><br/><br/>		
						{/*fileSaving()*/}													
						<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Download FTP File &nbsp;</button> &nbsp;&nbsp;											
					</div>)} else if(this.state.ftpprocess ==="6") {return (<div>	
						<h2>Select Client</h2>
						<select name="rCID" style={{width:'150px'}} value={this.state.rCID} onChange={this.handlerCIDChange}>
							<option value="">--select--</option>													
							{this.state.regComp.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}						
						</select><br/><br/>	
						{(()=>{if(this.state.rCID !=="") {return (<div>
							<h2>Select Pay Period</h2>
							<select name="paymentperiod" style={{width:'150px'}} value={this.state.paymentperiod} onChange={this.handlePayPeriodChange}>
								<option value="">--select--</option>													
								{this.state.rCIDPayFreq.map((sub,index) => (<option value={sub[1]} key={index}>{sub[2] + '~' +sub[3]}</option>))}						
							</select><br/><br/>	
							{(()=>{if(this.state.paymentperiod !=="") {return (<div>
								<h2>Select Action</h2>
								<select name="actions" style={{width:'150px'}} value={this.state.actions} onChange={this.handleChange}>
									<option value="">--select--</option>
									<option value="1">Send Email to Remind Client</option>
									<option value="2">Generate Yearly Salary Employee Hours</option>			
								</select><br/><br/>	
							</div>)}})()}
						</div>)}})()}
						<h2>{this.state.PayStatus == "" ? "":this.state.PayStatus}</h2><br/><br/>
						{(()=>{if(this.state.paymentperiod !=="" && this.state.actions !== "") {return (<div>
							<h2>{this.state.Resp==="" ? "":this.state.Resp}</h2><br/><br/>
							<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;{this.state.actions == 1 ? "Send Email":"Generate Hours"} &nbsp;</button> &nbsp;&nbsp;
						</div>)}})()}
					</div>)} else if(this.state.ftpprocess ==="7") {return (<div>	
						<h2>Checking Approval Deadline</h2><br/>
						<h3>{this.state.numofclient>0 ? "Deadline is approaching for "+this.state.numofclient+" clients. The first coming approval date is "+this.state.latestday+". Please send out reminding email within two days and on approval day only":"No deadling is approaching at this moment"}</h3><br/><br/>	
						
						<h2>Send Email</h2>
						<select name="actions" style={{width:'150px'}} value={this.state.actions} onChange={this.handleChange}>
							<option value="">--select--</option>
							<option value="1">--Send Email to Remind Client--</option>								
						</select><br/><br/>	
						
						
						<h2>{this.state.Resp==="" ? "":this.state.Resp}</h2><br/><br/>
						<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;{this.state.actions == 1 ? "Send Email":"Generate Hours"} &nbsp;</button> &nbsp;&nbsp;
						
					</div>)} else if(this.state.ftpprocess ==="0") {return (<div>												
						<h2>{this.state.RespRoll !=="" ? this.state.RespRoll:""}</h2><br/><br/>
						<h2>{this.state.Resp !=="" ? this.state.Resp:""}</h2><br/><br/>
						{(()=>{if(this.state.EIDProcess !=="") {return (<div>
							<div className="forinput">
								<label><h2>Employee ID</h2></label>
								<input type="text" name="EIDProcess" id="EIDProcess" value = {this.state.EIDProcess} onChange={this.handleChange} />								
							</div><br/><br/>
							<div className="forinput">
								<label><h2>Company ID</h2></label>
								<input type="text" name="CIDProcess" id="CIDProcess" value = {this.state.CIDProcess} onChange={this.handleChange} />								
							</div><br/><br/>
							<div className="forinput">
								<label><h2>Start Date</h2></label>
								<input type="text" name="STARTProcess" id="STARTProcess" value = {this.state.STARTProcess} onChange={this.handleChange} />
							</div><br/><br/>
							<div className="forinput">
								<label><h2>Payment Period</h2></label>
								<input type="text" name="PERIODProcess" id="PERIODProcess" value = {this.state.PERIODProcess} onChange={this.handleChange} />								
							</div><br/><br/>
							<h2>Update Payroll Status</h2>					
							<select name="updatestatusforpay" style={{width:'150px'}} value={this.state.updatestatusforpay} onChange={this.handleStatusChange}>
								<option value="">--select--</option>						
								<option value="1">Update Pay Status</option>													
							</select><br/><br/>	
						</div>)}})()}																	
						<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Calculate Payroll &nbsp;</button> &nbsp;&nbsp;					
					</div>)}})()}																	
					{(()=>{if(this.state.CID !=="" && this.state.commentComp ==="" && this.state.ftpprocess ==="1") {return (<div>						
						<div className="label-wrap" style={{textAlign:'center'}}>
							<div className="label">	
								<table>
									<thead>
										<tr>
											<th style={{width:'200px'}}>Name</th>			
											<th style={{width:'50px'}}>Period</th>		
											<th style={{width:'50px'}}>No. of Employees</th>
											<th style={{width:'50px'}}>Service Rate</th>	
											<th style={{width:'200px'}}>Amount of All Employee Net Payment</th>
											<th style={{width:'200px'}}>Amount of Service Charging Fees</th>
											<th style={{width:'200px'}}>Total Withdraw from Client Bank Accounts</th>											
										</tr>
									</thead><br/>
									<tbody>																			
									{this.state.chargingfee.map((item, index) => {
										return (<tr><td>{item[1]}</td>
											<td>{item[2]}</td>
											<td>{item[3]}</td>
											<td>{item[4]}</td>
											<td>{item[6]}</td>
											<td>{item[5]}</td>
											<td>{item[7]}</td>
										  </tr>
										  )})}
									</tbody>
								</table>
							</div>				
						</div><br/><br/>
						<h2>{this.state.Resp==="" ? "":this.state.Resp}</h2><br/><br/>					
						<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Generate FTP File &nbsp;</button> &nbsp;&nbsp;					
					</div>)}})()}							
				</form>			
			</div>
		)
	}
}




export default reportForm;

