import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupTaxRatesAmounts extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:19,ratesamounts:'',Resp:'', Lyear:'',
			total00:'',total01:'',total02:'',total03:'',total04:'',total05:'',total06:'',
			total10:'',total11:'',total12:'',total13:'',total14:'',total15:'',total16:'',
			total07:'',total08:'',total09:'',total010:'',total011:'',total012:'',total013:'',total014:'',
			total17:'',total18:'',total19:'',total120:'',total121:'',total122:'',total123:'',total124:'',
			employeeUpdate:'',yearclaimcode:'',Provs:[],indexvalue:'',indexyear:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);						
		this.handleYearChange=this.handleYearChange.bind(this)
		this.handleManageClaimChange=this.handleManageClaimChange.bind(this)		
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Provs:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});			
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
		this.setState({Lyear:""});			
	}	
	handleYearChange = event=>{		
		this.setState({Resp:""});
		this.setState({Lyear:""});
		this.setState({indexvalue:""});
		this.setState({yearclaimcode:""});		
		this.setState({total00:""}); this.setState({total01:""}); this.setState({total02:""});this.setState({total03:""});this.setState({total04:""});
			this.setState({total05:""});this.setState({total06:""});this.setState({total07:""});this.setState({total08:""});
			this.setState({total09:""});this.setState({total010:""});this.setState({total011:""});this.setState({total012:""});
			this.setState({total013:""});this.setState({total014:""});
		this.setState({total10:""}); this.setState({total11:""}); this.setState({total12:""});this.setState({total13:""});this.setState({total14:""});
			this.setState({total15:""});this.setState({total16:""});this.setState({total17:""});this.setState({total18:""});		
			this.setState({total19:""});this.setState({total120:""});this.setState({total121:""});this.setState({total122:""});
			this.setState({total123:""});this.setState({total124:""});
		const {name, value} = event.target				
		this.setState({[name]: value})			
		const userObject={			
				year:event.target.value,					
				amounts: this.state.ratesamounts}
		axios.post(process.env.REACT_APP_API_ENV+'api/updatecpps.php', userObject)
			.then(result => {
				if((this.state.ratesamounts==2 || this.state.ratesamounts==3) && result.data[0][0] == 'No')
					{this.setState({indexvalue:'No'})}
				else if(this.state.ratesamounts==1 && result.data[0][0] == 'YEAR')
					{this.setState({indexvalue:'No'})}
				else if(result.data[0][0] != 'No' && result.data[0][0] != 'YEAR')	{				
					this.setState({total00:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[2]).[0]});
					this.setState({total01:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[3]).[0]}); 
					this.setState({total02:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[4]).[0]});
					this.setState({total03:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[5]).[0]});
					this.setState({total04:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[6]).[0]});
					this.setState({total05:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[7]).[0]}); 
					this.setState({total06:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[8]).[0]});					
					this.setState({total07:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[9]).[0]});
					this.setState({total08:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[10]).[0]});
					this.setState({total09:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[11]).[0]}); 
					this.setState({total010:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[12]).[0]});					
					this.setState({total011:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[13]).[0]});	
					this.setState({total012:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[14]).[0]});	
					this.setState({total013:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[15]).[0]});	
					this.setState({total014:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[16]).[0]});					
					this.setState({total10:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[2]).[0]});
					this.setState({total11:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[3]).[0]}); 
					this.setState({total12:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[4]).[0]});
					this.setState({total13:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[5]).[0]});
					this.setState({total14:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[6]).[0]});
					this.setState({total15:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[7]).[0]});
					this.setState({total16:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[8]).[0]});						
					this.setState({total17:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[9]).[0]});
					this.setState({total18:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[10]).[0]});
					this.setState({total19:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[11]).[0]}); 
					this.setState({total120:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[12]).[0]});	
					this.setState({total121:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[13]).[0]});
					this.setState({total122:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[14]).[0]});
					this.setState({total123:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[15]).[0]});
					this.setState({total124:result.data.filter(sub=>sub[1]=='QC').map(sub=>sub[16]).[0]});
					
					this.setState({Lyear:result.data.filter(sub=>sub[1]=='FD').map(sub=>sub[0]).[0]});	
				}
			})	
		this.setState({Resp:""});				
	}
	
	handleManageClaimChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({yearclaimcode:''})		
		this.setState({Resp:""});		
		this.setState({indexvalue:""});
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {ratesamounts,total00,total01,total02,total03,total04,total05,total06,total07,total08,
				total09,total010,total011,total012,total013,total014,
				total10,total11,total12,total13,total14,total15,total16,total17,total18,total19,total120,
				total121,total122,total123,total124,yearclaimcode} = this.state			
		if (this.state.ratesamounts == "") {
			alert('Please select manage CPP type, Add, Update or Delete')
			return false
		}
		if(this.state.ratesamounts==1)  {					
			if(!yearclaimcode) {
				alert('Please select year for the CPP/QPP')
				return false
			}			
		if(!total00) {
				alert('Please enter YMPE value')
				return false
			}	
		if(!total01) {
				alert('Please enter Basic Exemption value')
				return false
			}
		if(!total02) {
				alert('Please enter max contributory earnings value')
				return false
			}
		if(!total03) {
				alert('Please enter employee contribution rate')
				return false
			}
		if(!total04) {
				alert('Please enter Employee max contribution value')
				return false
			}
		if(!total05) {
				alert('Please enter Self-employed max contribution value')
				return false
			}
		if(!total06) {
				alert('Please enter YMPE before rounding value')
				return false
			}				
		if(!total10) {
				alert('Please enter YMPE value for Quebec')
				return false
			}	
		if(!total11) {
				alert('Please enter Basic Exemption value for Quebec')
				return false
			}
		if(!total12) {
				alert('Please enter max contributory earnings value for Quebec')
				return false
			}
		if(!total13) {
				alert('Please enter employee contribution rate  for Quebec')
				return false
			}
		if(!total14) {
				alert('Please enter Employee max contribution value  for Quebec')
				return false
			}
		if(!total15) {
				alert('Please enter Self-employed max contribution value  for Quebec')
				return false
			}
		if(!total16) {
				alert('Please enter YMPE before rounding value for Quebec')
				return false
			}				
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,					
				yearclaimcode: this.state.yearclaimcode,						
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,total03:this.state.total03,total04:
					this.state.total04,total05:this.state.total05,total06:this.state.total06,
					total07:this.state.total07,total08:this.state.total08,total09:this.state.total09,total010:this.state.total010,
					total011:this.state.total011,total012:this.state.total012,total013:this.state.total013,total014:this.state.total014,					
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,total13:this.state.total13,
					total14:this.state.total14,total15:this.state.total15,total16:this.state.total16,
					total17:this.state.total17,total18:this.state.total18,total19:this.state.total19,total120:this.state.total120,
					total121:this.state.total121,total122:this.state.total122,total123:this.state.total123,total124:this.state.total124
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.ratesamounts==2)  {						
			if(!yearclaimcode) {
				alert('Please select year for the CPP updating')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,					
				yearclaimcode: this.state.yearclaimcode,
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,total03:this.state.total03,total04:
					this.state.total04,total05:this.state.total05,total06:this.state.total06,
					total07:this.state.total07,total08:this.state.total08,total09:this.state.total09,total010:this.state.total010,
					total011:this.state.total011,total012:this.state.total012,total013:this.state.total013,total014:this.state.total014,
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,total13:this.state.total13,
					total14:this.state.total14,total15:this.state.total15,total16:this.state.total16,
					total17:this.state.total17,total18:this.state.total18,total19:this.state.total19,total120:this.state.total120,
					total121:this.state.total121,total122:this.state.total122,total123:this.state.total123,total124:this.state.total124
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.ratesamounts==3)  {						
			if(!yearclaimcode) {
				alert('Please select year')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,					
				yearclaimcode: this.state.yearclaimcode
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {		console.log(this.state.Lyear)		
		return (					
			<div>      		
				<CommonHeader titles="Canada Pension Plan"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage CPP and QPP</h2></label>							
						<select name="ratesamounts" id="ratesamounts" style={{width:'150px'}} value={this.state.ratesamounts} onChange={this.handleManageClaimChange}>
							<option value="">--Manage Type--</option>
							<option value="1">Add New CPP</option>															
							<option value="2">Update CPP</option>												
							<option value="3">Remove CPP</option>		
						</select><br/><br/>	
					</div>	
					{(()=>{
						{/*add/update/delete CPP values*/}
						if(this.state.ratesamounts != "") {return(<div>							
							<label><h2>Select Year {this.state.yearclaimcode + " indexvalue="+this.state.indexvalue}</h2></label>							
							<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleYearChange}>
								<option value="">--Select Year--</option>									
								{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}									
							</select><br/><br/>			
							<h2>{(this.state.indexvalue !="No" && this.state.ratesamounts==1 && this.state.yearclaimcode !="") ? "These values are obtained from "+this.state.Lyear+", please update for current year":""}</h2>
							<h2>{(this.state.indexvalue == "No" && this.state.ratesamounts==1 && this.state.yearclaimcode !="") ? "Please enter the values":""}</h2>
							<h2>{(this.state.indexvalue=="No" && this.state.ratesamounts==2 && this.state.yearclaimcode !="") ? "Sorry, No CPP in the system, please add them first":((this.state.ratesamounts==2 && this.state.yearclaimcode !="") ? "Please modify CPP & QPP and update":"")}</h2>
							<h2>{(this.state.indexvalue=="No" && this.state.ratesamounts==3 && this.state.yearclaimcode !="") ? "Sorry, No CPP in the system":((this.state.ratesamounts==3 && this.state.yearclaimcode !="") ? "Please look at these values carefully and determine the removing":"")}</h2><br/><br/>
						</div>)} 
					})()}
					{/*Add/update/remove rates and amounts*/}
					{(()=>{if(this.state.ratesamounts != "" && this.state.yearclaimcode !="") {return(<div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'120px'}}>CPP/QPP</h2>
								<h2 style={{width:'120px'}}>YMPE</h2>
								<h2 style={{width:'120px'}}>Basic Exemption</h2>
								<h2 style={{width:'120px'}}>YMCE</h2>
								<h2 style={{width:'120px'}}>Contribution Rate</h2>
								<h2 style={{width:'120px'}}>Max Contribution</h2>
								<h2 style={{width:'120px'}}>Self-Employed Max Contribution</h2>
								<h2 style={{width:'120px'}}>YMPE before rounding</h2>								
							</div><br/><br/>
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'120px'}}>CPP</h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total00" value={this.state.total00} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total01" value={this.state.total01} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total02" value={this.state.total02} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total03" value={this.state.total03} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total04" value={this.state.total04} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total05" value={this.state.total05} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total06" value={this.state.total06} onChange={this.handleChange}/></h2>								
							</div><br/><br/><br/><br/>		
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'120px'}}>QPP</h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total10" value={this.state.total10} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total11" value={this.state.total11} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total12" value={this.state.total12} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total13" value={this.state.total13} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total14" value={this.state.total14} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total15" value={this.state.total15} onChange={this.handleChange}/></h2>
								<h2 style={{width:'120px'}}><input type="text" style={{width:'80px'}} name="total16" value={this.state.total16} onChange={this.handleChange}/></h2>
							</div><br/><br/>			
						</div><br/><br/>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>CPP/QPP (from 2023)</h2>
								<h2 style={{width:'150px'}}>Base Contribution Rate</h2>
								<h2 style={{width:'150px'}}>Max Base Contribution</h2>
								<h2 style={{width:'150px'}}>First Contribution Rate</h2>
								<h2 style={{width:'150px'}}>Max First Contribution</h2>								
							</div><br/><br/>
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>CPP</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total07" value={this.state.total07} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total08" value={this.state.total08} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total09" value={this.state.total09} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total010" value={this.state.total010} onChange={this.handleChange}/></h2>							
							</div><br/><br/><br/><br/>		
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>QPP</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total17" value={this.state.total17} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total18" value={this.state.total18} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total19" value={this.state.total19} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total120" value={this.state.total120} onChange={this.handleChange}/></h2>								
							</div><br/><br/>			
						</div><br/><br/>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>CPP/QPP (from 2024)</h2>
								<h2 style={{width:'150px'}}>YAMPE</h2>
								<h2 style={{width:'150px'}}>Second Addition Contribution</h2>
								<h2 style={{width:'150px'}}>Second Addition Contribution Rate</h2>
								<h2 style={{width:'150px'}}>Max Second Contribution</h2>								
							</div><br/><br/>
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>CPP</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total011" value={this.state.total011} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total012" value={this.state.total012} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total013" value={this.state.total013} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total014" value={this.state.total014} onChange={this.handleChange}/></h2>							
							</div><br/><br/><br/><br/>		
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>QPP</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total121" value={this.state.total121} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total122" value={this.state.total122} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total123" value={this.state.total123} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'80px'}} name="total124" value={this.state.total124} onChange={this.handleChange}/></h2>								
							</div><br/><br/>			
						</div><br/><br/>
						<h1>{this.state.Resp}</h1><br/><br/>
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupTaxRatesAmounts;
