import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import CommonHeader from '../clients/commons/Head'

class setupDays extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:32, ProgName:'', BName: '',Resp:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);			
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/taxseasondays.php', para)
			.then(result => {												
				this.setState({BName:result.data})								
		})		
	}	
		
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})					
	}
		
	handleSubmit = event => {		
		event.preventDefault()		
		const {ProgName} = this.state
		if(!ProgName) {
			alert('Please enter new days')
			return false
		}											
		const userObject={
			user:this.state.user,	
			names:this.state.names,
			adminitem: this.state.adminitem,
			ProgName:this.state.ProgName,				
			BName: this.state.BName								
		}
		axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
			.then((res) => {						
				this.setState({Resp:res.data});											
			}).catch((error) => {
				console.log(error)
			});		
	} 
	
	
	render() {				
		return (		
			<div>      	
				<CommonHeader titles="Tax"/>
				<form onSubmit={this.handleSubmit}>					
					<h2>Update Tax Season Days</h2>
					<input id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} readOnly />
					<br/><br/>								
					<h2>New Days</h2>
					<input id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange} />
					<br/><br/>														
					<h1>{this.state.Resp}</h1><br/><br/>						
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupDays;