import React from 'react'
import dayjs from 'dayjs';
import { useAuth } from "../context/auth";
import '../main.css'
import MenuBar from '../clients/commons/Menu'
import PrimaryFoot from '../clients/commons/PrimaryFooter'
import Timesheet from '../clients/TimeForm'
import Headers from '../clients/headers'


const now=dayjs().format("YYYY-MM-DD")



function Admin(props) {
  const { setAuthTokens } = useAuth();
			
  function logOut() {
    setAuthTokens();
  }
       
  return ( 
    <div className="generic">
		<div className="logins" align="center">	 			
			<Headers />
			<MenuBar /><br/><br/>			
			<h1>Time Sheet (<small>Today: {now}</small>) </h1><br/><br/>			
			<Timesheet />			
			<br/><br/><PrimaryFoot />
		</div>
	</div>
  );
}

export default Admin;