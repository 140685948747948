import React from 'react'
import '../main.css'
import axios from 'axios'
import CommonHeader from '../clients/commons/Head'


class setupEmployees extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {employee:'', Resp:'', FindManager:[],manager:'',
		user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);						
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/findapprovalmanager.php', para)
			.then(result => {							
				this.setState({FindManager:result.data})								
		})		
	}	
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {employee,manager} = this.state					
		if (this.state.employee == "") {
				alert('Please select assign or cancel approval right')
				return false
		}
		if(this.state.employee == "1")  {			
			if(!manager) {
				alert('Please select colleague for the approval right during absent')
				return false
			}	
			const userObject={
				user:this.state.user,	
				names:this.state.names,
				employee:this.state.employee,
				manager: this.state.manager
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/approvalright.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.employee == "2")  {								
			const userObject={
				user:this.state.user,				
				names:this.state.names,
				employee:this.state.employee				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/approvalright.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (		
			<div>      			
				<CommonHeader titles="Approval"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Assign Approver</h2></label>							
						<select name="employee" id="employee" style={{width:'150px'}} value={this.state.employee} onChange={this.handleChange}>
							<option value="">--select--</option>
							<option value="1">Assign Approval Right</option>						
							<option value="2">Cancel Approval Right</option>							
						</select><br/><br/>
					</div>
					{(()=>{
					{/*to assign approval*/}
						if(this.state.employee == "1") {return(<div>							
							<label><h2>Assign My Approval Right to :</h2></label>
							<select id="manager" name="manager" value = {this.state.manager} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select --</option>
								{this.state.FindManager.map(item=>(<option value={item[0]}>{item[3]} {item[4]}</option>))}
							</select><br/><br/>					
							<h1>{this.state.Resp}</h1><br/><br/>						
							<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
						{/*cancel approval right */}
						</div>)} else if(this.state.employee == "2") {return(<div>							
							<label><h2>Please click Submit to cancel assigned approval right on behalf of my absent</h2></label>							
							<br/><br/>							
							<h1>{this.state.Resp}</h1><br/><br/>												
							<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
						</div>)}
					})()}						
				</form>				
			</div>
		)
	}
}

export default setupEmployees;