import React from 'react'
import '../main.css'
import axios from 'axios'
import CommonHeader from '../clients/commons/Head'

class setupEmployees extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:29, assignment:'',ProgName:'',ProjName:'',Resp:'', pageTitle:[], employeeUpdate:'',employeeUpdates:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);		
		this.handleSubjectChange=this.handleSubjectChange.bind(this)
		this.handleAssignChange=this.handleAssignChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}							
		axios.post(process.env.REACT_APP_API_ENV+'api/findpagetitle.php', para)
			.then(result => {							
				this.setState({pageTitle:result.data})								
		})
	}		
	handleSubjectChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({Resp:""});		
		if(event.target.value !="") {
			this.setState({employeeUpdate:this.state.pageTitle.filter(sub=>sub[0]==event.target.value).map(item=>item[0])[0]})
			this.setState({ProgName:this.state.pageTitle.filter(sub=>sub[0]==event.target.value).map(item=>item[1])[0]})
			this.setState({ProjName:this.state.pageTitle.filter(sub=>sub[0]==event.target.value).map(item=>item[2])[0]})	
		}
	}
	
	handleAssignChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
		this.setState({employeeUpdate:""});
		this.setState({ProgName:""});
		this.setState({ProjName:""});
	}		
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}		

	handleSubmit = event => {		
		event.preventDefault()		
		const {assignment,employeeUpdate,employeeUpdates,ProgName,ProjName} = this.state		
		if (this.state.assignment == "") {
				alert('Please select manage assignment type, Add, Update or Delete?')
				return false
		}
		if(this.state.assignment == "1")  {
			if(!employeeUpdate) {
				alert('Please enter new page subject')
				return false
			}
			if(!ProgName) {
				alert('Please enter welcome word')
				return false
			}			
			if(!ProjName) {
				alert('Please enter page word')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				assignment:this.state.assignment,
				employeeUpdate:this.state.employeeUpdate,
				ProgName:this.state.ProgName,				
				ProjName: this.state.ProjName
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});				
		} else if(this.state.assignment == "2")  {
			if(!employeeUpdates) {
				alert('Please select employee assignment for the update')
				return false
			}	
			if(!employeeUpdate) {
				alert('Please enter updated subject name')
				return false
			}	
			if(!ProgName) {
				alert('Please enter updated welcome word')
				return false
			}		
			if(this.state.employeeUpdate !="HOME") {
				if(!ProjName) {
					alert('Please enter updated page word ')
					return false
				}
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				assignment:this.state.assignment,
				employeeUpdate:this.state.employeeUpdate,	
				employeeUpdates:this.state.employeeUpdates,
				ProgName: this.state.ProgName,
				ProjName: this.state.ProjName
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.assignment == "3")  {
			if(!employeeUpdate) {
				alert('Please select the page to delete')
				return false
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				assignment:this.state.assignment,
				employeeUpdate:this.state.employeeUpdate				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {							
		return (		
			<div>      
				<CommonHeader titles="System"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Page Titles</h2></label>							
						<select name="assignment" id="assignment" style={{width:'150px'}} value={this.state.assignment} onChange={this.handleAssignChange}>
							<option value="">--Select--</option>
							<option value="1">Add New Page Title</option>															
							<option value="2">Update Page Title</option>												
							<option value="3">Remove Page Titel</option>		
						</select><br/><br/>
					</div>
					{(()=>{
					{/*add new page*/}
						if(this.state.assignment == "1") {return(<div>
							<label><h2>Page Title Subject<small>(all uppcase)</small></h2></label>							
							<input type="text" id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>							
							<label><h2>Welcome Word</h2></label>								
							<input type="text" id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>							
							<label><h2>Page Word</h2></label>
							<input type="text" name="ProjName" id="ProjName" value = {this.state.ProjName} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>							
							<h1>{this.state.Resp}</h1><br/><br/>												
					{/*update page titel*/}
						</div>)} else if(this.state.assignment == "2") {return(<div>
							<label><h2>Select Subject {this.state.employeeUpdates}</h2></label>							
							<select id="employeeUpdates" name="employeeUpdates" value = {this.state.employeeUpdates} style={{width:'160px'}} onChange={this.handleSubjectChange}>
								<option value="">--Select Employee--</option>									
								{this.state.pageTitle.map((sub,index) => (<option value={sub[0]} key={index}>{sub[0]+ ':'+ sub[1] +' '+ sub[2]}</option>))}			
							</select><br/><br/>
							<label><h2>Page Title Subject</h2></label>							
							<input type="text" id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>														
							<label><h2>Welcome Word</h2></label>								
							<input type="text" id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>							
							<label><h2>Page Word</h2></label>
							<input type="text" name="ProjName" id="ProjName" value = {this.state.ProjName} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>							
							<h1>{this.state.Resp}</h1><br/><br/>													
					{/*delete page title*/}
						</div>)} else if(this.state.assignment == "3") {return(<div>
							<label><h2>Remove Page Title</h2></label>																						
							<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Select Employee--</option>									
								{this.state.pageTitle.map((sub,index) => (<option value={sub[0]} key={index}>{sub[0]+ ':'+ sub[1] +' '+ sub[2]}</option>))}			
							</select>
							<br/><br/>								
							<h1>{this.state.Resp}</h1><br/><br/>							
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupEmployees;