import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios';

class CopyRight extends React.Component {	  	
	constructor(props) {
		super(props);
		this.state = {CompName:[],user:localStorage.getItem('tokens')};				
	}

	componentDidMount() {				
		const para={user:this.state.user}			
		axios.post(process.env.REACT_APP_API_ENV+'api/FindClientName.php',para)
			.then(result => {				
				this.setState({CompName:result.data})													
			})		
	}
	render() {				
		return(
		
				
						<p>Copyright &copy; {this.state.CompName[1]+' '+this.state.CompName[0]}. All rights reserved. </p>
				
			
		)
	}
}


function PrimaryFooter(){
	return(
		<div className="primary-footer">
			<CopyRight />
		</div>
	)
}

export default PrimaryFooter