import React  from "react";
import '../main.css'
import axios from 'axios'
import ImageUpload from '../clients/uploadlogo'
import SetupBranch from '../clients/setupBranch'
import SetupBankAccount from '../clients/setupBankAccount'
import SetupPrograms from '../clients/setupPrograms'
import SetupEmployees from '../clients/setupEmployees'
import SetupAssignments from '../clients/setupAssignments'
import SetupTD1FormYear from '../clients/setupTD1FormYear'
import SetupPayrollYear from '../clients/setupPayrollYear'
import SetupTaxIndex from '../clients/setupTaxIndex'
import SetupClaimCodes from '../clients/setupClaimCodes'
import SetupManageItems from '../clients/setupManageItems'
import SetupPaymentItems from '../clients/setupPaymentItems'
import SetupTaxAmount from '../clients/setupTaxAmount'
import SetupTD1ClaimAmount from '../clients/setupTD1ClaimAmount'
import SetupTD1FormWording from '../clients/setupTD1FormWording'
import SetupTD1Formandwords from '../clients/setupTD1Formandwords'
import SetupTaxRatesAmounts from '../clients/setupTaxRatesAmounts'
import SetupOtherRatesAmounts from '../clients/setupOtherRatesAmounts'
import SetupCPP from '../clients/setupCPP'
import SetupEI from '../clients/setupEI'
import SetupOHP from '../clients/setupOHP'
import SetupSalesTax from '../clients/setupSalesTax'
import SetupBankingProcess from '../clients/setupBankingProcess'
import SetupPaymentDates from '../clients/setupPaymentDates'
import GenerateTD1Form from '../clients/generateTD1Form'
import ManageSystemPrice from '../clients/manageSystemPrice'
import AccountPage from '../clients/accountantPage'
import UpdateClientPrice from '../clients/updateClientPrice'
import UpdateWorkingHours from '../pages/updateWorkingHours'
import UpdateCompName from '../pages/updateCompName'
import UpdatePageTitles from '../pages/updatePageTitles'
import Uploadfiles from '../clients/uploadfiles'
import UpdateClientInfo from '../pages/updateClientInfo'
import Setuptaxdays from '../pages/setuptaxdays'
import SetupTables from "../pages/setupTables";
import CommonHeader from '../clients/commons/Head'
import ShownClients from '../clients/ShownClients'
import SetupHolidays from '../clients/SetupHolidays'
import SetupProfiles from '../clients/SetupProfiles'
import ShowEmployees from '../clients/ShowEmployees'


class addUpdate extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminjobs:'',industry: '', manageitems:[], userid:'', manageID:'',
		user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};				
		this.handleChange=this.handleChange.bind(this)
	}

	componentDidMount() {			
		const para={user:this.state.user,names:this.state.names}								
		axios.post(process.env.REACT_APP_API_ENV+'api/findindustry.php', para)
			.then(result => {							
				this.setState({Industry:result.data})								
			})				
		axios.post(process.env.REACT_APP_API_ENV+'api/manageitems.php')
			.then(result => {							
				this.setState({manageitems:result.data})								
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php', para)
			.then(result => {				
				this.setState({userid:result.data[11]})												
				this.setState({manageID:result.data[5]})		
			})
	}
	
	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})		
	}
	
	render() {				
		const indname = (this.state.Industry == "O") ? "Campus": "Program"	
		const projname = (this.state.Industry == "O") ? "Program": "Project"	
		return (
			<div>	 
				<CommonHeader titles="Management"/>
				<label><h2>Management Items</h2></label>							
					<select name="adminjobs" id="adminjobs" style={{width:'150px'}} value={this.state.adminjobs} onChange={this.handleChange}>
						<option value="">--Manage Items--</option>
						{(()=>{if(this.state.userid =="YES" && this.state.manageID=="1") {return (
							this.state.manageitems.filter(sub=>sub[2]=="YES").map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))							
						)} else if(this.state.userid =="YES" && this.state.manageID=="0") {return (
							this.state.manageitems.filter(sub=>sub[2]=="YES" && sub[0] !== "2").map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))							
						)} else if(this.state.userid =="NO" && this.state.manageID=="1") {return (
							this.state.manageitems.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))																													
						)} else if(this.state.userid =="NO" && this.state.manageID=="0") {return (
							this.state.manageitems.filter(sub=>sub[0] !=="2").map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))																													
						)}})()}
					</select><br/><br/>				
				{(()=>{
					if(this.state.adminjobs =="1") {return (
						<SetupBranch/>					
					)} else if(this.state.adminjobs =="2" && this.state.manageID=="1") {return (
						<SetupBankAccount/>					
					)} else if(this.state.adminjobs =="3") {return (<div>
						<h2>Set up program steps</h2><br/>
						<ol style={{textAlign:'left',width:'600px',color:'red'}}>
							<li>First, select Add New {indname} to set up {indname+'s'} as many as you have</li>
							<li>Second, select Add New {projname} to set up {projname+'s'} under this new {indname} as many as you have</li>
							<li>Third, you have to select Set up Cost Center to establish the connections among your company Subsidiaries, {indname+'s'}, {projname+'s'} and corresponding bank accounts </li>
							<li>Fourth, optionally update/delete your {indname}, {projname} and connections</li>
						</ol><br/>
						<SetupPrograms/>					
					</div>)} else if(this.state.adminjobs =="4") {return (<div>                  
						<label><h2>Choose Logo to Upload</h2></label>	
						<p><small>(Note: Your existing logo will be deleted from the system if you upload new one)</small></p><br/>						
						<ImageUpload />
					</div>)} else if(this.state.adminjobs =="5") {return (
						<SetupEmployees/>					
					)} else if(this.state.adminjobs =="6") {return (
						<SetupAssignments/>					
					)} else if(this.state.adminjobs =="7") {return (<div>
						<GenerateTD1Form/>
					</div>)} else if(this.state.adminjobs =="8") {return (
						<SetupTD1FormYear/>
					)} else if(this.state.adminjobs =="9") {return (
						<SetupClaimCodes/>					
					)} else if(this.state.adminjobs =="10") {return (
						<SetupTaxIndex/>		
					)} else if(this.state.adminjobs =="11") {return (
						<SetupPayrollYear/>
					)} else if(this.state.adminjobs =="12") {return (
						<SetupTaxAmount/>
					)} else if(this.state.adminjobs =="13") {return (
						<SetupTD1ClaimAmount/>
					)} else if(this.state.adminjobs =="14") {return (<div>
						{/*<SetupTD1FormWording/>*/}
						<SetupTD1Formandwords/>
					</div>)} else if(this.state.adminjobs =="15") {return (
						<SetupManageItems/>
					)} else if(this.state.adminjobs =="16") {return (
						<SetupPaymentItems/>
					)} else if(this.state.adminjobs =="17") {return (
						<SetupTaxRatesAmounts/>						
					)} else if(this.state.adminjobs =="18") {return (
						<SetupOtherRatesAmounts/>						
					)} else if(this.state.adminjobs =="19") {return (
						<SetupCPP/>						
					)} else if(this.state.adminjobs =="20") {return (
						<SetupEI/>						
					)} else if(this.state.adminjobs =="21") {return (
						<SetupOHP/>						
					)} else if(this.state.adminjobs =="22") {return (
						<SetupSalesTax/>						
					)} else if(this.state.adminjobs =="23") {return (
						<SetupBankingProcess/>						
					)} else if(this.state.adminjobs =="24") {return (
						<SetupPaymentDates/>						
					)} else if(this.state.adminjobs =="25") {return (
						<ManageSystemPrice/>						
					)} else if(this.state.adminjobs =="26") {return (
						<UpdateClientPrice/>						
					)} else if(this.state.adminjobs =="27") {return (
						<UpdateWorkingHours/>						
					)} else if(this.state.adminjobs =="28") {return (
						<UpdateCompName/>						
					)} else if(this.state.adminjobs =="29") {return (
						<UpdatePageTitles/>						
					)} else if(this.state.adminjobs =="30") {return (
						<Uploadfiles />
					)} else if(this.state.adminjobs =="31") {return (
						<UpdateClientInfo />
					)} else if(this.state.adminjobs =="32") {return (
						<Setuptaxdays />
					)} else if(this.state.adminjobs =="33") {return (
						<SetupTables />
					)} else if(this.state.adminjobs =="34") {return (
						<ShownClients />
					)} else if(this.state.adminjobs =="35") {return (
						<SetupHolidays />
					)} else if(this.state.adminjobs =="36") {return (
						<SetupProfiles />
					)} else if(this.state.adminjobs =="37") {return (
						<ShowEmployees />
					)}					
				})()}				
			</div>
		)
	}
}

export default addUpdate;