import React from 'react'
import {Link} from "react-router-dom";
import axios from 'axios'
import Google from '../clients/images/gmail_logo.gif'
import Miscroft from '../clients/images/windowslive_logo.png'
import Yahoo from '../clients/images/yahoomail_logo.gif'
import Facebook from '../clients/images/facebook.gif'
import Twitter from '../clients/images/twwtter.png'
import Linkedin from '../clients/images/linkedin.png'
import Adobe from '../clients/images/get_adobe_reader.png'
import RBC from '../clients/images/rbc_logo.png'
import TD from '../clients/images/td_logo.png'
import CIBC from '../clients/images/cibc_logo.png'
import SCOTIA from '../clients/images/scotiabank_logo.png'
import BMO from '../clients/images/bmo_logo.png'
import CommonHeader from '../common/Head'


class ResourceForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
		
	}
	
	render() {    		
		return(
			<div style={{textAlign:'center'}}>
				<CommonHeader titles="Resource"/>
				<h2><b>Federal Government of Canada</b></h2><br/>
				<Link to={{pathname:"https://www.canada.ca/en.html"}} target="_blank" >Government of Canada </Link><br/>				
				<Link to={{pathname:"https://www.canada.ca/en/services/benefits/publicpensions/cpp.html"}} target="_blank" >Canada Pension Plan</Link><br/>
				<Link to={{pathname:"https://www.canada.ca/en/revenue-agency.html"}} target="_blank" >Canada Revenue Agency</Link><br/>				
				<Link to={{pathname:"https://www.canada.ca/en/department-finance.html"}} target="_blank" >Department of Finance</Link><br/>				
				<Link to={{pathname:"https://www.canada.ca/en/treasury-board-secretariat.html"}} target="_blank" >Treasury Board of Canada Secretariat</Link><br/>
				<Link to={{pathname:"https://www.priv.gc.ca/en/"}} target="_blank" >Privacy Commissioner of Canada</Link><br/>
				<br/><br/><h2><b>Provincial Government of Canada</b></h2><br/>
				<Link to={{pathname:"https://www.alberta.ca/index.aspx"}} target="_blank" >Alberta</Link><br/>
				<Link to={{pathname:"https://www2.gov.bc.ca/gov/content/home"}} target="_blank" >British Columbia</Link><br/>
				<Link to={{pathname:"https://www.gov.mb.ca/"}} target="_blank" >Manitoba</Link><br/>
				<Link to={{pathname:"https://www.gnb.ca/"}} target="_blank" >New Brunswick</Link><br/>
				<Link to={{pathname:"https://www.gov.nl.ca/"}} target="_blank" >Newfoundland and Labrador</Link><br/>
				<Link to={{pathname:"https://www.gov.nt.ca/"}} target="_blank" >Northwest Territories</Link><br/>
				<Link to={{pathname:"https://beta.novascotia.ca/"}} target="_blank" >Nova Scotia</Link><br/>
				<Link to={{pathname:"https://www.gov.nu.ca/"}} target="_blank" >Nunavut</Link><br/>
				<Link to={{pathname:"https://www.ontario.ca/"}} target="_blank" >Ontario</Link><br/>
				<Link to={{pathname:"https://www.princeedwardisland.ca/en"}} target="_blank" >Prince Edward Island</Link><br/>
				<Link to={{pathname:"https://www.quebec.ca/"}} target="_blank" >Quebec</Link><br/>
				<Link to={{pathname:"https://www.saskatchewan.ca/"}} target="_blank" >Saskatchewan</Link><br/>
				<Link to={{pathname:"https://yukon.ca/"}} target="_blank" >Yukon</Link><br/>
				<br/><br/><h2><b>Associations</b></h2><br/>
				<Link to={{pathname:"https://www.payroll.ca/"}} target="_blank" >Payroll Association</Link><br/>
				<Link to={{pathname:"https://www.payments.ca/language"}} target="_blank" >Payment Association</Link><br/>
				<br/><br/><h2><b>Banks</b></h2><br/>
				<Link to={{pathname:"https://www1.royalbank.com/cgi-bin/rbaccess/rbunxcgi?F6=1&F7=IB&F21=IB&F22=HT&REQUEST=IBOnlineEnrollLink&LANGUAGE=ENGLISH&SPAGE=signinenre"}} target="_blank" ><img src={RBC} alt="icon" /></Link><br/>
				<Link to={{pathname:"https://easyweb.tdcanadatrust.com/"}} target="_blank" ><img src={TD} alt="icon" /></Link><br/>
				<Link to={{pathname:"https://www.cibconline.cibc.com/ebm-resources/public/banking/cibc/client/web/index.html#/signon"}} target="_blank" ><img src={CIBC} alt="icon" /></Link><br/>
				<Link to={{pathname:"https://www.scotiabank.com/global/en/global-site.html"}} target="_blank" ><img src={SCOTIA} alt="icon" /></Link><br/>
				<Link to={{pathname:"https://www.bmo.com/main/personal/bank-accounts/banking-services#online-banking"}} target="_blank" ><img src={BMO} alt="icon" /></Link><br/>
				<br/><br/><h2><b>Userful Webs</b></h2><br/>				
				<Link to={{pathname:"https://accounts.google.com/signup/v2/webcreateaccount?service=mail&continue=http%3A%2F%2Fmail.google.com%2Fmail%2Fe-11-10ded9b6e7caf20852bce801a278593e-8b4b0924a77a6a86a7506a1710f993392b58a8d3&flowName=GlifWebSignIn&flowEntry=SignUp"}} target="_blank" ><img src={Google} alt="icon" /></Link><br/>
				<Link to={{pathname:"https://www.microsoft.com/en-ca?ql=3"}} target="_blank" ><img src={Miscroft} alt="icon" /></Link><br/>
				<Link to={{pathname:"https://login.yahoo.com/account/create?specId=yidReg&altreg=0&.intl=ca&new=1&.done=http%3A//mail.yahoo.com&.src=ym&.v=0&.u=6fekfa147akmv&partner=&.partner=&pkg=&stepid=&.p=&promo=&.last="}} target="_blank" ><img src={Yahoo} alt="icon" /></Link><br/>
				<Link to={{pathname:"https://get.adobe.com/reader/"}} target="_blank" ><img src={Adobe} alt="icon" /></Link><br/>
				<Link to={{pathname:"https://www.facebook.com/"}} target="_blank" ><img src={Facebook} alt="icon" /></Link><br/>
				<Link to={{pathname:"https://twitter.com/?lang=en"}} target="_blank" ><img src={Twitter} alt="icon" /></Link><br/>
				<Link to={{pathname:"https://ca.linkedin.com/"}} target="_blank" ><img src={Linkedin} alt="icon" /></Link><br/>

			</div>	
		);
	}
}



export default ResourceForm