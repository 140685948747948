import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import SampleCheck from '../images/Canadian-Cheque.jpg'
import CommonHeader from '../clients/commons/Head'

class profileForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			firstname:'', lastname:'', SIN1:'', SIN2:'', SIN3:'', address:'', city:'', province:'', postcode1:'', 
			postcode2:'', phone1:'', phone2:'', phone3:'', email:'', bankname:'', branch:'', checknumber:'',
			Pro:[],Banks:[],birth1:'',birth2:'',birth3:'',YearDiff:'',
			currentProfile:[],payrolldu:[],pensionplan:'',uniondue:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);	
		this.handleDOBChange=this.handleDOBChange.bind(this);
		this.handleSubmit=this.handleSubmit.bind(this)		
	}

	componentDidMount() {
		const para={user:this.state.user,names:this.state.names}
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});	
		
		axios.post(process.env.REACT_APP_API_ENV+'api/bank.php')
			.then(result => {		
				this.setState({Banks:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});		
		axios.post(process.env.REACT_APP_API_ENV+'api/currentProfile.php', para)
			.then(result => {										
				this.setState({currentProfile:result.data})			
				this.setState({firstname:result.data[0]})			
				this.setState({lastname:result.data[1]})							
				this.setState({address:result.data[4]})			
				this.setState({city:result.data[5]})			
				this.setState({province:result.data[6]})			
				this.setState({bankname:result.data[14]})			
				this.setState({branch:result.data[10]})			
				this.setState({checknumber:result.data[12]})		
				if(result.data[2]){
					this.setState({birth1:(result.data[19]).substring(0,4)})		
					this.setState({birth2:(result.data[19]).substring(5,7)})		
					this.setState({birth3:(result.data[19]).substring(8,10)})		
					this.setState({SIN1:(result.data[2]).substring(0,3)})		
					this.setState({SIN2:(result.data[2]).substring(4,7)})		
					this.setState({SIN3:(result.data[2]).substring(8,12)})		
					this.setState({postcode1:(result.data[7]).substring(0,3)})		
					this.setState({postcode2:(result.data[7]).substring(4,7)})							
					this.setState({phone1:(result.data[3]).substring(0,3)})		
					this.setState({phone2:(result.data[3]).substring(4,7)})		
					this.setState({phone3:(result.data[3]).substring(8,12)})		
				}
				
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/payrolldeductions.php', para)
			.then(result => {		
				this.setState({payrolldu:result.data});
		})
	}	
			
	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})		
	}
	handleDOBChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})			
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {firstname, lastname, SIN1, SIN2, SIN3, address, city, province, postcode1, postcode2, phone1, phone2, phone3, 
				email, bankname, branch, checknumber,pensionplan,uniondue,birth1,birth2,birth3} = this.state			
		if(!firstname) {
			alert('Please enter your first name')
			return false
		}		
		if(!lastname) {
			alert('Please enter your last name')
			return false
		}	
		if(!birth1) {
			alert('Please enter your birth year')
			return false
		}
		if(!birth2) {
			alert('Please enter your birth month')
			return false
		}
		if(!birth3) {
			alert('Please enter your birth day')
			return false
		}
		if(!SIN1) {
			alert('Please enter your SIN first three numbers')
			return false
		}	
		if(!SIN2) {
			alert('Please enter your SIN middle three numbers')
			return false
		}	
		if(!SIN3) {
			alert('Please enter your SIN last three numbers')
			return false
		}	
		if(!address) {
			alert('Please enter your address')
			return false
		}
		if(!city) {
			alert('Please enter your City')
			return false
		}
		if(!province) {
			alert('Please select your province')
			return false
		}
		if(!postcode1) {
			alert('Please enter your post code first part')
			return false
		}
		if(!postcode2) {
			alert('Please enter your post code last part')
			return false
		}
		if(!phone1) {
			alert('Please enter your phone area number')
			return false
		}
		if(!phone2) {
			alert('Please enter your phone first three numbers')
			return false
		}
		if(!phone3) {
			alert('Please enter your phone last four numbers')
			return false
		}		
		if(!bankname) {
			alert('Please choose your bank')
			return false
		}
		if(!branch) {
			alert('Please enter your banking branch transit number which is 5-digit number')
			return false
		}
		if(!checknumber) {
			alert('Please enter your check account number')
			return false
		} else {
			const userObject = {
				firstname:this.state.firstname, 
				lastname:this.state.lastname, 
				birth1:this.state.birth1, 
				birth2:this.state.birth2, 
				birth3:this.state.birth3, 				
				SIN1:this.state.SIN1, 
				SIN2:this.state.SIN2, 
				SIN3:this.state.SIN3, 
				address:this.state.address, 
				city:this.state.city, 
				province:this.state.province, 
				postcode1:this.state.postcode1, 
				postcode2:this.state.postcode2,
				phone1:this.state.phone1, 
				phone2:this.state.phone2, 
				phone3:this.state.phone3, 
				email:this.state.user, 
				names:this.state.names,
				bankname:this.state.bankname, 
				branch:this.state.branch, 
				checknumber:this.state.checknumber,
				pensionplan:this.state.pensionplan,
				uniondue:this.state.uniondue
			};
			axios.post(process.env.REACT_APP_API_ENV+'api/setupprofile.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});					
				}).catch((error) => {
					console.log(error)
				});
		}
	}
	
	render() {
		
		return (
			<div>      			
				<CommonHeader titles="Profile"/>
				<form onSubmit={this.handleSubmit}>
					<div className="forinput">
						<label className="labelname">First Name:</label>
						<input type="text" name ="firstname" id="firstname" style={{width:'150px'}} value={this.state.firstname} onChange={this.handleChange} />
					</div><br/>
					<div className="forinput">
						<label className="labelname">Last Name: </label>
						<input type="text" name ="lastname" id="lastname" style={{width:'150px'}} value={this.state.lastname} onChange={this.handleChange} />
					</div><br/>
					<div className="forinput">
						<label className="labelname">Date of Birth (YYYY/MM/DD): </label>
						<input type="text" name ="birth1" id="birth1" style={{width:'60px'}} value={this.state.birth1} onChange={this.handleChange} maxLength="4"/>-
						<input type="text" name ="birth2" id="birth2" style={{width:'35px'}} value={this.state.birth2} onChange={this.handleChange} maxLength="2"/>-
						<input type="text" name ="birth3" id="birth3" style={{width:'35px'}} value={this.state.birth3} onChange={this.handleDOBChange} maxLength="2"/>
					</div><br/>
					<div className="forinput">
						<label className="labelname">Social Insurance Number: </label>
						<input type="text" name ="SIN1" id="SIN1" style={{width:'44px'}} value={this.state.SIN1} onChange={this.handleChange} maxLength="3"/>-
						<input type="text" name ="SIN2" id="SIN2" style={{width:'44px'}} value={this.state.SIN2} onChange={this.handleChange} maxLength="3"/>-
						<input type="text" name ="SIN3" id="SIN3" style={{width:'44px'}} value={this.state.SIN3} onChange={this.handleChange} maxLength="3"/>
					</div><br/>
					<div className="forinput">
						<label className="labelname">Address: </label>
						<input type="text" name ="address" id="address" style={{width:'150px'}} value={this.state.address} onChange={this.handleChange} />
					</div><br/>					
					<div className="forinput">
						<label className="labelname">City: </label>
						<input type="text" name ="city" id="city" style={{width:'150px'}} value={this.state.city} onChange={this.handleChange} />
					</div><br/>
					<div className="forinput">
						<label className="labelname">Province: </label>
						<select name="province" style={{width:'150px'}} value={this.state.province} onChange={this.handleChange}>
							<option value="">--Choose Your Province--</option>
							{this.state.Pro.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}										
						</select>
					</div><br/>
					<div className="forinput">
						<label className="labelname">Post Code: </label>
						<input type="text" name ="postcode1" id="postcode1" style={{width:'70px'}} value={this.state.postcode1} onChange={this.handleChange} maxLength="3"/>-
						<input type="text" name ="postcode2" id="postcode2" style={{width:'70px'}} value={this.state.postcode2} onChange={this.handleChange} maxLength="3"/>
					</div><br/>
					<div className="forinput">
						<label className="labelname">Phone Number: </label>
						<input type="text" name ="phone1" id="phone1" style={{width:'30px'}} value={this.state.phone1} onChange={this.handleChange} maxLength="3"/>-
						<input type="text" name ="phone2" id="phone2" style={{width:'40px'}} value={this.state.phone2} onChange={this.handleChange} maxLength="3"/>-
						<input type="text" name ="phone3" id="phone3" style={{width:'60px'}} value={this.state.phone3} onChange={this.handleChange} maxLength="4"/>
					</div><br/>
					<div className="forinput">
						<label className="labelname">Email Address: </label>
						<input type="text" name ="email" id="email" style={{width:'150px'}} value={this.state.user} readOnly />
					</div><br/>
					<div className="forinput">
						<label className="labelname">Bank Name: </label>
						<select name="bankname" style={{width:'150px'}} value={this.state.bankname} onChange={this.handleChange}>
							<option value="">--Choose your banking--</option>
							{this.state.Banks.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}										
						</select>
					</div><br/>					
					<div className="forinput">
						<label className="labelname">Branch Transit: </label>
						<input type="text" name ="branch" id="branch" style={{width:'150px'}} value={this.state.branch} onChange={this.handleChange} maxLength="5" />
					</div><br/>
					<div className="forinput">
						<label className="labelname">Checking Account Number: </label>
						<input type="text" name ="checknumber" id="checknumber" style={{width:'150px'}} value={this.state.checknumber} onChange={this.handleChange} maxLength="12" />
					</div><br/>
					<div>
					<label><b>Please see Sample Check for your reference:</b></label><br/><br/>
						<p><img src={SampleCheck} alt="check" /></p>							
						<p style={{textAlign:'center'}}>1: "12345" - This is 5 - digit branch transist number </p>
						<p style={{textAlign:'center'}}>2: "999"-This is 3-digit financial institution number</p>
						<p style={{textAlign:'center'}}>3: "456789012"-This is chequeing account number</p><br/>						
					</div><br/>	
					{(()=>{
						{/*pension*/}
						if(this.state.payrolldu[0] != "RRSP") {return(<div>
							<div className="forinput">
								<label className="labelname">Join Pension Plan ?</label>
								<select id="pensionplan" name="pensionplan" value = {this.state.pensionplan} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--select--</option>									
									<option value="0">--No, I don't join pension plan--</option>									
									<option value="1">--Yes, I join pension plan--</option>																		
								</select>								
							</div><br/>
						</div>)}
					})()}
					{(()=>{
						{/*union*/}
						if(this.state.payrolldu[1] != "UNION") {return(<div>
							<div className="forinput">
								<label className="labelname">Join Union ?</label>
								<select id="uniondue" name="uniondue" value = {this.state.uniondue} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--select--</option>									
									<option value="0">--No, I don't join Union--</option>									
									<option value="1">--Yes, I join Union--</option>																		
								</select>								
							</div><br/>
						</div>)}
					})()}
					<h1>{this.state.Resp}</h1><br/><br/>
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 
				</form>				
			</div>
		)
	}
}

export default profileForm;