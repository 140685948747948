import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import React, { useState, useEffect } from "react";
import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import axios from 'axios';
import Logo from '../images/img8.jpg'
import '../main.css'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


function createPDF() {

	return (
		<div className="generic">
			<h2>PDF Creation and Modification</h2>				
		</div>
	);
}

export default createPDF;