import React from 'react'
import '../main.css'
import axios from 'axios'
import SampleCheck from '../images/Canadian-Cheque.jpg'
import CommonHeader from '../clients/commons/Head'

class setupBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:2,costcenter:'', Resp:'', Nodata:'',Banks:[],SubC:[],selectedLName:'',
			LName: '', SName: props.SName, BName: '', FINID: '', BTransit: '', CNumber: '', 
			singleBank:'', user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);		
		this.handleCostCenterChange=this.handleCostCenterChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}									
		axios.post(process.env.REACT_APP_API_ENV+'api/bank.php')			
			.then((result) => {	
				this.setState({Banks:result.data});
		})	
		axios.post(process.env.REACT_APP_API_ENV+'api/branchupdates.php', para)
			.then(result => {												
				this.setState({SubC:result.data})								
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/bankupdates.php', para)
			.then(result => {							
				this.setState({UpdatingBank:result.data})								
		})
	}	
	handleBankChange = event => {		
		this.setState({singleBank: event.target.value});	
		axios.post(process.env.REACT_APP_API_ENV+'api/singlebank.php?ID='+ event.target.value)
			.then(result => {							
				this.setState({Nodata:result.data})
				this.setState({singBName:result.data})								
				this.setState({BName:result.data[2]})								
				this.setState({BTransit:result.data[3]})	
				this.setState({CNumber:result.data[4]})
				this.setState({LName:result.data[5]})					
			})
		this.setState({Resp:""});
	}
	
	handleCostCenterChange = event => {
		this.setState({singleBank:""})
		this.setState({LName:""})		
		this.setState({BName:""})
		this.setState({BTransit:""})
		this.setState({CNumber:""})				
		this.setState({costcenter: event.target.value})	
		this.setState({Resp:""});
	}
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	
	
	handleLNameChange = event => {		
		this.setState({selectedLName: event.target.value});			
		axios.post(process.env.REACT_APP_API_ENV+'api/singlebranch.php?BID='+event.target.value)
			.then(result => {			
				this.setState({Nodata:result.data})
				this.setState({singlebranch:result.data})								
				this.setState({SName:result.data[3]})								
				this.setState({CName:result.data[5]})	
				this.setState({ISIC:result.data[7]})	
				this.setState({CArea:(result.data[6]).substring(0,3)})	
				this.setState({Cthree:(result.data[6]).substring(4,7)})	
				this.setState({CPhone:(result.data[6]).substring(8,12)})	
				this.setState({CAddress:result.data[8]})	
				this.setState({CCity:result.data[9]})	
				this.setState({CProvince:result.data[10]})	
				this.setState({postcode1:(result.data[11]).substring(0,3)})	
				this.setState({postcode2:(result.data[11]).substring(4,7)})	
			})			
		this.setState({Resp:""});
	}
		
	handleSubmit = event => {		
		event.preventDefault()		
		const {costcenter, LName, BName, BTransit, CName, CNumber} = this.state					
		if (this.state.costcenter == "") {
				alert('Please select manage banking type, Add, Update or Delete?')
				return false
		} 
		if(this.state.costcenter == "1")  {
			if(this.state.selectedLName == "") {
				alert('Please select your sub company')
				return false
			}
			if(!BName) {
				alert('Please select your sub company business bank name')
				return false
			}
			if(!BTransit) {
				alert('Please enter your sub company business bank transit number which is 5-digit number shown in reference check sample')
				return false
			}		
			if(!CNumber) {
				alert('Please enter your sub company busness bank checking account number which is 9 or 12-digit number shown in sample check')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				costcenter:this.state.costcenter,
				LName: this.state.selectedLName,				
				BName: this.state.BName,				
				BTransit: this.state.BTransit,
				CNumber: this.state.CNumber
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.costcenter == "2")  {			
			if(this.state.singleBank == "") {
				alert('Please select your bank account for the updating')
				return false
			}
			if(!BName) {
				alert('Please select your sub company business bank name')
				return false
			}
			if(!BTransit) {
				alert('Please enter your sub company business bank transit number which is 5-digit number shown in reference check sample')
				return false
			}		
			if(!CNumber) {
				alert('Please enter your sub company busness bank checking account number which is 9 or 12-digit number shown in sample check')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				costcenter:this.state.costcenter,
				LName: this.state.singleBank,				
				BName: this.state.BName,				
				BTransit: this.state.BTransit,
				CNumber: this.state.CNumber
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});					
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.costcenter == "3")  {			
			if(this.state.singleBank == "") {
				alert('Please select the bank account to delete')
				return false
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				costcenter:this.state.costcenter,
				LName: this.state.singleBank,							
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});										
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {				
		const NoBankShow = (this.state.Nodata == "Sorry, No bankname was selected") ? "Sorry, No Bank account was selected":""				
		return (		
			<div>      						
				<CommonHeader titles="Bank"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Banking</h2></label>							
						<select name="costcenter" id="costcenter" style={{width:'150px'}} value={this.state.costcenter} onChange={this.handleCostCenterChange}>
							<option value="">--Manage Banking--</option>
							<option value="1">Add New Bank Account</option>						
							<option value="2">Update Bank Account</option>												
							<option value="3">Delete Bank Account</option>												
						</select><br/><br/>							
					</div>
					{(()=>{
					{/*to add new bank information*/}
						if(this.state.costcenter == "1") {return(<div>
							<label><h2>Add Bank Account</h2></label>
							<p>You can add as many as bank accounts for the selected Subsidiary</p><br/><br/>
							<label><h2>Choose Subsidiary for the adding Bank Account</h2></label>
							<select id="LName" name="LName" value = {this.state.selectedLName} style={{width:'160px'}} onChange={this.handleLNameChange}>
								<option value="">--Select Subsidiary--</option>									
								{this.state.SubC.map((sub,index) => (<option value={sub[0]} key={index}>{sub[2]}</option>))}			
							</select><br/><br/>
							<div className="forinput">
								<label><b>Bank Name:</b></label>			
								<select id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select Bank--</option>					
									{this.state.Banks.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}	
								</select>
							</div><br/>			
							<div className="forinput">
								<label><b>Branch Transit Number:</b></label>
								<input type="text" name="BTransit" id="BTransit"  maxLength="5" value = {this.state.BTransit} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><b>Checking Account Number:</b></label>
								<input type="text" name="CNumber" id="CNumber"  maxLength="12" value = {this.state.CNumber} onChange={this.handleChange} />
							</div><br/>
							<div>
								<label><b>Please see Sample Check for your reference:</b></label><br/><br/>										
								<p><img src={SampleCheck} alt="check" /></p>	
							</div>
							<div className="forinput">
								<h2>
								<ol style={{textAlign:'left',paddingLeft:'50px'}}>
									<li>"12345"---------This is 5-digit branch transist number</li>
									<li>"999"------------This is 3-digit financial institution number</li>
									<li>"456789012"---This is chequeing account number</li>
								</ol></h2><br/><br/>
							</div><br/>			
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*to update selected bank account*/}
						</div>)} else if(this.state.costcenter == "2") {return(<div>
							<label><h2>Choose Bank Account for the updating</h2></label>
							<select id="LName" name="LName" value = {this.state.singleBank} style={{width:'160px'}} onChange={this.handleBankChange}>
								<option value="">--Select Bank--</option>									
								{this.state.UpdatingBank.map((sub,index) => (<option value={sub[0]} key={index}>{sub[4]} - Account={sub[6]}</option>))}			
							</select><br/><br/>
							<label><h2>This Bank Account belongs to </h2></label>
							<input type="text" id="LName" name="LName" value = {this.state.LName} style={{width:'160px'}} readOnly /><br/><br/>
							<div className="forinput">
								<label><b>Bank Name:</b></label>			
								<select id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Select Bank--</option>					
									{this.state.Banks.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}	
								</select>
							</div><br/>			
							<div className="forinput">
								<label><b>Branch Transit Number:</b></label>
								<input type="text" name="BTransit" id="BTransit"  maxLength="5" value = {this.state.BTransit} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label><b>Checking Account Number:</b></label>
								<input type="text" name="CNumber" id="CNumber"  maxLength="12" value = {this.state.CNumber} onChange={this.handleChange} />
							</div><br/>
							<div>
								<label><b>Please see Sample Check for your reference:</b></label><br/><br/>										
								<p><img src={SampleCheck} alt="check" /></p>	
							</div>
							<div className="forinput">
								<h2>
								<ol style={{textAlign:'left',paddingLeft:'50px'}}>
									<li>"12345"---------This is 5-digit branch transist number</li>
									<li>"999"------------This is 3-digit financial institution number</li>
									<li>"456789012"---This is chequeing account number</li>
								</ol></h2><br/><br/>
							</div><br/>
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*to delete selected bank account*/}
						</div>)} else if(this.state.costcenter == "3") {return(<div>
							<label><h2>Choose Bank Account to Delete</h2></label>							
							<select id="LName" name="LName" value = {this.state.singleBank} style={{width:'160px'}} onChange={this.handleBankChange}>
								<option value="">--Select Bank--</option>									
								{this.state.UpdatingBank.map((sub,index) => (<option value={sub[0]} key={index}>{sub[4]} - Account={sub[6]}</option>))}			
							</select><br/><br/>
							<h1>{NoBankShow}</h1>
							<h1>{this.state.Resp}</h1><br/><br/>						
						</div>)}
					})()}					
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupBanking;