import React,{useEffect, useState} from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";

class MenuBar extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {management:'',administrative:'',
			hrs:'',accountant:'',manager:'',emp:'', Client:'',RepMag:'', CID:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};				
	}
	
	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}
		axios.post(process.env.REACT_APP_API_ENV+'api/validatingusers.php',para)
			.then(result => {						
				this.setState({management:result.data[5]})													
				this.setState({administrative:result.data[6]})		
				this.setState({hrs:result.data[7]})		
				this.setState({accountant:result.data[8]})		
				this.setState({manager:result.data[9]})		
				this.setState({emp:result.data[10]})								
				this.setState({Client:result.data[11]})			
				this.setState({RepMag:result.data[12]})		
				this.setState({CID:result.data[13]})		
			})		
	}
	render() {				
		return (<div className="cmenu-wrap">
			<div className="cmenu">
				<ul>
					<li><Link to = "/Clients">Timesheet</Link></li>					
					<li><Link to = "/report" >Report</Link></li>					
					{(()=>{
						if(this.state.administrative=="1") {return(
							<li><Link to = "/Admin">Admin</Link></li>
						)}
					})()}
					{(()=>{
						if(this.state.management=="1") {return(
							<li><Link to = "/management">Management</Link></li>
							)}
					})()}
					{(()=>{
						if(this.state.accountant=="1") {return(<div>							
							<li><Link to = "/accountant">Accountant</Link></li>
						</div>)}
					})()}	
					{(()=>{
						if(this.state.manager=="1" || this.state.RepMag > 0) {return(
							<li><Link to = "/approval" >Approval</Link></li>
						)}
					})()}
					<li><Link to = "/profile">Settings</Link></li>		
					{(()=>{if(this.state.CID == "5") {return(
						<li><Link to = "/UCSLogout">Logout</Link></li>
					)} else {return(
						<li><Link to = "/Logout">Logout</Link></li>
					)}})()}
				</ul>
			</div>
		</div>)
	}	
}


export default MenuBar