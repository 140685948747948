import React from "react";
import '../main.css'
import axios from 'axios'
import SetUpProfile from '../clients/ProfilePage'
import ViewCurrentProfile from '../clients/ViewCurrentProfile'
import SetupTD1Form from '../clients/setupTD1Form'
import ViewTD1Form from '../clients/viewTD1Form'
import ChangePWD from '../clients/setuppwd'
import CommonHeader from '../clients/commons/Head'

//to create timesheet input form
class currentForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {			
			isUpdating:false,	
			viewProfile:false,
			TD1Form:false,
			upTD1Form:false,
			upPWD:false,
			Comments:[],
			checknumber:'',
			firstname:'', lastname:'',address:'',city:'',province:'',Pro:[],formtype:'',EID:'',  CID:'',  BID:'',  birthday:'',  
			countryname:'',  basicamount:'',  caregive:'',  ageamount:'',  pensionamount:'',  tution:'',  disabilityamount:'',  
			spouseamount:'',  dependamount:'',  caregivedepend:'',  caregivedepend18:'', transferredAmount:'',  
			transferredfromdepend:'',  TotalAmount:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};		
		this.handleClick=this.handleClick.bind(this);		
		this.handleProfile=this.handleProfile.bind(this);
		this.handleTD1Form=this.handleTD1Form.bind(this);
		this.handleupTD1Form=this.handleupTD1Form.bind(this);
		this.handleChange=this.handleChange.bind(this);
		this.handlePWD=this.handlePWD.bind(this);
	}

	componentDidMount() {
		const para={user:this.state.user,names:this.state.names}
		axios.post(process.env.REACT_APP_API_ENV+'api/currentProfile.php', para)
			.then(result => {				
			if (result.status === 200) {							
				this.setState({Comments:result.data})						
				this.setState({firstname:result.data[0]})			
				this.setState({lastname:result.data[1]})							
				this.setState({address:result.data[4]})			
				this.setState({city:result.data[5]})			
				this.setState({province:result.data[6]})								
				if(result.data[2]){
					this.setState({SIN:result.data[2]})							
					this.setState({postcode:result.data[7]})							
				}				
			}
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {						
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});	
		axios.post(process.env.REACT_APP_API_ENV+'api/taxbasicinformation.php', para)
			.then(result => {							
				this.setState({year:result.data[0]})			
				this.setState({basicamount:result.data[2]})			
				this.setState({netincome:result.data[3]})			
		})	
		axios.post(process.env.REACT_APP_API_ENV+'api/viewtd1form.php', para)
			.then(result => {							
				this.setState({birthday:result.data[5]})			
				this.setState({countryname:result.data[6]})			
				this.setState({caregive:result.data[8]})					
				this.setState({ageamount:result.data[9]})	
				this.setState({pensionamount:result.data[10]})	
				this.setState({tution:result.data[11]})	
				this.setState({disabilityamount:result.data[12]})	
				this.setState({spouseamount:result.data[13]})	
				this.setState({dependamount:result.data[14]})	
				this.setState({caregivedepend:result.data[15]})	
				this.setState({caregivedepend18:result.data[16]})	
				this.setState({transferredAmount:result.data[17]})	
				this.setState({transferredfromdepend:result.data[18]})	
				this.setState({TotalAmount:result.data[19]})					
		})	
		
	}

	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})		
	}
	handleClick(){				
		this.setState(prevState=>({isUpdating: !prevState.isUpdating}))	
		this.setState({upTD1Form:false})
		this.setState({viewProfile:false})
		this.setState({upTD1Form:false})
		this.setState({formtype:''})
		this.setState({upPWD:false})
	}	
	handleProfile(){				
		this.setState(prevState=>({viewProfile: !prevState.viewProfile}))	
		this.setState({upTD1Form:false})
		this.setState({isUpdating:false})
		this.setState({upTD1Form:false})
		this.setState({formtype:''})
		this.setState({upPWD:false})
	}
	handleTD1Form(){				
		this.setState(prevState=>({TD1Form: !prevState.TD1Form}))			
		this.setState({viewProfile:false})
		this.setState({isUpdating:false})
		this.setState({upTD1Form:false})	
		this.setState({upPWD:false})		
	}
	handleupTD1Form(){				
		this.setState(prevState=>({upTD1Form: !prevState.upTD1Form}))	
		this.setState({viewProfile:false})
		this.setState({isUpdating:false})
		this.setState({TD1Form:false})
		this.setState({upPWD:false})
		this.setState({formtype:''})
	}
	handlePWD(){				
		this.setState(prevState=>({upPWD: !prevState.upPWD}))	
		this.setState({upTD1Form: false})	
		this.setState({viewProfile:false})
		this.setState({isUpdating:false})
		this.setState({TD1Form:false})
		this.setState({formtype:''})
	}
	render() {
		
		return (
			<div>      		
				<CommonHeader titles="Profile"/>
				<button onClick={this.handleProfile}>{this.state.viewProfile ? "View Profile Now":"View Profile ?"}</button>&nbsp;&nbsp;
				<button onClick={this.handleClick}>{this.state.isUpdating ? "Updating  Profile Now": "Update Your Profile?"}</button>&nbsp;&nbsp;
				<button onClick={this.handleTD1Form}>{this.state.TD1Form ? "View TD1 Form": "View TD1 Form?"}</button>&nbsp;&nbsp;
				<button onClick={this.handleupTD1Form}>{this.state.upTD1Form ? "Updating TD1 Form Now": "Updating TD1 Form?"}</button><br/><br/>
				<button onClick={this.handlePWD}>{this.state.upPWD ? "Change Password Now": "Change Password?"}</button><br/><br/>
				{(()=>{
						if(this.state.viewProfile === true) {return (<div>
							{/*<h2 ><b>Name:</b> {'   '}{this.state.Comments[0]} {' '} {this.state.Comments[1]}</h2><br/><br/>				
							<h2 ><b>SIN:</b> {'   '}{this.state.Comments[2]}</h2><br/><br/>				
							<h2 ><b>Address:</b> {'  '}{this.state.Comments[4]}{' '}{this.state.Comments[5]} {' '}{this.state.Comments[6]} {' '} {this.state.Comments[7]}{' '} {this.state.Comments[8]}</h2><br/><br/>
							<h2 ><b>Contact Phone:</b> {'  '}{this.state.Comments[3]}</h2><br/><br/>						
							<h2 ><b>Contact Email:</b>{'  '}{this.state.Comments[13]}</h2><br/><br/>						
							<h2 ><b>Bank:</b> {'   '}{this.state.Comments[9]}</h2><br/><br/>
							<h2 ><b>Branch Transit Number:</b>{' '}{this.state.Comments[10]}</h2><br/><br/>
							<h2 ><b>Checking Account Number:</b>{' '}{'xxxxxxxx'+this.state.Comments[12].slice(-4)}</h2><br/><br/>
							<h2 ><b>Pension Plan Memeber Rate:</b>{' '}{this.state.Comments[16]}</h2><br/><br/>
							<h2 ><b>Union Member Rate:</b>{' '}{this.state.Comments[17]}</h2><br/><br/>
							<h2 ><b>Salary Rate:</b>{' $'}{this.state.Comments[18]}</h2>
							<h2><small>{this.state.Comments[18] ==0 ? "Please check with your manager to update your $0 salary rate":""}</small></h2><br/><br/>
							*/}
							<ViewCurrentProfile/>
						</div>)}
				})()}				
				{(()=>{
						if(this.state.isUpdating === true) {return (
							<SetUpProfile/>)}
				})()}
				{(()=>{
						if(this.state.TD1Form === true) {return (
							<ViewTD1Form/>)}
				})()}
				
				{(()=>{
					if(this.state.upTD1Form === true) {return (<div>
						<SetupTD1Form/><br/><br/>						
					</div>)}						
				})()}
				{(()=>{
					if(this.state.upPWD === true) {return (<div>
						<ChangePWD/><br/><br/>						
					</div>)}						
				})()}
			</div>
		)
	}
}

export default currentForm;