import React from 'react'
import {Link} from "react-router-dom";


function MenuBar() {
	return(
		<div className="menu-wrap">
			<div className="menu">
				<ul>
					<li><Link to = "/">home</Link></li>
					<li><Link to = "/Registration" >Registration</Link></li>										
					<li><Link to = "/Resource">Resource </Link></li>								
					<li><Link to = "/Contact">Contact</Link></li>
					<li><Link to = "/Clients">Login</Link></li>										
				</ul>
			</div>
		</div>
	)
}

export default MenuBar