import {PDFDocument} from 'pdf-lib';
import { saveAs } from 'file-saver';
import CommonHeader from '../clients/commons/Head'
import React  from "react";
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

function TDerived($Type){		
	let TM=[]
	let HM=''
	let TT=''
	let FMT=''
	let Dates=''
	let alphabet=[]
	if($Type==="YB") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().subtract(18,'year').format('YYYY-MM-DD')
	} else if($Type==="Y") {
		HM=80
		TT='year'
		FMT='YYYY'
		Dates=dayjs().format('YYYY-MM-DD')
	} else if($Type==="M") {
		HM=12
		TT='month'
		FMT='MM'
		Dates=dayjs('2021-12-31').format('YYYY-MM-DD')
	} else if($Type==="D") {
		HM=31
		TT='day'
		FMT='DD'
		Dates=dayjs('2021-01-31').format('YYYY-MM-DD')
	} else if($Type==="P1") {
		HM=10
		TT='day'
		FMT='D'
		Dates=dayjs('2021-01-01').format('YYYY-MM-DD')
	} else if($Type==="PC") {
		const alpha = Array.from(Array(26)).map((e, i) => i + 65);
		alphabet = alpha.map((x) => String.fromCharCode(x));
	}
	
	for(let $i=0;$i<HM;$i++) {
		if($Type==="P1") {
			TM.push($i)
		} else {
			TM[$i]=dayjs(Dates).subtract($i,TT).format(FMT)
		}
	}
	if($Type==="PC") {
	 return alphabet
	} else {return TM;}
}

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
}

function writeVariables(pages, xvalue, yvalue, variables) {		
	pages.drawText(variables, {
			  x: xvalue,
			  y: yvalue,
			  size:10
	})
}
function writeVariablesSum(pages, xvalue, yvalue, variables) {		
	pages.drawText(variables, {
			  x: xvalue,
			  y: yvalue,
			  size:13
	})
}

//T4 PDF generator
function createT4PDF(PDFVars, Num, PDFSUM, NumSUM){			
	var existingPdfBytes = '';	
	fetch(process.env.REACT_APP_API_ENV + "api/gett4Pdf.php")
	.then(res => res.json()) 	
	.then(	
		async (result) => {		
			var obj = JSON.parse(result);
			var binaryData = base64ToArrayBuffer(obj.data);
			existingPdfBytes = binaryData;
			
			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			const subpdfDoc = await PDFDocument.create();
			
			for (let i = 0; i < Num; i++) {				
				const [page]= await subpdfDoc.copyPages(pdfDoc,[0]);
				subpdfDoc.addPage(page);				
			}
			const [page]= await subpdfDoc.copyPages(pdfDoc,[1]);
			subpdfDoc.addPage(page);
			//get page size and enter values
			const pages = subpdfDoc.getPages()			
			for (let i = 0; i < Num; i++) {				
				const firstPage  = pages[i]			
				// Get the width and height of the first page
				const { width, height } = firstPage.getSize()			
				writeVariables(firstPage, 310, height / 2 + 348, lastyear)
				writeVariables(firstPage, 310, height / 2 - 40, lastyear)								
				//employment income
				writeVariables(firstPage, 385, height / 2 + 308, PDFVars[i][55])			
				writeVariables(firstPage, 425, height / 2 + 308, PDFVars[i][54])
				writeVariables(firstPage, 385, height / 2 - 80, PDFVars[i][55])			
				writeVariables(firstPage, 425, height / 2 - 80, PDFVars[i][54])
				//employer
				writeVariables(firstPage, 50, height / 2 + 350, PDFVars[i][35])
				writeVariables(firstPage, 50, height / 2 + 325, PDFVars[i][37]+', '+PDFVars[i][38])
				writeVariables(firstPage, 50, height / 2 + 310, PDFVars[i][39] +', ' + PDFVars[i][40])
				writeVariables(firstPage, 50, height / 2 - 35, PDFVars[i][35])
				writeVariables(firstPage, 50, height / 2 - 55, PDFVars[i][37]+', '+PDFVars[i][38])
				writeVariables(firstPage, 50, height / 2 - 70, PDFVars[i][39] +', ' + PDFVars[i][40])
				//employee
				writeVariables(firstPage, 297, height / 2 + 272, PDFVars[i][50])
				writeVariables(firstPage, 297, height / 2 - 115, PDFVars[i][50])
				writeVariables(firstPage, 65, height / 2 + 185, PDFVars[i][45])
				writeVariables(firstPage, 65, height / 2 - 202, PDFVars[i][45])
				writeVariables(firstPage, 235, height / 2 + 185, PDFVars[i][44])
				writeVariables(firstPage, 235, height / 2 - 202, PDFVars[i][44])			
				writeVariables(firstPage, 65, height / 2 + 150, PDFVars[i][48])
				writeVariables(firstPage, 65, height / 2 + 110, PDFVars[i][49]+'     '+PDFVars[i][50]+'     '+PDFVars[i][51])
				writeVariables(firstPage, 65, height / 2 - 238, PDFVars[i][48])			
				writeVariables(firstPage, 65, height / 2 - 288, PDFVars[i][49]+'     '+PDFVars[i][50]+'     '+PDFVars[i][51])
				//RPP
				writeVariables(firstPage, 385, height / 2 + 165, PDFVars[i][19])			
				writeVariables(firstPage, 425, height / 2 + 165, PDFVars[i][71])
				writeVariables(firstPage, 385, height / 2 - 222, PDFVars[i][19])			
				writeVariables(firstPage, 425, height / 2 - 222, PDFVars[i][71])				
				writeVariables(firstPage, 385, height / 2 + 128, PDFVars[i][72])			
				writeVariables(firstPage, 425, height / 2 + 128, PDFVars[i][73])
				writeVariables(firstPage, 385, height / 2 - 260, PDFVars[i][72])			
				writeVariables(firstPage, 425, height / 2 - 260, PDFVars[i][73])
				//tax
				writeVariables(firstPage, 520, height / 2 + 308, PDFVars[i][13])			
				writeVariables(firstPage, 558, height / 2 + 308, PDFVars[i][0])
				writeVariables(firstPage, 520, height / 2 - 80, PDFVars[i][13])			
				writeVariables(firstPage, 558, height / 2 - 80, PDFVars[i][0])
				//EI
				writeVariables(firstPage, 233, height / 2 + 246, PDFVars[i][64])
				writeVariables(firstPage, 233, height / 2 - 142, PDFVars[i][64])
				writeVariables(firstPage, 520, height / 2 + 272, PDFVars[i][16])
				writeVariables(firstPage, 558, height / 2 + 272, PDFVars[i][2])			
				writeVariables(firstPage, 520, height / 2 - 115, PDFVars[i][16])
				writeVariables(firstPage, 558, height / 2 - 115, PDFVars[i][2])	
				writeVariables(firstPage, 385, height / 2 + 200, PDFVars[i][17])
				writeVariables(firstPage, 425, height / 2 + 200, PDFVars[i][1])
				writeVariables(firstPage, 385, height / 2 - 188, PDFVars[i][17])
				writeVariables(firstPage, 425, height / 2 - 188, PDFVars[i][1])
				//CPP
				writeVariables(firstPage, 210, height / 2 + 246, PDFVars[i][61])
				writeVariables(firstPage, 210, height / 2 - 142, PDFVars[i][61])
				writeVariables(firstPage, 385, height / 2 + 272, PDFVars[i][15])
				writeVariables(firstPage, 425, height / 2 + 272, PDFVars[i][3])
				writeVariables(firstPage, 385, height / 2 - 115, PDFVars[i][15])
				writeVariables(firstPage, 425, height / 2 - 115, PDFVars[i][3])
				writeVariables(firstPage, 520, height / 2 + 234, PDFVars[i][14])
				writeVariables(firstPage, 558, height / 2 + 234, PDFVars[i][4])
				writeVariables(firstPage, 520, height / 2 - 152, PDFVars[i][14])
				writeVariables(firstPage, 558, height / 2 - 152, PDFVars[i][4])
				//union---debug space or empty values
				writeVariables(firstPage, 520, height / 2 + 200, PDFVars[i][21])
				writeVariables(firstPage, 558, height / 2 + 200, PDFVars[i][20])
				writeVariables(firstPage, 520, height / 2 - 188, PDFVars[i][21])
				writeVariables(firstPage, 558, height / 2 - 188, PDFVars[i][20])
				//SIN		
				writeVariables(firstPage, 90, height / 2 + 246, PDFVars[i][46])
				writeVariables(firstPage, 90, height / 2 - 142, PDFVars[i][46])
				//other information --'57', '999,999' can be replaced by anything in the future if Fed has law required
				/*writeVariables(firstPage, 118, height / 2 + 57, '57')
				writeVariables(firstPage, 191, height / 2 + 57, '999,999')
				writeVariables(firstPage, 230, height / 2 + 57, '99')
				writeVariables(firstPage, 282, height / 2 + 57, '58')
				writeVariables(firstPage, 354, height / 2 + 57, '999,999')
				writeVariables(firstPage, 393, height / 2 + 57, '99')
				writeVariables(firstPage, 445, height / 2 + 57, '59')
				writeVariables(firstPage, 519, height / 2 + 57, '999,999')
				writeVariables(firstPage, 558, height / 2 + 57, '99')
				writeVariables(firstPage, 118, height / 2 + 20, '60')
				writeVariables(firstPage, 191, height / 2 + 20, '999,999')
				writeVariables(firstPage, 230, height / 2 + 20, '99')
				writeVariables(firstPage, 282, height / 2 + 20, '61')
				writeVariables(firstPage, 354, height / 2 + 20, '999,999')
				writeVariables(firstPage, 393, height / 2 + 20, '99')
				writeVariables(firstPage, 445, height / 2 + 20, '62')
				writeVariables(firstPage, 519, height / 2 + 20, '999,999')
				writeVariables(firstPage, 558, height / 2 + 20, '99')
				writeVariables(firstPage, 118, height / 2 - 332, '57')
				writeVariables(firstPage, 191, height / 2 - 332, '999,999')
				writeVariables(firstPage, 230, height / 2 - 332, '99')
				writeVariables(firstPage, 282, height / 2 - 332, '58')
				writeVariables(firstPage, 354, height / 2 - 332, '999,999')
				writeVariables(firstPage, 393, height / 2 - 332, '99')
				writeVariables(firstPage, 445, height / 2 - 332, '59')
				writeVariables(firstPage, 519, height / 2 - 332, '999,999')
				writeVariables(firstPage, 558, height / 2 - 332, '99')
				writeVariables(firstPage, 118, height / 2 - 368, '60')
				writeVariables(firstPage, 191, height / 2 - 368, '999,999')
				writeVariables(firstPage, 230, height / 2 - 368, '99')
				writeVariables(firstPage, 282, height / 2 - 368, '61')
				writeVariables(firstPage, 354, height / 2 - 368, '999,999')
				writeVariables(firstPage, 393, height / 2 - 368, '99')
				writeVariables(firstPage, 445, height / 2 - 368, '62')
				writeVariables(firstPage, 519, height / 2 - 368, '999,999')
				writeVariables(firstPage, 558, height / 2 - 368, '99')
				*/
			}
			const pdfBytes = await subpdfDoc.save()			
			var blob = new Blob([pdfBytes])
			saveAs(blob, 'T4_Employees_'+lastyear+'.pdf')
		},

		(error) => {
			alert('Error happens');
		}
	)
	//T4 Summary Report
	var existingPdfBytes = '';	
	fetch(process.env.REACT_APP_API_ENV+"api/gett4summPdf.php")
	.then(res => res.json()) 	
	.then(	
		async (result) => {		
			var obj = JSON.parse(result);
			var binaryData = base64ToArrayBuffer(obj.data);
			existingPdfBytes = binaryData;
			
			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			const subpdfDoc = await PDFDocument.create();
			
			for (let i = 0; i < NumSUM; i++) {				
				const [page]= await subpdfDoc.copyPages(pdfDoc,[0]);
				subpdfDoc.addPage(page);				
			}
			const [page]= await subpdfDoc.copyPages(pdfDoc,[1]);
			subpdfDoc.addPage(page);				
			//get page size and enter values
			const pages = subpdfDoc.getPages()			
			for (let i = 0; i < NumSUM; i++) {				
				const firstPage  = pages[i]			
				// Get the width and height of the first page
				const { width, height } = firstPage.getSize()			
				writeVariablesSum(firstPage, 195, height / 2 + 343, PDFSUM[i][28])				
				writeVariablesSum(firstPage, 285, height / 2 + 265, PDFSUM[i][22])
				writeVariablesSum(firstPage, 285, height / 2 + 240, PDFSUM[i][23]+' '+PDFSUM[i][24])
				writeVariablesSum(firstPage, 285, height / 2 + 215, PDFSUM[i][25]+' '+PDFSUM[i][27])
				
				writeVariablesSum(firstPage, 130, height / 2 + 172, PDFSUM[i][3])				
				writeVariablesSum(firstPage, 133, height / 2 + 136, PDFSUM[i][4])	
				writeVariablesSum(firstPage, 198, height / 2 + 136, PDFSUM[i][5])	
				writeVariablesSum(firstPage, 133, height / 2 + 100, PDFSUM[i][6])	
				writeVariablesSum(firstPage, 198, height / 2 + 100, PDFSUM[i][7])
				writeVariablesSum(firstPage, 133, height / 2 + 65, PDFSUM[i][38])	
				writeVariablesSum(firstPage, 198, height / 2 + 65, PDFSUM[i][39])
				
				writeVariablesSum(firstPage, 328, height / 2 + 172, PDFSUM[i][8])	
				writeVariablesSum(firstPage, 398, height / 2 + 172, PDFSUM[i][9])	
				writeVariablesSum(firstPage, 328, height / 2 + 136, PDFSUM[i][10])	
				writeVariablesSum(firstPage, 398, height / 2 + 136, PDFSUM[i][11])	
				writeVariablesSum(firstPage, 328, height / 2 + 100, PDFSUM[i][12])	
				writeVariablesSum(firstPage, 398, height / 2 + 100, PDFSUM[i][13])	
				writeVariablesSum(firstPage, 328, height / 2 + 63, PDFSUM[i][14])	
				writeVariablesSum(firstPage, 398, height / 2 + 63, PDFSUM[i][15])	
				writeVariablesSum(firstPage, 328, height / 2 + 27, PDFSUM[i][16])	
				writeVariablesSum(firstPage, 398, height / 2 + 27, PDFSUM[i][17])	
				writeVariablesSum(firstPage, 328, height / 2 - 9, PDFSUM[i][29])	
				writeVariablesSum(firstPage, 398, height / 2 - 9, PDFSUM[i][30])
				writeVariablesSum(firstPage, 328, height / 2 - 45, PDFSUM[i][18])	
				writeVariablesSum(firstPage, 398, height / 2 - 45, PDFSUM[i][19])
				writeVariablesSum(firstPage, 328, height / 2 - 117, PDFSUM[i][31])	
				writeVariablesSum(firstPage, 398, height / 2 - 117, PDFSUM[i][32])				
				writeVariablesSum(firstPage, 108, height / 2 - 200, PDFSUM[i][33])	
				writeVariablesSum(firstPage, 170, height / 2 - 200, PDFSUM[i][34])
				writeVariablesSum(firstPage, 258, height / 2 - 200, PDFSUM[i][35])	
				writeVariablesSum(firstPage, 328, height / 2 - 200, PDFSUM[i][36])
				
				writeVariablesSum(firstPage, 75, height / 2 - 333, PDFSUM[i][37])
			}
			const pdfBytes = await subpdfDoc.save()			
			var blob = new Blob([pdfBytes])
			saveAs(blob, 'T4_Employer_Summary_'+lastyear+'.pdf')
		},

		(error) => {
			alert('Error happens');
		}
	)
}
//T4A generator
function createT4APDF(PDFVars, Num, PDFSUM, NumSUM){		
	var existingPdfBytes = '';		
	fetch(process.env.REACT_APP_API_ENV+"api/gett4aPdf.php")
	.then(res => res.json()) 	
	.then(	
		async (result) => {		
			var obj = JSON.parse(result);
			var binaryData = base64ToArrayBuffer(obj.data);
			existingPdfBytes = binaryData;
			
			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			const subpdfDoc = await PDFDocument.create();			
			for (let i = 0; i < Num; i++) {				
				const [page]= await subpdfDoc.copyPages(pdfDoc,[0]);
				subpdfDoc.addPage(page);				
			}
			const [page]= await subpdfDoc.copyPages(pdfDoc,[1]);
			subpdfDoc.addPage(page);
			//get page size and enter values
			const pages = subpdfDoc.getPages()			
			for (let i = 0; i < Num; i++) {				
				const firstPage  = pages[i]			
				// Get the width and height of the first page
				const { width, height } = firstPage.getSize()					
				writeVariables(firstPage, 310, height / 2 + 450, lastyear)//
				writeVariables(firstPage, 310, height / 2 + 65, lastyear)//	
				//employment income
				writeVariables(firstPage, 515, height / 2 + 293, PDFVars[i][55])			
				writeVariables(firstPage, 551, height / 2 + 293, PDFVars[i][54])
				writeVariables(firstPage, 515, height / 2 - 92, PDFVars[i][55])			
				writeVariables(firstPage, 551, height / 2 - 92, PDFVars[i][54])
				//employer
				writeVariables(firstPage, 50, height / 2 + 440, PDFVars[i][35])//
				writeVariables(firstPage, 50, height / 2 + 55, PDFVars[i][35])//			
				//employee			
				writeVariables(firstPage, 65, height / 2 + 290, PDFVars[i][45])//
				writeVariables(firstPage, 65, height / 2 - 95, PDFVars[i][45])//
				writeVariables(firstPage, 235, height / 2 + 290, PDFVars[i][44])//
				writeVariables(firstPage, 235, height / 2 - 95, PDFVars[i][44])//			
				writeVariables(firstPage, 65, height / 2 + 260, PDFVars[i][48])//
				writeVariables(firstPage, 65, height / 2 + 230, PDFVars[i][49]+'     '+PDFVars[i][50]+'     '+PDFVars[i][51])//
				writeVariables(firstPage, 65, height / 2 - 125, PDFVars[i][48])			//
				writeVariables(firstPage, 65, height / 2 - 155, PDFVars[i][49]+'     '+PDFVars[i][50]+'     '+PDFVars[i][51])//
				//SIN		
				writeVariables(firstPage, 90, height / 2 + 350, PDFVars[i][46])//
				writeVariables(firstPage, 90, height / 2 - 35, PDFVars[i][46])//
			}
			const pdfBytes = await subpdfDoc.save()
			var blob = new Blob([pdfBytes])
			saveAs(blob, 'T4A_Employees_'+lastyear+'.pdf')
		},

		(error) => {
			alert('Error happens');
		}
	)
	//T4A Summary Report
	var existingPdfBytes = '';	
	fetch(process.env.REACT_APP_API_ENV+"api/gett4asummPdf.php")
	.then(res => res.json()) 	
	.then(	
		async (result) => {		
			var obj = JSON.parse(result);
			var binaryData = base64ToArrayBuffer(obj.data);
			existingPdfBytes = binaryData;
			
			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			const subpdfDoc = await PDFDocument.create();
			
			for (let i = 0; i < NumSUM; i++) {				
				const [page]= await subpdfDoc.copyPages(pdfDoc,[0]);
				subpdfDoc.addPage(page);				
			}
			const [page]= await subpdfDoc.copyPages(pdfDoc,[1]);
			subpdfDoc.addPage(page);				
			//get page size and enter values
			const pages = subpdfDoc.getPages()			
			for (let i = 0; i < NumSUM; i++) {				
				const firstPage  = pages[i]			
				// Get the width and height of the first page
				const { width, height } = firstPage.getSize()			
				writeVariablesSum(firstPage, 197, height / 2 + 333, PDFSUM[i][28])				
				writeVariables(firstPage, 263, height / 2 + 260, PDFSUM[i][22])
				writeVariables(firstPage, 263, height / 2 + 235, PDFSUM[i][23]+' '+PDFSUM[i][24])
				writeVariables(firstPage, 263, height / 2 + 210, PDFSUM[i][25]+' '+PDFSUM[i][27])
				
				writeVariables(firstPage, 480, height / 2 + 139, PDFSUM[i][3])				
				writeVariables(firstPage, 468, height / 2 - 4, PDFSUM[i][4])	
				writeVariables(firstPage, 520, height / 2 - 4, PDFSUM[i][5])	
							
				writeVariables(firstPage, 83, height / 2 - 257, PDFSUM[i][37])
			}
			const pdfBytes = await subpdfDoc.save()			
			var blob = new Blob([pdfBytes])
			saveAs(blob, 'T4_Employer_Summary_'+lastyear+'.pdf')
		},

		(error) => {
			alert('Error happens');
		}
	)
}
//T4A

class reportForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {paystub:'',report:1,payDetails:[], branch:'', BranchName:[], CID:'',BID:'',payPeriods:[],
						payperiod:'',start:'',end:'', pid:'', comments:'', chargingfee:[], t4comments:'',t4year:'',
						T4T:[],T4TS:[],T4TA:[],T4TAComments:'',T4TComments:'',T4TAS:[], Taxdays:'', T4Ready:'',T4Aready:'',
						downloadsA:'',downloads:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names'),
						Client:'',approvedpayment:[],banks:[],BName:'',FINID:'',BTransit:'',CNumber:'',TBName:'',TFINID:'',
						TFINID1:'',TBTransit:'',TCNumber:'',Resp:'',startingyear:[],
						fdate1:'',fdate2:'',fdate3:'',fof:'',Rest:'',HWamount:'',payname:'',Toname:'',payees:'',payeeall:[],
						pcomm:'',starting:'',ending:'',payorbank:[], TBNameID:'',clientfee:[]};		
		this.handleChange=this.handleChange.bind(this);		
		this.handleAccountingChange=this.handleAccountingChange.bind(this);	
		this.handlePeriodChange=this.handlePeriodChange.bind(this);		
		this.handleSubmit=this.handleSubmit.bind(this);
		this.handleT4Change=this.handleT4Change.bind(this);
		this.handlePayeeChange=this.handlePayeeChange.bind(this);
		this.handlePaymentChange=this.handlePaymentChange.bind(this);
		
	}

	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}	
		axios.post(process.env.REACT_APP_API_ENV+'api/validatingusers.php',para)
			.then(result => {						
				this.setState({Client:result.data[11]})			
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/taxseasondays.php', para)
			.then(result => {												
				this.setState({Taxdays:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/findbranch.php', para)
			.then(result => {
				if(result.data[0][3]=="No") {
					this.setState({comments:"No payment has been processed yet"})
				} else {
					this.setState({BranchName:result.data})					
				}
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/paymentperiod.php', para)
			.then(result => {
				if(result.data[0][0]=="No") {
					this.setState({comments:"No payment has been processed yet"})
				} else {
					this.setState({payPeriods:result.data})					
				}												
			})
		const userObject={				
					user:this.state.user,
					year:thisyear-1
				}		
		axios.post(process.env.REACT_APP_API_ENV+'api/employert4tax.php', userObject)
			.then(result => {
				if(result.data[0][0] !="No") {					
					this.setState({T4T:result.data})
					this.setState({T4Ready:result.data[0][66]})
				} else {
					this.setState({T4TComments:'No'})					
				}
		})	
		axios.post(process.env.REACT_APP_API_ENV+'api/employert4atax.php', userObject)
			.then(result => {
				if(result.data[0][0] !="No") {					
					this.setState({T4TA:result.data})	
					this.setState({T4AReady:result.data[0][66]})
				} else {
					this.setstT4TAComments({T4TAComments:'No'})					
				}
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/employert4summtax.php', userObject)
			.then(result => {
				if(result.data[0][0] !="No") {					
					this.setState({T4TS:result.data})	
				}
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/employert4asummtax.php', userObject)
			.then(result => {
				if(result.data[0][0] !="No") {					
					this.setState({T4TAS:result.data})	
				}
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/clientfees.php', para)
			.then(result => {
				this.setState({approvedpayment:result.data})									
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/bank.php', para)
			.then(result => {
				this.setState({Banks:result.data})									
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/payeeinformation.php',para)
			.then(result => {	
				this.setState({pcomm:result.data[0][0]})
				this.setState({payeeall:result.data})			
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/payorbanking.php',para)
			.then(result => {	
				this.setState({payorbank:result.data})			
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/startingyear.php')
				.then(result => {
					if(result.data[0][0] !="0") {					
						this.setState({startingyear:result.data})	
					} else {
						this.setState({Resp:"No Client Data"})
					}
				})
		axios.post(process.env.REACT_APP_API_ENV+'api/clientchargefee.php')
				.then(result => {
					if(result.data[0][0] !="0") {					
						this.setState({clientfee:result.data})	
					} else {
						this.setState({Resp:"No Client Data"})
					}
				})	
	}
						
	handleChange = event => {
		this.setState({payperiod:''})
		this.setState({t4comments:''})
		this.setState({T4TAComments:''})
		this.setState({T4TComments:''})
		this.setState({Rest:''})
		const {name, value} = event.target				
		this.setState({[name]: value})			
	}
		/*
	handleEndChange = event => {
		this.setState({payperiod:''})
		this.setState({t4comments:''})
		this.setState({T4TAComments:''})
		this.setState({T4TComments:''})
		this.setState({Rest:''})
		const {name, value} = event.target				
		this.setState({[name]: value})			
		if(event.target.value !="") {
			const userObject={				
					user:this.state.user,
					names:this.state.names,
					starting:this.state.starting,
					ending:event.target.value				
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/clientchargefee.php', userObject)
				.then(result => {
					if(result.data[0][0] !="0") {					
						this.setState({clientfee:result.data})	
					} else {
						this.setState({Resp:"No Client Data"})
					}
				})		
				
		}
	}
		*/
	handleT4Change = event => {
		this.setState({payperiod:''})
		this.setState({t4comments:''})
		this.setState({T4TAComments:''})
		this.setState({T4TComments:''})
		this.setState({Rest:''})
		const {name, value} = event.target				
		this.setState({[name]: value})				
	}
	
	handlePeriodChange = event => {		
		this.setState({payperiod:''})	
		this.setState({t4comments:''})
		this.setState({T4TAComments:''})
		this.setState({T4TComments:''})
		this.setState({Rest:''})
		const {name, value} = event.target				
		this.setState({[name]: value})	
		if(event.target.value !="" && this.state.paystub==1) {
			const userObject={				
					user:this.state.user,
					names:this.state.names,
					branch:this.state.branch,
					period:event.target.value				
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/withholding.php', userObject)
				.then(result => {					
					this.setState({payDetails:result.data})											
				})		
		} else if(event.target.value !="" && this.state.paystub==2) {
			const userObject={				
					user:this.state.user,
					names:this.state.names,
					branch:this.state.branch,
					period:event.target.value				
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/accountingfee.php', userObject)
				.then(result => {			
					this.setState({chargingfee:result.data})											
				})		
		}
	}

	handleAccountingChange = event => {		
		this.setState({branch:''})
		this.setState({payperiod:''})
		this.setState({t4comments:''})
		this.setState({T4TAComments:''})
		this.setState({T4TComments:''})
		this.setState({Rest:''})
		const {name, value} = event.target				
		this.setState({[name]: value})			
	}	
	
	handlePayeeChange = event => {		
		this.setState({payname:''})
		this.setState({Toname:''})
		this.setState({BName:''})
		this.setState({BTransit:''})
		this.setState({CNumber:''})
		this.setState({TBName:''})
		this.setState({TBTransit:''})
		this.setState({TCNumber:''})		
		const {name, value} = event.target				
		this.setState({[name]: value})	
		if(this.state.payorbank[0][0] != "0") {
			this.setState({payname:this.state.payorbank.filter(item=>item[0]==event.target.value).map(sub=>sub[1])[0]})
			this.setState({Toname:this.state.payorbank[0][8]})
			this.setState({FINID:this.state.payorbank.filter(item=>item[0]==event.target.value).map(sub=>sub[4])[0]})
			this.setState({BTransit:this.state.payorbank.filter(item=>item[0]==event.target.value).map(sub=>sub[5])[0]})
			this.setState({CNumber:this.state.payorbank.filter(item=>item[0]==event.target.value).map(sub=>sub[6])[0]})	
		}
	}	
	handlePaymentChange = event => {	
		this.setState({TFINID:''})
		this.setState({TBTransit:''})
		this.setState({TCNumber:''})		
		const {name, value} = event.target				
		this.setState({[name]: value})	
		if(this.state.payeeall[0][0] != "0") {
			this.setState({Toname:this.state.payeeall.filter(item=>item[0]==event.target.value).map(sub=>sub[1])[0]})
			this.setState({TFINID:this.state.payeeall.filter(item=>item[0]==event.target.value).map(sub=>sub[2])[0]})
			this.setState({TBTransit:this.state.payeeall.filter(item=>item[0]==event.target.value).map(sub=>sub[3])[0]})
			this.setState({TCNumber:this.state.payeeall.filter(item=>item[0]==event.target.value).map(sub=>sub[4])[0]})	
		}
	}	
	TDisplayFormat =(DFormat, DRow) =>{		
		const LB=[]							
		for (let i=0;i<DRow;i++) {			
			LB.push(<div key={i}>
			<div className="label-wrap" style={{textAlign:'center'}}>
				<div className="label">					
					{DFormat.slice(i*10,10*(i+1)).map((item, index) => {return<h2 style={{width:'95px'}} key={index}>{item}</h2>;})} 
				</div>				
			</div><br/><br/>
			</div>)
		}			
		return LB
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {t4year} = this.state		
		if(this.state.paystub==3) {
			const userObject={				
					user:this.state.user,	
					names:this.state.names,
					t4year:this.state.t4year				
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/t4taxrelease.php', userObject)
				.then(result => {							
					this.setState({t4comments:result.data})											
				})		
		} else if(this.state.paystub==5) {
			const userObject={				
					user:this.state.user,	
					names:this.state.names,
					FINID:this.state.FINID,	
					BTransit:this.state.BTransit,
					CNumber:this.state.CNumber,
					TFINID1:this.state.TBName,
					TFINID:this.state.TFINID,
					TBTransit:this.state.TBTransit,
					TCNumber:this.state.TCNumber,
					fdate1:this.state.fdate1,
					fdate2:this.state.fdate2,
					fdate3:this.state.fdate3,
					fof:this.state.fof,
					HWamount:this.state.HWamount,
					payname:this.state.payname,
					Toname:this.state.Toname,
					payees:this.state.payees
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/SpecialPayments.php', userObject)
				.then(result => {							
					this.setState({Rest:result.data})											
				})		
		}
	}
	
	render() {				
		return (
			<div>      
				<CommonHeader titles="Accounting"/>
				<form onSubmit={this.handleSubmit}>
					<h2> Accounting Reports</h2>					
					<select name="paystub" style={{width:'150px'}} value={this.state.paystub} onChange={this.handleAccountingChange}>
						<option value="">--Reports--</option>						
						<option value="1">--Withholding Reports--</option>						
						<option value="2">--Accounting Fee Reports--</option>					
						<option value="3">--T4/T4A Form--</option>												
						{this.state.Client=="NO" ? <option value="4">--Client Service Fee Charges--</option>:""}
						<option value="5">--Setup Payments--</option>	
					</select><br/><br/>
					{(()=>{if(this.state.paystub != "" && this.state.paystub != "3" && this.state.paystub != "4" && this.state.paystub != "5") {return (<div>
						<h2>Branch</h2>					
						<select name="branch" style={{width:'150px'}} value={this.state.branch} onChange={this.handleChange}>
							<option value="">--select--</option>						
							{this.state.BranchName.map((sub,index) => (<option value={sub[0]} key={index}>{sub[3]}</option>))}							
						</select><br/><br/>											
						<h2>{this.state.comments !="" ? this.state.comments:""}</h2>		
						{(()=>{if(this.state.branch != "") {return (<div>
							<h2>Payment Period</h2>					
							<select name="payperiod" style={{width:'150px'}} value={this.state.payperiod} onChange={this.handlePeriodChange}>
								<option value="">--select--</option>						
								{this.state.payPeriods.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]+' ~ '+sub[2]}</option>))}			
							</select><br/><br/>	
						</div>)}})()}
					</div>)}})()}
					{(()=>{if(this.state.paystub == "3") {return (<div>
						<h2>T4/T4A Release Year</h2>					
						<select name="t4year" style={{width:'150px'}} value={this.state.t4year} onChange={this.handleT4Change}>
							<option value="">--Select Year--</option>						
							<option value={thisyear}>{thisyear}</option>						
							<option value={thisyear-1}>{thisyear-1}</option>																									
						</select><br/><br/>	
						<h2>{this.state.t4comments=="" ? "":this.state.t4comments}</h2><br/>
						{(()=>{if(this.state.t4year != "" && dayjs().diff(dayjs(lastyear).endOf('year'),'day')  > 0	&& dayjs().diff(dayjs(lastyear).endOf('year'),'day')  < this.state.Taxdays) {return (<div>					
							<input type="submit" name="submit" value="Generate T4/T4A tax form" /><br/><br/>							
						</div>)} else if(this.state.t4year != "" && dayjs().diff(dayjs(lastyear).endOf('year'),'day')  >= this.state.Taxdays) {return (<div>					
							<h2>{this.state.t4year == lastyear ? "Year "+lastyear+" Tax season is over and no T4/T4A can be generated now":"Year "+thisyear+" tax season is not coming yet, please wait until end of this year"}</h2>
						</div>)}})()}
						{(()=>{if(this.state.t4year != "") {return (<div>
							{(()=>{if(this.state.T4TComments !="No" && this.state.T4Ready == "0" && dayjs().diff(dayjs(lastyear).endOf('year'),'day') > 0 && dayjs().diff(dayjs(lastyear).endOf('year'),'day')  < 120) {return (
								<h2>Year {lastyear} T4 is not available now</h2>						
							)} else if(this.state.T4TComments != "No" && this.state.T4Ready == "1" && dayjs().diff(dayjs(lastyear).endOf('year'),'day') > 0 && dayjs().diff(dayjs(lastyear).endOf('year'),'day') < this.state.Taxdays){return(<div>
								<h2>Download T4 and T4 Summary For CRA Report Only</h2>
								<select name="downloads" style={{width:'150px'}} value={this.state.downloads} onChange={this.handleChange}>
									<option value="">--select--</option>						
									<option value="1">Download T4 and T4 Summary</option>															
								</select>
								<h3>Employee T4 can be printed from their own computers</h3><br/><br/>
							</div>)}})()}
							{(()=>{if(this.state.T4TAComments !="No" && this.state.T4AReady == "0" && dayjs().diff(dayjs(lastyear).endOf('year'),'day') > 0 && dayjs(lastyear).endOf('year').diff(dayjs(),'day') < 120) {return (
								<h2>Year {lastyear} T4A is not available now</h2>						
							)} else if(this.state.T4TAComments != "No" && this.state.T4AReady == "1" && dayjs().diff(dayjs(lastyear).endOf('year'),'day') > 0 && dayjs().diff(dayjs(lastyear).endOf('year'),'day') < this.state.Taxdays){return(<div>
								<h2>Download T4A and T4A Summary For CRA Report Only</h2>
								<select name="downloadsA" style={{width:'150px'}} value={this.state.downloadsA} onChange={this.handleChange}>
									<option value="">--select--</option>						
									<option value="1">Download T4A and T4A Summary</option>															
								</select><h3>Employee T4A can be printed from their own computers</h3><br/><br/>
							</div>)}})()}
						</div>)}})()}
						{(()=>{if(this.state.paystub == "3" && this.state.t4year !="" && this.state.downloads =="1" && this.state.T4TComments !="No") {return (<div>
							<h1>Downloading T4 Now <small>(This is for CRA report only, Employee T4 can be printed from their computer)</small></h1>
							{createT4PDF(this.state.T4T,this.state.T4T.length, this.state.T4TS, this.state.T4TS.length)}
						</div>)}})()}
						{(()=>{if(this.state.paystub == "3" && this.state.t4year !="" && this.state.downloadsA =="1" && this.state.T4TAComments !="No") {return (<div>
							<h1>Downloading T4/T4A Now <small>(This is for CRA report only, Employee T4/T4A can be printed from their computer)</small></h1>
							{createT4APDF(this.state.T4TA,this.state.T4TA.length, this.state.T4TAS, this.state.T4TAS.length)}							
						</div>)}})()}
					</div>)}})()}
					{(()=>{if(this.state.payperiod !== "" && this.state.paystub == "1") {return (<div>
						<h2 style={{textAlign:'left'}}><b>{this.state.BranchName.filter(sub=>sub[0]==this.state.branch).map(sub=>sub[3])}</b></h2><br/>
						<div className="label-wrap" style={{textAlign:'center'}}>
							<div className="label">	
								<table>
									<thead>
										<tr>
											<th style={{width:'95px'}}>Name</th>
											<th style={{width:'95px'}}>Current Payment</th>
											<th style={{width:'95px'}}>Net Payment</th>
											<th style={{width:'95px'}}>Federal Tax</th>
											<th style={{width:'95px'}}>Provinical Tax</th>
											<th style={{width:'95px'}}>CPP</th>								
											<th style={{width:'95px'}}>Employer CPP</th>								
											<th style={{width:'95px'}}>EI</th>								
											<th style={{width:'95px'}}>Employer EI</th>								
											<th style={{width:'95px'}}>Withholding</th>	
										</tr>
									</thead><br/>
									<tbody>																			
									{this.state.payDetails.slice(0, this.state.payDetails.length).map((item, index) => {
										return (item[34]==' ' ?<tr style={{height:'55px',marginTop:0,marginBottom:'25px'}}>											
											<td colSpan={9} style={{textAlign:'right'}}><b>{item[35]+'-- From banking account: '+item[38]+'--Total Remittance:'}</b></td>											
											<td>{'$'+item[31]}</td>
										  </tr>:
										  <tr><td>{item[34] + ' ' +item[35]}</td>
											<td>{'$'+item[7]}</td>
											<td>{'$'+item[4]}</td>
											<td>{'$'+item[9]}</td>
											<td>{'$'+item[11]}</td>
											<td>{'$'+item[15]}</td>
											<td>{'$'+item[19]}</td>
											<td>{'$'+item[17]}</td>
											<td>{'$'+item[21]}</td>
											<td>{'$'+item[31]}</td>
										  </tr>
										  )})}
									</tbody>
								</table>
							</div>				
						</div><br/>														
					</div>)} else if(this.state.payperiod !== "" && this.state.paystub == "2") {return (<div>
						<h2 style={{textAlign:'left'}}><b>{this.state.BranchName.filter(sub=>sub[0]==this.state.branch).map(sub=>sub[3])+" (Monthly Fee was assigned to each bank of your company proportionally)"}</b></h2><br/>
						<div className="label-wrap" style={{textAlign:'center'}}>
							<div className="label">	
								<table>
									<thead>
										<tr>											
											<th style={{width:'200px'}}>Company</th>
											<th style={{width:'150px'}}>Charging Bank</th>
											<th style={{width:'150px'}}>Service Type</th>
											<th style={{width:'200px'}}>Service Fee</th>
											<th style={{width:'200px'}}>Employees</th>
											<th style={{width:'200px'}}>Total Charging Fees</th>											
										</tr>
									</thead><br/>
									<tbody>																			
									{this.state.chargingfee.slice(0, this.state.chargingfee.length).map((item, index) => {
										return (item[0]=='' ?<tr style={{height:'55px',marginTop:0,marginBottom:'25px'}}>											
											<td colSpan={5} style={{textAlign:'right'}}><b>{'Total:'}</b></td>																						
																				
											<td>{'$'+item[5]}</td>
										  </tr>:
										  <tr>
											<td>{item[8]}</td>
											<td>{item[3]}</td>
											<td>{item[7]}</td>
											<td>{item[6]}</td>
											<td>{item[4]}</td>											
											<td>{'$'+item[5]}</td>																				
										  </tr>
										  )})}
									</tbody>
								</table>
							</div>				
						</div><br/>						
					</div>)}})()}		
					{(()=>{if(this.state.paystub == "4") {return (<div>
						<h2>Select Year</h2>					
						<select name="starting" style={{width:'150px'}} value={this.state.starting} onChange={this.handleChange}>
							<option value="">--select--</option>						
							{this.state.startingyear.map((sub,index) => (<option value={sub[0]}>{sub[0]}</option>))}						
						</select><br/><br/>																	
						<h2>Select Month</h2>					
						<select name="ending" style={{width:'150px'}} value={this.state.ending} onChange={this.handleChange}>
							<option value="">--select--</option>						
							{this.state.startingyear.filter(sub=>sub[0] == this.state.starting).map((sub,index) => (<option value={sub[1]}>{sub[2]}</option>))}						
						</select><br/><br/>							
					</div>)}})()}
					{(()=>{if(this.state.paystub == "4" && this.state.ending !="") {return (<div>						
						<div className="label-wrap" style={{textAlign:'center'}}>
							<div className="label">	
								<table>
									<thead>
										<tr>											
											<th style={{width:'100px'}}>Date</th>
											<th style={{width:'150px'}}>Company Name</th>
											<th style={{width:'150px'}}>Company Branch</th>
											<th style={{width:'150px'}}>Service Type</th>
											<th style={{width:'200px'}}>Per Employee Rate</th>
											<th style={{width:'200px'}}>Monthly Rate</th>
											<th style={{width:'200px'}}>Paid Employees</th>
											<th style={{width:'200px'}}>Service Fees</th>											
										</tr>
									</thead><br/>
									<tbody>																			
									{this.state.clientfee.filter(sub=>sub[2] == this.state.starting && sub[3] == this.state.ending).slice(0, this.state.clientfee.length).map((item, index) => {
										return (
										/*item[0]==' ' ? <tr style={{height:'55px',marginTop:0,marginBottom:'25px'}}>											
											<td colSpan={3} style={{textAlign:'right'}}><b>{'Total:'}</b></td>																						
											<td>{item[4]}</td>											
											<td>{'$'+item[5]}</td>
										  </tr>:
										  <tr><td>{item[1]}</td>
											<td>{'$'+item[2]}</td>
											<td>{'$'+item[3]}</td>
											<td>{item[4]}</td>											
											<td>{'$'+item[5]}</td>																				
										</tr>*/
										item[0]==' ' ? <tr style={{height:'55px',marginTop:0,marginBottom:'25px'}}>											
											<td colSpan={6} style={{textAlign:'right'}}><b>{'Total:'}</b></td>																						
											<td>{item[10]}</td>
											<td>{'$'+item[8]}</td>
										  </tr>:
										  <tr><td>{item[2]+'-'+item[12]}</td>
											<td>{item[11]}</td>
											<td>{item[4]}</td>
											<td>{item[9]}</td>
											<td>{item[5]}</td>											
											<td>{item[6]}</td>											
											<td>{item[10]}</td>											
											<td>{'$'+item[8]}</td>																				
										</tr>
										
										
										  )})}
										  
									</tbody>
								</table>
							</div>				
						</div><br/>	
						<h2>{this.state.Resp}</h2>
					</div>)}})()}	
					{(()=>{if(this.state.paystub == "5") {return (<div>
						<h2>Payment Functions</h2>	
						<select name="payees" value = {this.state.payees} style={{width:'150px'}} onChange={this.handleChange}>
							<option value="">--Select--</option>
							<option value="1">--Add Payee--</option>
							<option value="2">--Setup Payment--</option>
						</select><br/><br/>	
						<h2>{this.state.pcomm == 0 ? "Since no payee set up in the system, please add Payee first":""}</h2>
						{(()=>{if(this.state.payees == "2") {return (<div>
							<h2>Payor Bank Information</h2>								
							<select name="BName" value = {this.state.BName} style={{width:'150px'}} onChange={this.handlePayeeChange}>
								<option value="">--Select Bank--</option>
								{this.state.payorbank.map(item => (<option value={item[0]} key={item[0]}>{item[3]}</option>))}
							</select><br/><br/>	
							<h2>Payor Name</h2>							
							<input type="text" name ="payname" style={{width:'150px'}} value={this.state.payname} onChange={this.handleChange} /><br/><br/>
							<h2>Payment Amount</h2>							
							<input type="text" name ="HWamount" style={{width:'150px'}} value={this.state.HWamount} onChange={this.handleChange} /><br/><br/>
							<div className="forinput">
								<label className="labelname">Financial Institution Number:</label>
								<input type="text" name ="FINID" style={{width:'150px'}} value={this.state.BName} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Branch Transit Number:</label>
								<input type="text" name ="BTransit" style={{width:'150px'}} value={this.state.BTransit} onChange={this.handleChange} maxLength="5"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Checking Account Number:</label>
								<input type="text" name ="CNumber" style={{width:'150px'}} value={this.state.CNumber} onChange={this.handleChange} maxLength="12"/>
							</div><br/>
							
							<h2>Payee Bank Information</h2>						
							<select name="TBNameID" style={{width:'150px'}} value={this.state.TBNameID} onChange={this.handlePaymentChange}>
								<option value="">--Select Bank--</option>
								{this.state.payeeall.map(item => (<option value={item[0]} key={item[0]}>{item[5]}</option>))}
							</select><br/><br/>								
							<h2>Payee Name</h2>							
							<input type="text" name ="Toname" style={{width:'150px'}} value={this.state.Toname} onChange={this.handleChange} /><br/><br/>
							
							<div className="forinput">
								<label className="labelname">Financial Institution Number:</label>
								<input type="text" name ="TFINID" style={{width:'150px'}} value={this.state.TFINID} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Branch Transit Number:</label>
								<input type="text" name ="TBTransit" style={{width:'150px'}} value={this.state.TBTransit} onChange={this.handleChange} maxLength="5"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Checking Account Number:</label>
								<input type="text" name ="TCNumber" style={{width:'150px'}} value={this.state.TCNumber} onChange={this.handleChange} maxLength="12"/>
							</div><br/>
						</div>)}})()}
						{(()=>{if(this.state.payees == "1") {return (<div>
							<h2>Payee Name</h2>							
							<input type="text" name ="Toname" style={{width:'150px'}} value={this.state.Toname} onChange={this.handleChange} /><br/><br/>
							<h2>Payee Bank Information</h2>									
							<select name="TBName" style={{width:'150px'}} value={this.state.TBName} onChange={this.handleChange}>
								<option value="">--Select Bank--</option>
								{this.state.Banks.map(item => (<option value={item[2]} key={item[0]}>{item[1]}</option>))}
							</select><br/><br/>								
							<div className="forinput">
								<label className="labelname">Financial Institution Number:</label>
								<input type="text" name ="TFINID" style={{width:'150px'}} value={this.state.TBName} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Branch Transit Number:</label>
								<input type="text" name ="TBTransit" style={{width:'150px'}} value={this.state.TBTransit} onChange={this.handleChange} maxLength="5"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Checking Account Number:</label>
								<input type="text" name ="TCNumber" style={{width:'150px'}} value={this.state.TCNumber} onChange={this.handleChange} maxLength="12"/>
							</div><br/>
						</div>)}})()}
						{(()=>{if(this.state.payees == "2") {return (<div>
							<label><b>When to Pay</b></label><br/>										
							<label style={{width: '150px',display:'inline-block',textAlign:'right'}}></label>						
							<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Year</small></label>
							<label style={{width: '50px',display:'inline-block',textAlign:'center'}}><small>Month</small></label>
							<label style={{width: '55px',display:'inline-block',textAlign:'center'}}><small>Day</small></label><br/>																	
							<label style={{width: '150px',display:'inline-block'}}><b>Payday:</b></label>	
							<select style={{width: '55px',border:'1px solid #4195fc'}} name="fdate1" value={this.state.fdate1} onChange={this.handleChange}>
								<option value="">--</option> 
								<option value={thisyear}>{thisyear}</option> 
								<option value={thisyear}>{nextyear}</option> 
							</select>-
							<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate2" value={this.state.fdate2} onChange={this.handleChange}>
								<option value="">--</option>
								{TDerived('M').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}
							</select>-
							<select style={{width: '44px',border:'1px solid #4195fc'}} name="fdate3" value={this.state.fdate3} onChange={this.handleChange}>
								<option value="">--</option>
								{TDerived('D').reverse().map(item => (<option value={item.slice(0,4)} key={item.key}>{item.slice(0,4)}</option>))}
							</select><br/><br/>
							<label style={{width: '150px',display:'inline-block'}}><b>How Often:</b></label>							
							<select style={{width: '150px',border:'1px solid #4195fc'}} name="fof" value={this.state.fof} onChange={this.handleChange}>
								<option value="">--</option>
								<option value="1">-1-</option>
								<option value="2">-2-</option>
								<option value="3">-3-</option>
								<option value="4">-4-</option>
								<option value="5">-5-</option>
								<option value="6">-6-</option>
								<option value="7">-7-</option>
								<option value="8">-8-</option>
								<option value="9">-9-</option>
								<option value="10">10--</option>
								<option value="11">-11-</option>
								<option value="12">-12-</option>
							</select><br/><br/><br/>
						</div>)}})()}
						<h3>{this.state.Rest}</h3><br/><br/>
						<input type="submit" name="submit" value="Submit for Payment" /><br/><br/>
					</div>)}})()}
				</form>			
			</div>
		)
	}
}




export default reportForm;

