import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupTaxAmounts extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:14,managementitem:'', Resp:'',Pro:[],employeeUpdate:'', basicbottom:'', 
		basichigh:'',nsadditional:'',incomerange:'',incomerangede:'',cppmax:'',cppexemption:'',cppee:'',cpper:'',
		eimax:'',eiee:'',eier:'',qpipmax:'',qpipee:'',qpiper:'',yearclaimcode:'',taxcomments:'',infirmamt:'',
		infirmex:'',copytd1form:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);
		this.handleManageChange=this.handleManageChange.bind(this)
		this.handleUpdateChange=this.handleUpdateChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {				
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				console.log(result.data)
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});			
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
	}	
	handleManageChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({employeeUpdate:""});	
		this.setState({yearclaimcode:''})
		this.setState({basicamount:''})
		this.setState({basicbottom:''})
		this.setState({basichigh:''})
		this.setState({nsadditional:''})
		this.setState({incomerange:''})
		this.setState({incomerangede:''})
		this.setState({cppmax:''})
		this.setState({cppexemption:''})
		this.setState({cppee:''})
		this.setState({cpper:''})
		this.setState({eimax:''})
		this.setState({eiee:''})
		this.setState({eier:''})
		this.setState({qpipmax:''})
		this.setState({qpipee:''})
		this.setState({qpiper:''})				
		this.setState({infirmamt:''})
		this.setState({infirmre:''})
		this.setState({infirmex:''})
		this.setState({copytd1form:''})
	}
	handleUpdateChange = event=>{		
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({basicamount:''})
		this.setState({basicbottom:''})
		this.setState({basichigh:''})
		this.setState({nsadditional:''})
		this.setState({incomerange:''})
		this.setState({incomerangede:''})
		this.setState({cppmax:''})
		this.setState({cppexemption:''})
		this.setState({cppee:''})
		this.setState({cpper:''})
		this.setState({eimax:''})
		this.setState({eiee:''})
		this.setState({eier:''})
		this.setState({qpipmax:''})
		this.setState({qpipee:''})
		this.setState({qpiper:''})
		this.setState({infirmamt:''})
		this.setState({infirmre:''})
		this.setState({infirmex:''})		
		this.setState({taxcomments:""})
		this.setState({copytd1form:''})
		if(this.state.managementitem =='2'||this.state.managementitem==3) {
			const userObject={state:event.target.value,
							  year:this.state.yearclaimcode}
			axios.post(process.env.REACT_APP_API_ENV+'api/updatetd1formwording.php',userObject)
				.then(result => {
					if (!result.data || result.data==0) {
						this.setState({taxcomments:'Sorry, no TD1 Form Wording in the system, please add wording first'})
					} else  {						
						this.setState({basicamount:result.data[2]})
						this.setState({basicbottom:result.data[3]})
						this.setState({basichigh:result.data[4]})
						this.setState({nsadditional:result.data[5]})
						this.setState({incomerange:result.data[6]})
						this.setState({incomerangede:result.data[7]})
						this.setState({cppmax:result.data[9]})
						this.setState({cppexemption:result.data[10]})
						this.setState({cppee:result.data[11]})
						this.setState({cpper:result.data[12]})
						this.setState({eimax:result.data[13]})
						this.setState({eiee:result.data[14]})
						this.setState({eier:result.data[15]})
						this.setState({qpipmax:result.data[8]})
						this.setState({qpipee:result.data[16]})
						this.setState({qpiper:result.data[18]})							
						this.setState({infirmamt:result.data[17]})								
						this.setState({infirmex:result.data[19]})								
					} 
				})		
		}
		this.setState({Resp:""});		
	}
	handleYearChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({employeeUpdate:''})
		this.setState({Resp:""});
		this.setState({copytd1form:''})
	}
	handleSubmit = event => {		
		event.preventDefault()		
		const {managementitem,basicamount,yearclaimcode, employeeUpdate,basicbottom,basichigh,nsadditional,incomerange,incomerangede,
				cppmax,cppexemption,cppee,cpper,eimax,eiee,eier,qpipmax,qpipee,qpiper,infirmamt,infirmex,copytd1form} = this.state			
		if (this.state.managementitem == "") {
			alert('Please select action type for the tax amounts: Add, Update or Delete')
			return false
		}		
		if(this.state.managementitem==1)  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}
			if(this.state.copytd1form == "" || this.state.copytd1form==0) {
				if(!basicamount ) {
					alert('Please enter Total Lines in TD1 Form')
					return false
				}						
				if(!basicbottom) {
					alert('Please enter basic personal amount wording in TD1 Form')
					return false
				}						
				if(this.state.employeeUpdate=='FD'||this.state.employeeUpdate=='YT') {
					if(!basichigh) {
						alert('Please enter wording for Caregive amount for infirm children under age 18 in TD1 Form')
						return false
					}	
				}
				if(!nsadditional) {
					alert('Please enter wording for age amount')
					return false
				}				
				if(!incomerange) {
					alert('Please enter wording for pension income amount')
					return false
				}
				if(!incomerangede && (this.state.employeeUpdate != 'ON' && this.state.employeeUpdate != 'AB')) {			
					alert('Please enter wording for Tuition amount')
					return false
				}
				if(!qpipmax) {			
					alert('Please enter wording for disability amount')
					return false
				}
				if(!cppmax) {			
					alert('Please enter wording for spouse or common-paw partner amount')
					return false
				}
				if(!cppexemption) {
					alert('Please enter wording of amount for an eligible dependant')
					return false
				}			
				if(!cppee) {
					alert('Please enter wording of caregiver amount for eligible dependant or spouse or common-law partner')
					return false
				}
				if(this.state.employeeUpdate != 'ON' && this.state.employeeUpdate !='BC') {						
					if(!cpper) {
						alert('Please enter wording of caregiver amount for dependant(s) age 18 or older')
						return false
					}
				}
				if(!eimax) {
					alert('Please enter wording form amounts transferred from your spouse or common-law partner')
					return false
				}
				if(!eiee) {
					alert('Please enter wording for Amounts transferred from a dependant')
					return false
				}
				if(!eier) {						
					alert('Please enter wording for Total claim amount')
					return false
				}	
				if(!basichigh && this.state.employeeUpdate =='SK') {									
					alert('Please enter wording for senior supplementary amount')
					return false
				}
				if(!qpipee && this.state.employeeUpdate =='MB') {
					alert('Please enter wording for family tax benefit')
					return false
				} 
				if(this.state.employeeUpdate =='NS') {
					if(!qpipee) {
						alert('Please enter wording for Age supplement amount')
						return false
					} 
					if(!infirmamt) {						
						alert('Please enter wording for spouse supplement amount')
						return false
					}						
					if(!qpiper) {									
						alert('Please enter wording for eligible dependant supplement amount')
						return false
					}
				}	
				if(this.state.employeeUpdate != 'ON' && this.state.employeeUpdate !='BC') {									
					if(!infirmex) {									
						alert('Please enter Line number which the TD1 Form some lines point to')
						return false
					}				
				}
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				basicamount:this.state.basicamount,
				basicbottom:this.state.basicbottom,
				basichigh:this.state.basichigh,
				nsadditional:this.state.nsadditional,
				incomerange:this.state.incomerange,
				incomerangede:this.state.incomerangede,
				cppmax:this.state.cppmax,
				cppexemption:this.state.cppexemption,
				cppee:this.state.cppee,
				cpper:this.state.cpper,
				eimax:this.state.eimax,
				eiee:this.state.eiee,
				eier:this.state.eier,
				qpipmax:this.state.qpipmax,
				qpipee:this.state.qpipee,
				qpiper:this.state.qpiper,
				infirmamt:this.state.infirmamt,				
				infirmex:this.state.infirmex,
				copytd1form:this.state.copytd1form
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {		
					console.log(res.data)
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} if(this.state.managementitem==2)  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}
				if(!basicamount ) {
					alert('Please enter Total Lines in TD1 Form')
					return false
				}						
				if(!basicbottom) {
					alert('Please enter basic personal amount wording in TD1 Form')
					return false
				}						
				if(this.state.employeeUpdate=='FD'||this.state.employeeUpdate=='YT') {
					if(!basichigh) {
						alert('Please enter wording for Caregive amount for infirm children under age 18 in TD1 Form')
						return false
					}	
				}
				if(!nsadditional) {
					alert('Please enter wording for age amount')
					return false
				}				
				if(!incomerange) {
					alert('Please enter wording for pension income amount')
					return false
				}
				if(!incomerangede && (this.state.employeeUpdate != 'ON' && this.state.employeeUpdate != 'AB')) {			
					alert('Please enter wording for Tuition amount')
					return false
				}
				if(!qpipmax) {			
					alert('Please enter wording for disability amount')
					return false
				}
				if(!cppmax) {			
					alert('Please enter wording for spouse or common-paw partner amount')
					return false
				}
				if(!cppexemption) {
					alert('Please enter wording of amount for an eligible dependant')
					return false
				}			
				if(!cppee) {
					alert('Please enter wording of caregiver amount for eligible dependant or spouse or common-law partner')
					return false
				}
				if(this.state.employeeUpdate != 'ON' && this.state.employeeUpdate !='BC') {						
					if(!cpper) {
						alert('Please enter wording of caregiver amount for dependant(s) age 18 or older')
						return false
					}
				}
				if(!eimax) {
					alert('Please enter wording form amounts transferred from your spouse or common-law partner')
					return false
				}
				if(!eiee) {
					alert('Please enter wording for Amounts transferred from a dependant')
					return false
				}
				if(!eier) {						
					alert('Please enter wording for Total claim amount')
					return false
				}	
				if(!basichigh && this.state.employeeUpdate =='SK') {									
					alert('Please enter wording for senior supplementary amount')
					return false
				}
				if(!qpipee && this.state.employeeUpdate =='MB') {
					alert('Please enter wording for family tax benefit')
					return false
				} 
				if(this.state.employeeUpdate =='NS') {
					if(!qpipee) {
						alert('Please enter wording for Age supplement amount')
						return false
					} 
					if(!infirmamt) {						
						alert('Please enter wording for spouse supplement amount')
						return false
					}						
					if(!qpiper) {									
						alert('Please enter wording for eligible dependant supplement amount')
						return false
					}
				}	
				if(this.state.employeeUpdate != 'ON' && this.state.employeeUpdate !='BC') {									
					if(!infirmex) {									
						alert('Please enter Line number which the TD1 Form some lines point to')
						return false
					}				
				}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				basicamount:this.state.basicamount,
				basicbottom:this.state.basicbottom,
				basichigh:this.state.basichigh,
				nsadditional:this.state.nsadditional,
				incomerange:this.state.incomerange,
				incomerangede:this.state.incomerangede,
				cppmax:this.state.cppmax,
				cppexemption:this.state.cppexemption,
				cppee:this.state.cppee,
				cpper:this.state.cpper,
				eimax:this.state.eimax,
				eiee:this.state.eiee,
				eier:this.state.eier,
				qpipmax:this.state.qpipmax,
				qpipee:this.state.qpipee,
				qpiper:this.state.qpiper,
				infirmamt:this.state.infirmamt,				
				infirmex:this.state.infirmex,
				copytd1form:this.state.copytd1form
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {		
					console.log(res.data)
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.managementitem==3)  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}	
			const userObject={		
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (		
			<div>   
				<CommonHeader titles="TD1Form"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>TD1 Form Wording</h2></label>							
						<select name="managementitem" id="managementitem" style={{width:'150px'}} value={this.state.managementitem} onChange={this.handleManageChange}>
							<option value="">--TD1 Form Wording--</option>
							<option value="1">Set up TD1Form Wording</option>															
							<option value="2">Update TD1Form Wording</option>												
							<option value="3">Remove TD1Form Wording</option>									
						</select><br/><br/>	
						{(()=>{if(this.state.managementitem != "") {return(<div>						
								<label><h2>Select Year</h2></label>							
								<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleYearChange}>
									<option value="">--Select Year--</option>									
									{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}									
								</select><br/><br/>
								{(()=>{						
									if(this.state.yearclaimcode !="" ) {return(<div>
										<label><h2>Choose Federal or Province</h2></label>							
										<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleUpdateChange}>
											<option value="">--Federal or Province--</option>									
											{this.state.Pro.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
										</select><br/><br/>
										{(()=>{						
											if(this.state.employeeUpdate !="" && this.state.managementitem==1) {return(<div>
												<label><h2>You can copy Previous Year TD1 Form Wording and Update them if needed</h2></label>							
												<select id="copytd1form" name="copytd1form" value = {this.state.copytd1form} style={{width:'160px'}} onChange={this.handleChange}>
													<option value="">--Copy or Not Copy--</option>									
													<option value="0">--Do not Copy--</option>									
													<option value="1">--Yes, Copy--</option>									
												</select><br/><br/>
											</div>)}									
										})()}
									</div>)}																		
								})()}
							</div>)} 							
						})()}												
					</div>	
					{(()=>{
						{/*set up/update/remove wording*/}
						if(this.state.managementitem != "" && this.state.employeeUpdate != ""  && this.state.copytd1form != 1) {return(<div>							
							<h1>{this.state.taxcomments}</h1><br/><br/>
							<div className="forinput">
								<label className="labelname">Total Lines in TD1 Form: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>					
							<div className="forinput">
								<label className="labelname">Basic Personal Amount Wording: </label>
								<input type="text" name ="basicbottom" id="basicbottom" style={{width:'150px'}} value={this.state.basicbottom} onChange={this.handleChange} />
							</div><br/>	
							{(()=>{
								{/*Only FD and YT*/}
								if(this.state.employeeUpdate == "FD"||this.state.employeeUpdate == "YT") {return(<div>											
									<div className="forinput">
										<label className="labelname">Caregive Amount for Infirm Children (18-): </label>
										<input type="text" name ="basichigh" id="basichigh" style={{width:'150px'}} value={this.state.basichigh} onChange={this.handleChange} />
									</div><br/>				
								</div>)} 							
							})()}								
							<div className="forinput">
								<label className="labelname">Age Amount Wording: </label>
								<input type="text" name ="nsadditional" id="nsadditional" style={{width:'150px'}} value={this.state.nsadditional} onChange={this.handleChange} />
							</div><br/>							
							<div className="forinput">
								<label className="labelname">Pension Income Amount Wording: </label>
								<input type="text" name ="incomerange" id="incomerange" style={{width:'150px'}} value={this.state.incomerange} onChange={this.handleChange} />
							</div><br/>	
							{(()=>{
								{/*not ON, AB*/}
								if(this.state.employeeUpdate != "ON" && this.state.employeeUpdate != "AB") {return(<div>									
									<div className="forinput">
										<label className="labelname">Tuition Wording: </label>
										<input type="text" name ="incomerangede" id="incomerangede" style={{width:'150px'}} value={this.state.incomerangede} onChange={this.handleChange} />
									</div><br/>																		
								</div>)} 							
							})()}	
							<div className="forinput">
								<label className="labelname">Disability Amount Wording: </label>
								<input type="text" name ="qpipmax" id="qpipmax" style={{width:'150px'}} value={this.state.qpipmax} onChange={this.handleChange} />
							</div><br/>	
							<div className="forinput">
								<label className="labelname">Spouse Amount Wording: </label>
								<input type="text" name ="cppmax" id="cppmax" style={{width:'150px'}} value={this.state.cppmax} onChange={this.handleChange} />
							</div><br/>								
							<div className="forinput">
								<label className="labelname">Eligible Dependant Amount Wording: </label>
								<input type="text" name ="cppexemption" id="cppexemption" style={{width:'150px'}} value={this.state.cppexemption} onChange={this.handleChange} />
							</div><br/>	
							<div className="forinput">
								<label className="labelname">Eligible Dependant Caregive Wording: </label>
								<input type="text" name ="cppee" id="cppee" style={{width:'150px'}} value={this.state.cppee} onChange={this.handleChange} />
							</div><br/>						
							{(()=>{
								{/*not ON, BC*/}
								if(this.state.employeeUpdate != "ON" && this.state.employeeUpdate != "BC") {return(<div>									
									<div className="forinput">
										<label className="labelname">Eligible Dependant Caregive Wording (18+): </label>
										<input type="text" name ="cpper" id="cpper" style={{width:'150px'}} value={this.state.cpper} onChange={this.handleChange} />
									</div><br/>
								</div>)} 							
							})()}	
							<div className="forinput">
								<label className="labelname">Amount Transferred From Spouse Wording: </label>
								<input type="text" name ="eimax" id="eimax" style={{width:'150px'}} value={this.state.eimax} onChange={this.handleChange} />
							</div><br/>																			
							<div className="forinput">
								<label className="labelname">Amount Transferred From Dependant Wording: </label>
								<input type="text" name ="eiee" id="eiee" style={{width:'150px'}} value={this.state.eiee} onChange={this.handleChange} />
							</div><br/>								
							<div className="forinput">
								<label className="labelname">Total Claim Amount Wording: </label>
								<input type="text" name ="eier" id="eier" style={{width:'150px'}} value={this.state.eier} onChange={this.handleChange} />
							</div><br/>
							{(()=>{
								{/*SK*/}
								if(this.state.employeeUpdate == "SK") {return(<div>
									<div className="forinput">
										<label className="labelname">Senior Supplementary Amount Wording: </label>
										<input type="text" name ="basichigh" id="basichigh" style={{width:'150px'}} value={this.state.basichigh} onChange={this.handleChange} />
									</div><br/>
								</div>)} 							
							})()}
							{(()=>{
								{/*MB*/}
								if(this.state.employeeUpdate == "MB") {return(<div>
									<div className="forinput">
										<label className="labelname">Family Tax Benefit Wording:</label>
										<input type="text" name ="qpipee" id="qpipee" style={{width:'150px'}} value={this.state.qpipee} onChange={this.handleChange} />
									</div><br/>	
								</div>)} 							
							})()}
							{(()=>{
								{/*NS*/}
								if(this.state.employeeUpdate == "NS") {return(<div>
									<div className="forinput">
										<label className="labelname">Age Supplement Amount Wording:</label>
										<input type="text" name ="qpipee" id="qpipee" style={{width:'150px'}} value={this.state.qpipee} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">Spouse Supplement Amount Wording:</label>
										<input type="text" name ="infirmamt" id="infirmamt" style={{width:'150px'}} value={this.state.infirmamt} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">Dependant Supplement Amount Wording: </label>
										<input type="text" name ="qpiper" id="qpiper" style={{width:'150px'}} value={this.state.qpiper} onChange={this.handleChange} />
									</div><br/>											
								</div>)} 							
							})()}
							{(()=>{
								{/*not in ON, BC */}
								if(this.state.employeeUpdate != "ON" && this.state.employeeUpdate != "BC") {return(<div>							
									<div className="forinput">
										<label className="labelname">Point to Line Number : </label>
										<input type="text" name ="infirmex" id="infirmex" style={{width:'150px'}} value={this.state.infirmex} onChange={this.handleChange} />
									</div><br/>
								</div>)} 							
							})()}							
							<h1>{this.state.Resp}</h1><br/><br/>						
						</div>)} 
					})()}	
					<h1>{this.state.copytd1form==1?this.state.Resp:""}</h1><br/><br/>	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupTaxAmounts;