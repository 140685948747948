import React from "react";
import axios from 'axios'
import dayjs from 'dayjs';
import CommonHeader from '../clients/commons/Head'


//to create timesheet input form
class approvalForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			firstname : '',	lastname : '', address : '', city:'', province:'', postcode:'', branch : '',
			checknumber: '', bankname:'',phone:'',email:'', finnumber:'',
			isUpdating:false,tempc:[],paystub:'', pemployee:'',pemployeecomments:'', ptimesheet:'', report:2,
			timeSheetEmployee:[], paymentSheet:[], payfreq:'', paytimes:'', starting:'',
			dayFormat:[],weekFormat:[],nameForInput:[],dispLength:'',TotalHours:'',lumpsum:'',
			valueInput:[], comments:'',apprej:'', TestValue:[], deadlines:'',paydays:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};		
		this.handleChange=this.handleChange.bind(this);
		this.handleReportChange = this.handleReportChange.bind(this);		
		this.handlePayChange=this.handlePayChange.bind(this);	
		this.handleEmployeeChange=this.handleEmployeeChange.bind(this);
		this.handleTimesheetChange=this.handleTimesheetChange.bind(this);
		this.handleApprovalChange=this.handleApprovalChange.bind(this);
					
	}

	componentDidMount() {				
		const para={user:this.state.user, names:this.state.names}
		axios.post(process.env.REACT_APP_API_ENV+'api/timesheetemployee.php', para)
			.then(result => {
				if(result.data[0][0] !="No") {	
					this.setState({timeSheetEmployee:result.data})	
					this.setState({pemployeecomments:''})
				} else {this.setState({pemployeecomments:'No'})}
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/paymenttimesheet.php', para)
			.then(result => {						
				this.setState({paymentSheet:result.data})	
			})	
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php', para)
			.then(result => {				
				this.setState({payfreq:result.data[4]})																
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/clientapprovaldeadline.php', para)
			.then(result => {				
				this.setState({deadlines:result.data[1]})																
				this.setState({paydays:result.data[0]})
			})			
				
		this.setState({checknumber: this.state.checknumber.slice(0,this.state.checknumber.length-4) + 'xxxx'})
	}
	
	//the following is working function, but changed to below
	TDisplayFormat_ORIG =(DFormat, MD, VES) =>{	
		const lines =Math.ceil(MD/7)		
		const LB=[]
		for (let i=0;i<lines;i++) {			
			LB.push(<div key={i}><div className="label-wrap">
				<div className="label">					
					{DFormat.slice(i*7,((7*(i+1)>MD) ? MD:7*(i+1))).map((item, index) => {return<h2 key={index}>{item}</h2>;})} 
				</div>				
			</div>
			<div className="label-wrap">
				<div className="label">	
					{VES.slice(i*7,((7*(i+1)>MD) ? MD:7*(i+1))).map((item, index) => {return<h2 key={index}><input type="text" style={{width:'100px'}} name={item} value={item} readOnly/></h2>;})}
				</div>
			</div><br/><br/></div>)
		}
			
		return LB
	}
	TDisplayFormat =(DFormat, MD, VES) =>{	
		const lines =Math.ceil(MD/7)	
		const SecondWeekdays = MD > 13 ? 14:13
		const ThirdWeekdays  = MD == 15 ? 15:(MD == 16 ? 16 : 21)
		const FifthWeekdays  = MD > 30 ? 31:(MD > 29 ? 30 : 29)
		return(<div>
			<div className="label-wrap">
				<div className="label">					
					{DFormat.slice(0,7).map((item, index) => {return<h2 key={index}>{item}</h2>;})}
				</div>				
			</div>
			<div className="label-wrap">
				<div className="label">				
					<h2><input type="text" style={{width:'100px'}} name="Dates1" value={this.state.Dates1} onChange={this.handleChange}/></h2>
					<h2><input type="text" style={{width:'100px'}} name="Dates2" value={this.state.Dates2} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates3" value={this.state.Dates3} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates4" value={this.state.Dates4} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates5" value={this.state.Dates5} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates6" value={this.state.Dates6} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates7" value={this.state.Dates7} onChange={this.handleChange}/></h2>					
				</div>
			</div><br/><br/>		
			{MD > 7 ? 
			<div className="label-wrap">
				<div className="label">					
					{DFormat.slice(7,SecondWeekdays).map((item, index) => {return<h2 key={index}>{item}</h2>;})} 
				</div>				
			</div>:""}
			{MD > 7 ? 
			<div className="label-wrap">
				<div className="label">				
					<h2><input type="text" style={{width:'100px'}} name="Dates8" value={this.state.Dates8} onChange={this.handleChange}/></h2>
					<h2><input type="text" style={{width:'100px'}} name="Dates9" value={this.state.Dates9} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates10" value={this.state.Dates10} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates11" value={this.state.Dates11} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates12" value={this.state.Dates12} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates13" value={this.state.Dates13} onChange={this.handleChange}/></h2>					
					{MD > 13 ? <h2><input type="text" style={{width:'100px'}} name="Dates14" value={this.state.Dates14} onChange={this.handleChange}/></h2>:""}					
				</div>
			</div>:""}<br/><br/>			
			{MD > 14 ? 
			<div className="label-wrap">
				<div className="label">					
					{DFormat.slice(14,ThirdWeekdays).map((item, index) => {return<h2 key={index}>{item}</h2>;})} 
				</div>				
			</div>:""}
			{MD > 14 ? 
			<div className="label-wrap">
				<div className="label">				
					<h2><input type="text" style={{width:'100px'}} name="Dates15" value={this.state.Dates15} onChange={this.handleChange}/></h2>
					{ThirdWeekdays>15 ? <h2><input type="text" style={{width:'100px'}} name="Dates16" value={this.state.Dates16} onChange={this.handleChange}/></h2>:""}
					{ThirdWeekdays>16 ? <h2><input type="text" style={{width:'100px'}} name="Dates17" value={this.state.Dates17} onChange={this.handleChange}/></h2>:""}					
					{ThirdWeekdays>16 ? <h2><input type="text" style={{width:'100px'}} name="Dates18" value={this.state.Dates18} onChange={this.handleChange}/></h2>:""}				
					{ThirdWeekdays>16 ? <h2><input type="text" style={{width:'100px'}} name="Dates19" value={this.state.Dates19} onChange={this.handleChange}/></h2>:""}				
					{ThirdWeekdays>16 ? <h2><input type="text" style={{width:'100px'}} name="Dates20" value={this.state.Dates20} onChange={this.handleChange}/></h2>:""}					
					{ThirdWeekdays>16 ? <h2><input type="text" style={{width:'100px'}} name="Dates21" value={this.state.Dates21} onChange={this.handleChange}/></h2>:""}				
				</div>
			</div>:""}<br/><br/>
			{MD > 17 ? <div>
			<div className="label-wrap">
				<div className="label">					
					{DFormat.slice(21,28).map((item, index) => {return<h2 key={index}>{item}</h2>;})} 
				</div>				
			</div>
			<div className="label-wrap">
				<div className="label">				
					<h2><input type="text" style={{width:'100px'}} name="Dates22" value={this.state.Dates22} onChange={this.handleChange}/></h2>
					<h2><input type="text" style={{width:'100px'}} name="Dates23" value={this.state.Dates23} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates24" value={this.state.Dates24} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates25" value={this.state.Dates25} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates26" value={this.state.Dates26} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates27" value={this.state.Dates27} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates28" value={this.state.Dates28} onChange={this.handleChange}/></h2>					
				</div>
			</div><br/><br/></div>:""}
			{MD > 28 ? <div>
			<div className="label-wrap">
				<div className="label">					
					{DFormat.slice(28,FifthWeekdays).map((item, index) => {return<h2 key={index}>{item}</h2>;})} 
				</div>				
			</div>
			<div className="label-wrap">
				<div className="label">				
					{FifthWeekdays > 28 ? <h2><input type="text" style={{width:'100px'}} name="Dates29" value={this.state.Dates29} onChange={this.handleChange}/></h2>:""}
					{FifthWeekdays > 29 ? <h2><input type="text" style={{width:'100px'}} name="Dates30" value={this.state.Dates30} onChange={this.handleChange}/></h2>:""}					
					{FifthWeekdays > 30 ? <h2><input type="text" style={{width:'100px'}} name="Dates31" value={this.state.Dates31} onChange={this.handleChange}/></h2>:""}										
				</div>
			</div><br/><br/></div>:""}
		</div>)							
	}
	
	handleChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({pemployeecomments:''})
	}
	
	handleReportChange = event => {
		this.setState({report: event.target.value});
		this.setState({pemployeecomments:''})
	}
	
	handlePayChange = event => {								
		this.setState({paystub: event.target.value});
		this.setState({pemployeecomments:''})
		
	}
	
	handleEmployeeChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({ptimesheet:''})
		this.setState({pemployeecomments:''})
		this.setState({Resp:''})		
	}
	
	handleTimesheetChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({dispLength:dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[6]).[0]).diff(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0],'d')+1})
		this.setState({lumpsum:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[7]).[0]})
		this.setState({TotalHours:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[8]).[0]})
		this.state.valueInput.length=0		
		for (let i=0;i<dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[6]).[0]).diff(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0],'d')+1;i++) {			
			//this.state.Nums.push(JSON.parse(dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0]).add(i,'day').format("D")))															
			let j=JSON.parse(dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0]).add(i,'day').format("D"))+8							
			this.state.valueInput.push(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[JSON.parse(dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0]).add(i,'day').format("D"))+8]).[0])															
		}
		if (this.state.payfreq == 1 || this.state.payfreq == 2) {			
			this.state.dayFormat.length=0
			this.state.weekFormat.length=0
			this.state.nameForInput.length=0
				for (let j=0;j<7*this.state.payfreq;j++) {
					this.state.dayFormat.push(dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0]).add(j,'day').format("YYYY-MM-DD"))
					this.state.weekFormat.push(dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0]).add(j,'day').format("ddd"))	
					this.state.nameForInput.push('Dates'+ dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0]).add(j,'day').format("D"))															
				}								
		} else if (this.state.payfreq == 3 || this.state.payfreq == 4) {																			
			this.state.dayFormat.length=0
			this.state.weekFormat.length=0
			this.state.nameForInput.length=0				
			for (let j=0;j<dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[6]).[0]).diff(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0],'d')+1;j++) {
				this.state.dayFormat.push(dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0]).add(j,'day').format("YYYY-MM-DD"));
				this.state.weekFormat.push(dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0]).add(j,'day').format("ddd"))	
				this.state.nameForInput.push('Dates'+ dayjs(this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0]).add(j,'day').format("D"))						
			}				
						
		}
		this.setState({apprej:''})
		this.setState({starting:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[5]).[0]})
		this.setState({Dates1:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[9]).[0]})
		this.setState({Dates2:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[10]).[0]})
		this.setState({Dates3:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[11]).[0]})
		this.setState({Dates4:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[12]).[0]})
		this.setState({Dates5:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[13]).[0]})
		this.setState({Dates6:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[14]).[0]})
		this.setState({Dates7:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[15]).[0]})
		this.setState({Dates8:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[16]).[0]})
		this.setState({Dates9:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[17]).[0]})
		this.setState({Dates10:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[18]).[0]})
		this.setState({Dates11:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[19]).[0]})
		this.setState({Dates12:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[20]).[0]})
		this.setState({Dates13:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[21]).[0]})
		this.setState({Dates14:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[22]).[0]})
		this.setState({Dates15:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[23]).[0]})
		this.setState({Dates16:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[24]).[0]})
		this.setState({Dates17:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[25]).[0]})
		this.setState({Dates18:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[26]).[0]})
		this.setState({Dates19:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[27]).[0]})
		this.setState({Dates20:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[28]).[0]})
		this.setState({Dates21:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[29]).[0]})
		this.setState({Dates22:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[30]).[0]})
		this.setState({Dates23:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[31]).[0]})
		this.setState({Dates24:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[32]).[0]})
		this.setState({Dates25:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[33]).[0]})
		this.setState({Dates26:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[34]).[0]})
		this.setState({Dates27:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[35]).[0]})
		this.setState({Dates28:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[36]).[0]})
		this.setState({Dates29:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[37]).[0]})
		this.setState({Dates30:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[38]).[0]})
		this.setState({Dates31:this.state.paymentSheet.filter(item=>item[0]==event.target.value).map(sub =>sub[39]).[0]})
		this.setState({Resp:''})
	}
	handleApprovalChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({comments:''})		
		this.setState({Resp:''})
		this.setState({pemployeecomments:''})
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {report, pemployee, ptimesheet, apprej, comments} = this.state			
		if(this.state.report == "") {
			alert('Please select report or timesheet')
			return false
		}			
		if(this.state.pemployee == "") {
			alert('Please select employee')
			return false
		}					
		if(this.state.ptimesheet == "") {
			alert('Please select employee timesheet for approval')
			return false
		}
		if(this.state.apprej == "") {
			alert('Please determine Approve or Reject')
			return false
		} else if(this.state.apprej == "RES") {
			if(this.state.comments == "") {
				alert('Sorry, please provide your comments since you decide to reject the timesheet')
				return false
			}
		}
		const userObject={
				user:this.state.user,
				names:this.state.names,
				report:this.state.report,	
				pemployee: this.state.pemployee, 
				ptimesheet: this.state.ptimesheet, 
				apprej:  this.state.apprej,
				comments:this.state.comments
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/approvetimehours.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});
	}
	render() {			
		return (
			<div>      
				<CommonHeader titles="Timesheet Approval"/>
				<form onSubmit={this.handleSubmit}>						
					<h2>Select Employee</h2>
					<select name="pemployee" style={{width:'150px'}} value={this.state.pemployee} onChange={this.handleEmployeeChange}>
						<option value="">--Select Employee--</option>									
						{this.state.pemployeecomments =='' ? this.state.timeSheetEmployee.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1] +' '+sub[2]}</option>)):''}			
					</select><br/><br/>
					<h2>{"The coming approval deadline is "+this.state.deadlines+" and salary payment day is "+this.state.paydays}<br/></h2><br/>
						
					<h2>{this.state.pemployeecomments == "No"? "Your assigned employee timesheets are not submitted yet or approved already":""}</h2>
					{(()=>{
						if(this.state.pemployee !== "") {return (<div>
							<h2>Approve {this.state.timeSheetEmployee.filter(item=>item[0] == this.state.pemployee).map(item=>item[1])} Time Sheet </h2>
							<select name="ptimesheet" style={{width:'150px'}} value={this.state.ptimesheet} onChange={this.handleTimesheetChange}>
								<option value="">--Select Timesheet for Approval--</option>									
								{this.state.paymentSheet.filter(item=>item[1]==this.state.pemployee).map((sub,index) => (<option value={sub[0]} key={index}>{sub[5] +'  to  '+sub[6] + ' (' + sub[40] + ': ' + sub[41]+')'}</option>))}			
							</select><br/><br/>									
						</div>)}
					})()}	
					{(()=>{
						if(this.state.ptimesheet !== "" && this.state.lumpsum ==1) {return (<div>
							<label><h2>Total Hours</h2></label>		
							<input type="text" name="TotalHours" value={this.state.TotalHours} style={{width:'150px'}} readOnly/><br/>
							<small>This is Bonus or other Non-Periodic Payment</small><br/><br/>
							<label><h2>Approve or Reject</h2></label>			
							<select name="apprej" style={{width:'150px'}} value={this.state.apprej} onChange={this.handleApprovalChange}>
								<option value="">--Select--</option>
								<option value="APP">--Approval--</option>
								<option value="RES">--Reject--</option>
							</select><br/><br/>	
						</div>)} else if(this.state.ptimesheet !== "" && this.state.lumpsum ==0) {return (<div>
						<label><h2>Total Hours</h2></label>		
						<input type="text" name="TotalHours" value={this.state.TotalHours} style={{width:'150px'}} readOnly/><br/><br/>
						<div className="label-wrap">
							<div className="label">					
								{this.state.weekFormat.slice(0,7).map((item,index) => <h2 key={index}>{item}</h2>)} 
							</div>				
						</div>										
						{this.TDisplayFormat(this.state.dayFormat,this.state.dispLength,this.state.valueInput)}
						<label><h2>Approve or Reject</h2></label>			
						<select name="apprej" style={{width:'150px'}} value={this.state.apprej} onChange={this.handleApprovalChange}>
							<option value="">--Select--</option>
							<option value="APP">--Approval--</option>
							<option value="RES">--Reject--</option>
						</select><br/><br/>										
						</div>)}
					})()}	
					{(()=>{
						if(this.state.apprej == "RES") {return (<div>
							<label><h2>Comments</h2></label>						
							<textarea name="comments" id="comments" rows="3" value = {this.state.comments} onChange={this.handleChange} />
							<p>if reject, please comment</p><br/><br/>																
						</div>)}
					})()}						
					<h1>{this.state.Resp}</h1><br/><br/>									
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 								
				</form>			
			</div>
		)
	}
}

export default approvalForm;