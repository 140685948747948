import CommonHeader from '../common/Head'
import React,{useEffect, useState} from 'react'
import {Link,withRouter} from "react-router-dom";
import SampleCheck from '../images/Canadian-Cheque.jpg'
import axios from 'axios'
import dayjs from 'dayjs';
const SundayofWeek=StartDays(1);//dayjs().day(0).format("YYYY-MM-DD");
const DayofMonth=StartDays(3);//dayjs().date(1).format("YYYY-MM-DD");


function StartDays($Type){	
	let TDates=[]
	let TDays=[]
	for(let $i=0;$i<7;$i++) {
		TDates[$i]=dayjs().day(0).add($i+1,'week').format("YYYY-MM-DD");
	}
	for(let $i=0;$i<3;$i++) {
		TDays[$i]=dayjs().date(1).add($i+1,'month').format("YYYY-MM-DD");
	}
	if($Type==1||$Type==2) {
		return TDates;
	} else if($Type==3||$Type==4) {
		return TDays;
	}
	
}

class RegForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			currentStep: 1,	payfreq: '0', paybegin:'0', LName: '', SName: '', ISIC: '', CName: '', CArea:'', Cthree:'', CPhone: '',
			Email: '', Password: '', Password2: '', CAddress: '', CCity: '', CProvince: '', CPostcode: '', Where: '', 
			BName: '', FINID: '', BTransit: '', CNumber: '',Pro:'', Country:'CAN',Resp:'', items:[],Industry:[], 
			media:[],Banks:[], payType:[],payPrice:[],sertype:'',numemp:'0'}
		this.handleChange=this.handleChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)
	}
	
	
	handleChange = event => {
		const {name, value} = event.target
		this.setState({[name]: value})    
	}
		
	handleSubmit = event => {
		event.preventDefault()
		//this.props.history.push('/RegSub')
		const { payfreq, paybegin,LName, SName, ISIC, CName, CArea, Cthree, CPhone, Email, Password, Password2, CAddress, 
				CCity, CProvince, CPostcode, Where, BName, FINID, BTransit, CNumber,sertype,numemp} = this.state			
		if(payfreq =='0') {
			alert('Please select your company pay frequency')
			return false
		}	
		if(sertype =='0') {
			alert('Please choose monthly service fee payment or per employee payment')
			return false
		} else if(sertype =='1') {
			if(numemp =='0') {
				alert('Please tell us how many employees in your company')
				return false
			}
		}				
		if(paybegin =='0') {
			alert('Please select your company start date')
			return false
		}
		if(!LName){
			alert('Please enter your company registered legal name')
			return false
		}
		if(!ISIC){
			alert('Please tell us what is your business')
			return false
		}		
		if(!SName){
			alert('Please enter your company short name which will be displayed in your employee personal page')
			return false
		}
		if(!CName){
			alert('Please enter your company contact person')
			return false
		}
		if(!CArea){
			alert('Please enter your phone area code')
			return false
		}
		if(!Cthree){
			alert('Please enter your phone first three numbers')
			return false
		}
		if(!CPhone){
			alert('Please enter your phone last four numbers so that we can reach your company in case for the support')
			return false
		}
		if(!Email){
			alert('Please enter your email address as your loginID for the future use')
			return false
		} else if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(Email))) {
			alert('Sorry, please enter correct email format')
			return false
		}
		if(!Password){
			alert('Please enter your password')
			return false
		} else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(Password)) {
			alert('Password must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters')
			return false
		}
		if(!Password2){
			alert('Sorry, please confirm your password')
			return false
		} else if (Password2 !== Password){
			alert('Sorry, your password is not matched, pleare re-enter your password' )
			return false
		}
		if(!CAddress){
			alert('Please enter your company address')
			return false
		}
		if(!CCity){
			alert('Please enter your company city location')
			return false
		}
		if(!CProvince){
			alert('Please enter your company province location')
			return false
		}		
		if(!CPostcode){
			alert('Please enter your company address post code')
			return false
		} else if (!/^[A-Za-z]\d[A-Za-z][-]\d[A-Za-z]\d$/.test(CPostcode)){
			alert('Sorry, post code must be as format of A1A-2B2')
			return false
		}	
		if(!Where){
			alert('Please let us know how you know us so that we can serve you better')
			return false
		} 
		if(!BName){
			alert('Please select your company banking name')
			return false
		}	
		if(!BTransit){
			alert('Please enter branch transist number')
			return false
		}	
		if(!CNumber){
			alert('Please enter your company banking checking account number')
			return false
		} else {			
			const userObject = {
				payfreq: this.state.payfreq,				
				paybegin: this.state.paybegin,
				LName: this.state.LName,
				SName: this.state.SName,
				ISIC:  this.state.ISIC,
				CName: this.state.CName,
				CArea: this.state.CArea,
				Cthree: this.state.Cthree,
				CPhone: this.state.CPhone,
				Email: this.state.Email,
				Password: this.state.Password,
				CAddress: this.state.CAddress,
				CCity: this.state.CCity,
				CProvince: this.state.CProvince,
				CPostcode: this.state.CPostcode,
				Country: this.state.Country,
				Where: this.state.Where,
				BName: this.state.BName,
				FINID: this.state.BName,
				BTransit: this.state.BTransit,
				CNumber: this.state.CNumber,
				sertype:this.state.sertype,
				numemp:this.state.numemp
			};
			axios.post(process.env.REACT_APP_API_ENV+'api/registrationSubmit.php', userObject)
				.then((res) => {
					console.log(res.data)		
					this.setState({Resp:res.data});
					if (this.state.Resp === 'No') {
						this.props.history.push('/NotYet')
					} else if (this.state.Resp === 'Yes') {
						this.props.history.push('/Already')
					} else if (this.state.Resp === 'Pass') {
						this.props.history.push('/RegSub')
					}
				}).catch((error) => {
					console.log(error)
				});
			
		}
	}
  
	_next = () => {
		let currentStep = this.state.currentStep
		currentStep = currentStep >= 2? 3: currentStep + 1
		this.setState({currentStep: currentStep})
	}
    
	_prev = () => {
		let currentStep = this.state.currentStep
		currentStep = currentStep <= 1? 1: currentStep - 1
		this.setState({currentStep: currentStep})
	}

/*
* the functions for our button
*/
	previousButton() {
		let currentStep = this.state.currentStep;
		if(currentStep !==1){
			return (
				<button className="btn btn-secondary" type="button" onClick={this._prev}> 
					Previous
				</button>
			)
		}
		return null;
	}

	nextButton(){
		let currentStep = this.state.currentStep;
		if(currentStep <3){
			return (
				<button className="btn btn-primary float-right" type="button" onClick={this._next}>
					Next
				</button>        
			)
		}
		return null;
	}
	
	render() {    
		const {history}=this.props;
		return (
			<React.Fragment>	
				<CommonHeader titles="Registration"/>
				<form onSubmit={this.handleSubmit}>		
					<h2>{this.state.items}</h2>
					<PayFreq currentStep={this.state.currentStep} handleChange={this.handleChange}
						payfreq={this.state.payfreq} 						
						paybegin={this.state.paybegin}
						sertype={this.state.sertype}
						numemp={this.state.numemp}/>
					<ComProfile currentStep={this.state.currentStep} handleChange={this.handleChange}
						LName={this.state.LName} 
						SName={this.state.SName} 
						ISIC={this.state.ISIC} 						
						CName={this.state.CName} 
						CArea={this.state.CArea} 
						Cthree={this.state.Cthree} 
						CPhone={this.state.CPhone} 					
						Email={this.state.Email} 
						Password={this.state.Password} 
						Password2={this.state.Password2} 
						CAddress={this.state.CAddress} 
						CCity={this.state.CCity} 
						CProvince={this.state.CProvince} 
						CPostcode={this.state.CPostcode} 
						Where={this.state.Where} 
						/>
					<BankProfile currentStep={this.state.currentStep} handleChange={this.handleChange}
						BName={this.state.BName} 
						FINID={this.state.FINID} 
						BTransit={this.state.BTransit} 
						CNumber={this.state.CNumber} 						
						/>
					<br/>
					{this.previousButton()}
					{this.nextButton()}
				</form>
			</React.Fragment>
		);
	}
}

function PayFreq(props) {			
	const [items, setItems] = useState([]);	
	const [payprices, setPayprices] = useState([]);
	const [paytypeprice, setPaytypeprice] = useState([]);
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/frequency.php')
			.then(res => res.json())
			.then((result) => {	
				setItems(result);
			})
	}, [])			
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/paymentprice.php')
			.then(res => res.json())
			.then((result) => {				
				setPayprices(result);
			})
	}, [])
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/paytypeprice.php')
			.then(res => res.json())
			.then((result) => {				
				setPaytypeprice(result);
			})
	}, [])
	if (props.currentStep !== 1) {
		return null
	} 	
	return(
		<div>
			<h2><b>Time Management</b></h2>			
			<br/>
			<p>Regardless of how your business would like to track and manage your employee time, 
				if your business is in need of a time-sheet with employee hours, 
				if you are tired of tracking time manually, this is a great solution.
				this Employee Time Management system will provide an online time tracking solution 
				to track employee working hours.</p>
			<br/>			
			<h2><b>Time Management Process</b></h2><br/>
				<p>1. Your employees enter their working hours at anytime and anywhere </p>
				<p>2. Your employees submit their working hours for approval</p>
				<p>3. The designated manager will approve their working hours</p>				
			<br/>
			<h2><b>Payroll Service</b></h2>
			<br/>
				<p>Once your employee working hours are submitted and approved, our Payroll service will process 
					the working hours and calculate the employee payments and finally deposit the payment into your employee banking accounts</p>
			<br/>
			<h2><b>Payroll Service Process</b></h2><br/>
				<p>4. Payroll Service receives the approved working hours from the designated managers</p>
				<p>5. Payroll Service will calculate your employees payment with tax, EI, CPP and other deductions</p>
				<p>6. Payroll Service will withdraw the payment from your company designated banking account</p>
				<p>7. Payroll Service will directly deposit the payment into your employee designated banking account</p>							
				<br/><br/>		
			<h2><b>Salary Payment Frequency</b></h2>
			<select id="payfreq" name="payfreq" value = {props.payfreq} onChange={props.handleChange}>
				<option value="0">--Select Pay Frequency--</option>		
				{items.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}								
			</select>	
			<br/><br/>		
			<h2><b>Payroll Service Fee Payment Type</b></h2>
			<select id="sertype" name="sertype" value = {props.sertype} onChange={props.handleChange}>
				<option value="0">--Select--</option>		
				<option value="1">--Monthly Fee--</option>
				<option value="2">--Per Employee Payment Fee--</option>				
			</select>	
			<br/><br/>		
			<h2><b>{props.sertype =="1" ? "How Many Employees in your Company":""}</b></h2>
			{props.sertype =="1" ? <select id="numemp" name="numemp" value = {props.numemp} onChange={props.handleChange}>
				<option value="0">--Select--</option>		
				<option value="1">  &le; 30  </option>
				<option value="2">	30 &lt;Employees &le;50  </option>
				<option value="3">  50 &lt;Employees &le;100  </option>
				<option value="4">  100 &lt;Employees &le;200  </option>
				<option value="5">  200 &lt;Employees &le;300  </option>				
				<option value="6">  &gt; 300  </option>				
			</select>:""}<br/><br/>			
			<h2><b>{props.payfreq !="0" && props.sertype == "2" ? "We will charge Your Company Service Fee":""}</b></h2>
			<h2>{props.payfreq !="0" && props.sertype == 2 ? "$"+payprices[0] + " per employee per payment plus "+ (props.payfreq==1 ? payprices[8] + " base run cost $" + payprices[1]:(props.payfreq==2 ? payprices[9] + " base run cost $" + payprices[2]:(props.payfreq==3 ? payprices[10] + " base run cost $" + payprices[3]:payprices[11] + " base run cost $" + payprices[4])))+" which will be assigned proportionally to your branches or bank accounts":""}</h2>
			<h2><b>{props.payfreq !="0" && props.sertype == "1" && props.numemp != "0" ? "We will charge your company service fee":""}</b></h2>
			<h2><b>{props.payfreq !="0" && props.sertype == "1" && props.numemp != "0" ? "$"+paytypeprice.filter(item=>item[0]==props.numemp).map(item=>item[3]) + 
					(paytypeprice.filter(item=>item[0]==props.numemp).map(item=>item[0]) == "6" ? " multiplied by your employee head count":"") + " monthly no matter what your payment frequency. And this service fee will be assigned proportionally to your branches or bank accounts":""}</b></h2>
			<br/>			
			<h2><b>When will your company start the system payment proecess</b></h2>
			<select id="paybegin" name="paybegin" value = {props.paybegin} onChange={props.handleChange}>
				<option value="0">--Select Start Date--</option>														
				{props.payfreq==1||props.payfreq==2 ? SundayofWeek.map((item, index) => {return<option value={item} key={index}>{item}</option>;}):DayofMonth.map((item, index) => {return<option value={item} key={index}>{item}</option>;})}					
			</select><br/><br/>
		</div>		
	);
}

function ComProfile(props) {			
	const [items, setItems] = useState([]);
	const [provinces, setProvinces] = useState([]);
	const [media, setMedia] = useState([]);
		useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/industry.php')
			.then(res => res.json())
			.then((result) => {	
				setItems(result);
			})
	}, [])
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(res => res.json())
			.then((result) => {	
				setProvinces(result);
			})
	}, [])
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/media.php')
			.then(res => res.json())
			.then((result) => {	
				setMedia(result);
			})
	}, [])	
	if (props.currentStep !== 2) {
		return null
	}
	return(
		<div>
			<h2><b>Headquarter Profile Registration</b></h2><br/>
			<p>Please register your headquarter company profile information. Once your registration is complete, you can login to the system 
				and add as many as sub-companies from your personal page and modify/update your company information.</p>
			<br/>
			<label><b>Company Legal Name:</b></label><br/>
			<input type="text" name="LName" id="LName" value = {props.LName} onChange={props.handleChange}/><br/><br/>
			<label><b>Company Short Name:</b></label><br/>
			<input type="text" name="SName" id="SName" value = {props.SName} onChange={props.handleChange} /> to be displayed in your employee personal page<br/><br/>
			<label><b>Company Industry:</b></label><br/>
			<select id="ISIC" name="ISIC" value = {props.ISIC} style={{width:'160px'}} onChange={props.handleChange}>
					<option value="0">--Select Business Industry--</option>
					{items.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}			
					{/*{this.state.Industry.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))*/}			
			</select><br/><br/>
			<label><b>Contact Person:</b></label><br/>
			<input type="text" name="CName" id="CName" value = {props.CName} onChange={props.handleChange} /> The person we can contact for the support<br/><br/>
			{/*<label><b>Contact Phone <small>(416-123-4567)</small>:</b></label><br/>
			<input type="text" name="CPhone" id="CPhone" value = {props.CPhone} onChange={props.handleChange} maxLength="12"/> The phone we can reach the contact person for the support<br/><br/>			
			*/}
			<label><b>Contact Phone</b></label><br/>
			<input type="text" style={{width:'30px'}} name="CArea" id="CArea" value = {props.CArea} onChange={props.handleChange} maxLength="3"/>
			- <input type="text" style={{width:'40px'}} name="Cthree" id="Cthree" value = {props.Cthree} onChange={props.handleChange} maxLength="3"/> - <input type="text" style={{width:'60px'}} name="CPhone" id="CPhone" value = {props.CPhone} onChange={props.handleChange} maxLength="4"/> <br/><br/>			
			
			
			<label><b>LoginID (email):</b></label><br/>
			<input type="text" name="Email" id="Email" value = {props.Email} onChange={props.handleChange} /><br/><br/>		
			<label><b>Password:</b></label><br/>
			<input type="password" name="Password" id="Password" value = {props.Password} onChange={props.handleChange} /><br/><br/>
			<label><b>Confirm Password:</b></label><br/>
			<input type="password" name="Password2" id="Password2" value = {props.Password2} onChange={props.handleChange} /><br/><br/>
			<label><b>Company Address:</b></label><br/>
			<input type="text" name="CAddress" id="CAddress" value = {props.CAddress} onChange={props.handleChange} /><br/><br/>
			<label><b>City:</b></label><br/>
			<input type="text" name="CCity" id="CCity" value = {props.CCity} onChange={props.handleChange} /><br/><br/>
			<label><b>Province:</b></label><br/>			
			<select id="CProvince" name="CProvince" value = {props.CProvince} style={{width:'160px'}} onChange={props.handleChange}>
					<option value="0">--Select Province--</option>
					{provinces.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}			
					{/*{this.state.Pro.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))*/}			
			</select><br/><br/>			
			<label><b>Country:</b></label><br/>
			<input type="text" name="CCountry" id="CCountry" value="Canada" readOnly/><br/><br/>
			<label><b>Post Code <small>(A1A-1A1)</small>:</b></label><br/>
			<input type="text" name="CPostcode" id="CPostcode" value = {props.CPostcode} onChange={props.handleChange} maxLength="7"/><br/><br/>
			<label><b>How did you know us:</b></label><br/>			
			<select name="Where" id = "Where" value = {props.Where} onChange={props.handleChange}>
					<option value="">--Please tell us how you know us--</option>
					{media.map(item => (<option value={item} key={item}>{item}</option>))}			
					{/*{this.state.media.map(item => (<option value={item} key={item}>{item}</option>))*/}			
			</select> Please let us know how you know us so that we can serve you better<br/><br/>		
		</div>	
	);
}

function BankProfile(props) {	
	const [items, setItems] = useState([]);
	const [finid,setFinid] = useState();
	const bankcode=props.BName;
	useEffect(() => {		
		fetch(process.env.REACT_APP_API_ENV+'api/bank.php')
			.then(res => res.json())
			.then((result) => {					
				setItems(result);
			})
	}, [])	
	if (props.currentStep !== 3) {
		return null
	} 	
  return(
    <React.Fragment>
		<div>
			<h2><b>Banking Information</b></h2><br/>
			<p>Your employee salary payment plus our charging fees will be deducted from your provided bank account and/or additional bank accounts you may provide later. Your contact person name will 
			be considered as electronic authorization signature for the Pre-Authorized Debit (PAD) process. You can update/modify the banking information in your personal page after registration. 
			You can add additional banking accounts for your employee payment. You may revoke the authorization or cancel the service at any time. 
			You have certain recourse rights if any debit does not comply with the agreed amounts, for example, you have the right to receive the reimbusement for any debit that is not authorized or is
			not consistent with the agreed amounts.
			</p>			
			<br/>		
			<label><b>Business Bank Name:</b></label><br/>			
			<select id="BName" name="BName" value = {props.BName} style={{width:'160px'}} onChange={props.handleChange}>
					<option value="">--Select Bank--</option>
					{items.map(item => (<option value={item[2]} key={item[0]}>{item[1]}</option>))}	
					{/*{this.state.Banks.map(item => (<option value={item[2]} key={item[0]}>{item[1]}</option>))*/}	
			</select><br/><br/>
			<label><b>Financial Institution Number:</b></label><br/>
			<input type="text" name="FINID" id="FINID"  value = {props.BName} readOnly /><br/><br/>
			<label><b>Branch Transit Number:</b></label><br/>
			<input type="text" name="BTransit" id="BTransit"  maxLength="5" value = {props.BTransit} onChange={props.handleChange} /> This is 5-digit branch transit number<br/><br/>			
			<label><b>Checking Account Number:</b></label><br/>
			<input type="text" name="CNumber" id="CNumber"  maxLength="12" value = {props.CNumber} onChange={props.handleChange} /> This is 9 or 12-digit checking account number<br/><br/>
			<label><b>Please see Sample Check for your reference:</b></label><br/><br/>
					
			<p><img src={SampleCheck} alt="check" /></p>	
			<h2>
			<ol>
				<li>"12345"-This is 5-digit branch transist number</li>
				<li>"999"-This is 3-digit financial institution number</li>
				<li>"456789012"-This is chequeing account number</li>
			</ol></h2><br/><br/>					
			<label><strong>Please read the following term and privacy carefully before you submit</strong></label><br/><br/>
			<p><input type="checkbox" id="signoff" name="signoff" value = "yes" required /> I accept the <Link to="/Privacy" target="_blank" >Term and Privacy</Link></p><br/>							
		</div><br/>
		<button className="btn btn-success btn-block">Submit</button><br/>
		
    </React.Fragment>
  );
}


export default withRouter(RegForm)