import CommonHeader from '../clients/commons/Head'
import React from "react";
import axios from 'axios'
import dayjs from 'dayjs';
import {PDFDocument} from 'pdf-lib';
import { saveAs } from 'file-saver';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')



function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
}

function writeVariables(pages, xvalue, yvalue, variables) {		
	pages.drawText(variables, {
			  x: xvalue,
			  y: yvalue,
			  size:10
	})
}
//paystub PDF generator
function createPDF(PDFVars){			
	var existingPdfBytes = '';	
	fetch(process.env.REACT_APP_API_ENV+"api/getpaystubPdf.php")
	.then(res => res.json()) 	
	.then(	
		async (result) => {		
			var obj = JSON.parse(result);
			var binaryData = base64ToArrayBuffer(obj.data);
			existingPdfBytes = binaryData;
			
			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			
			// Get the first page of the document
			const pages = pdfDoc.getPages()
			const firstPage = pages[0]

			// Get the width and height of the first page
			const { width, height } = firstPage.getSize()
			writeVariables(firstPage, 73, height / 2 + 213, PDFVars[35])
			writeVariables(firstPage, 73, height / 2 + 198, PDFVars[37])
			writeVariables(firstPage, 73, height / 2 + 183, PDFVars[38] +' ' + PDFVars[39])
			writeVariables(firstPage, 73, height / 2 + 168, PDFVars[40])
			writeVariables(firstPage, 73, height / 2 + 153, 'Tel: '+PDFVars[36])
			writeVariables(firstPage, 396, height / 2 + 213, PDFVars[43])
			
			writeVariables(firstPage, 73, height / 2 + 105, PDFVars[44] + ' ' + PDFVars[45])
			writeVariables(firstPage, 73, height / 2 + 90, PDFVars[48])
			writeVariables(firstPage, 73, height / 2 + 75, PDFVars[49] + ' ' + PDFVars[50])
			writeVariables(firstPage, 73, height / 2 + 60, PDFVars[51])
			writeVariables(firstPage, 73, height / 2 + 45, 'Tel: '+PDFVars[47])
			writeVariables(firstPage, 396, height / 2 + 105, PDFVars[46])
			
			writeVariables(firstPage, 73, height / 2 - 3, PDFVars[5])
			writeVariables(firstPage, 396, height / 2 - 3, PDFVars[6])
			//regular pay
			writeVariables(firstPage, 73, height / 2 - 55, PDFVars[54])
			writeVariables(firstPage, 167, height / 2 - 55, PDFVars[52])
			writeVariables(firstPage, 264, height / 2 - 55, PDFVars[53])
			writeVariables(firstPage, 360, height / 2 - 55, PDFVars[7])
			writeVariables(firstPage, 456, height / 2 - 55, PDFVars[55])
			if(PDFVars[65] !=3) {
				//vacation pay
				writeVariables(firstPage, 73, height / 2 - 70, PDFVars[58])
				writeVariables(firstPage, 360, height / 2 - 70, PDFVars[22])
				writeVariables(firstPage, 456, height / 2 - 70, PDFVars[23])
				//holiday pay
				writeVariables(firstPage, 73, height / 2 - 85, PDFVars[70])
				writeVariables(firstPage, 360, height / 2 - 85, PDFVars[68])
				writeVariables(firstPage, 456, height / 2 - 85, PDFVars[69])
				//federal tax
				writeVariables(firstPage, 360, height / 2 - 132, PDFVars[8])
				writeVariables(firstPage, 456, height / 2 - 132, PDFVars[9])
				//provinicial tax
				writeVariables(firstPage, 360, height / 2 - 147, PDFVars[10])
				writeVariables(firstPage, 456, height / 2 - 147, PDFVars[11])
				//EI
				writeVariables(firstPage, 360, height / 2 - 165, PDFVars[16])
				writeVariables(firstPage, 456, height / 2 - 165, PDFVars[17])
				//CPP
				writeVariables(firstPage, 360, height / 2 - 180, PDFVars[14])
				writeVariables(firstPage, 456, height / 2 - 180, PDFVars[15])
				//union pay
				writeVariables(firstPage, 73, height / 2 - 195, PDFVars[57])
				writeVariables(firstPage, 360, height / 2 - 195, PDFVars[20])
				writeVariables(firstPage, 456, height / 2 - 195, PDFVars[21])
				//pension
				writeVariables(firstPage, 73, height / 2 - 210, PDFVars[56])
				writeVariables(firstPage, 360, height / 2 - 21, PDFVars[18])
				writeVariables(firstPage, 456, height / 2 - 210, PDFVars[19])
				//other deduction
				writeVariables(firstPage, 73, height / 2 - 225, PDFVars[67])
				writeVariables(firstPage, 360, height / 2 - 225, PDFVars[26])
				writeVariables(firstPage, 456, height / 2 - 225, PDFVars[27])				
				//additional tax included in Fed and Provincial tax already
				/*writeVariables(firstPage, 73, height / 2 - 237, PDFVars[59])
				writeVariables(firstPage, 360, height / 2 - 237, PDFVars[60])
				writeVariables(firstPage, 456, height / 2 - 237, PDFVars[61])*/
			}
			//net pay		
			if(PDFVars[65] ==3 ) {
				writeVariables(firstPage, 360, height / 2 - 300, PDFVars[7])
				writeVariables(firstPage, 456, height / 2 - 300, PDFVars[55])
			} else {
				writeVariables(firstPage, 360, height / 2 - 300, PDFVars[24])
				writeVariables(firstPage, 456, height / 2 - 300, PDFVars[25])			
			}			
			const pdfBytes = await pdfDoc.save()
			var blob = new Blob([pdfBytes])
			saveAs(blob, PDFVars[6]+'_Pay_Stub.pdf')
		},

		(error) => {
			alert('Error happens');
		}
	)
}
//above paystub pdf file

//T4 PDF generator
function createT4PDF(PDFVars, Num){			
	var existingPdfBytes = '';	
	fetch(process.env.REACT_APP_API_ENV+"api/gett4Pdf.php")
	.then(res => res.json()) 	
	.then(	
		async (result) => {		
			var obj = JSON.parse(result);
			var binaryData = base64ToArrayBuffer(obj.data);
			existingPdfBytes = binaryData;
			/*
			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			
			// Get the first page of the document
			const pages = pdfDoc.getPages()
			const firstPage = pages[0]		*/

			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			const subpdfDoc = await PDFDocument.create();
			
			for (let i = 0; i < Num; i++) {				
				const [page]= await subpdfDoc.copyPages(pdfDoc,[0]);
				subpdfDoc.addPage(page);				
			}
			const [page]= await subpdfDoc.copyPages(pdfDoc,[1]);
			subpdfDoc.addPage(page);
			
			//get page size and enter values
			const pages = subpdfDoc.getPages()			
			for (let i = 0; i < Num; i++) {				
				const firstPage  = pages[i]				
				// Get the width and height of the first page
				const { width, height } = firstPage.getSize()			
				writeVariables(firstPage, 310, height / 2 + 348, lastyear)
				writeVariables(firstPage, 310, height / 2 - 40, lastyear)	
				//employment income
				writeVariables(firstPage, 385, height / 2 + 308, PDFVars[i][55])			
				writeVariables(firstPage, 425, height / 2 + 308, PDFVars[i][54])
				writeVariables(firstPage, 385, height / 2 - 80, PDFVars[i][55])			
				writeVariables(firstPage, 425, height / 2 - 80, PDFVars[i][54])
				//employer
				writeVariables(firstPage, 50, height / 2 + 350, PDFVars[i][35])
				writeVariables(firstPage, 50, height / 2 + 325, PDFVars[i][37]+', '+PDFVars[i][38])
				writeVariables(firstPage, 50, height / 2 + 310, PDFVars[i][39] +', ' + PDFVars[i][40])
				writeVariables(firstPage, 50, height / 2 - 35, PDFVars[i][35])
				writeVariables(firstPage, 50, height / 2 - 55, PDFVars[i][37]+', '+PDFVars[i][38])
				writeVariables(firstPage, 50, height / 2 - 70, PDFVars[i][39] +', ' + PDFVars[i][40])
				//employee
				writeVariables(firstPage, 297, height / 2 + 272, PDFVars[i][50])
				writeVariables(firstPage, 297, height / 2 - 115, PDFVars[i][50])
				writeVariables(firstPage, 65, height / 2 + 185, PDFVars[i][45])
				writeVariables(firstPage, 65, height / 2 - 202, PDFVars[i][45])
				writeVariables(firstPage, 235, height / 2 + 185, PDFVars[i][44])
				writeVariables(firstPage, 235, height / 2 - 202, PDFVars[i][44])			
				writeVariables(firstPage, 65, height / 2 + 150, PDFVars[i][48])
				writeVariables(firstPage, 65, height / 2 + 110, PDFVars[i][49]+'     '+PDFVars[i][50]+'     '+PDFVars[i][51])
				writeVariables(firstPage, 65, height / 2 - 238, PDFVars[i][48])			
				writeVariables(firstPage, 65, height / 2 - 288, PDFVars[i][49]+'     '+PDFVars[i][50]+'     '+PDFVars[i][51])
				//RPP
				writeVariables(firstPage, 385, height / 2 + 165, PDFVars[i][19])			
				writeVariables(firstPage, 425, height / 2 + 165, PDFVars[i][18])
				writeVariables(firstPage, 385, height / 2 - 222, PDFVars[i][19])			
				writeVariables(firstPage, 425, height / 2 - 222, PDFVars[i][18])				
				writeVariables(firstPage, 385, height / 2 + 128, PDFVars[i][67])			
				writeVariables(firstPage, 425, height / 2 + 128, PDFVars[i][68])
				writeVariables(firstPage, 385, height / 2 - 260, PDFVars[i][67])			
				writeVariables(firstPage, 425, height / 2 - 260, PDFVars[i][68])
				//tax
				writeVariables(firstPage, 520, height / 2 + 308, PDFVars[i][13])			
				writeVariables(firstPage, 558, height / 2 + 308, PDFVars[i][0])
				writeVariables(firstPage, 520, height / 2 - 80, PDFVars[i][13])			
				writeVariables(firstPage, 558, height / 2 - 80, PDFVars[i][0])
				//EI
				writeVariables(firstPage, 233, height / 2 + 246, PDFVars[i][64])
				writeVariables(firstPage, 233, height / 2 - 142, PDFVars[i][64])
				writeVariables(firstPage, 520, height / 2 + 272, PDFVars[i][16])
				writeVariables(firstPage, 558, height / 2 + 272, PDFVars[i][2])			
				writeVariables(firstPage, 520, height / 2 - 115, PDFVars[i][16])
				writeVariables(firstPage, 558, height / 2 - 115, PDFVars[i][2])	
				writeVariables(firstPage, 385, height / 2 + 200, PDFVars[i][17])
				writeVariables(firstPage, 425, height / 2 + 200, PDFVars[i][1])
				writeVariables(firstPage, 385, height / 2 - 188, PDFVars[i][17])
				writeVariables(firstPage, 425, height / 2 - 188, PDFVars[i][1])
				//CPP
				writeVariables(firstPage, 210, height / 2 + 246, PDFVars[i][61])
				writeVariables(firstPage, 210, height / 2 - 142, PDFVars[i][61])
				writeVariables(firstPage, 385, height / 2 + 272, PDFVars[i][15])
				writeVariables(firstPage, 425, height / 2 + 272, PDFVars[i][3])
				writeVariables(firstPage, 385, height / 2 - 115, PDFVars[i][15])
				writeVariables(firstPage, 425, height / 2 - 115, PDFVars[i][3])
				writeVariables(firstPage, 520, height / 2 + 234, PDFVars[i][14])
				writeVariables(firstPage, 558, height / 2 + 234, PDFVars[i][4])
				writeVariables(firstPage, 520, height / 2 - 152, PDFVars[i][14])
				writeVariables(firstPage, 558, height / 2 - 152, PDFVars[i][4])
				//union---debug space or empty values
				writeVariables(firstPage, 520, height / 2 + 200, PDFVars[i][21])
				writeVariables(firstPage, 558, height / 2 + 200, PDFVars[i][20])
				writeVariables(firstPage, 520, height / 2 - 188, PDFVars[i][21])
				writeVariables(firstPage, 558, height / 2 - 188, PDFVars[i][20])
				//SIN		
				writeVariables(firstPage, 90, height / 2 + 246, PDFVars[i][46])
				writeVariables(firstPage, 90, height / 2 - 142, PDFVars[i][46])
				//other information --'57', '999,999' can be replaced by anything in the future if Fed has law required
				/*writeVariables(firstPage, 118, height / 2 + 57, '57')
				writeVariables(firstPage, 191, height / 2 + 57, '999,999')
				writeVariables(firstPage, 230, height / 2 + 57, '99')
				writeVariables(firstPage, 282, height / 2 + 57, '58')
				writeVariables(firstPage, 354, height / 2 + 57, '999,999')
				writeVariables(firstPage, 393, height / 2 + 57, '99')
				writeVariables(firstPage, 445, height / 2 + 57, '59')
				writeVariables(firstPage, 519, height / 2 + 57, '999,999')
				writeVariables(firstPage, 558, height / 2 + 57, '99')
				writeVariables(firstPage, 118, height / 2 + 20, '60')
				writeVariables(firstPage, 191, height / 2 + 20, '999,999')
				writeVariables(firstPage, 230, height / 2 + 20, '99')
				writeVariables(firstPage, 282, height / 2 + 20, '61')
				writeVariables(firstPage, 354, height / 2 + 20, '999,999')
				writeVariables(firstPage, 393, height / 2 + 20, '99')
				writeVariables(firstPage, 445, height / 2 + 20, '62')
				writeVariables(firstPage, 519, height / 2 + 20, '999,999')
				writeVariables(firstPage, 558, height / 2 + 20, '99')
				writeVariables(firstPage, 118, height / 2 - 332, '57')
				writeVariables(firstPage, 191, height / 2 - 332, '999,999')
				writeVariables(firstPage, 230, height / 2 - 332, '99')
				writeVariables(firstPage, 282, height / 2 - 332, '58')
				writeVariables(firstPage, 354, height / 2 - 332, '999,999')
				writeVariables(firstPage, 393, height / 2 - 332, '99')
				writeVariables(firstPage, 445, height / 2 - 332, '59')
				writeVariables(firstPage, 519, height / 2 - 332, '999,999')
				writeVariables(firstPage, 558, height / 2 - 332, '99')
				writeVariables(firstPage, 118, height / 2 - 368, '60')
				writeVariables(firstPage, 191, height / 2 - 368, '999,999')
				writeVariables(firstPage, 230, height / 2 - 368, '99')
				writeVariables(firstPage, 282, height / 2 - 368, '61')
				writeVariables(firstPage, 354, height / 2 - 368, '999,999')
				writeVariables(firstPage, 393, height / 2 - 368, '99')
				writeVariables(firstPage, 445, height / 2 - 368, '62')
				writeVariables(firstPage, 519, height / 2 - 368, '999,999')
				writeVariables(firstPage, 558, height / 2 - 368, '99')
				*/
			}
			const pdfBytes = await subpdfDoc.save()
			var blob = new Blob([pdfBytes])
			saveAs(blob, 'T4_'+lastyear+'.pdf')
		},

		(error) => {
			alert('Error happens');
		}
	)
}
//T4A generator
function createT4APDF(PDFVars, Num){		
	var existingPdfBytes = '';	
	fetch(process.env.REACT_APP_API_ENV+"api/gett4aPdf.php")
	.then(res => res.json()) 	
	.then(	
		async (result) => {		
			var obj = JSON.parse(result);
			var binaryData = base64ToArrayBuffer(obj.data);
			existingPdfBytes = binaryData;
			/*
			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			
			// Get the first page of the document
			const pages = pdfDoc.getPages()
			const firstPage = pages[0]		*/	
			
			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			const subpdfDoc = await PDFDocument.create();
			
			for (let i = 0; i < Num; i++) {				
				const [page]= await subpdfDoc.copyPages(pdfDoc,[0]);
				subpdfDoc.addPage(page);				
			}
			const [page]= await subpdfDoc.copyPages(pdfDoc,[1]);
			subpdfDoc.addPage(page);
			
			//get page size and enter values
			const pages = subpdfDoc.getPages()			
			for (let i = 0; i < Num; i++) {				
				const firstPage  = pages[i]				
				// Get the width and height of the first page
				const { width, height } = firstPage.getSize()			
				writeVariables(firstPage, 310, height / 2 + 450, lastyear)//
				writeVariables(firstPage, 310, height / 2 + 65, lastyear)//	
				//employment income
				writeVariables(firstPage, 517, height / 2 + 293, PDFVars[i][55])			
				writeVariables(firstPage, 551, height / 2 + 293, PDFVars[i][54])
				writeVariables(firstPage, 517, height / 2 - 92, PDFVars[i][55])			
				writeVariables(firstPage, 551, height / 2 - 92, PDFVars[i][54])
				//employer
				writeVariables(firstPage, 50, height / 2 + 440, PDFVars[i][35])//
				writeVariables(firstPage, 50, height / 2 + 55, PDFVars[i][35])//			
				//employee			
				writeVariables(firstPage, 65, height / 2 + 290, PDFVars[i][45])//
				writeVariables(firstPage, 65, height / 2 - 95, PDFVars[i][45])//
				writeVariables(firstPage, 235, height / 2 + 290, PDFVars[i][44])//
				writeVariables(firstPage, 235, height / 2 - 95, PDFVars[i][44])//			
				writeVariables(firstPage, 65, height / 2 + 260, PDFVars[i][48])//
				writeVariables(firstPage, 65, height / 2 + 230, PDFVars[i][49]+'     '+PDFVars[i][50]+'     '+PDFVars[i][51])//
				writeVariables(firstPage, 65, height / 2 - 125, PDFVars[i][48])			//
				writeVariables(firstPage, 65, height / 2 - 155, PDFVars[i][49]+'     '+PDFVars[i][50]+'     '+PDFVars[i][51])//
				//SIN		
				writeVariables(firstPage, 90, height / 2 + 350, PDFVars[i][46])//
				writeVariables(firstPage, 90, height / 2 - 35, PDFVars[i][46])//
			}
			const pdfBytes = await subpdfDoc.save()
			var blob = new Blob([pdfBytes])
			saveAs(blob, 'T4A_'+lastyear+'.pdf')
		},

		(error) => {
			alert('Error happens');
		}
	)
}
//T4A


class reportForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {paystub:'',report:1,paymentSheet:[],BranchName:[],comments:'',branch:'',programs:[], program:'', paysave:'',
						PDFStub:[],T4T:[], T4C:'',taxform:'',downloads:'',Taxdays:'', EmpType:'', T4Yes:'',
						reportitem:'',payschedule:[],
						user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);		
		this.handlePayChange=this.handlePayChange.bind(this);			
		this.handleBranchChange=this.handleBranchChange.bind(this);		
		this.handleProgramChange=this.handleProgramChange.bind(this);	
		this.handleT4Change=this.handleT4Change.bind(this);
		this.handleRepChange=this.handleRepChange.bind(this);
	}
	
	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}							
		axios.post(process.env.REACT_APP_API_ENV+'api/taxseasondays.php', para)
			.then(result => {												
				this.setState({Taxdays:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/findpaymentbranch.php', para)
			.then(result => {
				if(result.data[0][3]=="No") {
					this.setState({comments:"Please wait for your payment to be processed"})
				} else {
					this.setState({BranchName:result.data})					
				}
		})
		const userObject={				
					user:this.state.user,
					names:this.state.names,
					year:thisyear-1
				}		
		axios.post(process.env.REACT_APP_API_ENV+'api/checkt4tax.php', userObject)
			.then(result => {console.log(result.data)
				if(result.data[0][0] =="No") {
					this.setState({T4C:'No'})
				} else {
					this.setState({T4T:result.data})	
					this.setState({T4Yes:result.data[0][66]})	
					this.setState({EmpType:result.data[0][65]})	
				}
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/paymentschedule.php', para)
			.then(result => {
				if(result.data[0][3]=="No") {
					this.setState({comments:"Please wait for your payment schedule to be set up"})
				} else {
					this.setState({payschedule:result.data})					
				}
		})
		
	}
						
	handleChange = event => {		
		const {name, value} = event.target				
		this.setState({[name]: value})		
		const userObject={				
					user:this.state.user,
					names:this.state.names,
					branch:this.state.branch,					
					paystub:this.state.paystub
				}
		if(event.target.value==1) {
			axios.post(process.env.REACT_APP_API_ENV+'api/paystupPDF.php', userObject)
				.then(result => {
					this.setState({PDFStub:result.data})	
			})	
		}
		this.setState({T4C:''})
	}
	handleRepChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})
	}
	
	handleProgramChange = event => {
		this.setState({paystub:''})
		this.setState({downloads:''})
		this.setState({T4C:''})
		this.setState({taxform:''})
		const {name, value} = event.target				
		this.setState({[name]: value})		
		const userObject={				
					user:this.state.user,
					names:this.state.names,
					branch:this.state.branch,
					program:event.target.value								
				}					
		axios.post(process.env.REACT_APP_API_ENV+'api/paystup.php', userObject)
			.then(result => {
				this.setState({paymentSheet:result.data})	
		})	
		
	}
	handleT4Change = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})
	}
	handlePayChange = event => {								
		const {name, value} = event.target				
		this.setState({[name]: value})	
		const userObject={				
					user:this.state.user,
					names:this.state.names,
					paystub:event.target.value
				}					
		axios.post(process.env.REACT_APP_API_ENV+'api/payperiodsave.php', userObject)
			.then(result => {
				this.setState({paysave:result.data})	
		})
		this.setState({downloads:''})
		this.setState({T4C:''})
		this.setState({taxform:''})
	}	
		
	handleBranchChange = event => {
		this.setState({program:''})
		this.setState({paystub:''})		
		this.setState({downloads:''})
		this.setState({T4C:''})
		this.setState({taxform:''})
		const {name, value} = event.target				
		this.setState({[name]: value})		
		const userObject={				
					user:this.state.user,
					names:this.state.names,
					branch:event.target.value								
				}			
		axios.post(process.env.REACT_APP_API_ENV+'api/findpayprogram.php', userObject)
				.then(result => {	
					this.setState({programs:result.data})											
		})			
		axios.post(process.env.REACT_APP_API_ENV+'api/paystup.php', userObject)
			.then(result => {
				this.setState({paymentSheet:result.data})	
		})	
	}
	render() {			
		return (			
			<div>     
					<CommonHeader titles="Report"/>
					<h2>Reports</h2><br/>
					<select name="reportitem" style={{width:'150px'}} value={this.state.reportitem} onChange={this.handleRepChange}>
							<option value="">--select--</option>						
							<option value="1">--Payment Schedule--</option>				
							<option value="2">--Pay Stup Report--</option>						
					</select><br/><br/>	
					{(()=>{if(this.state.reportitem == "1") {return (<div>
						<div className="label-wrap" style={{textAlign:'center'}}>
							<div className="label">	
								<table>
									<thead>
										<tr>
											<th style={{width:'200px'}}>Pay Period Start Date</th>			
											<th style={{width:'200px'}}>Pay Period End Date</th>		
											<th style={{width:'200px'}}>Timesheet Approval Deadline</th>
											<th style={{width:'200px'}}>Payment Deposit Date</th>
										</tr>
									</thead>
									<tbody>																			
									{this.state.payschedule.map((item, index) => {
										return (<tr><td>{item[1]}</td>
											<td>{item[2]}</td>
											<td>{item[3]}</td>
											<td>{item[4]}</td>											
										  </tr>
										  )})}
									</tbody>
								</table>
							</div>				
						</div><br/><br/>
					</div>)}})()}
					{(()=>{if(this.state.reportitem == "2") {return (<div>
						<h2>Paystup Reports</h2><br/>
						<h2>Branch</h2>					
						<select name="branch" style={{width:'150px'}} value={this.state.branch} onChange={this.handleBranchChange}>
								<option value="">--select--</option>						
								{this.state.BranchName.map((sub,index) => (<option value={sub[0]} key={index}>{sub[3]}</option>))}			
						</select><br/><br/>											
						<h2>{this.state.comments !="" ? this.state.comments:""}</h2>						
						{/*(()=>{if(this.state.branch != "") {return (<div>
							<h2>Program</h2>					
							<select name="program" style={{width:'150px'}} value={this.state.program} onChange={this.handleProgramChange}>
									<option value="">--select--</option>						
									{this.state.programs.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]+' '+sub[2]}</option>))}			
							</select><br/><br/>
						</div>)}})()*/}
						{(()=>{if(this.state.branch != "") {return (<div>
							<h2> Payment Period </h2>					
							<select name="paystub" style={{width:'150px'}} value={this.state.paystub} onChange={this.handlePayChange}>
								<option value="">--Pay Period--</option>						
								{this.state.paymentSheet.map((sub,index) => (<option value={sub[0]} key={index}>{'Banking '+sub[4]+': '+sub[5] +' - '+sub[6]}</option>))}			
							</select><br/><br/>
							<h2>{this.state.paysave !="" ? this.state.paysave:""}</h2><br/>
						</div>)}})()}
						{(()=>{if(this.state.paystub != "" && this.state.branch !="") {return (<div>
							<h2>Paystub</h2><br/><br/>
							<div className="forinput" style={{textAlign:'left'}}>						
									<label className="labelname"><h2><b>Employer</b>:</h2><br/>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[35])}</h2>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[37])}</h2>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[38])+' '+
									this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[39])}</h2>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[40])}</h2>
									<h2>{'Tel:'+this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[36])}</h2>
									</label>						
									<label className="labelname"><h2><b>Payment Date</b>:</h2><br/>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[43])}</h2></label>						
							</div><br/>
							<div className="forinput" style={{textAlign:'left'}}>						
									<label className="labelname"><h2><b>Employee</b>:</h2><br/>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[44])+' '+
									this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[45])}</h2>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[48])}</h2>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[49])+' '+
									this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[50])}</h2>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[51])}</h2>
									<h2>{'Tel:'+this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[47])}</h2>
									</label>						
									<label className="labelname"><h2><b>SIN</b>:</h2><br/>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[46])}</h2></label>						
							</div><br/>
							<div className="forinput" style={{textAlign:'left'}}>						
									<label className="labelname"><h2><b>Period Beginning</b>:</h2><br/>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[5])}</h2>
									</label>						
									<label className="labelname"><h2><b>Period Ending</b>:</h2><br/>
									<h2>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[6])}</h2></label>						
							</div><br/>
							<tr><td style={{textAlign:'center',width:'150px'}}><b>Earnings</b></td>
								<td style={{textAlign:'center',width:'100px'}}><b>Rate</b></td>
								<td style={{textAlign:'center',width:'100px'}}><b>Hours</b></td>
								<td style={{textAlign:'center',width:'100px'}}><b>Current</b></td>
								<td style={{textAlign:'center',width:'150px'}}><b>Year-to-date</b></td>
							</tr>
							<tr><td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[54])==1 ? "Non-Regular":"Regular"}</td>
								<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub && sub[54] != 1).map(sub=>sub[52])}</td>
								<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub && sub[54] != 1).map(sub=>sub[53])}</td>
								<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[7])}</td>
								<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[55])}</td>
							</tr>
							{(()=>{if(this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[64]) != "3") {return (<div>
								{(()=>{if(this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[58]) > 0) {return (<div>
									<tr><td style={{textAlign:'center',width:'150px'}}>Vacation</td>
										<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
										<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
										<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[22])}</td>
										<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[23])}</td>
									</tr>	
								</div>)}})()}
								{(()=>{if(this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[66]) > 0) {return (<div>
									<tr><td style={{textAlign:'center',width:'150px'}}>Holiday Pay</td>
										<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
										<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
										<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[66])}</td>
										<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[67])}</td>
									</tr>	
								</div>)}})()}
								<br/>
								<tr><td style={{textAlign:'center',width:'150px'}}>Federal Tax</td>
									<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
									<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
									<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[8])}</td>
									<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[9])}</td>
								</tr>
								<tr><td style={{textAlign:'center',width:'150px'}}>Provincial Tax</td>
									<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
									<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
									<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[10])}</td>
									<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[11])}</td>
								</tr>							
								<tr><td style={{textAlign:'center',width:'150px'}}>CPP</td>
									<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
									<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
									<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[14])}</td>
									<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[15])}</td>
								</tr>
								<tr><td style={{textAlign:'center',width:'150px'}}>EI</td>
									<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
									<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
									<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[16])}</td>
									<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[17])}</td>
								</tr>
								{(()=>{if(this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[56]) > 0) {return (<div>
									<tr><td style={{textAlign:'center',width:'150px'}}>Pension Contribution</td>
										<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
										<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
										<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[18])}</td>
										<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[19])}</td>
									</tr>	
								</div>)}})()}	
								{(()=>{if(this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[57]) > 0) {return (<div>
									<tr><td style={{textAlign:'center',width:'150px'}}>Union Due</td>
										<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
										<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
										<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[20])}</td>
										<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[21])}</td>
									</tr>	
								</div>)}})()}
								{(()=>{if(this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[27]) > 0) {return (<div>
									<tr><td style={{textAlign:'center',width:'150px'}}>Other Deduction</td>
										<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
										<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
										<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[26])}</td>
										<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[27])}</td>
									</tr><br/>
								</div>)}})()}
							</div>)}})()}
							<tr><td style={{textAlign:'center',width:'150px'}}>Net Payment</td>
								<td style={{textAlign:'center',width:'100px'}}>{' '}</td>
								<td style={{textAlign:'center',width:'100px'}}>{' '}</td>							
								<td style={{textAlign:'center',width:'100px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[64])==3 ? this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[7]):this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[24])}</td>
								<td style={{textAlign:'center',width:'150px'}}>{this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[64])==3 ? this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[55]):this.state.paymentSheet.filter(sub=>sub[0]==this.state.paystub).map(sub=>sub[25])}</td>														
							</tr><br/>
							
							<h2>Pay Stub Downloading</h2>					
							<select name="downloads" style={{width:'150px'}} value={this.state.downloads} onChange={this.handleChange}>
								<option value="">--select--</option>						
								<option value="1">Download Paystub</option>						
								<option value="2">Do not Download Paystub</option>						
							</select><br/><br/>		

						</div>)}})()}
						{(()=>{if(this.state.paystub !== "" && this.state.branch !=="" && this.state.downloads =="1" && this.state.PDFStub.length != 0) {return (<div>
							<h1>Downloading your Pay Stub Now...</h1>
							{createPDF(this.state.PDFStub)}
						</div>)}})()}
						{(()=>{if(this.state.T4C == "") {return (<div>
							{(()=>{if(this.state.T4Yes == "0" && dayjs().diff(dayjs(lastyear).endOf('year'),'day') > 0 && dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 120) {return (
								<h2>Year {lastyear} T4 or T4A is not available now</h2>						
							)} else if(this.state.T4Yes == "1" && dayjs().diff(dayjs(lastyear).endOf('year'),'day') > 0 && dayjs().diff(dayjs(lastyear).endOf('year'),'day') < this.state.Taxdays){return(<div>
								{(()=>{if(this.state.EmpType == "3") {return (<div>
									<br/><h2>{lastyear} T4A tax form is available now</h2>								
									<select name="taxform" style={{width:'150px'}} value={this.state.taxform} onChange={this.handleT4Change}>
										<option value="">--select--</option>						
										<option value="1">Download T4A</option>						
										<option value="2">Do not Download T4A</option>						
									</select><br/><br/>	
								</div>)} else if(this.state.EmpType != "3"){return(<div>
									<br/><h2>{lastyear} T4 tax form is available now</h2>
									<select name="taxform" style={{width:'150px'}} value={this.state.taxform} onChange={this.handleT4Change}>
										<option value="">--select--</option>						
										<option value="1">Download T4</option>						
										<option value="2">Do not Download T4</option>						
									</select><br/><br/>	
								</div>)}})()}
							</div>)}})()}
						</div>)}})()}
						{(()=>{if(this.state.EmpType == "3" && this.state.taxform == "1" && this.state.T4T.length != 0) {return (<div>
							<h2>Downloading T4A Tax Form</h2><br/>								
							{createT4APDF(this.state.T4T, this.state.T4T.length)}
						</div>)} else if(this.state.EmpType != "3" && this.state.taxform == "1" && this.state.T4T.length != 0){return(<div>
							<h2>Downloading T4 Tax Form</h2><br/>								
							{createT4PDF(this.state.T4T, this.state.T4T.length)}
						</div>)}})()}
					</div>)}})()}
			</div>
		)
	}
}

export default reportForm;

