import React from 'react'
import '../main.css'
import axios from 'axios'
import CommonHeader from '../clients/commons/Head'
import SampleCheck from '../images/Canadian-Cheque.jpg'

class setupEmployees extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:36,Pro:[],Banks:[],payrolldu:[],UpdatingEmployee:[], EID:'', CID:'', BID:'',email:'',
			firstname:'', lastname:'', SIN1:'', SIN2:'', SIN3:'', address:'', city:'', province:'', postcode1:'', 
			postcode2:'', phone1:'', phone2:'', phone3:'', email:'', bankname:'', branch:'', checknumber:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);			
		this.handleEmployeeChange=this.handleEmployeeChange.bind(this)	
		this.handleProfileChange=this.handleProfileChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}	
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});	
		axios.post(process.env.REACT_APP_API_ENV+'api/bank.php')
			.then(result => {		
				this.setState({Banks:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});	
		axios.post(process.env.REACT_APP_API_ENV+'api/payrolldeductions.php', para)
			.then(result => {		
				this.setState({payrolldu:result.data});
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/add_updateProfile.php', para)
			.then(result => {
				this.setState({UpdatingEmployee:result.data})								
		})
		
	}	
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	
	handleProfileChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({EID:""})		
		this.setState({CID:""})							
		this.setState({BID:""})	
		this.setState({firstname:""})
		this.setState({lastname:""})		
		this.setState({email:""})	
		this.setState({address:""})
		this.setState({city:""})				
		this.setState({birth1:""})		
		this.setState({birth2:""})
		this.setState({birth3:""})
		this.setState({SIN1:""})		
		this.setState({SIN2:""})		
		this.setState({SIN3:""})		
		this.setState({postcode1:""})		
		this.setState({postcode2:""})
		this.setState({phone1:""})
		this.setState({phone2:""})
		this.setState({phone3:""})
		this.setState({province:""})
		this.setState({bankname:""})
		this.setState({branch:""})
		this.setState({checknumber:""})		
		this.setState({employeeUpdate:""})
		this.setState({Resp:""});
	}
	handleEmployeeChange = event => {				
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({employeeUpdate: event.target.value})
		this.setState({firstname:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[0])})
		this.setState({lastname:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[1])})		
		this.setState({email:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[13])})	
		if(this.state.employee == 2) {
			this.setState({firstname:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[0])})
			this.setState({lastname:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[1])})		
			this.setState({email:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[13])})	
			this.setState({address:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[4])})
			this.setState({city:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[5])})				
			this.setState({birth1:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[19]))})		
			this.setState({birth2:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[26]))})
			this.setState({birth3:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[27]))})
			this.setState({SIN1:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[2]))})		
			this.setState({SIN2:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[23]))})		
			this.setState({SIN3:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[24]))})		
			this.setState({postcode1:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[7]))})		
			this.setState({postcode2:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[25]))})
			this.setState({phone1:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[3]))})		
			this.setState({phone2:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[21]))})
			this.setState({phone3:(this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[22]))})			
			this.setState({province:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[6])})
			this.setState({bankname:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[14])})
			this.setState({branch:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[10])})
			this.setState({checknumber:this.state.UpdatingEmployee.filter(item=>item[15] == event.target.value).map(item=>item[12])})
		}		
	}	
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {employee,employeeUpdate, firstname, lastname, SIN1, SIN2, SIN3, address, city, province, postcode1, postcode2, phone1, phone2, phone3, 
				email, bankname, branch, checknumber,pensionplan,uniondue,birth1,birth2,birth3, EID, BID, CID} = this.state					
		if (this.state.employee == "") {
				alert('Please select manage employee profile type, Add, Update or Delete?')
				return false
		}
		if(this.state.employee == "1" || this.state.employee == "2")  {
			if(!firstname) {
				alert('Please enter the employee first name')
				return false
			}
			if(!lastname) {
				alert('Please enter the employee last name')
				return false
			}
			if(!birth1) {
				alert('Please enter employee birth year')
				return false
			}
			if(!birth2) {
				alert('Please enter employee birth month')
				return false
			}
			if(!birth3) {
				alert('Please enter employee birth day')
				return false
			}
			if(!SIN1) {
				alert('Please enter employee SIN first three numbers')
				return false
			}	
			if(!SIN2) {
				alert('Please enter employee SIN middle three numbers')
				return false
			}	
			if(!SIN3) {
				alert('Please enter employee SIN last three numbers')
				return false
			}	
			if(!address) {
				alert('Please enter employee address')
				return false
			}
			if(!city) {
				alert('Please enter employee City')
				return false
			}
			if(!province) {
				alert('Please select employee province')
				return false
			}
			if(!postcode1) {
				alert('Please enter employee post code first part')
				return false
			}
			if(!postcode2) {
				alert('Please enter employee post code last part')
				return false
			}
			if(!phone1) {
				alert('Please enter employee phone area number')
				return false
			}
			if(!phone2) {
				alert('Please enter employee phone first three numbers')
				return false
			}
			if(!phone3) {
				alert('Please enter employee phone last four numbers')
				return false
			}		
			if(!bankname) {
				alert('Please choose employee bank')
				return false
			}
			if(!branch) {
				alert('Please enter employee banking branch transit number which is 5-digit number')
				return false
			}
			if(!checknumber) {
				alert('Please enter employee check account number')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				CID: this.state.CID,				
				BID: this.state.BID,				
				EID: this.state.employeeUpdate,
				employee:this.state.employee,
				firstname:this.state.firstname, 
				lastname:this.state.lastname, 
				birth1:this.state.birth1, 
				birth2:this.state.birth2, 
				birth3:this.state.birth3, 				
				SIN1:this.state.SIN1, 
				SIN2:this.state.SIN2, 
				SIN3:this.state.SIN3, 
				address:this.state.address, 
				city:this.state.city, 
				province:this.state.province, 
				postcode1:this.state.postcode1, 
				postcode2:this.state.postcode2,
				phone1:this.state.phone1, 
				phone2:this.state.phone2, 
				phone3:this.state.phone3, 
				email:this.state.email, 
				names:this.state.names,
				bankname:this.state.bankname, 
				branch:this.state.branch, 
				checknumber:this.state.checknumber,
				pensionplan:this.state.pensionplan,
				uniondue:this.state.uniondue				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.employee == "3")  {			
			if(this.state.employeeUpdate == "") {
				alert('Please select the employee')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,					
				EID: this.state.employeeUpdate,
				employee:this.state.employee,								
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});	
				
		}
	}
	
	render() {									
		return (		
			<div>      				
				<CommonHeader titles="Employee"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Profile</h2></label>							
						<select name="employee" id="employee" style={{width:'150px'}} value={this.state.employee} onChange={this.handleProfileChange}>
							<option value="">--Manage Employee Profile--</option>
							<option value="1">Add New Employee Profile</option>						
							<option value="2">Update Employee Profile</option>												
							<option value="3">Remove Employee Profile</option>												
						</select><br/><br/>
					</div>
					{(()=>{{/*to add employee profile*/}
						if(this.state.employee == "1") {return(<div>
							<label><h2>Add Employee Profile</h2></label>								
							<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
								<option value="">--Select Employee Profile--</option>									
								{this.state.UpdatingEmployee.filter(sub=>sub[6] == 'XX').map(sub=> (<option value={sub[15]}>{sub[0]} {sub[1]}</option>))}
							</select><br/>
							<br/><br/>
							<div className="forinput">
								<label className="labelname">First Name:</label>
								<input type="text" name ="firstname" id="firstname" style={{width:'150px'}} value={this.state.firstname} readOnly />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Last Name: </label>
								<input type="text" name ="lastname" id="lastname" style={{width:'150px'}} value={this.state.lastname} readOnly />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Date of Birth (YYYY/MM/DD): </label>
								<input type="text" name ="birth1" id="birth1" style={{width:'60px'}} value={this.state.birth1} onChange={this.handleChange} maxLength="4"/>-
								<input type="text" name ="birth2" id="birth2" style={{width:'35px'}} value={this.state.birth2} onChange={this.handleChange} maxLength="2"/>-
								<input type="text" name ="birth3" id="birth3" style={{width:'35px'}} value={this.state.birth3} onChange={this.handleChange} maxLength="2"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Social Insurance Number: </label>
								<input type="text" name ="SIN1" id="SIN1" style={{width:'44px'}} value={this.state.SIN1} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="SIN2" id="SIN2" style={{width:'44px'}} value={this.state.SIN2} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="SIN3" id="SIN3" style={{width:'44px'}} value={this.state.SIN3} onChange={this.handleChange} maxLength="3"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Address: </label>
								<input type="text" name ="address" id="address" style={{width:'150px'}} value={this.state.address} onChange={this.handleChange} />
							</div><br/>					
							<div className="forinput">
								<label className="labelname">City: </label>
								<input type="text" name ="city" id="city" style={{width:'150px'}} value={this.state.city} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Province: </label>
								<select name="province" style={{width:'150px'}} value={this.state.province} onChange={this.handleChange}>
									<option value="">--Choose Employee Province--</option>
									{this.state.Pro.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}										
								</select>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Post Code: </label>
								<input type="text" name ="postcode1" id="postcode1" style={{width:'70px'}} value={this.state.postcode1} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="postcode2" id="postcode2" style={{width:'70px'}} value={this.state.postcode2} onChange={this.handleChange} maxLength="3"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Phone Number: </label>
								<input type="text" name ="phone1" id="phone1" style={{width:'30px'}} value={this.state.phone1} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="phone2" id="phone2" style={{width:'40px'}} value={this.state.phone2} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="phone3" id="phone3" style={{width:'60px'}} value={this.state.phone3} onChange={this.handleChange} maxLength="4"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Email Address: </label>
								<input type="text" name ="email" id="email" style={{width:'150px'}} value={this.state.email} readOnly />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Bank Name: </label>
								<select name="bankname" style={{width:'150px'}} value={this.state.bankname} onChange={this.handleChange}>
									<option value="">--Choose Employee banking--</option>
									{this.state.Banks.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}										
								</select>
							</div><br/>					
							<div className="forinput">
								<label className="labelname">Branch Transit: </label>
								<input type="text" name ="branch" id="branch" style={{width:'150px'}} value={this.state.branch} onChange={this.handleChange} maxLength="5" />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Checking Account Number: </label>
								<input type="text" name ="checknumber" id="checknumber" style={{width:'150px'}} value={this.state.checknumber} onChange={this.handleChange} maxLength="12" />
							</div><br/>
							<div>
							<label><b>Please see Sample Check for your reference:</b></label><br/><br/>
								<p><img src={SampleCheck} alt="check" /></p>							
								<p style={{textAlign:'center'}}>1: "12345" - This is 5 - digit branch transist number </p>
								<p style={{textAlign:'center'}}>2: "999"-This is 3-digit financial institution number</p>
								<p style={{textAlign:'center'}}>3: "456789012"-This is chequeing account number</p><br/>						
							</div><br/>	
							{(()=>{
								{/*pension*/}
								if(this.state.payrolldu[0] != "RRSP") {return(<div>
									<div className="forinput">
										<label className="labelname">Join Pension Plan ?</label>
										<select id="pensionplan" name="pensionplan" value = {this.state.pensionplan} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--select--</option>									
											<option value="0">--No, I don't join pension plan--</option>									
											<option value="1">--Yes, I join pension plan--</option>																		
										</select>								
									</div><br/>
								</div>)}
							})()}
							{(()=>{
								{/*union*/}
								if(this.state.payrolldu[1] != "UNION") {return(<div>
									<div className="forinput">
										<label className="labelname">Join Union ?</label>
										<select id="uniondue" name="uniondue" value = {this.state.uniondue} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--select--</option>									
											<option value="0">--No, I don't join Union--</option>									
											<option value="1">--Yes, I join Union--</option>																		
										</select>								
									</div><br/>
								</div>)}
							})()}
							<h1>{this.state.Resp}</h1><br/><br/>						
						{/*update employee profile*/}
						</div>)} if(this.state.employee == "2") {return(<div>
							<label><h2>Update Employee Profile</h2></label>	
							<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
								<option value="">--Select Employee Profile--</option>									
								{this.state.UpdatingEmployee.map(sub=> (<option value={sub[15]}>{sub[0]} {sub[1]}</option>))}
							</select><br/>							
							<br/><br/>
							<div className="forinput">
								<label className="labelname">First Name:</label>
								<input type="text" name ="firstname" id="firstname" style={{width:'150px'}} value={this.state.firstname} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Last Name: </label>
								<input type="text" name ="lastname" id="lastname" style={{width:'150px'}} value={this.state.lastname} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Date of Birth (YYYY/MM/DD): </label>
								<input type="text" name ="birth1" id="birth1" style={{width:'60px'}} value={this.state.birth1} onChange={this.handleChange} maxLength="4"/>-
								<input type="text" name ="birth2" id="birth2" style={{width:'35px'}} value={this.state.birth2} onChange={this.handleChange} maxLength="2"/>-
								<input type="text" name ="birth3" id="birth3" style={{width:'35px'}} value={this.state.birth3} onChange={this.handleChange} maxLength="2"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Social Insurance Number: </label>
								<input type="text" name ="SIN1" id="SIN1" style={{width:'44px'}} value={this.state.SIN1} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="SIN2" id="SIN2" style={{width:'44px'}} value={this.state.SIN2} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="SIN3" id="SIN3" style={{width:'44px'}} value={this.state.SIN3} onChange={this.handleChange} maxLength="3"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Address: </label>
								<input type="text" name ="address" id="address" style={{width:'150px'}} value={this.state.address} onChange={this.handleChange} />
							</div><br/>					
							<div className="forinput">
								<label className="labelname">City: </label>
								<input type="text" name ="city" id="city" style={{width:'150px'}} value={this.state.city} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Province: </label>
								<select name="province" style={{width:'150px'}} value={this.state.province} onChange={this.handleChange}>
									<option value="">--Choose Employee Province--</option>
									{this.state.Pro.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}										
								</select>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Post Code: </label>
								<input type="text" name ="postcode1" id="postcode1" style={{width:'70px'}} value={this.state.postcode1} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="postcode2" id="postcode2" style={{width:'70px'}} value={this.state.postcode2} onChange={this.handleChange} maxLength="3"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Phone Number: </label>
								<input type="text" name ="phone1" id="phone1" style={{width:'30px'}} value={this.state.phone1} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="phone2" id="phone2" style={{width:'40px'}} value={this.state.phone2} onChange={this.handleChange} maxLength="3"/>-
								<input type="text" name ="phone3" id="phone3" style={{width:'60px'}} value={this.state.phone3} onChange={this.handleChange} maxLength="4"/>
							</div><br/>
							<div className="forinput">
								<label className="labelname">Email Address: </label>
								<input type="text" name ="email" id="email" style={{width:'150px'}} value={this.state.email} onChange={this.handleChange} />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Bank Name: </label>
								<select name="bankname" style={{width:'150px'}} value={this.state.bankname} onChange={this.handleChange}>
									<option value="">--Choose Employee banking--</option>
									{this.state.Banks.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}										
								</select>
							</div><br/>					
							<div className="forinput">
								<label className="labelname">Branch Transit: </label>
								<input type="text" name ="branch" id="branch" style={{width:'150px'}} value={this.state.branch} onChange={this.handleChange} maxLength="5" />
							</div><br/>
							<div className="forinput">
								<label className="labelname">Checking Account Number: </label>
								<input type="text" name ="checknumber" id="checknumber" style={{width:'150px'}} value={this.state.checknumber} onChange={this.handleChange} maxLength="12" />
							</div><br/>
							<div>
							<label><b>Please see Sample Check for your reference:</b></label><br/><br/>
								<p><img src={SampleCheck} alt="check" /></p>							
								<p style={{textAlign:'center'}}>1: "12345" - This is 5 - digit branch transist number </p>
								<p style={{textAlign:'center'}}>2: "999"-This is 3-digit financial institution number</p>
								<p style={{textAlign:'center'}}>3: "456789012"-This is chequeing account number</p><br/>						
							</div><br/>	
							{(()=>{
								{/*pension*/}
								if(this.state.payrolldu[0] != "RRSP") {return(<div>
									<div className="forinput">
										<label className="labelname">Join Pension Plan ?</label>
										<select id="pensionplan" name="pensionplan" value = {this.state.pensionplan} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--select--</option>									
											<option value="0">--No, I don't join pension plan--</option>									
											<option value="1">--Yes, I join pension plan--</option>																		
										</select>								
									</div><br/>
								</div>)}
							})()}
							{(()=>{
								{/*union*/}
								if(this.state.payrolldu[1] != "UNION") {return(<div>
									<div className="forinput">
										<label className="labelname">Join Union ?</label>
										<select id="uniondue" name="uniondue" value = {this.state.uniondue} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--select--</option>									
											<option value="0">--No, I don't join Union--</option>									
											<option value="1">--Yes, I join Union--</option>																		
										</select>								
									</div><br/>
								</div>)}
							})()}
							<h1>{this.state.Resp}</h1><br/><br/>						
						{/*remove employee profile*/}
						</div>)} else if(this.state.employee == "3") {return(<div>
							<label><h2>Delete Employee Profile from System</h2></label>									
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Employee Profile--</option>									
									{this.state.UpdatingEmployee.map(sub=> (<option value={sub[15]}>{sub[0]} {sub[1]}</option>))}
								</select><br/>														
							<br/><br/>							
							<h1>{this.state.Resp}</h1><br/><br/>												
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupEmployees;