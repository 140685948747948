import CommonHeader from '../clients/commons/Head'
import React from 'react'
import dayjs from 'dayjs';
const axios = require("axios");
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class ReactUploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state ={file: '', msg:'',isSelected:false,Pro:[],years:'',province:'',
		user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
		this.handleChange=this.handleChange.bind(this);
    }
	componentDidMount() {			
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});	
	}
    onFormSubmit(e){		
        e.preventDefault();		
		const {years,province} = this.state			
        const formData = new FormData();		
        formData.append('myImage',this.state.file);
		formData.append('email',this.state.user);	
		formData.append('years',this.state.years);	
		formData.append('province',this.state.province);	
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        axios.post(process.env.REACT_APP_API_ENV+"api/uploadfile.php",formData,config)
            .then((response) => {
				this.setState({msg:response.data});
            }).catch((error) => {
        });
    }
    onChange(e) {
        this.setState({file:e.target.files[0]});
		this.setState({isSelected:true});
    }
	handleChange = event => {					
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({isSelected:false})
	}

    render() {
        return (<div>
			<CommonHeader titles="TD1 Form"/>
            <form onSubmit={this.onFormSubmit}>          
				<h2>Select Year</h2>					
				<select name="years" style={{width:'150px'}} value={this.state.years} onChange={this.handleChange}>
					<option value="">--select--</option>						
					{dayjs().diff(dayjs(thisyear).startOf('year'),'day') < 183 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}					
				</select><br/><br/>	
				{(()=>{if(this.state.years != "") {return(<div>
					<h2>Select Province (for TD1 Form), T4 or T4A</h2>					
					<select name="province" style={{width:'150px'}} value={this.state.province} onChange={this.handleChange}>
						<option value="">--Choose Province--</option>
						{this.state.Pro.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}										
						<option value="T4">T4</option>
						<option value="T4A">T4A</option>
					</select>
					<p><small>{this.state.province == "" ? "":"The chosed file name must contain "+this.state.province}</small></p>
					<br/><br/>	
					{(()=>{if(this.state.province != "") {return(<div>
						<input type="file" name="file" style={{width:'160px'}} onChange= {this.onChange} /><br/><br/>	
						{this.state.isSelected ? (<div>
							<h2>Fiename:{this.state.file.name}</h2>
							<h2>Fietype:{this.state.file.type}</h2>
							<h2>Size in type:{this.state.file.size}</h2><br/><br/>					
						</div>):""}
						<h2>{this.state.msg}</h2><br/><br/>
						<input type="submit" name="submit" value="Upload" />
					</div>)}})()}
				</div>)}})()}
            </form>
        </div>)
    }
}

export default ReactUploadFile

