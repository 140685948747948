import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')


class setupManages extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:15,managementitem:'', Resp:'',employeeUpdate:'', manageitems:[],ProgName:'',
						clientitem:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);
		this.handleManageChange=this.handleManageChange.bind(this)
		this.handleUpdateChange=this.handleUpdateChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {					
		axios.post(process.env.REACT_APP_API_ENV+'api/manageitems.php')
			.then(result => {							
				this.setState({manageitems:result.data})								
		})	
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
	}	
	handleManageChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});			
	}
	handleUpdateChange = event=>{		
		const {name, value} = event.target				
		this.setState({[name]: value})			
		//this.setState({ProgName:this.state.manageitems.filter(sub=>sub[0]==event.target.value).map(sub=>sub[1])})
		this.setState({Resp:""});
	}
	handleSubmit = event => {		
		event.preventDefault()		
		const {managementitem,ProgName,employeeUpdate,clientitem} = this.state			
		if (this.state.managementitem == "") {
			alert('Please select management type: Add, Update or Delete')
			return false
		}
		if(this.state.managementitem==1)  {
			if(!ProgName) {
				alert('Please enter your new management item name')
				return false
			}	
			if(!clientitem) {
				alert('Please select whether this is client management item or just our system item')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				ProgName:this.state.ProgName,
				clientitem:this.state.clientitem
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});						
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.managementitem==2)  {
			if(!employeeUpdate) {
				alert('Please select management item for the updating')
				return false
			}			
			if(!ProgName) {
				alert('Please enter new management item name for the updating')
				return false
			}	
			if(!clientitem) {
				alert('Please select whether this is client management item or just our system item')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,
				ProgName:this.state.ProgName,	
				employeeUpdate:this.state.employeeUpdate,
				clientitem:this.state.clientitem
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});							
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.managementitem==3)  {
			if(!employeeUpdate) {
				alert('Please select management item')
				return false
			}						
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,								
				managementitem:this.state.managementitem,
				employeeUpdate:this.state.employeeUpdate				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});						
				}).catch((error) => {
					console.log(error)
				});		
		}		
	}
	
	render() {						
		return (		
			<div>      		
				<CommonHeader titles="Management"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Items</h2></label>							
						<select name="managementitem" id="managementitem" style={{width:'150px'}} value={this.state.managementitem} onChange={this.handleManageChange}>
							<option value="">--Manage Items--</option>
							<option value="1">Add New Management Item</option>															
							<option value="2">Update Management Item</option>												
							<option value="3">Remove Management Item</option>		
						</select><br/><br/>	
					</div>	
					{(()=>{
						{/*add new management item*/}
						if(this.state.managementitem == "1") {return(<div>
							<label><h2>New Management Item Name</h2></label>														
							<input type="text" name="ProgName" id="ProgName" value = {this.state.ProgName} onChange={this.handleChange} />
							<br/><br/>		
							<label><h2>This Item Belongs to</h2></label>														
							<select id="clientitem" name="clientitem" value = {this.state.clientitem} style={{width:'160px'}} onChange={this.handleChange}>
								<option value="">--Management Items--</option>									
								<option value="YES">Client Management Item</option>									
								<option value="NO">System Management Item</option>									
							</select>
							<br/><br/>	
							<h1>{this.state.Resp}</h1><br/><br/>
						{/*update management item*/}
						</div>)} else if(this.state.managementitem == "2") {return(<div>
							<label><h2>Select Management Item</h2></label>							
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleUpdateChange}>
									<option value="">--Management Items--</option>									
									{this.state.manageitems.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
								</select>
							<br/><br/>
							{(()=>{if(this.state.employeeUpdate != "") {return(<div>
									<label><h2>New Management Item Name</h2></label>																						
									<input type="text" name="ProgName" id="ProgName" value = {this.state.ProgName} onChange={this.handleChange} />
									<br/><br/>	
									<label><h2>This Item Belongs to</h2></label>														
									<select id="clientitem" name="clientitem" value = {this.state.clientitem} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Management Items--</option>									
										<option value="YES">Client Management Item</option>									
										<option value="NO">System Management Item</option>	
									</select>
									<br/><br/>
									<h1>{this.state.Resp}</h1><br/><br/>
								</div>)}
							})()}
						{/*remove management item*/}
						</div>)} else if(this.state.managementitem == "3") {return(<div>
							<label><h2>Select Management Item</h2></label>							
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="">--Management Items--</option>									
									{this.state.manageitems.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
								</select>
							<br/><br/>							
							<h1>{this.state.Resp}</h1><br/><br/>
						</div>)} 
					})()}					
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupManages;