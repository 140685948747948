import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';

class setupPayRollYear extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:35,taxindex:'',stateab:'',statebc:'',statemb:'',statenb:'',statenl:'',
					statent:'',statens:'',statenu:'',stateon:'',statepe:'',stateqc:'',statesk:'',stateyt:'',statefd:'',
					Provs:[],newHolidayname:'', newHolidaydate:'',selectHolidayname:'',returnholiday:[], holidayUp:[],
					user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);		
		this.handleHolidayChange=this.handleHolidayChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {					
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Provs:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});	
		axios.post(process.env.REACT_APP_API_ENV+'api/findholidays.php')
			.then(result => {		
				this.setState({returnholiday:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});
		axios.post(process.env.REACT_APP_API_ENV+'api/updateholidays.php')
			.then(result => {		
				this.setState({holidayUp:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});						
	}	
	
	handleHolidayChange = event=>{		
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({Resp:''})
			{/*this.setState({stateab:''})
		this.setState({statebc:''})
		this.setState({statemb:''})
		this.setState({statenb:''})
		this.setState({statenl:''})
		this.setState({statent:''})
		this.setState({statens:''})
		this.setState({statenu:''})
		this.setState({stateon:''})
		this.setState({statepe:''})
		this.setState({stateqc:''})
		this.setState({statesk:''})
		this.setState({stateyt:''})
	this.setState({statefd:''})*/}
		this.setState({newHolidaydate:this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]})
		this.setState({newHolidayname:event.target.value})
		this.setState({selectHolidayname:event.target.value})			
		this.setState({stateab:this.state.holidayUp.filter(item=>item[1] == 'AB' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({statebc:this.state.holidayUp.filter(item=>item[1] == 'BC' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({statemb:this.state.holidayUp.filter(item=>item[1] == 'MB' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({statenb:this.state.holidayUp.filter(item=>item[1] == 'NB' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({statenl:this.state.holidayUp.filter(item=>item[1] == 'NL' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({statent:this.state.holidayUp.filter(item=>item[1] == 'NT' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({statens:this.state.holidayUp.filter(item=>item[1] == 'NS' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({statenu:this.state.holidayUp.filter(item=>item[1] == 'NU' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({stateon:this.state.holidayUp.filter(item=>item[1] == 'ON' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({statepe:this.state.holidayUp.filter(item=>item[1] == 'PE' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({stateqc:this.state.holidayUp.filter(item=>item[1] == 'QC' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({statesk:this.state.holidayUp.filter(item=>item[1] == 'SK' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({stateyt:this.state.holidayUp.filter(item=>item[1] == 'YT' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		this.setState({statefd:this.state.holidayUp.filter(item=>item[1] == 'FD' && item[0] == event.target.value && item[2] == this.state.returnholiday.filter(item=>item[0] == event.target.value).map(item=>item[1]).[0]).map(item=>item[3]).[0]})
		
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {taxindex,newHolidayname,newHolidaydate,stateab,statebc,statemb,statenb,statenl,statent,statens,statenu,stateon,statepe,stateqc,
				statesk,stateyt,statefd} = this.state			
		if (this.state.taxindex == "") {
			alert('Please select manage holiday type, Add or Update or delete')
			return false
		}
		if(!newHolidayname) {
			alert('Please enter new holiday name')
			return false
		}	
		if(!newHolidaydate) {
			alert('Please enter new holiday date')
			return false
		}
		if (this.state.taxindex != "3") {
			if(!stateab) {
				alert('Please choose whether this holiday is applicable to Albert')
				return false
			}
			if(!statebc) {
				alert('Please choose whether this holiday is applicable to British Columbia')
				return false
			}
			if(!statemb) {
				alert('Please choose whether this holiday is applicable to Mantiba')
				return false
			}
			if(!statenb) {
				alert('Please choose whether this holiday is applicable to New Brunswick')
				return false
			}
			if(!statenl) {
				alert('Please choose whether this holiday is applicable to Newfoundland and Labrador')
				return false
			}
			if(!statens) {
				alert('Please choose whether this holiday is applicable to Nova Scotia')
				return false
			}
			if(!statent) {
				alert('Please choose whether this holiday is applicable to Northwest Territories')
				return false
			}			
			if(!statenu) {
				alert('Please choose whether this holiday is applicable to Nunavut')
				return false
			}
			if(!stateon) {
				alert('Please choose whether this holiday is applicable to Ontario')
				return false
			}
			if(!statepe) {
				alert('Please choose whether this holiday is applicable to Prince Edward Island')
				return false
			}
			if(!stateqc) {
				alert('Please choose whether this holiday is applicable to Quebec')
				return false
			}
			if(!statesk) {
				alert('Please choose whether this holiday is applicable to Saskatchewan')
				return false
			}
			if(!stateyt) {
				alert('Please choose whether this holiday is applicable to Yukon')
				return false
			}
			if(!statefd) {
				alert('Please choose whether this holiday is applicable to Federal')
				return false
			}
		}		
		const userObject={
			user:this.state.user,
			names:this.state.names,
			adminitem:this.state.adminitem,				
			taxindex:this.state.taxindex,					
			newHolidayname: this.state.newHolidayname,
			newHolidaydate: this.state.newHolidaydate,
			selectHolidayname: this.state.selectHolidayname,
			stateab:this.state.stateab,
			statebc:this.state.statebc,
			statemb:this.state.statemb,
			statenb:this.state.statenb,
			statenl:this.state.statenl,
			statent:this.state.statent,
			statens:this.state.statens,
			statenu:this.state.statenu,
			stateon:this.state.stateon,
			statepe:this.state.statepe,
			stateqc:this.state.stateqc,
			statesk:this.state.statesk,
			stateyt:this.state.stateyt,
			statefd:this.state.statefd				
		}
		axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
			.then((res) => {						
				this.setState({Resp:res.data});											
			}).catch((error) => {
				console.log(error)
			});				
	}
	
	render() {			
		return (		
			<div>      				
				<CommonHeader titles="Payroll Holidays"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Holidays {'Alberta '+this.state.stateab}</h2></label>							
						<select name="taxindex" id="taxindex" style={{width:'150px'}} value={this.state.taxindex} onChange={this.handleChange}>
							<option value="">--Manage Holidays--</option>
							<option value="1">Add New Holidays</option>
							<option value="2">Update Holidays</option>
							<option value="3">Remove Holidays</option>
						</select><br/><br/>								
					</div>	
					{(()=>{if(this.state.taxindex == "1") {return(<div>
						<div>
							<label><h2>New Holiday Name</h2></label>							
							<input type="text" name="newHolidayname" style={{width:'150px'}} value={this.state.newHolidayname} onChange={this.handleChange} />
							<br/><br/>	
							<label><h2>New Holiday Date</h2></label>							
							<input type="text" name="newHolidaydate" style={{width:'150px'}} value={this.state.newHolidaydate} onChange={this.handleChange} />
							<br/><br/>	
						</div>	
					</div>)} else if(this.state.taxindex != "1" && this.state.taxindex != "") {return(<div>
						<div>
							<label><h2>Select Holiday</h2></label>							
							<select name="selectHolidayname" id="selectHolidayname" style={{width:'150px'}} value={this.state.selectHolidayname} onChange={this.handleHolidayChange}>
								<option value="">--Select --</option>
								{this.state.returnholiday.map((item,index)=>(<option value={item[0]} key={index}>{item[0]+' --- '+item[1]}</option>))}
							</select><br/><br/>								
						</div>
						{(()=>{if(this.state.selectHolidayname != "") {return(<div>
							<div>
								<label><h2>{this.state.taxindex ==2 ? "Update Holiday Name":"Delete Holiday Name"}</h2></label>							
								<input type="text" name="newHolidayname" style={{width:'150px'}} value={this.state.newHolidayname} onChange={this.handleChange} />
								<br/><br/>	
								<label><h2>{this.state.taxindex ==2 ? "Update Holiday Date":"Holiday Date"}</h2></label>	
								<input type="text" name="newHolidaydate" style={{width:'150px'}} value={this.state.newHolidaydate} onChange={this.handleChange} />
								<br/><br/>	
							</div>	
						</div>)}})()}
					</div>)}})()}
					{(()=>{if(this.state.taxindex != "" && this.state.newHolidayname !="" && this.state.taxindex !="3") {return(<div>
						<label><h2>Apply to following provinces</h2></label><br/><br/>	
						<div className="label-wrap">
							<div className="label">	
								{this.state.Provs.slice(0,7).map((sub,index) => (<h2>{sub[1]}</h2>))}									
							</div>				
						</div>
						<div className="label-wrap">
							<div className="label">	
								<h2><select style={{width:'80px'}}  name="stateab" value={this.state.stateab} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select style={{width:'80px'}}  name="statebc" value={this.state.statebc} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="statemb" value={this.state.statemb} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="statenb" value={this.state.statenb} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="statenl" value={this.state.statenl} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="statens" value={this.state.statens} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="statent" value={this.state.statent} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
							</div>				
						</div><br/><br/><br/>
						<div className="label-wrap">
							<div className="label">	
								{this.state.Provs.slice(7,14).map((sub,index) => (<h2>{sub[1]}</h2>))}			
							</div>				
						</div>
						<div className="label-wrap">
							<div className="label">	
								<h2><select  style={{width:'80px'}}  name="statenu" value={this.state.statenu} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="stateon" value={this.state.stateon} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="statepe" value={this.state.statepe} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="stateqc" value={this.state.stateqc} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="statesk" value={this.state.statesk} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="stateyt" value={this.state.stateyt} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
								<h2><select  style={{width:'80px'}}  name="statefd" value={this.state.statefd} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value="1">--{this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
										<option value="0">--Not {this.state.taxindex == 3 ? "Delete":"Apply"}--</option>
									</select>
								</h2>
							</div>				
						</div><br/><br/>						
					</div>)}})()}	
					{(()=>{if(this.state.taxindex != "" && this.state.newHolidayname !="") {return(<div>
						<h1 style={{whiteSpace: 'pre-line'}}>{this.state.Resp}</h1><br/><br/>						
						<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
					</div>)}})()}	
				</form>				
			</div>
		)
	}
}

export default setupPayRollYear;