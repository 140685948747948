import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupPayRollYear extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:10,taxindex:'',yearclaimcode:'',stateab:'',statebc:'',statemb:'',statenb:'',statenl:'',
					statent:'',statens:'',statenu:'',stateon:'',statepe:'',stateqc:'',statesk:'',stateyt:'',statefd:'',
					Provs:[],indexcomms:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names'),
					yearIndex:'',OptionTax:'', AddIndex:''};					
		this.handleChange=this.handleChange.bind(this);
		this.handleClaimChange=this.handleClaimChange.bind(this)
		this.handleManageIndexChange=this.handleManageIndexChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {					
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Provs:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});					
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
		this.setState({indexcomms:""});		
	}
	
	handleManageIndexChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({yearclaimcode:''})
		this.setState({employeeUpdate:''})
		this.setState({Resp:""});
		this.setState({indexcomms:""});		
		this.setState({AddIndex:''})
	}
	handleClaimChange = event=>{
		this.setState({stateab:''})
		this.setState({statebc:''})
		this.setState({statemb:''})
		this.setState({statenb:''})
		this.setState({statenl:''})
		this.setState({statent:''})
		this.setState({statens:''})
		this.setState({statenu:''})
		this.setState({stateon:''})
		this.setState({statepe:''})
		this.setState({stateqc:''})
		this.setState({statesk:''})
		this.setState({stateyt:''})
		this.setState({statefd:''})
		this.setState({yearIndex:''})
		this.setState({indexcomms:""})		
		this.setState({AddIndex:''})
		const {name, value} = event.target				
		this.setState({[name]: value})
		if(event.target.value !=""){
			const userObject={YEAR:event.target.value,
							  user:this.state.user,
							  OptionTax:this.state.taxindex}
			axios.post(process.env.REACT_APP_API_ENV+'api/updateYearIndex.php',userObject)
				.then(result => {
					if (result.data[0][0] == 'No' && this.state.taxindex == 1) {
						this.setState({AddIndex:'NO'})
					} else if (result.data[0][0] == 'No' && this.state.taxindex == 2) {
						this.setState({indexcomms:'Sorry, no index for this year, please add index first'})
					} else if (result.data[0][0] != 'No') {
						this.setState({updateindexvalue:result.data})
						this.setState({AddIndex:'YES'})
						this.setState({stateab:result.data.filter(item=>item[0] == 'AB').map(item=>item[1]).[0]})
						this.setState({statebc:result.data.filter(item=>item[0] == 'BC').map(item=>item[1]).[0]})
						this.setState({statemb:result.data.filter(item=>item[0] == 'MB').map(item=>item[1]).[0]})
						this.setState({statenb:result.data.filter(item=>item[0] == 'NB').map(item=>item[1]).[0]})
						this.setState({statenl:result.data.filter(item=>item[0] == 'NL').map(item=>item[1]).[0]})
						this.setState({statent:result.data.filter(item=>item[0] == 'NT').map(item=>item[1]).[0]})
						this.setState({statens:result.data.filter(item=>item[0] == 'NS').map(item=>item[1]).[0]})
						this.setState({statenu:result.data.filter(item=>item[0] == 'NU').map(item=>item[1]).[0]})
						this.setState({stateon:result.data.filter(item=>item[0] == 'ON').map(item=>item[1]).[0]})
						this.setState({statepe:result.data.filter(item=>item[0] == 'PE').map(item=>item[1]).[0]})
						this.setState({stateqc:result.data.filter(item=>item[0] == 'QC').map(item=>item[1]).[0]})
						this.setState({statesk:result.data.filter(item=>item[0] == 'SK').map(item=>item[1]).[0]})
						this.setState({stateyt:result.data.filter(item=>item[0] == 'YT').map(item=>item[1]).[0]})
						this.setState({statefd:result.data.filter(item=>item[0] == 'FD').map(item=>item[1]).[0]})
						this.setState({yearIndex:result.data.filter(item=>item[0] == 'YEAR').map(item=>item[1]).[0]})
					} 
				})		
		}
		
		this.setState({Resp:""});
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {taxindex,yearclaimcode,stateab,statebc,statemb,statenb,statenl,statent,statens,statenu,stateon,statepe,stateqc,
				statesk,stateyt,statefd} = this.state			
		if (this.state.taxindex == "") {
			alert('Please select manage index type, Add or Update')
			return false
		}
		if(this.state.taxindex==1)  {						
			if(!yearclaimcode) {
				alert('Please select tax index year')
				return false
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				taxindex:this.state.taxindex,					
				yearclaimcode: this.state.yearclaimcode,
				stateab:this.state.stateab,
				statebc:this.state.statebc,
				statemb:this.state.statemb,
				statenb:this.state.statenb,
				statenl:this.state.statenl,
				statent:this.state.statent,
				statens:this.state.statens,
				statenu:this.state.statenu,
				stateon:this.state.stateon,
				statepe:this.state.statepe,
				stateqc:this.state.stateqc,
				statesk:this.state.statesk,
				stateyt:this.state.stateyt,
				statefd:this.state.statefd				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.taxindex==2)  {						
			if(!yearclaimcode) {
				alert('Please select tax index year')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				taxindex:this.state.taxindex,					
				yearclaimcode: this.state.yearclaimcode,
				stateab:this.state.stateab,
				statebc:this.state.statebc,
				statemb:this.state.statemb,
				statenb:this.state.statenb,
				statenl:this.state.statenl,
				statent:this.state.statent,
				statens:this.state.statens,
				statenu:this.state.statenu,
				stateon:this.state.stateon,
				statepe:this.state.statepe,
				stateqc:this.state.stateqc,
				statesk:this.state.statesk,
				stateyt:this.state.stateyt,
				statefd:this.state.statefd				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (		
			<div>      				
				<CommonHeader titles="Tax Index"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Tax Index</h2></label>							
						<select name="taxindex" id="taxindex" style={{width:'150px'}} value={this.state.taxindex} onChange={this.handleManageIndexChange}>
							<option value="">--Manage Tax Index--</option>
							<option value="1">Add New Tax Index</option>															
							<option value="2">Update Tax Index</option>																					
						</select><br/><br/>								
					</div>	
					{(()=>{if(this.state.taxindex != "") {return(<div>						
							<label><h2>Select Year</h2></label>							
							<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleClaimChange}>
								<option value="">--Select Year--</option>																	
								{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}
							</select><br/><br/><br/>	
							<h1>{this.state.taxindex==1 && dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 183 ? thisyear+' Tax Index should be set up before ' + thisyear + ' or at least in the first six months of '+thisyear:''}</h1><br/><br/>
							<h1>{this.state.taxindex ==1 && this.state.yearclaimcode !="" && this.state.yearclaimcode == this.state.yearIndex && this.state.AddIndex != "No" ? "Current year "+this.state.yearclaimcode+" Index has been set up, you can check the accuracy":(this.state.taxindex ==1 && this.state.yearclaimcode !="" && this.state.yearclaimcode != this.state.yearIndex && this.state.AddIndex != "No" ? (this.state.yearIndex >this.state.yearclaimcode || this.state.yearclaimcode ==this.state.yearIndex ?"Current year "+this.state.yearclaimcode+" Index has been added into the system, please update if needed":"Please add current year "+this.state.yearclaimcode+" Index into the system based on populated year "+this.state.yearIndex+" Index values"):"")}</h1><br/><br/>							
						</div>)} 
					})()}						
					{(()=>{if(this.state.taxindex != "" && this.state.yearclaimcode !="") {return(<div>																																			
						<h2>{this.state.taxindex==1 ? "Please enter index values":"Please update index"}</h2><br/><br/>						
						<div className="label-wrap">
							<div className="label">	
								{this.state.Provs.slice(0,7).map((sub,index) => (<h2>{sub[1]}</h2>))}									
							</div>				
						</div>
						<div className="label-wrap">
							<div className="label">	
								<h2><input type="text" style={{width:'80px'}}  name="stateab" value={this.state.stateab} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statebc" value={this.state.statebc} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statemb" value={this.state.statemb} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statenb" value={this.state.statenb} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statenl" value={this.state.statenl} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statens" value={this.state.statens} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statent" value={this.state.statent} onChange={this.handleChange}/></h2>								
							</div>				
						</div><br/><br/><br/>
						<div className="label-wrap">
							<div className="label">	
								{this.state.Provs.slice(7,14).map((sub,index) => (<h2>{sub[1]}</h2>))}			
							</div>				
						</div>
						<div className="label-wrap">
							<div className="label">	
								<h2><input type="text" style={{width:'80px'}}  name="statenu" value={this.state.statenu} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="stateon" value={this.state.stateon} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statepe" value={this.state.statepe} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="stateqc" value={this.state.stateqc} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statesk" value={this.state.statesk} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="stateyt" value={this.state.stateyt} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statefd" value={this.state.statefd} onChange={this.handleChange}/></h2>
							</div>				
						</div><br/><br/>
						<h1>{this.state.Resp}</h1><br/><br/>
						</div>)}						
					})()}
					<h1>{this.state.indexcomms}</h1><br/><br/>
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupPayRollYear;