import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupPayRollYear extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:9,claimcode:'',Resp:'',total00:'',total01:'',total02:'',total03:'',total10:'',total11:'',total12:'',
			total13:'',total20:'',total21:'',total22:'',total23:'',total30:'',total31:'',total32:'',total33:'',total40:'',
			total41:'',total42:'',total43:'',total50:'',total51:'',total52:'',total53:'',total60:'',total61:'',total62:'',
			total63:'',total70:'',total71:'',total72:'',total73:'',total80:'',total81:'',total82:'',total83:'',total90:'',
			total91:'',total92:'',total93:'',total100:'',total101:'',total102:'',total103:'',employeeUpdate:'',indexyear:'',
			codeentered:'',yearclaimcode:'',Provs:[],indexvalue:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);
		this.handleClaimChange=this.handleClaimChange.bind(this)		
		this.handleEmployeeChange=this.handleEmployeeChange.bind(this)
		this.handleYearChange=this.handleYearChange.bind(this)
		this.handleManageClaimChange=this.handleManageClaimChange.bind(this)
		this.handleUpClaimChange=this.handleUpClaimChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {						
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Provs:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});					
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
	}	
	handleYearChange = event=>{		
		this.setState({Resp:""});
		this.setState({indexyear:""})
		this.setState({total00:""}); this.setState({total01:""}); this.setState({total02:""});this.setState({total03:""});
		this.setState({total10:""}); this.setState({total11:""}); this.setState({total12:""});this.setState({total13:""});
		this.setState({total20:""}); this.setState({total21:""}); this.setState({total22:""});this.setState({total23:""});
		this.setState({total30:""}); this.setState({total31:""}); this.setState({total32:""});this.setState({total33:""});
		this.setState({total40:""}); this.setState({total41:""}); this.setState({total42:""});this.setState({total43:""});
		this.setState({total50:""}); this.setState({total51:""}); this.setState({total52:""});this.setState({total53:""});
		this.setState({total60:""}); this.setState({total61:""}); this.setState({total62:""});this.setState({total63:""});
		this.setState({total70:""}); this.setState({total71:""}); this.setState({total72:""});this.setState({total73:""});
		this.setState({total80:""}); this.setState({total81:""}); this.setState({total82:""});this.setState({total83:""});
		this.setState({total90:""}); this.setState({total91:""}); this.setState({total92:""});this.setState({total93:""});
		this.setState({total100:""}); this.setState({total101:""}); this.setState({total102:""});this.setState({total103:""})		
		this.setState({indexvalue:""})		
		this.setState({codeentered:""})	
		const {name, value} = event.target				
		this.setState({[name]: value})			
		const userObject={
				STATE:this.state.employeeUpdate,				
				thisYEAR: event.target.value}
		axios.post(process.env.REACT_APP_API_ENV+'api/indexupdate.php', userObject)
			.then(result => {	
				if(result.data[0][0] != 'BPAF')	{
					if(result.data[0][0] == 'BPAF1')	{
						this.setState({Resp:"Please go to managing item -- Manage Tax Amounts (CPP, EI,etc) first and come back again"});
					} else {
					this.setState({total00:"No claim amount"}); this.setState({total01:"No claim amount"}); this.setState({total02:0});this.setState({total03:0});
					this.setState({total10:0}); this.setState({total11:result.data[1][2]}); this.setState({total12:result.data[1][3]});this.setState({total13:result.data[1][4]});
					this.setState({total20:result.data[2][1]}); this.setState({total21:result.data[2][2]}); this.setState({total22:result.data[2][3]});this.setState({total23:result.data[2][4]});
					this.setState({total30:result.data[3][1]}); this.setState({total31:result.data[3][2]}); this.setState({total32:result.data[3][3]});this.setState({total33:result.data[3][4]});
					this.setState({total40:result.data[4][1]}); this.setState({total41:result.data[4][2]}); this.setState({total42:result.data[4][3]});this.setState({total43:result.data[4][4]});
					this.setState({total50:result.data[5][1]}); this.setState({total51:result.data[5][2]}); this.setState({total52:result.data[5][3]});this.setState({total53:result.data[5][4]});
					this.setState({total60:result.data[6][1]}); this.setState({total61:result.data[6][2]}); this.setState({total62:result.data[6][3]});this.setState({total63:result.data[6][4]});
					this.setState({total70:result.data[7][1]}); this.setState({total71:result.data[7][2]}); this.setState({total72:result.data[7][3]});this.setState({total73:result.data[7][4]});
					this.setState({total80:result.data[8][1]}); this.setState({total81:result.data[8][2]}); this.setState({total82:result.data[8][3]});this.setState({total83:result.data[8][4]});
					this.setState({total90:result.data[9][1]}); this.setState({total91:result.data[9][2]}); this.setState({total92:result.data[9][3]});this.setState({total93:result.data[9][4]});
					this.setState({total100:result.data[10][1]}); this.setState({total101:result.data[10][2]}); this.setState({total102:result.data[10][3]});this.setState({total103:result.data[10][4]})		
					this.setState({codeentered:result.data[11][0]});	
					}
				} else {this.setState({indexvalue:'No'})}
			})	
		this.setState({Resp:""});	
	}

	
	handleManageClaimChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({employeeUpdate:''})
		
		this.setState({Resp:""});		
	}
	handleEmployeeChange = event => {				
		this.setState({employeeUpdate: event.target.value})	
		this.setState({yearclaimcode:""})
		this.setState({total00:""}); this.setState({total01:""}); this.setState({total02:""});this.setState({total03:""});
		this.setState({total10:""}); this.setState({total11:""}); this.setState({total12:""});this.setState({total13:""});
		this.setState({total20:""}); this.setState({total21:""}); this.setState({total22:""});this.setState({total23:""});
		this.setState({total30:""}); this.setState({total31:""}); this.setState({total32:""});this.setState({total33:""});
		this.setState({total40:""}); this.setState({total41:""}); this.setState({total42:""});this.setState({total43:""});
		this.setState({total50:""}); this.setState({total51:""}); this.setState({total52:""});this.setState({total53:""});
		this.setState({total60:""}); this.setState({total61:""}); this.setState({total62:""});this.setState({total63:""});
		this.setState({total70:""}); this.setState({total71:""}); this.setState({total72:""});this.setState({total73:""});
		this.setState({total80:""}); this.setState({total81:""}); this.setState({total82:""});this.setState({total83:""});
		this.setState({total90:""}); this.setState({total91:""}); this.setState({total92:""});this.setState({total93:""});
		this.setState({total100:""}); this.setState({total101:""}); this.setState({total102:""});this.setState({total103:""})		
		this.setState({Resp:""});
	}
	
	handleClaimChange = event=>{		
		const {name, value} = event.target				
		this.setState({[name]: value})	
		if (event.target.value==thisyear){
			this.setState({indexyear:lastyear})
		} else if (event.target.value==nextyear){
			this.setState({indexyear:thisyear})
		}
		this.setState({Resp:""});
		this.setState({total00:""}); this.setState({total01:""}); this.setState({total02:""});this.setState({total03:""});
		this.setState({total10:""}); this.setState({total11:""}); this.setState({total12:""});this.setState({total13:""});
		this.setState({total20:""}); this.setState({total21:""}); this.setState({total22:""});this.setState({total23:""});
		this.setState({total30:""}); this.setState({total31:""}); this.setState({total32:""});this.setState({total33:""});
		this.setState({total40:""}); this.setState({total41:""}); this.setState({total42:""});this.setState({total43:""});
		this.setState({total50:""}); this.setState({total51:""}); this.setState({total52:""});this.setState({total53:""});
		this.setState({total60:""}); this.setState({total61:""}); this.setState({total62:""});this.setState({total63:""});
		this.setState({total70:""}); this.setState({total71:""}); this.setState({total72:""});this.setState({total73:""});
		this.setState({total80:""}); this.setState({total81:""}); this.setState({total82:""});this.setState({total83:""});
		this.setState({total90:""}); this.setState({total91:""}); this.setState({total92:""});this.setState({total93:""});
		this.setState({total100:""}); this.setState({total101:""}); this.setState({total102:""});this.setState({total103:""})		
		this.setState({indexvalue:""})		
		if(this.state.claimcode !="" && this.state.yearclaimcode !="") {
			this.setState({employeeUpdate:''})
		}
	}
	handleUpClaimChange = event=>{		
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({indexvalue:""});
		this.setState({Resp:""});
		const userObject={		
			YEAR:event.target.value,
			STATE:this.state.employeeUpdate}
		axios.post(process.env.REACT_APP_API_ENV+'api/updateclaimcode.php', userObject)
			.then(result => {									
				if(result.data[0][0] != 'No')	{
					this.setState({total00:"No claim amount"}); this.setState({total01:"No claim amount"}); this.setState({total02:result.data[0][3]});this.setState({total03:result.data[0][4]});
					this.setState({total10:result.data[1][1]}); this.setState({total11:result.data[1][2]}); this.setState({total12:result.data[1][3]});this.setState({total13:result.data[1][4]});
					this.setState({total20:result.data[2][1]}); this.setState({total21:result.data[2][2]}); this.setState({total22:result.data[2][3]});this.setState({total23:result.data[2][4]});
					this.setState({total30:result.data[3][1]}); this.setState({total31:result.data[3][2]}); this.setState({total32:result.data[3][3]});this.setState({total33:result.data[3][4]});
					this.setState({total40:result.data[4][1]}); this.setState({total41:result.data[4][2]}); this.setState({total42:result.data[4][3]});this.setState({total43:result.data[4][4]});
					this.setState({total50:result.data[5][1]}); this.setState({total51:result.data[5][2]}); this.setState({total52:result.data[5][3]});this.setState({total53:result.data[5][4]});
					this.setState({total60:result.data[6][1]}); this.setState({total61:result.data[6][2]}); this.setState({total62:result.data[6][3]});this.setState({total63:result.data[6][4]});
					this.setState({total70:result.data[7][1]}); this.setState({total71:result.data[7][2]}); this.setState({total72:result.data[7][3]});this.setState({total73:result.data[7][4]});
					this.setState({total80:result.data[8][1]}); this.setState({total81:result.data[8][2]}); this.setState({total82:result.data[8][3]});this.setState({total83:result.data[8][4]});
					this.setState({total90:result.data[9][1]}); this.setState({total91:result.data[9][2]}); this.setState({total92:result.data[9][3]});this.setState({total93:result.data[9][4]});
					this.setState({total100:result.data[10][1]}); this.setState({total101:result.data[10][2]}); this.setState({total102:result.data[10][3]});this.setState({total103:result.data[10][4]})		
				} else {this.setState({indexvalue:'No'})}
			})		
	}
	handleSubmit = event => {		
		event.preventDefault()		
		const {claimcode,total00,total01,total02,total03,total10,total11,total12,total13,total20,total21,total22,total23,total30,total31,total32,total33,
				total40,total41,total42,total43,total50,total51,total52,total53,total60,total61,total62,total63,total70,total71,total72,total73,total80,total81,total82,
				total83,total90,total91,total92,total93,total100,total101,total102,total103, 
				employeeUpdate,yearclaimcode,indexvalue} = this.state			
		if (this.state.claimcode == "") {
			alert('Please select manage claim code type, Add, Update or Delete')
			return false
		}
		if(this.state.claimcode==1)  {
			if(!employeeUpdate) {
				alert('Please select province for the claim code')
				return false
			}			
			if(!yearclaimcode) {
				alert('Please select year for the claim code')
				return false
			}				
			if(!total00) {
				alert('Please enter value for claim 0')
				return false
			}			
			if(!total62) {
				alert('Please enter value for claim 6')
				return false
			}			
			if(!total102) {
				alert('Please enter value for claim 10')
				return false
			}					
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				claimcode:this.state.claimcode,	
				employeeUpdate:this.state.employeeUpdate,
				yearclaimcode: this.state.yearclaimcode,
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,total03:this.state.total03,
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,total13:this.state.total13,
				total20:this.state.total20,total21:this.state.total21,total22:this.state.total22,total23:this.state.total23,
				total30:this.state.total30,total31:this.state.total31,total32:this.state.total32,total33:this.state.total33,
				total40:this.state.total40,total41:this.state.total41,total42:this.state.total42,total43:this.state.total43,
				total50:this.state.total50,total51:this.state.total51,total52:this.state.total52,total53:this.state.total53,
				total60:this.state.total60,total61:this.state.total61,total62:this.state.total62,total63:this.state.total63,
				total70:this.state.total70,total71:this.state.total71,total72:this.state.total72,total73:this.state.total73,
				total80:this.state.total80,total81:this.state.total81,total82:this.state.total82,total83:this.state.total83,
				total90:this.state.total90,total91:this.state.total91,total92:this.state.total92,total93:this.state.total93,
				total100:this.state.total100,total101:this.state.total101,total102:this.state.total102,total103:this.state.total103				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.claimcode==2)  {
			if(!employeeUpdate) {
				alert('Please select province for the claim code updating')
				return false
			}			
			if(!yearclaimcode) {
				alert('Please select year for the claim code updating')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				claimcode:this.state.claimcode,	
				employeeUpdate:this.state.employeeUpdate,
				yearclaimcode: this.state.yearclaimcode,
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,total03:this.state.total03,
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,total13:this.state.total13,
				total20:this.state.total20,total21:this.state.total21,total22:this.state.total22,total23:this.state.total23,
				total30:this.state.total30,total31:this.state.total31,total32:this.state.total32,total33:this.state.total33,
				total40:this.state.total40,total41:this.state.total41,total42:this.state.total42,total43:this.state.total43,
				total50:this.state.total50,total51:this.state.total51,total52:this.state.total52,total53:this.state.total53,
				total60:this.state.total60,total61:this.state.total61,total62:this.state.total62,total63:this.state.total63,
				total70:this.state.total70,total71:this.state.total71,total72:this.state.total72,total73:this.state.total73,
				total80:this.state.total80,total81:this.state.total81,total82:this.state.total82,total83:this.state.total83,
				total90:this.state.total90,total91:this.state.total91,total92:this.state.total92,total93:this.state.total93,
				total100:this.state.total100,total101:this.state.total101,total102:this.state.total102,total103:this.state.total103				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.claimcode==3)  {
			if(!employeeUpdate) {
				alert('Please select province')
				return false
			}			
			if(!yearclaimcode) {
				alert('Please select year')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				claimcode:this.state.claimcode,	
				employeeUpdate:this.state.employeeUpdate,
				yearclaimcode: this.state.yearclaimcode,
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (		
			<div>      	
				<CommonHeader titles="Claim Code"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Claim Code</h2></label>							
						<select name="claimcode" id="claimcode" style={{width:'150px'}} value={this.state.claimcode} onChange={this.handleManageClaimChange}>
							<option value="">--Manage Claim Code--</option>
							<option value="1">Add New Claim Code</option>															
							<option value="2">Update Claim Code</option>												
							<option value="3">Remove Claim Code</option>		
						</select><br/><br/>	
					</div>	
					{(()=>{
						{/*add new claim code*/}
						if(this.state.claimcode == "1") {return(<div>
							<label><h2>Select Province</h2></label>							
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Province--</option>									
									{this.state.Provs.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
								</select><br/><br/>								
							{(()=>{if(this.state.employeeUpdate != "") {return(<div>
									<label><h2>Select Year</h2></label>							
										<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleYearChange}>
											<option value="">--Select Year--</option>																				
											{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}
										</select>
									<br/><br/>										
							</div>)}})()}	
							<h2>{this.state.indexvalue=="No"? "Please enter basic personal tax credit return":""}</h2><br/><br/>
							<h2>{this.state.codeentered == "CODE"? this.state.yearclaimcode + " claim code for " + this.state.employeeUpdate+ " has been entered, please check the accuracy":""}</h2><br/><br/>
						{/*update claim code*/}
						</div>)} else if(this.state.claimcode == "2") {return(<div>
							<label><h2>Select Province</h2></label>							
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Province--</option>									
									{this.state.Provs.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
								</select>
							<br/><br/>
							{(()=>{if(this.state.employeeUpdate != "") {return(<div>
									<label><h2>Select Year</h2></label>							
										<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleUpClaimChange}>
											<option value="">--Select Year--</option>									
											{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}									
										</select>
									<br/><br/>											
								</div>)}
							})()}				
						<h2>{(this.state.claimcode==2 && this.state.yearclaimcode=="") ? "":"Please modify claim code values and update"}</h2><br/><br/>
						{/*remove claim code*/}
						</div>)} else if(this.state.claimcode == "3") {return(<div>
							<label><h2>Select Province</h2></label>							
								<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleEmployeeChange}>
									<option value="">--Select Province--</option>									
									{this.state.Provs.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
								</select>
							<br/><br/>
							{(()=>{if(this.state.employeeUpdate != "") {return(<div>
									<label><h2>Select Year</h2></label>							
										<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleUpClaimChange}>
											<option value="">--Select Year--</option>									
											<option value={lastyear}>{lastyear}</option>									
											<option value={thisyear}>{thisyear}</option>									
											<option value={nextyear}>{nextyear}</option>									
										</select>
									<br/><br/>											
								</div>)}
							})()}
						<h2>{(this.state.claimcode==3 && this.state.yearclaimcode=="") ? "":"Please look at the claim code values carefully and determine the removing"}</h2><br/><br/>						
						</div>)} 
					})()}
					{/*Add new claim code/update claim code/delete claim code*/}
					{(()=>{if(this.state.claimcode != "" && this.state.employeeUpdate !="" && this.state.yearclaimcode !="") {return(<div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>Claim Code</h2><h2 style={{width:'170px'}}>Total claim amount ($) from</h2>
								<h2 style={{width:'150px'}}>Total claim amount ($) to</h2><h2 style={{width:'150px'}}>Option 1, TCP = ($)</h2>
								<h2 style={{width:'150px'}}>Option 1, K1P ($)</h2>
							</div>				
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>0</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total00" value={this.state.total00} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total01" value={this.state.total01} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total02" value={this.state.total02} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total03" value={this.state.total03} onChange={this.handleChange}/></h2>
							</div>				
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>1</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total10" value={this.state.total10} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total11" value={this.state.total11} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total12" value={this.state.total12} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total13" value={this.state.total13} onChange={this.handleChange}/></h2>
							</div>				
						</div>				
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>2</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total20" value={this.state.total20} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total21" value={this.state.total21} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total22" value={this.state.total22} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total23" value={this.state.total23} onChange={this.handleChange}/></h2>
							</div>				
						</div>				
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>3</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total30" value={this.state.total30} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total31" value={this.state.total31} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total32" value={this.state.total32} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total33" value={this.state.total33} onChange={this.handleChange}/></h2>
							</div>				
						</div>				
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>4</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total40" value={this.state.total40} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total41" value={this.state.total41} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total42" value={this.state.total42} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total43" value={this.state.total43} onChange={this.handleChange}/></h2>
							</div>				
						</div>				
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>5</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total50" value={this.state.total50} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total51" value={this.state.total51} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total52" value={this.state.total52} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total53" value={this.state.total53} onChange={this.handleChange}/></h2>
							</div>				
						</div>				
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>6</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total60" value={this.state.total60} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total61" value={this.state.total61} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total62" value={this.state.total62} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total63" value={this.state.total63} onChange={this.handleChange}/></h2>
							</div>				
						</div>				
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>7</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total70" value={this.state.total70} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total71" value={this.state.total71} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total72" value={this.state.total72} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total73" value={this.state.total73} onChange={this.handleChange}/></h2>
							</div>				
						</div>				
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>8</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total80" value={this.state.total80} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total81" value={this.state.total81} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total82" value={this.state.total82} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total83" value={this.state.total83} onChange={this.handleChange}/></h2>
							</div>				
						</div>				
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>9</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total90" value={this.state.total90} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total91" value={this.state.total91} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total92" value={this.state.total92} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total93" value={this.state.total93} onChange={this.handleChange}/></h2>
							</div>				
						</div>	
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'130px'}}>10</h2>
								<h2 style={{width:'170px'}}><input type="text" style={{width:'100px'}} name="total100" value={this.state.total100} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total101" value={this.state.total101} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total102" value={this.state.total102} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total103" value={this.state.total103} onChange={this.handleChange}/></h2>
							</div>				
						</div><br/><br/>			
						<h2>{(this.state.indexvalue!="No"&&this.state.indexvalue!="")? "These values are obtained by "+this.state.indexyear+" claim code values multiplied by "+this.state.yearclaimcode+" index values. If no changes required, please click Submit button to add them to the system":""}</h2><br/><br/>
						<h1>{this.state.Resp}</h1><br/><br/>
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupPayRollYear;