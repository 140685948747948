import React from "react";
import dayjs from 'dayjs';
import '../main.css'
import axios from 'axios'
import CommonHeader from '../common/Head'


class contactForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {firstname:'', lastname:'',compname:'',email:'',comments:'',Resp:''};		
		this.handleChange=this.handleChange.bind(this);			
		this.handleSubmit=this.handleSubmit.bind(this)		
	}

	
	handleChange = event => {	
		const {name, value} = event.target		
		this.setState({[name]: value})			
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {firstname, lastname,email} = this.state			
		if(this.state.firstname == "") {
			alert('Please enter your first name')
			return false
		}			
		if(this.state.lastname == "") {
			alert('Please enter your last name')
			return false
		} 		
		if(this.state.email == "") {
			alert('Please enter your email address')
			return false
		} 		
		const userObject={
				firstname:this.state.firstname,	
				lastname: this.state.lastname,				
				email: this.state.email
				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/retrievepwd.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});
	}
	
	render() {							
		return (
			<div>				
				<CommonHeader titles="PASSWORD"/>
				<form onSubmit={this.handleSubmit}>							
					<label><h2>First Name</h2></label>							
					<input name="firstname" id="firstname" style={{width:'150px'}} value={this.state.firstname} onChange={this.handleChange} />
					<br/><br/><br/>
					<label><h2 >Last Name</h2></label>						
					<input name="lastname" id="lastname" style={{width:'150px'}} value={this.state.lastname} onChange={this.handleChange} />														
					<br/><br/><br/>
					<label><h2 >Email</h2></label>						
					<input name="email" id="email" style={{width:'150px'}} value={this.state.email} onChange={this.handleChange} />														
					<br/><br/><br/>
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> &nbsp;&nbsp;<br/><br/>
					<h2>{this.state.Resp=="" ? "":this.state.Resp}</h2>
				</form>
			</div>
		)
	}
}

export default contactForm;