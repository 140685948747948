/* eslint-disable import/first */
import React from 'react'
import '../main.css'
import axios from 'axios'
import MenuBar from '../common/Menu'
import RegForm from '../common/regsub'
import BusinessName from '../common/BusinessName'
import PrimaryFooter from '../common/PrimaryFooter'
import BusinessBanner from '../common/BusinessBanner'


class RegistrationTitle extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {HM_Title:''};				
	}

	componentDidMount() {				
		const userObject={
				Titles:"REGSUBMITTER"				
			}
		axios.post(process.env.REACT_APP_API_ENV+'api/FindTitle.php', userObject)
			.then(result => {
				this.setState({HM_Title:result.data})													
			})		
	}
	render() {				
		return(
			<div className="title">
				<h1>{this.state.HM_Title} </h1>
			</div>
		)
	}
}

function GenericPage() {
	return(		
		<div className="generic">
			<div className="panel">	  
				<RegistrationTitle />											
				<RegForm />
			</div>
		</div>		
	)
}

function MainPage(){
	return(
		<div className="page">
			<GenericPage />
			
		</div>		
	)
}

export default function EmployeeLogin() {
	return(
		<div>
			<MenuBar />
			<BusinessName />			
			<BusinessBanner />
			<MainPage />
			<PrimaryFooter />
		</div>		
	)
}