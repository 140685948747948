import React, { useState, useEffect } from "react";
import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {Link,Redirect} from "react-router-dom";
import axios from 'axios';
import Logo from '../img/logo_5.jpg'
import '../main.css'
import { useAuth } from "../context/auth";
import Clients from "../clients/Timesheet";
import CommonHeader from '../common/Head';
import RootDir from '../common/apiaddress.json'


class RegistrationTitle extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}

	componentDidMount() {		
		axios.post(RootDir.root_dir+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data})													
			})		
	}
	render() {				
		return(
			<div className="title">
				<CommonHeader titles="Login"/>
				<div align="center">
					<br/><br/>
					
					<h1><img src={Logo} alt="icon" /></h1>		
				
				<h2>Login to Unique Chinese School Payroll System </h2><br/>
				</div>
			</div>
		)
	}
}

function Login(props) {
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [isError, setIsError] = useState(false);	
	const [userName, setUserName] = useState("");
	const [passWord, setPassWord] = useState("");	
	const [comments, setComments] = useState("");	
	const { setAuthTokens } = useAuth(); 
	const [temp, setTemp]=useState([]);
	let referer;	
	if(props.location.state !== undefined) {
		referer = props.location.state.referer;
	} else {
		referer = "/";
	}		
	referer='/Clients';		
	function postLogin(props) {	 				
		axios.post(RootDir.root_dir+'api/users.php', props)
			.then(result => {			
			if (result.status === 200) {				
				if(result.data[0] ==='NoEmail') {
					setComments('Sorry, you have not enter your username/password yet');						
				} else if(result.data[0] ==='NoReg') {
					setComments('Sorry, you enter wrong username/password or you have not registered yet')
				} else {				
					setAuthTokens(result.data);
					setLoggedIn(true);		
				}
			} else {
				setIsError(true);
			}
		}).catch(e => {
			setIsError(true);
		});
	}			
	if (isLoggedIn) {
		return <Redirect to={referer} />;
	}

	
	const initialValues = {
			userName: '',
			passWord: ''     
	};
	
	const validationSchema=Yup.object().shape({
						userName: Yup.string()
							.email('Username is not a valid email address')
							.required('Username is required'),
						passWord: Yup.string()
							.required('password is required'),                    
	})
	
	return (
		<div className="generic">
			<div className="logins" align="center">	 					
				<RegistrationTitle />				
				<Formik initialValues={initialValues} validationSchema={validationSchema} 
						onSubmit={(values, { setSubmitting }) => {
							setTimeout(() => {							
							  postLogin(values);
							  setSubmitting(false);
							}, 500);
						}}			
				>
					{({ errors, status, touched }) => {
					return(
						<Form>                        							
                            <label htmlFor="userName">Username</label><br/>
                            <Field id="userName" name="userName" type="text" className={'form-control' + (errors.userName && touched.userName ? ' is-invalid' : '')} placeholder='email'/>
                            <ErrorMessage name="userName" component="div" className="invalid-feedback" /><br/><br/>
							
							<label htmlFor="passWord">Password</label><br/>
                            <Field id="passWord" name="passWord" type="password" className={'form-control' + (errors.passWord && touched.passWord ? ' is-invalid' : '')} placeholder='password' />
                            <ErrorMessage name="passWord" component="div" className="invalid-feedback" /><br/><br/>
                          													
							<h2>{comments}</h2>
							<br/><br/>	
							
                            <button type="submit" className="btn btn-primary mr-2" width="150px" >&nbsp;Login &nbsp;</button>							
							&nbsp;&nbsp;
                            <button type="reset" className="btn btn-secondary">&nbsp;Reset&nbsp;</button><br/><br/>
						</Form>
						)
					}}
				</Formik>
				<p><Link to = "/UCSPassword">Forgot Password?</Link></p>
			</div>
		</div>
	);
}

export default Login;