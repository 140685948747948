import React from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";


class CopyRight extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}

	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data})													
			})		
	}
	render() {				
		return(
			<div className="copyright-wrap">
				<div className="panel">
					<div className="content">
						<p>Copyright &copy; 2019  {this.state.CompName[0]}. All rights reserved. </p>
						<p><Link to="/" target="_blank">Home</Link>&nbsp;|&nbsp;
							<Link to="/Privacy" target="_blank">Privacy Policy</Link>
							&nbsp;|&nbsp;
							<Link to="/Resource" target="_blank">Resource</Link>
							&nbsp;|&nbsp;
							<Link to="/Contact" target="_blank">Contact US</Link></p>
					</div>
				</div>
			</div>
		)
	}
}

function PrimaryFooter(){
	return(
		<div className="primary-footer">
			<CopyRight />
		</div>
	)
}

export default PrimaryFooter