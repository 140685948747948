import React from 'react'
import axios from 'axios';
import {Helmet} from "react-helmet";

class HeadInfo extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:'', user:localStorage.getItem('tokens')};				
	}

	componentDidMount() {			
		const para={user:this.state.user}
		axios.post(process.env.REACT_APP_API_ENV+'api/FindClientName.php', para)
			.then(result => {				
				this.setState({CompName:result.data[0]})													
			})		
	}
	
	render(){
		return (
			<Helmet>				
					<title>{this.props.titles+'|'+this.state.CompName+' Payroll System'}</title>
					<meta name="description" content="Payroll Online Service" />      				
					<meta name="description" content="Reduce Business Costs and Increase Overall Efficiency" />
					<meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
					<link rel="canonical" href={process.env.REACT_APP_API_ENV} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content="Online Payroll Service Company in Canada" />
					<meta property="og:description" content="Reduce your business costs and increase overall efficiency" />
					<meta property="og:url" content={process.env.REACT_APP_API_ENV} />
					<meta property="og:site_name" content="Online Payroll Services Companies in Canada" />								
			</Helmet>
		)
	}
}

export default HeadInfo