/* eslint-disable import/first */
import React from 'react'
import '../main.css'
import axios from 'axios';
import MenuBar from '../common/Menu'
import ContactForm from '../common/ContactForm'
import LoginPage from '../admin/quoteform'
import PrimaryFooter from '../common/PrimaryFooter'
import BusinessName from '../common/BusinessName'
import BusinessBanner from '../common/BusinessBanner'


class SearchImage extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}

	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data})													
			})		
	}
	render() {				
		return(
			<div className="title">
				<h1> Contact Information</h1>
			</div>
		)
	}
}


class HomeTitle extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {HM_Title:''};				
	}

	componentDidMount() {				
		const userObject={
				Titles:"CONTACTOR"				
			}
		axios.post(process.env.REACT_APP_API_ENV+'api/FindTitle.php', userObject)
			.then(result => {
				this.setState({HM_Title:result.data})													
			})		
	}
	render() {				
		return(
			<div className="title">
				<h1>{this.state.HM_Title} </h1>
			</div>
		)
	}
}

function Primary() {
	return(
		<div className="primary-col">
			<div className="generic">
				<div className="panel">	  
					<HomeTitle />										
					<ContactForm />
				</div>
			</div>
		</div>
	)
}



function LoginTool(){
	return(
		<div className="search">			
			<LoginPage />			
		</div>
	)
}


class SearchCon extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {starting:'', ending:'', email:'',phone:'', address:''};				
	}

	componentDidMount() {						
		axios.post(process.env.REACT_APP_API_ENV+'api/workinghours.php')
			.then(result => {console.log(result.data)
				this.setState({starting:result.data[0]})													
				this.setState({ending:result.data[1]})													
				this.setState({email:result.data[2]})	
				this.setState({phone:result.data[3]})	
				this.setState({address:result.data[4]})	
			})		
	}
	render() {
		return(		
			<ul>
			  <li><b>Business Hours<small>{' ('+'except holidays)'}</small>:</b></li>
			  <li>{'Monday to Friday: '+this.state.starting+' to '+this.state.ending+' EST'}</li>
			  <br/>
			  <li><b>Email:</b></li>
			  <li>{this.state.email}</li>
			  <br/>
			  <li><b>Phone:</b></li>
			  <li>{this.state.phone}</li>
			  <br/>
			  <li><b>Address:</b></li>
			  <li>{this.state.address}</li>
			  <br/>
			</ul>     
		)
	}
}

function SearchPanel(){
	return(
		<div className="panel">
			<SearchImage />
			<div className="content">
				<SearchCon />
			</div>
		</div>
	)
}

function SideBar() {
	return(
		<div className="side-bar">			
			<SearchPanel />			
		</div>
	)
}

function HomePage(){
	return(
		<div className="page">
			<Primary />
			<SideBar />
		</div>		
	)
}

export default function Home() {
	return(
		<div>
			<MenuBar />
			<BusinessName />			
			<BusinessBanner />
			<HomePage />		
			<PrimaryFooter />
		</div>		
	)
}

	