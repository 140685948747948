import React from 'react'
import '../main.css'
import axios from 'axios'
import CommonHeader from '../clients/commons/Head'

class setupTables extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {SetTables:[], employee:'',tablenames:[],tables:'',newtable:'',querys:'',newname:'',
			updatetables:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);				
		this.handleManageEmployeeChange=this.handleManageEmployeeChange.bind(this)	
		this.handleUpdateChange=this.handleUpdateChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {						
		axios.post(process.env.REACT_APP_API_ENV+'api/findtables.php')
			.then(result => {				
				this.setState({tablenames:result.data})
			})		
	}
	
	
	handleChange = event => {	
		this.setState({querys:""})
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""})
	}
	
	handleManageEmployeeChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		if(event.target.value == 1) {		
			axios.post(process.env.REACT_APP_API_ENV+'api/SetupTables.php')
				.then(result => {
					this.setState({SetTables:result.data})												
			})
		}
	}
	
	handleUpdateChange = event => {		
		this.setState({tables:""})
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({querys:""})
		const userObject = {	
				updatingtables:this.state.updatingtables,
				tables:event.target.value
		}	
		axios.post(process.env.REACT_APP_API_ENV+'api/tablecolumns.php',userObject)
			.then(result => {
				this.setState({querys:result.data})												
		})
		
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {employee,} = this.state					
		if (this.state.employee == "") {
				alert('Please select how to manage tables')
				return false
		}
		if(this.state.employee == "1")  {
			axios.post(process.env.REACT_APP_API_ENV+'api/SetupTables.php')
			.then(result => {
				this.setState({SetTables:result.data})												
			})						
		} else if(this.state.employee == "2")  {
			const userObject = {	
				querys:this.state.querys,
				newname:this.state.newname
			 }				
			axios.post(process.env.REACT_APP_API_ENV+'api/createnewtable.php',userObject)
			.then(result => {
				this.setState({newtable:result.data})												
			})	
		} else if(this.state.employee == "3")  {
			const userObject = {	
				querys:this.state.querys,
				tables:this.state.tables,
				updatingtables:this.state.updatingtables
			 }				
			axios.post(process.env.REACT_APP_API_ENV+'api/updateexistingtables.php',userObject)
			.then(result => {
				this.setState({updatetables:result.data})												
			})	
		}
	}
	
	render() {									
		return (		
			<div>      				
				<CommonHeader titles="Payroll Tables"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Tables</h2></label>							
						<select name="employee" id="employee" style={{width:'150px'}} value={this.state.employee} onChange={this.handleManageEmployeeChange}>
							<option value="">--Manage Tables--</option>
							<option value="1">Initialize Tables</option>						
							<option value="2">Add New Table</option>
							<option value="3">Update Existing Table</option>
						</select><br/><br/>
					</div>
					{(()=>{if(this.state.employee == "1") {return(<div>							
						<h2>This function will generate all required and necessary tables for the system to run</h2><br/>						
						<label style={{width: '80px',display:'inline-block',textAlign:'center'}}><b>Sequence</b></label>	
						<label style={{width: '120px',display:'inline-block',textAlign:'left'}}><b>Table Name</b></label>			
						<label style={{width: '300px',display:'inline-block',textAlign:'left'}}><b>Table Description</b></label>			
						{this.state.SetTables.slice(0, this.state.SetTables.length).map(item => {return (<div>		
							<label style={{width: '80px',display:'inline-block',textAlign:'center'}}>{item[2]}</label>
							<label style={{width: '120px',display:'inline-block',textAlign:'left'}}>{item[0]}</label>
							<label style={{width: '300px',display:'inline-block',textAlign:'left'}}>{item[1]}</label>
						</div>)})}<br/><br/>					
					</div>)} else if(this.state.employee == "2") {return(<div>
						<label><h2>New Table Name</h2></label>
						<input type="text" name="newname" style={{width: '150px'}} value={this.state.newname} onChange={this.handleChange}/><br/><br/>
						<label><h2>Query</h2></label>
						<textarea name="querys" rows="10" value = {this.state.querys} style={{width: '500px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
						<h2>{this.state.newtable}</h2><br/>
					</div>)} else if(this.state.employee == "3") {return(<div>
						<label><h2>Select Action</h2></label>	
						<select name="updatingtables" style={{width:'150px'}} value={this.state.updatingtables} onChange={this.handleChange}>
							<option value="">--Manage Tables--</option>
							<option value="1">Insert Record</option>
							<option value="2">Update Record</option>
							<option value="3">Delete Record</option>
							<option value="4">Other Actions</option>
						</select><br/><br/>
						<label><h2>Select Table</h2></label>	
						<select name="tables" style={{width:'150px'}} value={this.state.tables} onChange={this.handleUpdateChange}>
							<option value="">--Manage Tables--</option>
							{this.state.tablenames.map((d,key)=>(<option value={d[0]} key={d[0]}>{d[0]}</option>))}	
						</select><br/><br/>
						{(()=>{if(this.state.updatingtables == "1" && this.state.tables !== "") {return(<div>
							<label><h2>Please update numerical value and string value '-'</h2></label>
						</div>)} else if(this.state.updatingtables == "2" && this.state.tables !== "") {return(<div>
							<label><h2>Please update numerical value and string value '-' and add condition after WHERE</h2></label>
						</div>)} else if(this.state.updatingtables == "3" && this.state.tables !== "") {return(<div>
							<label><h2>Please add condition after WHERE</h2></label>
						</div>)} else if(this.state.updatingtables == "4" && this.state.tables !== "") {return(<div>
							<label><h2>Please write your query in the below box for your selected table</h2></label>
						</div>)}})()}
							<textarea name="querys" rows="10" value = {this.state.querys} style={{width: '500px',border:'1px solid #4195fc'}} onChange={this.handleChange} /><br/><br/>
							<h2>{this.state.updatetables}</h2><br/>
					</div>)}})()}	
					{(()=>{if(this.state.employee !== "1" && this.state.employee !== "") {return(<div>
						<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
					</div>)}})()}
				</form>				
			</div>
		)
	}
}

export default setupTables;