import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')


class setupPayRollYear extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:22,claimcode:'',Resp:'',
			total00:'',total01:'',total02:'',total10:'',total11:'',total12:'',
			total20:'',total21:'',total22:'',total30:'',total31:'',total32:'',
			total40:'',total41:'',total42:'',total50:'',total51:'',total52:'',
			total60:'',total61:'',total62:'',total70:'',total71:'',total72:'',
			total80:'',total81:'',total82:'',total90:'',total91:'',total92:'',
			total100:'',total101:'',total102:'',total110:'',total111:'',total112:'',
			total120:'',total121:'',total122:'',total130:'',total131:'',total132:'',
			yearclaimcode:'',Provs:[],user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);								
		this.handleManageClaimChange=this.handleManageClaimChange.bind(this)		
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Provs:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});					
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
	}	
	
	handleManageClaimChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})		
		if(event.target.value==2 || event.target.value ==3) {
			this.setState({total00:this.state.Provs.filter(sub=>sub[0]=='FD').map(sub=>sub[2]).[0]}); this.setState({total01:this.state.Provs.filter(sub=>sub[0]=='FD').map(sub=>sub[3]).[0]});this.setState({total02:this.state.Provs.filter(sub=>sub[0]=='FD').map(sub=>sub[4]).[0]});
			this.setState({total10:this.state.Provs.filter(sub=>sub[0]=='AB').map(sub=>sub[2]).[0]}); this.setState({total11:this.state.Provs.filter(sub=>sub[0]=='AB').map(sub=>sub[3]).[0]});this.setState({total12:this.state.Provs.filter(sub=>sub[0]=='AB').map(sub=>sub[4]).[0]});
			this.setState({total20:this.state.Provs.filter(sub=>sub[0]=='BC').map(sub=>sub[2]).[0]}); this.setState({total21:this.state.Provs.filter(sub=>sub[0]=='BC').map(sub=>sub[3]).[0]});this.setState({total22:this.state.Provs.filter(sub=>sub[0]=='BC').map(sub=>sub[4]).[0]});
			this.setState({total30:this.state.Provs.filter(sub=>sub[0]=='MB').map(sub=>sub[2]).[0]}); this.setState({total31:this.state.Provs.filter(sub=>sub[0]=='MB').map(sub=>sub[3]).[0]});this.setState({total32:this.state.Provs.filter(sub=>sub[0]=='MB').map(sub=>sub[4]).[0]});
			this.setState({total40:this.state.Provs.filter(sub=>sub[0]=='NB').map(sub=>sub[2]).[0]}); this.setState({total41:this.state.Provs.filter(sub=>sub[0]=='NB').map(sub=>sub[3]).[0]});this.setState({total42:this.state.Provs.filter(sub=>sub[0]=='NB').map(sub=>sub[4]).[0]});
			this.setState({total50:this.state.Provs.filter(sub=>sub[0]=='NL').map(sub=>sub[2]).[0]}); this.setState({total51:this.state.Provs.filter(sub=>sub[0]=='NL').map(sub=>sub[3]).[0]});this.setState({total52:this.state.Provs.filter(sub=>sub[0]=='NL').map(sub=>sub[4]).[0]});
			this.setState({total60:this.state.Provs.filter(sub=>sub[0]=='NS').map(sub=>sub[2]).[0]}); this.setState({total61:this.state.Provs.filter(sub=>sub[0]=='NS').map(sub=>sub[3]).[0]});this.setState({total62:this.state.Provs.filter(sub=>sub[0]=='NS').map(sub=>sub[4]).[0]});
			this.setState({total70:this.state.Provs.filter(sub=>sub[0]=='NT').map(sub=>sub[2]).[0]}); this.setState({total71:this.state.Provs.filter(sub=>sub[0]=='NT').map(sub=>sub[3]).[0]});this.setState({total72:this.state.Provs.filter(sub=>sub[0]=='NT').map(sub=>sub[4]).[0]});
			this.setState({total80:this.state.Provs.filter(sub=>sub[0]=='NU').map(sub=>sub[2]).[0]}); this.setState({total81:this.state.Provs.filter(sub=>sub[0]=='NU').map(sub=>sub[3]).[0]});this.setState({total82:this.state.Provs.filter(sub=>sub[0]=='NU').map(sub=>sub[4]).[0]});
			this.setState({total90:this.state.Provs.filter(sub=>sub[0]=='ON').map(sub=>sub[2]).[0]}); this.setState({total91:this.state.Provs.filter(sub=>sub[0]=='ON').map(sub=>sub[3]).[0]});this.setState({total92:this.state.Provs.filter(sub=>sub[0]=='ON').map(sub=>sub[4]).[0]});
			this.setState({total100:this.state.Provs.filter(sub=>sub[0]=='PE').map(sub=>sub[2]).[0]}); this.setState({total101:this.state.Provs.filter(sub=>sub[0]=='PE').map(sub=>sub[3]).[0]});this.setState({total102:this.state.Provs.filter(sub=>sub[0]=='PE').map(sub=>sub[4]).[0]});
			this.setState({total110:this.state.Provs.filter(sub=>sub[0]=='QC').map(sub=>sub[2]).[0]}); this.setState({total111:this.state.Provs.filter(sub=>sub[0]=='QC').map(sub=>sub[3]).[0]});this.setState({total112:this.state.Provs.filter(sub=>sub[0]=='QC').map(sub=>sub[4]).[0]});
			this.setState({total120:this.state.Provs.filter(sub=>sub[0]=='SK').map(sub=>sub[2]).[0]}); this.setState({total121:this.state.Provs.filter(sub=>sub[0]=='SK').map(sub=>sub[3]).[0]});this.setState({total122:this.state.Provs.filter(sub=>sub[0]=='SK').map(sub=>sub[4]).[0]});
			this.setState({total130:this.state.Provs.filter(sub=>sub[0]=='YT').map(sub=>sub[2]).[0]}); this.setState({total131:this.state.Provs.filter(sub=>sub[0]=='YT').map(sub=>sub[3]).[0]});this.setState({total132:this.state.Provs.filter(sub=>sub[0]=='YT').map(sub=>sub[4]).[0]});
		}
		this.setState({Resp:""});		
	}
		
	handleSubmit = event => {		
		event.preventDefault()		
		const {claimcode,total00,total01,total02,total10,total11,total12,total20,total21,total22,total30,total31,total32,
				total40,total41,total42,total50,total51,total52,total60,total61,total62,total70,total71,total72,
				total80,total81,total82,total90,total91,total92,total100,total101,total102,total110,total111,total112,
				total120,total121,total122,total130,total131,total132} = this.state			
		if (this.state.claimcode == "") {
			alert('Please select manage sales tax rate type, Add, Update or Delete')
			return false
		}
		if(this.state.claimcode==1 || this.state.claimcode==2)  {
			if(!total00) {
				alert('Please enter Federal GST value')
				return false
			}						
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				claimcode:this.state.claimcode,					
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,
				total20:this.state.total20,total21:this.state.total21,total22:this.state.total22,
				total30:this.state.total30,total31:this.state.total31,total32:this.state.total32,
				total40:this.state.total40,total41:this.state.total41,total42:this.state.total42,
				total50:this.state.total50,total51:this.state.total51,total52:this.state.total52,
				total60:this.state.total60,total61:this.state.total61,total62:this.state.total62,
				total70:this.state.total70,total71:this.state.total71,total72:this.state.total72,
				total80:this.state.total80,total81:this.state.total81,total82:this.state.total82,
				total90:this.state.total90,total91:this.state.total91,total92:this.state.total92,
				total100:this.state.total100,total101:this.state.total101,total102:this.state.total102,
				total110:this.state.total110,total111:this.state.total111,total112:this.state.total112,
				total120:this.state.total120,total121:this.state.total121,total122:this.state.total122,
				total130:this.state.total130,total131:this.state.total131,total132:this.state.total132
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.claimcode==3)  {
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				claimcode:this.state.claimcode,				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (		
			<div>      	
				<CommonHeader titles="Sales Tax Rate"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Provincial Sales Tax Rates</h2></label>							
						<select name="claimcode" id="claimcode" style={{width:'150px'}} value={this.state.claimcode} onChange={this.handleManageClaimChange}>
							<option value="">--Manage Sales Tax--</option>
							<option value="1">Add New Sales Tax Rates</option>															
							<option value="2">Update Sales Tax Rates</option>												
							<option value="3">Remove Sales Tax Rates</option>		
						</select><br/><br/>	
					</div>											
					{(()=>{if(this.state.claimcode != "") {return(<div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Province</h2>
								<h2 style={{width:'150px'}}>GST</h2>
								<h2 style={{width:'150px'}}>PST</h2>
								<h2 style={{width:'150px'}}>HST</h2>								
							</div>				
						</div><br/>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Federal</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total00" value={this.state.total00} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total01" value={this.state.total01} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total02" value={this.state.total02} onChange={this.handleChange}/></h2>								
							</div>				
						</div><br/>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Alberta</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total10" value={this.state.total10} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total11" value={this.state.total11} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total12" value={this.state.total12} onChange={this.handleChange}/></h2>								
							</div>				
						</div><br/>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>British Columbia</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total20" value={this.state.total20} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total21" value={this.state.total21} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total22" value={this.state.total22} onChange={this.handleChange}/></h2>								
							</div>				
						</div>	<br/>			
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Manitoba</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total30" value={this.state.total30} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total31" value={this.state.total31} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total32" value={this.state.total32} onChange={this.handleChange}/></h2>								
							</div>				
						</div>	<br/>			
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>New Brunswick</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total40" value={this.state.total40} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total41" value={this.state.total41} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total42" value={this.state.total42} onChange={this.handleChange}/></h2>
							</div>				
						</div>	<br/>			
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Newfoundland and Labrador</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total50" value={this.state.total50} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total51" value={this.state.total51} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total52" value={this.state.total52} onChange={this.handleChange}/></h2>								
							</div>				
						</div>	<br/>			
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Nova Scotia</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total60" value={this.state.total60} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total61" value={this.state.total61} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total62" value={this.state.total62} onChange={this.handleChange}/></h2>								
							</div>				
						</div>	<br/>			
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Northwest Territories</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total70" value={this.state.total70} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total71" value={this.state.total71} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total72" value={this.state.total72} onChange={this.handleChange}/></h2>								
							</div>				
						</div>	<br/>			
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Nunavut</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total80" value={this.state.total80} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total81" value={this.state.total81} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total82" value={this.state.total82} onChange={this.handleChange}/></h2>								
							</div>				
						</div>	<br/>			
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Ontario</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total90" value={this.state.total90} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total91" value={this.state.total91} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total92" value={this.state.total92} onChange={this.handleChange}/></h2>								
							</div>				
						</div>	<br/>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Prince Edward Island</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total100" value={this.state.total100} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total101" value={this.state.total101} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total102" value={this.state.total102} onChange={this.handleChange}/></h2>								
							</div>				
						</div><br/>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Quebec</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total110" value={this.state.total110} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total111" value={this.state.total111} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total112" value={this.state.total112} onChange={this.handleChange}/></h2>								
							</div>				
						</div>	<br/>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Saskatchewan</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total120" value={this.state.total120} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total121" value={this.state.total121} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total122" value={this.state.total122} onChange={this.handleChange}/></h2>								
							</div>				
						</div>	<br/>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'200px'}}>Yukon</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total130" value={this.state.total130} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total131" value={this.state.total131} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total132" value={this.state.total132} onChange={this.handleChange}/></h2>								
							</div>				
						</div>	<br/><br/>									
						<h1>{this.state.Resp}</h1><br/><br/>
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupPayRollYear;