import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')


class setupManages extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:16,managementitem:'', Resp:'',employeeUpdate:'', manageitems:[],pensionrate:0,
		pensionplan:0,pensionrateer:0,uniondue:0,unionrate:0,vacationpay:4,vacationre:0,holidaypay:0,
		user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);
		this.handleManageChange=this.handleManageChange.bind(this)
		this.handleUpdateChange=this.handleUpdateChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {				
		axios.post(process.env.REACT_APP_API_ENV+'api/manageitems.php')
			.then(result => {							
				this.setState({manageitems:result.data})								
		})	
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
	}	
	handleManageChange = event => {
		this.setState({pensionplan:0})
		this.setState({pensionrate:0})
		this.setState({pensionrateer:0})		
		this.setState({uniondue:0})
		this.setState({unionrate:0})
		this.setState({vacationpay:0})	
		this.setState({holidaypay:0})
		const {name, value} = event.target				
		this.setState({[name]: value})		
		const userObject={
				user:this.state.user,
				names:this.state.names,
				status: event.target.value
			}
		if(event.target.value == 2||event.target.value==3) {
			axios.post(process.env.REACT_APP_API_ENV+'api/paydeductionupdate.php',userObject)
				.then(result => {							
					this.setState({pensionrate:result.data.filter(sub=>sub[0]=='RRSP').map(sub=>sub[2]).[0]})	
					this.setState({pensionrateer:result.data.filter(sub=>sub[0]=='RRSPR').map(sub=>sub[2]).[0]})	
					if(result.data.filter(sub=>sub[0]=='RRSP').map(sub=>sub[2]) >0) {
						this.setState({pensionplan:1})
					}
					this.setState({unionrate:result.data.filter(sub=>sub[0]=='UNION').map(sub=>sub[2]).[0]})				
					if(result.data.filter(sub=>sub[0]=='UNION').map(sub=>sub[2]) >0) {
						this.setState({uniondue:1})
					}
					this.setState({vacationpay:result.data.filter(sub=>sub[0]=='VCPAY').map(sub=>sub[2]).[0]})				
					this.setState({holidaypay:result.data.filter(sub=>sub[0]=='HOLIDAYPAY').map(sub=>sub[2]).[0]})
			})	
		}
		this.setState({Resp:""});			
	}
	handleUpdateChange = event=>{		
		const {name, value} = event.target				
		this.setState({[name]: value})			
		//this.setState({ProgName:this.state.manageitems.filter(sub=>sub[0]==event.target.value).map(sub=>sub[1])})
		this.setState({Resp:""});
	}
	handleSubmit = event => {		
		event.preventDefault()		
		const {managementitem,ProgName,employeeUpdate,pensionplan,pensionrate,pensionrateer,uniondue, unionrate,vacationpay,vacationre,holidaypay} = this.state			
		if (this.state.managementitem == "") {
			alert('Please select management type: Add, Update or Delete')
			return false
		}
		if(this.state.managementitem==1)  {
			if(this.state.pensionplan == 1) {
				if(!pensionrate) {
					alert('Please enter employee pension contribution rate in your company')
					return false
				}
				if(!pensionrateer) {
					alert('Please enter employer pension contribution rate in your company')
					return false
				}
			}
			if(this.state.uniondue == 1) {
				if(!unionrate) {
					alert('Please enter union deduction rate in your company')
					return false
				}
			}					
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				pensionrate:this.state.pensionrate,
				unionrate:this.state.unionrate,
				vacationpay:this.state.vacationpay,
				pensionplan:this.state.pensionplan,
				pensionrateer:this.state.pensionrateer,
				uniondue:this.state.uniondue,
				holidaypay:this.state.holidaypay
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.managementitem==2)  {
			if(this.state.pensionplan == 1) {
				if(!pensionrate) {
					alert('Please enter pension deduction rate in your company')
					return false
				}
			}
			if(this.state.uniondue == 1) {
				if(!unionrate) {
					alert('Please enter union deduction rate in your company')
					return false
				}
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				pensionrate:this.state.pensionrate,
				unionrate:this.state.unionrate,
				vacationpay:this.state.vacationpay,
				pensionplan:this.state.pensionplan,
				pensionrateer:this.state.pensionrateer,
				uniondue:this.state.uniondue,
				holidaypay:this.state.holidaypay
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.managementitem==3)  {			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				pensionrate:this.state.pensionrate,
				unionrate:this.state.unionrate,
				vacationpay:this.state.vacationpay,
				pensionplan:this.state.pensionplan,
				pensionrateer:this.state.pensionrateer,
				uniondue:this.state.uniondue,
				vacationre:this.state.vacationre,
				holidaypay:this.state.holidaypay
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (		
			<div>    
				<CommonHeader titles="Payment Items"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Company Payroll Deductions</h2></label>							
						<select name="managementitem" id="managementitem" style={{width:'150px'}} value={this.state.managementitem} onChange={this.handleManageChange}>
							<option value="">--Manage Payroll Deductions--</option>
							<option value="1">Set up Payroll Inforamtion</option>															
							<option value="2">Update Payroll Information</option>												
							<option value="3">Remove Payroll Items</option>		
						</select><br/><br/>	
					</div>	
					{(()=>{
						{/*set up/update/remove payroll deduction item*/}
						if(this.state.managementitem != "") {return(<div>
							<label><h2>{this.state.managementitem==3 ? "Remove Pension Plan ?":"Does your company have pension plan?"}</h2></label>
							<select id="pensionplan" name="pensionplan" value = {this.state.pensionplan} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="0">--No pension plan--</option>									
									<option value="1">--Yes, pension plan--</option>																		
							</select><br/>
							<small>{this.state.managementitem==2 && this.state.pensionplan==0 ? "No pension rate updated required":""}</small>
							<small>{this.state.managementitem==3 && this.state.pensionplan==1 ? "pension rate will be deleted from system":""}</small>
							<br/><br/>
							{(()=>{
								if(this.state.pensionplan == "1") {return(<div>
									<label><h2>Employee Contribution Rate</h2></label>
									<input type="text" name="pensionrate" id="pensionrate" value = {this.state.pensionrate} onChange={this.handleChange} />
									<br/><br/>
									<label><h2>Employer Contribution Rate</h2></label>
									<input type="text" name="pensionrateer" id="pensionrateer" value = {this.state.pensionrateer} onChange={this.handleChange} />
									<br/><br/>
								</div>)}
							})()}
							<label><h2>{this.state.managementitem==3 ? "Remove Union Rate ?":"Does your company have Union?"}</h2></label>
							<select id="uniondue" name="uniondue" value = {this.state.uniondue} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="0">--No Union--</option>									
									<option value="1">--Yes, Union--</option>																		
							</select><br/>
							<small>{this.state.managementitem==2 && this.state.uniondue==0 ? "No union rate updated required":""}</small>
							<small>{this.state.managementitem==3 && this.state.uniondue==1 ? "union rate will be deleted from system":""}</small>
							<br/><br/>
							
							{(()=>{
								if(this.state.uniondue == "1") {return(<div>
									<label><h2>Payroll Deduction Rate</h2></label>
									<input type="text" name="unionrate" id="unionrate" value = {this.state.unionrate} onChange={this.handleChange} />
									<br/><br/>
								</div>)}
							})()}	
							
							{(()=>{
								if(this.state.managementitem == "3") {return(<div>
									<label><h2>Remove Vacation Pay ?</h2></label>
									<select id="vacationre" name="vacationre" value = {this.state.vacationre} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="0">--No--</option>									
										<option value="1">--Yes--</option>
									</select><br/><br/>
								</div>)}
							})()}
							
							<label><h2>Vacation Pay Rate</h2></label>
							<input type="text" name="vacationpay" id="vacationpay" value = {this.state.vacationpay} onChange={this.handleChange} /><br/>
							<small>{this.state.managementitem==1||this.state.managementitem==2 ? "Basically, vacation pay is 4% of gross wages, after five years employment at least 6%. You can update employee vacation pay rate in Manage Employee section":""}</small>														
							<br/><br/>
							<select id="holidaypay" name="holidaypay" value = {this.state.holidaypay} style={{width:'160px'}} onChange={this.handleChange}>
									<option value="0">--No Holiday Pay--</option>									
									<option value="1">--Yes, Holiday Pay--</option>																		
							</select><br/><br/>
							<h1>{this.state.Resp}</h1><br/><br/>						
						</div>)}})()}					
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupManages;