import CommonHeader from '../clients/commons/Head'
import React from 'react';
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import {PDFDocument} from 'pdf-lib';
import { saveAs } from 'file-saver';
const thisyear=dayjs().format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
}

function writeVariables(pages, xvalue, yvalue, variables) {	
	pages.drawText(variables, {
			  x: xvalue,
			  y: yvalue,
			  size:10
	})
}

function createPDF(EMP, YEARS, PROV, PDFVars){//this TD1 Form is based on 2023 version. All other years version may vary and need incorporate into this
	var existingPdfBytes = '';	
	fetch(process.env.REACT_APP_API_ENV+"api/getPdf.php?year="+YEARS+"&province="+PROV)
	.then(res => res.json()) 	
	.then(	
		async (result) => {		
			var obj = JSON.parse(result);
			var binaryData = base64ToArrayBuffer(obj.data);
			existingPdfBytes = binaryData;
			
			const pdfDoc = await PDFDocument.load(existingPdfBytes);
			// Embed the Helvetica font, this can be nothing
			//const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
			
			// Get the first page of the document
			const pages = pdfDoc.getPages()
			const firstPage = pages[0]
			const secondPage = pages[1]

			// Get the width and height of the first page
			const { width, height } = firstPage.getSize()
			//this is for 2023, if later years format is changed, we need add condition year
			if(PDFVars[1]=='FD') {
				writeVariables(firstPage, 90, height / 2 + 290, PDFVars[23])//last name
				writeVariables(firstPage, 230, height / 2 + 290, PDFVars[4]+' ('+PDFVars[3]+')')//first name and initials
				writeVariables(firstPage, 335, height / 2 + 290, PDFVars[5])//datebirth
				writeVariables(firstPage, 450, height / 2 + 290, PDFVars[2])//employee ID
				writeVariables(firstPage, 30, height / 2 + 260, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])//address
				writeVariables(firstPage, 252, height / 2 + 260, PDFVars[29])//post code
				writeVariables(firstPage, 265, height / 2 + 260, PDFVars[30])
				writeVariables(firstPage, 275, height / 2 + 260, PDFVars[31])
				writeVariables(firstPage, 290, height / 2 + 260, PDFVars[32])
				writeVariables(firstPage, 300, height / 2 + 260, PDFVars[33])
				writeVariables(firstPage, 312, height / 2 + 260, PDFVars[34])//post code
				writeVariables(firstPage, 335, height / 2 + 260, 'Canada')
				writeVariables(firstPage, 485, height / 2 + 260, PDFVars[24])//SIN
				writeVariables(firstPage, 498, height / 2 + 260, PDFVars[35])
				writeVariables(firstPage, 510, height / 2 + 260, PDFVars[36])
				writeVariables(firstPage, 522, height / 2 + 260, PDFVars[37])
				writeVariables(firstPage, 535, height / 2 + 260, PDFVars[38])
				writeVariables(firstPage, 547, height / 2 + 260, PDFVars[39])
				writeVariables(firstPage, 558, height / 2 + 260, PDFVars[40])
				writeVariables(firstPage, 570, height / 2 + 260, PDFVars[41])
				writeVariables(firstPage, 582, height / 2 + 260, PDFVars[42])//SIN
				if(YEARS != 2024) {
					writeVariables(firstPage, 522, height / 2 + 220, PDFVars[7])//basic amount
				}
				writeVariables(firstPage, 522, height / 2 + 175, PDFVars[8])
				writeVariables(firstPage, 522, height / 2 + 145, PDFVars[9])
				writeVariables(firstPage, 522, height / 2 + 115, PDFVars[10])
				writeVariables(firstPage, 522, height / 2 + 85, PDFVars[11])
				writeVariables(firstPage, 522, height / 2 + 60, PDFVars[12])
				writeVariables(firstPage, 522, height / 2- 5,  PDFVars[13])
				writeVariables(firstPage, 522, height / 2- 85, PDFVars[14])
				writeVariables(firstPage, 522, height / 2- 125, PDFVars[15])
				writeVariables(firstPage, 522, height / 2- 195, PDFVars[16])
				writeVariables(firstPage, 522, height / 2- 225, PDFVars[17])
				writeVariables(firstPage, 522, height / 2- 255, PDFVars[18])
				writeVariables(firstPage, 522, height / 2- 280, PDFVars[19])				
				writeVariables(secondPage, 30, height / 2 + 205, PDFVars[43])
				writeVariables(secondPage, 30, height / 2 + 160, PDFVars[44])
				writeVariables(secondPage, 30, height / 2 + 110, PDFVars[45])
				writeVariables(secondPage, 30, height / 2 + 100, PDFVars[46])				
				writeVariables(secondPage, 515, height / 2 - 27 , PDFVars[47])
				writeVariables(secondPage, 515, height / 2 - 100, PDFVars[48])				
				writeVariables(secondPage, 230, height / 2 - 315, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 315, PDFVars[0])				
			} else if(PDFVars[1]=='ON') {								
				writeVariables(firstPage, 90, height / 2 + 280, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 280, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 280, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 280, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 250, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 254, height / 2 + 250, PDFVars[29])
				writeVariables(firstPage, 267, height / 2 + 250, PDFVars[30])
				writeVariables(firstPage, 277, height / 2 + 250, PDFVars[31])
				writeVariables(firstPage, 292, height / 2 + 250, PDFVars[32])
				writeVariables(firstPage, 302, height / 2 + 250, PDFVars[33])
				writeVariables(firstPage, 314, height / 2 + 250, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 250, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 250, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 250, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 250, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 250, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 250, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 250, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 250, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 250, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 250, PDFVars[42])
				writeVariables(firstPage, 530, height / 2 + 195, PDFVars[9])
				writeVariables(firstPage, 530, height / 2 + 160, PDFVars[10])
				writeVariables(firstPage, 530, height / 2 + 135, PDFVars[12])				
				writeVariables(firstPage, 530, height / 2 + 95, PDFVars[13])	
				writeVariables(firstPage, 530, height / 2 + 15, PDFVars[14])	
				writeVariables(firstPage, 530, height / 2 - 75, PDFVars[15])	
				writeVariables(firstPage, 530, height / 2 - 110, PDFVars[17])	
				writeVariables(firstPage, 530, height / 2 - 135, PDFVars[18])	
				writeVariables(firstPage, 530, height / 2 - 165, PDFVars[19])	
				writeVariables(secondPage, 30, height / 2 + 228, PDFVars[43])
				writeVariables(secondPage, 30, height / 2 + 180, PDFVars[44])
				writeVariables(secondPage, 230, height / 2 - 95, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 95, PDFVars[0])				
			} else if(PDFVars[1]=='NS') {
				writeVariables(firstPage, 90, height / 2 + 290, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 290, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 290, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 290, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 260, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 254, height / 2 + 260, PDFVars[29])
				writeVariables(firstPage, 267, height / 2 + 260, PDFVars[30])
				writeVariables(firstPage, 278, height / 2 + 260, PDFVars[31])
				writeVariables(firstPage, 292, height / 2 + 260, PDFVars[32])
				writeVariables(firstPage, 304, height / 2 + 260, PDFVars[33])
				writeVariables(firstPage, 316, height / 2 + 260, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 260, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 260, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 260, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 260, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 260, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 260, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 260, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 260, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 260, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 260, PDFVars[42])
				if(YEARS != 2024) {
					writeVariables(firstPage, 522, height / 2 + 200, PDFVars[7])
				}
				writeVariables(firstPage, 522, height / 2 + 170, PDFVars[9])
				writeVariables(firstPage, 522, height / 2 + 140, PDFVars[20])				
				writeVariables(firstPage, 522, height / 2 + 110, PDFVars[10])
				writeVariables(firstPage, 522, height / 2 + 50, PDFVars[11])				
				writeVariables(firstPage, 522, height / 2 + 25, PDFVars[12])				
				writeVariables(firstPage, 522, height / 2- 15,  PDFVars[13])
				writeVariables(firstPage, 522, height / 2- 90,  PDFVars[21])
				writeVariables(firstPage, 522, height / 2- 195, PDFVars[14])
				writeVariables(firstPage, 522, height / 2- 260, PDFVars[22])
				writeVariables(secondPage, 522, height / 2 + 300, PDFVars[15])
				writeVariables(secondPage, 522, height / 2 + 210, PDFVars[16])
				writeVariables(secondPage, 522, height / 2 + 180, PDFVars[17])
				writeVariables(secondPage, 522, height / 2 + 150, PDFVars[18])
				writeVariables(secondPage, 522, height / 2 + 130, PDFVars[19])				
				writeVariables(secondPage, 30, height / 2 + 90, PDFVars[43])
				writeVariables(secondPage, 30, height / 2 + 50, PDFVars[44])								
				writeVariables(secondPage, 230, height / 2 - 340, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 340, PDFVars[0])			
			} else if(PDFVars[1]=='NL') {				
				writeVariables(firstPage, 90, height / 2 + 280, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 280, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 280, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 280, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 250, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 252, height / 2 + 250, PDFVars[29])
				writeVariables(firstPage, 265, height / 2 + 250, PDFVars[30])
				writeVariables(firstPage, 275, height / 2 + 250, PDFVars[31])
				writeVariables(firstPage, 290, height / 2 + 250, PDFVars[32])
				writeVariables(firstPage, 300, height / 2 + 250, PDFVars[33])
				writeVariables(firstPage, 312, height / 2 + 250, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 250, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 250, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 250, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 250, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 250, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 250, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 250, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 250, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 250, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 250, PDFVars[42])		
				writeVariables(firstPage, 530, height / 2 + 190, PDFVars[9])				
				writeVariables(firstPage, 530, height / 2 + 160, PDFVars[10])				
				writeVariables(firstPage, 530, height / 2 + 110, PDFVars[11])				
				writeVariables(firstPage, 530, height / 2 + 65, PDFVars[12])
				writeVariables(firstPage, 530, height / 2 + 15, PDFVars[13])
				writeVariables(firstPage, 530, height / 2 - 45, PDFVars[14])					
				writeVariables(firstPage, 530, height / 2 - 135, PDFVars[15])	
				writeVariables(firstPage, 530, height / 2 - 210, PDFVars[16])					
				writeVariables(firstPage, 530, height / 2 - 270, PDFVars[17])	
				writeVariables(firstPage, 530, height / 2 - 300, PDFVars[18])	
				writeVariables(firstPage, 530, height / 2 - 330, PDFVars[19])	
				writeVariables(secondPage, 30, height / 2 + 220, PDFVars[43])
				writeVariables(secondPage, 30, height / 2 + 166, PDFVars[44])
				writeVariables(secondPage, 230, height / 2 - 110, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 110, PDFVars[0])			
			} else if(PDFVars[1]=='AB') {//base year=2023
				writeVariables(firstPage, 90, height / 2 + 280, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 280, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 280, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 280, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 250, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 252, height / 2 + 250, PDFVars[29])
				writeVariables(firstPage, 265, height / 2 + 250, PDFVars[30])
				writeVariables(firstPage, 275, height / 2 + 250, PDFVars[31])
				writeVariables(firstPage, 290, height / 2 + 250, PDFVars[32])
				writeVariables(firstPage, 300, height / 2 + 250, PDFVars[33])
				writeVariables(firstPage, 312, height / 2 + 250, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 250, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 250, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 250, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 250, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 250, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 250, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 250, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 250, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 250, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 250, PDFVars[42])					
				writeVariables(firstPage, 530, height / 2 + 205, PDFVars[9])				
				writeVariables(firstPage, 530, height / 2 + 165, PDFVars[10])
				writeVariables(firstPage, 530, height / 2 + 140, PDFVars[12])
				writeVariables(firstPage, 530, height / 2 + 95, PDFVars[13])
				writeVariables(firstPage, 530, height / 2 + 30, PDFVars[14])
				writeVariables(firstPage, 530, height / 2 - 35, PDFVars[15])
				writeVariables(firstPage, 530, height / 2 - 110, PDFVars[16])
				writeVariables(firstPage, 530, height / 2 - 160, PDFVars[17])
				writeVariables(firstPage, 530, height / 2 - 180, PDFVars[18])	
				writeVariables(firstPage, 530, height / 2 - 200, PDFVars[19])	
				writeVariables(secondPage, 30, height / 2 + 227, PDFVars[43])
				writeVariables(secondPage, 30, height / 2 + 170, PDFVars[44])
				writeVariables(secondPage, 230, height / 2 - 100, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 100, PDFVars[0])	
			} else if(PDFVars[1]=='BC') {
				writeVariables(firstPage, 90, height / 2 + 275, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 275, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 275, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 275, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 245, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 252, height / 2 + 245, PDFVars[29])
				writeVariables(firstPage, 265, height / 2 + 245, PDFVars[30])
				writeVariables(firstPage, 275, height / 2 + 245, PDFVars[31])
				writeVariables(firstPage, 290, height / 2 + 245, PDFVars[32])
				writeVariables(firstPage, 300, height / 2 + 245, PDFVars[33])
				writeVariables(firstPage, 312, height / 2 + 245, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 245, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 245, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 245, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 245, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 245, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 245, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 245, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 245, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 245, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 245, PDFVars[42])					
				writeVariables(firstPage, 530, height / 2 + 185, PDFVars[9])					
				writeVariables(firstPage, 530, height / 2 + 155, PDFVars[10])							
				writeVariables(firstPage, 530, height / 2 + 135, PDFVars[11])	
				writeVariables(firstPage, 530, height / 2 + 105, PDFVars[12])
				writeVariables(firstPage, 530, height / 2 + 50, PDFVars[13])				
				writeVariables(firstPage, 530, height / 2 - 10, PDFVars[14])					
				writeVariables(firstPage, 530, height / 2 - 110, PDFVars[15])	
				writeVariables(firstPage, 530, height / 2 - 135, PDFVars[17])	
				writeVariables(firstPage, 530, height / 2 - 165, PDFVars[18])	
				writeVariables(firstPage, 530, height / 2 - 190, PDFVars[19])	
				writeVariables(secondPage, 32, height / 2 + 235, PDFVars[43])
				writeVariables(secondPage, 32, height / 2 + 186, PDFVars[44])
				writeVariables(secondPage, 230, height / 2 - 102, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 102, PDFVars[0])			
			} else if(PDFVars[1]=='MB') {
				writeVariables(firstPage, 90, height / 2 + 285, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 285, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 285, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 285, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 255, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 252, height / 2 + 255, PDFVars[29])
				writeVariables(firstPage, 265, height / 2 + 255, PDFVars[30])
				writeVariables(firstPage, 275, height / 2 + 255, PDFVars[31])
				writeVariables(firstPage, 290, height / 2 + 255, PDFVars[32])
				writeVariables(firstPage, 300, height / 2 + 255, PDFVars[33])
				writeVariables(firstPage, 312, height / 2 + 255, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 255, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 255, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 255, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 255, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 255, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 255, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 255, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 255, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 255, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 255, PDFVars[42])					
				writeVariables(firstPage, 530, height / 2 + 200, PDFVars[9])								
				writeVariables(firstPage, 530, height / 2 + 170, PDFVars[10])				
				writeVariables(firstPage, 530, height / 2 + 130, PDFVars[11])								
				writeVariables(firstPage, 530, height / 2 + 80, PDFVars[12])				
				writeVariables(firstPage, 530, height / 2 + 40, PDFVars[13])				
				writeVariables(firstPage, 530, height / 2 - 10, PDFVars[14])
				writeVariables(firstPage, 530, height / 2 - 100, PDFVars[15])	
				writeVariables(firstPage, 530, height / 2 - 180, PDFVars[16])
				writeVariables(firstPage, 530, height / 2 - 215, PDFVars[17])	
				writeVariables(firstPage, 530, height / 2 - 240, PDFVars[18])					
				writeVariables(firstPage, 530, height / 2 - 265, PDFVars[8])	
				writeVariables(firstPage, 530, height / 2 - 285, PDFVars[19])	
				writeVariables(secondPage, 33, height / 2 + 223, PDFVars[43])
				writeVariables(secondPage, 33, height / 2 + 175, PDFVars[44])
				writeVariables(secondPage, 230, height / 2 - 90, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 90, PDFVars[0])				
			} else if(PDFVars[1]=='SK') {			
				writeVariables(firstPage, 90, height / 2 + 275, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 275, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 275, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 275, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 245, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 252, height / 2 + 245, PDFVars[29])
				writeVariables(firstPage, 265, height / 2 + 245, PDFVars[30])
				writeVariables(firstPage, 275, height / 2 + 245, PDFVars[31])
				writeVariables(firstPage, 290, height / 2 + 245, PDFVars[32])
				writeVariables(firstPage, 300, height / 2 + 245, PDFVars[33])
				writeVariables(firstPage, 312, height / 2 + 245, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 245, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 245, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 245, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 245, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 245, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 245, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 245, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 245, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 245, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 245, PDFVars[42])				
				writeVariables(firstPage, 530, height / 2 + 190, PDFVars[9])								
				writeVariables(firstPage, 530, height / 2 + 165, PDFVars[20])					
				writeVariables(firstPage, 530, height / 2 + 140, PDFVars[10])					
				writeVariables(firstPage, 530, height / 2 + 115, PDFVars[12])	
				writeVariables(firstPage, 530, height / 2 + 65, PDFVars[13])								
				writeVariables(firstPage, 530, height / 2 - 15, PDFVars[14])				
				writeVariables(firstPage, 530, height / 2 - 70, PDFVars[11])	
				writeVariables(firstPage, 530, height / 2 - 145, PDFVars[15])					
				writeVariables(firstPage, 530, height / 2 - 200, PDFVars[16])				
				writeVariables(firstPage, 530, height / 2 - 260, PDFVars[17])	
				writeVariables(firstPage, 530, height / 2 - 280, PDFVars[18])									
				writeVariables(firstPage, 530, height / 2 - 300, PDFVars[19])	
				writeVariables(secondPage, 31, height / 2 + 225, PDFVars[43])
				writeVariables(secondPage, 31, height / 2 + 175, PDFVars[44])
				writeVariables(secondPage, 230, height / 2 - 110, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 110, PDFVars[0])			
			} else if(PDFVars[1]=='YT') {//base year=2023
				writeVariables(firstPage, 90, height / 2 + 269, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 269, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 269, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 269, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 239, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 252, height / 2 + 239, PDFVars[29])
				writeVariables(firstPage, 265, height / 2 + 239, PDFVars[30])
				writeVariables(firstPage, 275, height / 2 + 239, PDFVars[31])
				writeVariables(firstPage, 290, height / 2 + 239, PDFVars[32])
				writeVariables(firstPage, 300, height / 2 + 239, PDFVars[33])
				writeVariables(firstPage, 312, height / 2 + 239, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 239, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 239, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 239, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 239, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 239, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 239, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 239, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 239, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 239, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 239, PDFVars[42])				
				//above for personal information
				writeVariables(firstPage, 530, height / 2 + 195, PDFVars[7])//item 1						
				writeVariables(firstPage, 530, height / 2 + 155, PDFVars[8])//item 2		
				writeVariables(firstPage, 530, height / 2 + 130, PDFVars[9])//Item 3										
				writeVariables(firstPage, 530, height / 2 + 105, PDFVars[10])//Item 4
				writeVariables(firstPage, 530, height / 2 + 65, PDFVars[11])//Item 5
				writeVariables(firstPage, 530, height / 2 + 45, PDFVars[12])//Item 6
				writeVariables(firstPage, 530, height / 2 + 5, PDFVars[13])//Item 7
				writeVariables(firstPage, 530, height / 2 - 85, PDFVars[14])//Item 8
				writeVariables(firstPage, 530, height / 2 - 150, PDFVars[15])//Item 9				
				writeVariables(firstPage, 530, height / 2 - 195, PDFVars[16])//Item 10		
				writeVariables(firstPage, 530, height / 2 - 245, PDFVars[17])//Item 11
				writeVariables(firstPage, 530, height / 2 - 275, PDFVars[18])//Item 12						
				writeVariables(firstPage, 530, height / 2 - 295, PDFVars[19])//Item 13
				writeVariables(secondPage, 30, height / 2 + 215, PDFVars[43])//second page
				writeVariables(secondPage, 30, height / 2 + 165, PDFVars[44])//second page
				writeVariables(secondPage, 230, height / 2 - 110, PDFVars[4]+' '+PDFVars[23])//signature
				writeVariables(secondPage, 505, height / 2 - 110, PDFVars[0])//signature date
			} else if(PDFVars[1]=='NB') {
				writeVariables(firstPage, 90, height / 2 + 275, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 275, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 275, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 275, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 245, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 252, height / 2 + 245, PDFVars[29])
				writeVariables(firstPage, 265, height / 2 + 245, PDFVars[30])
				writeVariables(firstPage, 275, height / 2 + 245, PDFVars[31])
				writeVariables(firstPage, 290, height / 2 + 245, PDFVars[32])
				writeVariables(firstPage, 300, height / 2 + 245, PDFVars[33])
				writeVariables(firstPage, 312, height / 2 + 245, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 245, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 245, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 245, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 245, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 245, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 245, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 245, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 245, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 245, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 245, PDFVars[42])
				writeVariables(firstPage, 530, height / 2 + 195, PDFVars[9])				
				writeVariables(firstPage, 530, height / 2 + 165, PDFVars[10])				
				writeVariables(firstPage, 530, height / 2 + 140, PDFVars[11])				
				writeVariables(firstPage, 530, height / 2 + 115, PDFVars[12])
				writeVariables(firstPage, 530, height / 2 + 85, PDFVars[13])
				writeVariables(firstPage, 530, height / 2 + 5, PDFVars[14])					
				writeVariables(firstPage, 530, height / 2 - 75, PDFVars[15])	
				writeVariables(firstPage, 530, height / 2 - 180, PDFVars[16])					
				writeVariables(firstPage, 530, height / 2 - 210, PDFVars[17])	
				writeVariables(firstPage, 530, height / 2 - 235, PDFVars[18])	
				writeVariables(firstPage, 530, height / 2 - 255, PDFVars[19])	
				writeVariables(secondPage, 30, height / 2 + 225, PDFVars[43])
				writeVariables(secondPage, 30, height / 2 + 175, PDFVars[44])
				writeVariables(secondPage, 230, height / 2 - 100, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 100, PDFVars[0])
			} else if(PDFVars[1]=='NT') {
				writeVariables(firstPage, 90, height / 2 + 270, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 270, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 270, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 270, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 235, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 252, height / 2 + 235, PDFVars[29])
				writeVariables(firstPage, 265, height / 2 + 235, PDFVars[30])
				writeVariables(firstPage, 275, height / 2 + 235, PDFVars[31])
				writeVariables(firstPage, 290, height / 2 + 235, PDFVars[32])
				writeVariables(firstPage, 300, height / 2 + 235, PDFVars[33])
				writeVariables(firstPage, 312, height / 2 + 235, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 235, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 235, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 235, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 235, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 235, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 235, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 235, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 235, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 235, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 235, PDFVars[42])	
				writeVariables(firstPage, 530, height / 2 + 175, PDFVars[9])				
				writeVariables(firstPage, 530, height / 2 + 155, PDFVars[10])				
				writeVariables(firstPage, 530, height / 2 + 125, PDFVars[11])				
				writeVariables(firstPage, 530, height / 2 + 60, PDFVars[12])
				writeVariables(firstPage, 530, height / 2 + 10, PDFVars[13])
				writeVariables(firstPage, 530, height / 2 - 50, PDFVars[14])					
				writeVariables(firstPage, 530, height / 2 - 150, PDFVars[15])	
				writeVariables(firstPage, 530, height / 2 - 240, PDFVars[16])					
				writeVariables(firstPage, 530, height / 2 - 270, PDFVars[17])	
				writeVariables(firstPage, 530, height / 2 - 300, PDFVars[18])	
				writeVariables(firstPage, 530, height / 2 - 330, PDFVars[19])	
				writeVariables(secondPage, 30, height / 2 + 221, PDFVars[43])
				writeVariables(secondPage, 30, height / 2 + 173, PDFVars[44])
				writeVariables(secondPage, 230, height / 2 - 100, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 100, PDFVars[0])			
			} else if(PDFVars[1]=='NU') {
				writeVariables(firstPage, 90, height / 2 + 290, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 290, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 290, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 290, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 265, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 252, height / 2 + 265, PDFVars[29])
				writeVariables(firstPage, 265, height / 2 + 265, PDFVars[30])
				writeVariables(firstPage, 275, height / 2 + 265, PDFVars[31])
				writeVariables(firstPage, 290, height / 2 + 265, PDFVars[32])
				writeVariables(firstPage, 300, height / 2 + 265, PDFVars[33])
				writeVariables(firstPage, 312, height / 2 + 265, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 265, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 265, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 265, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 265, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 265, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 265, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 265, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 265, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 265, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 265, PDFVars[42])				
				writeVariables(firstPage, 530, height / 2 + 210, PDFVars[9])				
				writeVariables(firstPage, 530, height / 2 + 180, PDFVars[10])				
				writeVariables(firstPage, 530, height / 2 + 120, PDFVars[11])				
				writeVariables(firstPage, 530, height / 2 + 85, PDFVars[12])
				writeVariables(firstPage, 530, height / 2 + 30, PDFVars[13])
				writeVariables(firstPage, 530, height / 2 - 20, PDFVars[14])					
				writeVariables(firstPage, 530, height / 2 - 105, PDFVars[15])	
				writeVariables(firstPage, 530, height / 2 - 190, PDFVars[16])					
				writeVariables(firstPage, 530, height / 2 - 220, PDFVars[17])	
				writeVariables(firstPage, 530, height / 2 - 250, PDFVars[18])	
				writeVariables(firstPage, 530, height / 2 - 280, PDFVars[19])	
				writeVariables(secondPage, 33, height / 2 + 222, PDFVars[43])
				writeVariables(secondPage, 33, height / 2 + 175, PDFVars[44])
				writeVariables(secondPage, 230, height / 2 - 90, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 90, PDFVars[0])
			} else if(PDFVars[1]=='PE') {			
				writeVariables(firstPage, 90, height / 2 + 285, PDFVars[23])
				writeVariables(firstPage, 230, height / 2 + 285, PDFVars[4]+' ('+PDFVars[3]+')')
				writeVariables(firstPage, 335, height / 2 + 285, PDFVars[5])
				writeVariables(firstPage, 450, height / 2 + 285, PDFVars[2])
				writeVariables(firstPage, 30, height / 2 + 255, PDFVars[26]+', '+PDFVars[27]+', '+PDFVars[28])
				writeVariables(firstPage, 253, height / 2 + 255, PDFVars[29])
				writeVariables(firstPage, 266, height / 2 + 255, PDFVars[30])
				writeVariables(firstPage, 276, height / 2 + 255, PDFVars[31])
				writeVariables(firstPage, 291, height / 2 + 255, PDFVars[32])
				writeVariables(firstPage, 301, height / 2 + 255, PDFVars[33])
				writeVariables(firstPage, 313, height / 2 + 255, PDFVars[34])
				writeVariables(firstPage, 335, height / 2 + 255, 'Canada')
				writeVariables(firstPage, 487, height / 2 + 255, PDFVars[24])
				writeVariables(firstPage, 500, height / 2 + 255, PDFVars[35])
				writeVariables(firstPage, 512, height / 2 + 255, PDFVars[36])
				writeVariables(firstPage, 524, height / 2 + 255, PDFVars[37])
				writeVariables(firstPage, 537, height / 2 + 255, PDFVars[38])
				writeVariables(firstPage, 549, height / 2 + 255, PDFVars[39])
				writeVariables(firstPage, 560, height / 2 + 255, PDFVars[40])
				writeVariables(firstPage, 572, height / 2 + 255, PDFVars[41])
				writeVariables(firstPage, 584, height / 2 + 255, PDFVars[42])				
				writeVariables(firstPage, 530, height / 2 + 200, PDFVars[9])				
				writeVariables(firstPage, 530, height / 2 + 165, PDFVars[10])				
				writeVariables(firstPage, 530, height / 2 + 130, PDFVars[11])				
				writeVariables(firstPage, 530, height / 2 + 80, PDFVars[12])
				writeVariables(firstPage, 530, height / 2 + 15, PDFVars[13])
				writeVariables(firstPage, 530, height / 2 - 20, PDFVars[14])					
				writeVariables(firstPage, 530, height / 2 - 130, PDFVars[15])	
				writeVariables(firstPage, 530, height / 2 - 205, PDFVars[16])					
				writeVariables(firstPage, 530, height / 2 - 250, PDFVars[17])	
				writeVariables(firstPage, 530, height / 2 - 290, PDFVars[18])	
				writeVariables(firstPage, 530, height / 2 - 315, PDFVars[19])	
				writeVariables(secondPage, 30, height / 2 + 215, PDFVars[43])
				writeVariables(secondPage, 30, height / 2 + 168, PDFVars[44])
				writeVariables(secondPage, 230, height / 2 - 100, PDFVars[4]+' '+PDFVars[23])
				writeVariables(secondPage, 505, height / 2 - 100, PDFVars[0])
			}
			
			const pdfBytes = await pdfDoc.save()
			var blob = new Blob([pdfBytes])
			saveAs(blob, YEARS+'_TD1_Form_'+PROV+'.pdf')
		},
		// Note: it's important to handle errors here
		// instead of a catch() block so that we don't swallow
		// exceptions from actual bugs in components.
		(error) => {
			alert('Error happens');
		}
	)
}

class PDFApp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {Pro:[],province:'',formtype:'',Employees:[],pemployee:'',selectyear:'',viewcomments:'', criterias:'', 
						PDFVars:[],user:localStorage.getItem('tokens'), names:localStorage.getItem('names'),TD1Year:''}	
		this.handleChange=this.handleChange.bind(this);
		this.handleStateChange=this.handleStateChange.bind(this);
		this.handlePEIDChange=this.handlePEIDChange.bind(this);
	};							
	componentDidMount() {
		const para={user:this.state.user,names:this.state.names}
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {						
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});			
		{/*axios.post(process.env.REACT_APP_API_ENV+'api/currentProfile.php', para)
			.then(result => {	
				this.setState({province:result.data[6]})			
	})	*/}
		axios.post(process.env.REACT_APP_API_ENV+'api/employeeupdate.php', para)
			.then(result => {									
				this.setState({Employees:result.data})								
		})
	}	
	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({viewcomments:''})
	}
	handlePEIDChange = event =>{	
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({viewcomments:''})
		this.setState({province:this.state.Employees.filter(item=>item[0]==event.target.value).map(item=>item[16])})
	}
	handleStateChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({viewcomments:''})
		const userObject={
				EID:this.state.pemployee,
				YEARS:this.state.selectyear,
				state: event.target.value
			}
		axios.post(process.env.REACT_APP_API_ENV+'api/getPdfVariables.php',userObject)
			.then(result => {	
				if(result.data[1]=='No') {
					this.setState({viewcomments:'Sorry, your TD1 Form is not set up, system will take care of payroll tax deduction'})
					this.setState({criterias:''})															
				} else {
					this.setState({PDFVars:result.data})	
					this.setState({viewcomments:''})					
					this.setState({criterias:'1'})
					this.setState({TD1Year:result.data[49]})
					if(this.state.selectyear != result.data[49] && result.data[49] !=9999 ) {
						this.setState({viewcomments:'You do not have '+this.state.selectyear+' TD1 Form data'})					
					}
				}
			})			
	}
	render () {
		return (
			<div className="App">
				<CommonHeader titles="TD1 Form"/>
				<label><h2>Select Employee</h2></label>							
				<select name="pemployee" style={{width:'150px'}} value={this.state.pemployee} onChange={this.handlePEIDChange}>
					<option value="">--Select Employee--</option>									
					{this.state.Employees.map((sub,index) => (<option value={sub[0]} key={index}>{sub[7] +' '+sub[8]}</option>))}			
				</select><br/><br/>
				{(()=>{if(this.state.pemployee !== "") {return (<div>
					<label><h2>Select Year</h2></label>							
					<select name="selectyear" style={{width:'150px'}} value={this.state.selectyear} onChange={this.handleChange}>
						<option value="">--Select Year--</option>									
						{dayjs().diff(dayjs(thisyear).startOf('year'),'day') > 300 ? <option value={nextyear}>{nextyear}</option>:''}
						<option value={thisyear}>{thisyear}</option>
						{thisyear-1 > 2020 ? <option value={thisyear-1}>{thisyear-1}</option>:''}
						{thisyear-2 > 2020 ? <option value={thisyear-2}>{thisyear-2}</option>:''}
						{thisyear-3 > 2020 ? <option value={thisyear-3}>{thisyear-3}</option>:''}
						{thisyear-4 > 2020 ? <option value={thisyear-4}>{thisyear-4}</option>:''}
						{thisyear-5 > 2020 ? <option value={thisyear-5}>{thisyear-5}</option>:''}
						{thisyear-6 > 2020 ? <option value={thisyear-6}>{thisyear-6}</option>:''}
					</select><br/><br/>
				</div>)}})()}
				{(()=>{if(this.state.pemployee !== "" && this.state.selectyear !="") {return (<div>
					<label><h2>Select TD1 Form</h2></label>							
					<select name="formtype" id="formtype" style={{width:'150px'}} value={this.state.formtype} onChange={this.handleStateChange}>
						<option value="">--Select TD1 Form--</option>														
						<option value="FD">Federal</option>															
						{this.state.Pro.filter(item=>item[0] == this.state.province).map(item=>(<option value={item[0]}>{item[1]}</option>))}
					</select><br/><br/>						
					<h1>{this.state.viewcomments != "" ? this.state.viewcomments:""}</h1><br/><br/>
				</div>)}})()}
				{(()=>{if(this.state.pemployee !== "" && this.state.TD1Year !="" && this.state.formtype !="" && this.state.criterias !=="") {return (<div>
					<h1>Downloading {this.state.formtype=="FD" ? "Federal ":this.state.formtype} TD1 Form now...</h1>
					{createPDF(this.state.pemployee, this.state.TD1Year, this.state.formtype, this.state.PDFVars)}									
				</div>)}})()}
			</div>
		)
	}
}
export default PDFApp;



