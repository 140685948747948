import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupTaxAmounts extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:14,managementitem:'', Resp:'',Pro:[],employeeUpdate:'', basicbottom:'', 
		nsadditional:'',incomerange:'',yearclaimcode:'',taxcomments:'',copytd1form:'',TD1words:[],predata:'',
		user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);
		this.handleManageChange=this.handleManageChange.bind(this)
		this.handleUpdateChange=this.handleUpdateChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {				
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
	}	
	handleManageChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({employeeUpdate:""});	
		this.setState({yearclaimcode:''})
		this.setState({basicamount:''})
		this.setState({basicbottom:''})
		this.setState({nsadditional:''})
		this.setState({incomerange:''})
		this.setState({copytd1form:''})
		this.setState({predata:''})
	}
	handleUpdateChange = event=>{		
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({basicamount:''})
		this.setState({basicbottom:''})		
		this.setState({nsadditional:''})
		this.setState({incomerange:''})
		this.setState({copytd1form:''})
		this.setState({predata:''})
		if(this.state.managementitem =='2'||this.state.managementitem==3) {
			const userObject={state:event.target.value,
							  year:this.state.yearclaimcode}
			axios.post(process.env.REACT_APP_API_ENV+'api/updateTD1fromandwords.php',userObject)
				.then(result => {					
					this.setState({TD1words:result.data})							
				})		
		}
		this.setState({Resp:""});		
	}
	handleYearChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({employeeUpdate:''})
		this.setState({Resp:""});
		this.setState({copytd1form:''})
		this.setState({predata:''})
	}
	
	handleURChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({basicamount:this.state.TD1words.filter(item=>item[0]==event.target.value).map(item=>item[3])})
		this.setState({basicbottom:this.state.TD1words.filter(item=>item[0]==event.target.value).map(item=>item[4])})
		this.setState({nsadditional:this.state.TD1words.filter(item=>item[0]==event.target.value).map(item=>item[5])})
		this.setState({incomerange:this.state.TD1words.filter(item=>item[0]==event.target.value).map(item=>item[6])})
	}
	
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {managementitem,basicamount,yearclaimcode, employeeUpdate,basicbottom,nsadditional,incomerange,copytd1form} = this.state			
		if (this.state.managementitem == "") {
			alert('Please select action type for the tax amounts: Add, Update or Delete')
			return false
		}		
		if(this.state.managementitem==1)  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}
			if(this.state.predata == "") {
				if(!basicamount ) {
					alert('Please enter TD1 From Item Number')
					return false
				}	
				if(!nsadditional) {
					alert('Please enter TD1 From Item Name')
					return false
				}				
				if(!incomerange) {
					alert('Please enter TD1 From wording')
					return false
				}				
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				basicamount:this.state.basicamount,
				basicbottom:this.state.basicbottom,
				nsadditional:this.state.nsadditional,
				incomerange:this.state.incomerange,
				copytd1form:this.state.copytd1form,
				predata:this.state.predata
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {		
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} if(this.state.managementitem==2)  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}
			if(!basicamount ) {
				alert('Please enter TD1 From Item Number')
				return false
			}
			if(!nsadditional) {
				alert('Please enter TD1 From Item Name')
				return false
			}				
			if(!incomerange) {
				alert('Please enter TD1 From Wording')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				basicamount:this.state.basicamount,
				basicbottom:this.state.basicbottom,		
				nsadditional:this.state.nsadditional,
				incomerange:this.state.incomerange,
				copytd1form:this.state.copytd1form
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {		
					
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.managementitem==3)  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}	
			const userObject={		
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				copytd1form:this.state.copytd1form
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (		
			<div>   
				<CommonHeader titles="TD1Form"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>TD1 Form Wording</h2></label>							
						<select name="managementitem" id="managementitem" style={{width:'150px'}} value={this.state.managementitem} onChange={this.handleManageChange}>
							<option value="">--TD1 Form Wording--</option>
							<option value="1">Set up TD1Form Wording</option>															
							<option value="2">Update TD1Form Wording</option>												
							<option value="3">Remove TD1Form Wording</option>									
						</select><br/><br/>	
						{(()=>{if(this.state.managementitem != "") {return(<div>						
								<label><h2>Select Year</h2></label>							
								<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleYearChange}>
									<option value="">--Select Year--</option>									
									{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}									
								</select><br/><br/>
								{(()=>{if(this.state.yearclaimcode !="" ) {return(<div>
										<label><h2>Choose Federal or Province</h2></label>							
										<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleUpdateChange}>
											<option value="">--Federal or Province--</option>									
											{this.state.Pro.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
										</select><br/><br/>
										{(()=>{if(this.state.employeeUpdate !="" && (this.state.managementitem==2 ||this.state.managementitem==3)) {return(<div>
												<label><h2>Select Item</h2></label>							
												<select id="copytd1form" name="copytd1form" value = {this.state.copytd1form} style={{width:'160px'}} onChange={this.handleURChange}>													
													<option value="">--Item--</option>									
													{this.state.TD1words.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]+'~'+sub[2]+'~'+sub[3]+'~'+sub[4]}</option>))}																				
												</select><br/><br/>
										</div>)} else if(this.state.employeeUpdate !="" && this.state.managementitem==1) {return(<div>
												<label><h2>Copy Previous Year Data</h2></label>							
												<select id="predata" name="predata" value = {this.state.predata} style={{width:'160px'}} onChange={this.handleChange}>													
													<option value="">--No Copy--</option>									
													<option value="1">--Yes, Copy--</option>
												</select><br/><br/>
										</div>)}})()}
								</div>)}})()}
						</div>)}})()}												
					</div>	
					{/*set up/update/remove wording*/}
					{(()=>{if(this.state.managementitem != "" && this.state.employeeUpdate != ""  && this.state.predata != 1) {return(<div>							
							<h1>{this.state.taxcomments}</h1><br/><br/>
							<div className="forinput">
								<label className="labelname">TD1 Form Item #: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'300px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>					
							<div className="forinput">
								<label className="labelname">TD1 Form Bullet: </label>
								<input type="text" name ="basicbottom" id="basicbottom" style={{width:'300px'}} value={this.state.basicbottom} onChange={this.handleChange} />
							</div><br/>														
							<div className="forinput">
								<label className="labelname">TD1 Form Item Name: </label>
								<input type="text" name ="nsadditional" id="nsadditional" style={{width:'300px'}} value={this.state.nsadditional} onChange={this.handleChange} />
							</div><br/>							
							<div className="forinput">
								<label className="labelname">TD1 Form Wording: </label>
								<input type="text" name ="incomerange" id="incomerange" style={{width:'300px'}} value={this.state.incomerange} onChange={this.handleChange} />
							</div><br/>							
							<h1>{this.state.Resp}</h1><br/><br/>						
					</div>)}})()}	
					<h1>{this.state.predata==1?this.state.Resp:""}</h1><br/><br/>	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupTaxAmounts;