import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupTaxAmounts extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:12,managementitem:'', Resp:'',Pro:[],employeeUpdate:'', basicbottom:'', basichigh:'',
		nsadditional:'',incomerange:'',incomerangede:'',cppmax:'',cppexemption:'',cppee:'',cpper:'', eimax:'',eiee:'',noncredit:'',nomiator:'',
		eier:'',qpipmax:'',qpipee:'',qpiper:'',yearclaimcode:'',AddIndex:'', DBYear:'', 
		user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);
		this.handleManageChange=this.handleManageChange.bind(this)
		this.handleUpdateChange=this.handleUpdateChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {						
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});			
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
	}	
	handleManageChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({employeeUpdate:""});	
		this.setState({yearclaimcode:''})
		this.setState({basicamount:''})
		this.setState({basicbottom:''})
		this.setState({basichigh:''})
		this.setState({nsadditional:''})
		this.setState({incomerange:''})
		this.setState({incomerangede:''})
		this.setState({cppmax:''})
		this.setState({cppexemption:''})
		this.setState({cppee:''})
		this.setState({cpper:''})
		this.setState({eimax:''})
		this.setState({eiee:''})
		this.setState({eier:''})
		this.setState({qpipmax:''})
		this.setState({qpipee:''})
		this.setState({qpiper:''})
		this.setState({Resp:""});		
		this.setState({Resp:""});	
		this.setState({AddIndex:""});
	}
	handleUpdateChange = event=>{		
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({basicamount:''})
		this.setState({basicbottom:''})
		this.setState({basichigh:''})
		this.setState({nsadditional:''})
		this.setState({incomerange:''})
		this.setState({incomerangede:''})
		this.setState({cppmax:''})
		this.setState({cppexemption:''})
		this.setState({cppee:''})
		this.setState({cpper:''})
		this.setState({eimax:''})
		this.setState({eiee:''})
		this.setState({eier:''})
		this.setState({qpipmax:''})
		this.setState({qpipee:''})
		this.setState({qpiper:''})		
		this.setState({AddIndex:""})
		this.setState({DBYear:""})		
		this.setState({nomiator:""})	
		this.setState({noncredit:""})	
		const userObject={state:event.target.value,
						  year:this.state.yearclaimcode,
						  TaxAction:this.state.managementitem}
		axios.post(process.env.REACT_APP_API_ENV+'api/updatetaxamounts.php',userObject)
			.then(result => {
				if ((!result.data || result.data[0] === 'No') && this.state.managementitem === 1) {
					this.setState({AddIndex:'NO'})
				} else if ((!result.data || result.data[0] === 'No') && (this.state.managementitem === 2 || this.state.managementitem === 3)) {
					this.setState({indexcomms:'Sorry, no index for this year, please add index first'})
				} else if (result.data && result.data[0] !== 'No') {
					this.setState({AddIndex:'YES'})
					this.setState({DBYear:result.data[0]})
					this.setState({basicamount:result.data[2]})
					this.setState({basicbottom:result.data[3]})
					this.setState({basichigh:result.data[4]})
					this.setState({nsadditional:result.data[5]})
					this.setState({incomerange:result.data[6]})
					this.setState({incomerangede:result.data[7]})
					this.setState({cppmax:result.data[8]})
					this.setState({cppexemption:result.data[9]})
					this.setState({cppee:result.data[10]})
					this.setState({cpper:result.data[11]})
					this.setState({eimax:result.data[12]})
					this.setState({eiee:result.data[13]})
					this.setState({eier:result.data[14]})
					this.setState({qpipmax:result.data[15]})
					this.setState({qpipee:result.data[16]})
					this.setState({qpiper:result.data[17]})	
					this.setState({nomiator:result.data[18]})
					this.setState({noncredit:result.data[19]})	
				} 
			})		
		
		this.setState({Resp:""});		
	}
	handleYearChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({employeeUpdate:''})
		this.setState({Resp:""});
	}
	handleSubmit = event => {		
		event.preventDefault()		
		const {managementitem,basicamount,employeeUpdate,basicbottom,basichigh,nsadditional,incomerange,incomerangede,
				cppmax,cppexemption,cppee, cpper,eimax, eiee,eier,qpipmax,qpipee,qpiper,yearclaimcode,noncredit,nomiator} = this.state				
		if (this.state.managementitem == "") {
			alert('Please select action type for the tax amounts: Add, Update or Delete')
			return false
		}		
		if(this.state.managementitem == "1")  {									
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}
			if(!basicamount ) {
				alert('Please enter basic personal amount, if not available, enter 0')
				return false
			}						
			if(!basicbottom && (this.state.employeeUpdate==='FD'||this.state.employeeUpdate==='QC'|| this.state.employeeUpdate==='YT'||this.state.employeeUpdate==='NS')) {
				alert('Please enter lowest net income for basic personal amount, if not available, enter 0')
				return false
			}						
			if(!basichigh && (this.state.employeeUpdate==='FD'|| this.state.employeeUpdate==='QC'|| this.state.employeeUpdate==='YT'|| this.state.employeeUpdate==='NS')) {
				alert('Please enter highest net income for basic personal amount which cannot be reduced any more, if not available, enter 0')
				return false
			}	
			if(!nsadditional && this.state.employeeUpdate==='NS') {
				alert('Please enter Nova Scotia additional Personal Credit Amount')
				return false
			}				
			if(!incomerange && (this.state.employeeUpdate==='FD' || this.state.employeeUpdate==='YT') ) {
				alert('Please enter Yukon or Federal Claim Code Chart net income range')
				return false
			}
			if(!incomerangede && (this.state.employeeUpdate==='FD' || this.state.employeeUpdate==='YT') ) {
				alert('Please enter Yukon or Federal Claim Code Chart net income range demonitor')
				return false
			}
			if(!cppmax && this.state.employeeUpdate==='FD') {
				alert('Please enter maximum pensionable earnings')
				return false
			}
			if(!cppexemption && this.state.employeeUpdate==='FD') {
				alert('Please enter CPP exemption amount')
				return false
			}			
			if(!cppee && this.state.employeeUpdate==='FD') {
				alert('Please enter CPP employee rate(%)')
				return false
			}
			if(!cpper && this.state.employeeUpdate==='FD') {
				alert('Please enter CPP employer rate(%)')
				return false
			}
			if(!eimax && this.state.employeeUpdate==='FD') {
				alert('Please enter maximum insurable earnings')
				return false
			}
			if(!eiee && this.state.employeeUpdate==='FD') {
				alert('Please enter EI employee rate(%)')
				return false
			}
			if(!eier && this.state.employeeUpdate==='FD') {
				alert('Please enter EI employer rate(%)')
				return false
			}			
			if(!qpipmax && this.state.employeeUpdate==='QC') {
				alert('Please enter maximum Quebec Parental Insurable Earnings')
				return false
			}
			if(!qpipee && this.state.employeeUpdate==='QC') {
				alert('Please enter QPIP employee rate(%)')
				return false
			}
			if(!qpiper && this.state.employeeUpdate==='QC') {
				alert('Please enter QPIP employer rate(%)')
				return false
			}	
			if(!nomiator && (this.state.employeeUpdate==='FD'|| this.state.employeeUpdate==='QC'|| this.state.employeeUpdate==='YT'|| this.state.employeeUpdate==='NS')) {
				alert('Please enter the nominator value for basic personal amount calcualtion , if no, enter 0')
				return false
			}
			if(!noncredit) {
				alert('Please enter non refundable tax credit')
				return false
			} 		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				basicamount:this.state.basicamount,
				basicbottom:this.state.basicbottom,
				basichigh:this.state.basichigh,
				nsadditional:this.state.nsadditional,
				incomerange:this.state.incomerange,
				incomerangede:this.state.incomerangede,
				cppmax:this.state.cppmax,
				cppexemption:this.state.cppexemption,
				cppee:this.state.cppee,
				cpper:this.state.cpper,
				eimax:this.state.eimax,
				eiee:this.state.eiee,
				eier:this.state.eier,
				qpipmax:this.state.qpipmax,
				qpipee:this.state.qpipee,
				qpiper:this.state.qpiper,
				nomiator:this.state.nomiator,
				noncredit:this.state.noncredit
				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} if(this.state.managementitem == "2")  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}
			if(!basicamount ) {
				alert('Please enter basic personal amount, if not available, enter 0')
				return false
			}						
			if(!basicbottom && (this.state.employeeUpdate==='FD'|| this.state.employeeUpdate==='QC'|| this.state.employeeUpdate==='YT'|| this.state.employeeUpdate==='NS')) {
				alert('Please enter lowest net income for basic personal amount, if not available, enter 0')
				return false
			}						
			if(!basichigh && (this.state.employeeUpdate==='FD'|| this.state.employeeUpdate==='QC'|| this.state.employeeUpdate==='YT'|| this.state.employeeUpdate==='NS')) {
				alert('Please enter highest net income for basic personal amount which cannot be reduced any more, if not available, enter 0')
				return false
			}	
			if(!nsadditional && this.state.employeeUpdate==='NS') {
				alert('Please enter Nova Scotia additional Personal Credit Amount')
				return false
			}				
			if(!incomerange && (this.state.employeeUpdate==='FD' || this.state.employeeUpdate==='YT') ) {
				alert('Please enter Yukon or Federal Claim Code Chart net income range')
				return false
			}
			if(!incomerangede && (this.state.employeeUpdate==='FD' || this.state.employeeUpdate==='YT') ) {
				alert('Please enter Yukon or Federal Claim Code Chart net income range demonitor')
				return false
			}
			if(!cppmax && this.state.employeeUpdate==='FD') {
				alert('Please enter maximum pensionable earnings')
				return false
			}
			if(!cppexemption && this.state.employeeUpdate==='FD') {
				alert('Please enter CPP exemption amount')
				return false
			}			
			if(!cppee && this.state.employeeUpdate==='FD') {
				alert('Please enter CPP employee rate(%)')
				return false
			}
			if(!cpper && this.state.employeeUpdate==='FD') {
				alert('Please enter CPP employer rate(%)')
				return false
			}
			if(!eimax && this.state.employeeUpdate==='FD') {
				alert('Please enter maximum insurable earnings')
				return false
			}
			if(!eiee && this.state.employeeUpdate==='FD') {
				alert('Please enter EI employee rate(%)')
				return false
			}
			if(!eier && this.state.employeeUpdate==='FD') {
				alert('Please enter EI employer rate(%)')
				return false
			}			
			if(!qpipmax && this.state.employeeUpdate==='QC') {
				alert('Please enter maximum Quebec Parental Insurable Earnings')
				return false
			}
			if(!qpipee && this.state.employeeUpdate==='QC') {
				alert('Please enter QPIP employee rate(%)')
				return false
			}
			if(!qpiper && this.state.employeeUpdate==='QC') {
				alert('Please enter QPIP employer rate(%)')
				return false
			}	
			if(!nomiator && (this.state.employeeUpdate==='FD'|| this.state.employeeUpdate==='QC'|| this.state.employeeUpdate==='YT'|| this.state.employeeUpdate==='NS')) {
				alert('Please enter the nominator value for basic personal amount calcualtion , if no, enter 0')
				return false
			}
			if(!noncredit) {
				alert('Please enter non refundable tax credit')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				basicamount:this.state.basicamount,
				basicbottom:this.state.basicbottom,
				basichigh:this.state.basichigh,
				nsadditional:this.state.nsadditional,
				incomerange:this.state.incomerange,
				incomerangede:this.state.incomerangede,
				cppmax:this.state.cppmax,
				cppexemption:this.state.cppexemption,
				cppee:this.state.cppee,
				cpper:this.state.cpper,
				eimax:this.state.eimax,
				eiee:this.state.eiee,
				eier:this.state.eier,
				qpipmax:this.state.qpipmax,
				qpipee:this.state.qpipee,
				qpiper:this.state.qpiper,
				nomiator:this.state.nomiator,
				noncredit:this.state.noncredit
				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {					
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} if(this.state.managementitem == "3")  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}	
			const userObject={			
				adminitem:this.state.adminitem,		
				user:this.state.user,
				names:this.state.names,
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {		
		return (		
			<div>      		
				<CommonHeader titles="Tax"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Tax Amounts</h2></label>							
						<select name="managementitem" id="managementitem" style={{width:'150px'}} value={this.state.managementitem} onChange={this.handleManageChange}>
							<option value="">--Tax Amount Process--</option>
							<option value="1">Set up Tax Amounts</option>															
							<option value="2">Update Tax Amounts</option>												
							<option value="3">Remove Tax Amounts</option>		
						</select><br/><br/>	
						{(()=>{if(this.state.managementitem !== "") {return(<div>						
								<label><h2>Select Year</h2></label>							
								<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleYearChange}>
									<option value="">--Select Year--</option>									
									{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}									
								</select><br/><br/>
								{(()=>{						
									if(this.state.yearclaimcode !=="" ) {return(<div>
										<label><h2>Choose Federal or Province</h2></label>							
										<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleUpdateChange}>
											<option value="">--Federal or Province--</option>									
											{this.state.Pro.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
										</select><br/><br/>
									</div>)}
								})()}
							</div>)} 							
						})()}												
					</div>	
					{(()=>{{/*set up/update/remove tax amounts*/}
						if(this.state.managementitem !== "" && this.state.employeeUpdate !== "") {return(<div>																					
							{(()=>{if(this.state.managementitem === 1 && this.state.AddIndex === "YES") {return(<div>
								<h2>{this.state.yearclaimcode !== this.state.DBYear ? "Please update the below "+this.state.DBYear+" tax amount information for "+this.state.yearclaimcode:this.state.yearclaimcode+" Tax amount infomration has been added in the system, please check the accuracy"}</h2><br/><br/>
							</div>)}})()}						
							<div className="forinput">
								<label className="labelname">Basic Personal Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>	
							{(()=>{if(this.state.employeeUpdate === "NS"||this.state.employeeUpdate === "QC"||this.state.employeeUpdate === "FD"||this.state.employeeUpdate === "YT") {return(<div>														
								<div className="forinput">
									<label className="labelname">Taxiable Income for Basic Personal Amount: </label>
									<input type="text" name ="basicbottom" id="basicbottom" style={{width:'150px'}} value={this.state.basicbottom} onChange={this.handleChange} />
								</div><br/>	
								<div className="forinput">
									<label className="labelname">Taxibale Income for Max Personal Amount: </label>
									<input type="text" name ="basichigh" id="basichigh" style={{width:'150px'}} value={this.state.basichigh} onChange={this.handleChange} />
								</div><br/>	
							</div>)}})()}						
							{(()=>{{/*Nova Scotia Additional Amount*/}
								if(this.state.managementitem !== "" && this.state.employeeUpdate === "NS") {return(<div>
									<div className="forinput">
										<label className="labelname">Nova Scotia Additional Amount: </label>
										<input type="text" name ="nsadditional" id="nsadditional" style={{width:'150px'}} value={this.state.nsadditional} onChange={this.handleChange} />
									</div><br/>	
								</div>)} else if(this.state.managementitem !== "" && (this.state.employeeUpdate === "FD" || this.state.employeeUpdate==='YT')) {return(<div>
									<div className="forinput">
										<label className="labelname">Claim Code Chart Income Range: </label>
										<input type="text" name ="incomerange" id="incomerange" style={{width:'150px'}} value={this.state.incomerange} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">BPAF/BPAYT/BPANS Formula Denomiator: </label>
										<input type="text" name ="incomerangede" id="incomerangede" style={{width:'150px'}} value={this.state.incomerangede} onChange={this.handleChange} />
									</div><br/>	
								</div>)}
							})()}
							{/*Claim Code Chart Income Range for all provinces except FD and YT since presented in above*/}
							{(()=>{if(this.state.managementitem !== "" && (this.state.employeeUpdate !== "FD" && this.state.employeeUpdate !=='YT')) {return(<div>
								<div className="forinput">
										<label className="labelname">Claim Code Chart Income Range: </label>
										<input type="text" name ="incomerange" id="incomerange" style={{width:'150px'}} value={this.state.incomerange} onChange={this.handleChange} />
								</div><br/>	
							</div>)}})()}	
							{(()=>{{/*Federal CPP and EI*/}
								if(this.state.managementitem !== "" && this.state.employeeUpdate === "FD") {return(<div>
									<div className="forinput">
										<label className="labelname">CPP Maximum Pensionable Earnings: </label>
										<input type="text" name ="cppmax" id="cppmax" style={{width:'150px'}} value={this.state.cppmax} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">CPP Basic Exemption: </label>
										<input type="text" name ="cppexemption" id="cppexemption" style={{width:'150px'}} value={this.state.cppexemption} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">CPP Employee Rate (%): </label>
										<input type="text" name ="cppee" id="cppee" style={{width:'150px'}} value={this.state.cppee} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">CPP Employer Rate(%): </label>
										<input type="text" name ="cpper" id="cpper" style={{width:'150px'}} value={this.state.cpper} onChange={this.handleChange} />
									</div><br/>
									<div className="forinput">
										<label className="labelname">Maximum Insurable Earnings: </label>
										<input type="text" name ="eimax" id="eimax" style={{width:'150px'}} value={this.state.eimax} onChange={this.handleChange} />
									</div><br/>																			
									<div className="forinput">
										<label className="labelname">EI Employee Rate (%): </label>
										<input type="text" name ="eiee" id="eiee" style={{width:'150px'}} value={this.state.eiee} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">EI Employer Rate(%): </label>
										<input type="text" name ="eier" id="eier" style={{width:'150px'}} value={this.state.eier} onChange={this.handleChange} />
									</div><br/>									
							</div>)} else if(this.state.managementitem !== "" && this.state.employeeUpdate==='QC') {return(<div>
									<div className="forinput">
										<label className="labelname">QPIP Maximum Insurable Earnings: </label>
										<input type="text" name ="qpipmax" id="qpipmax" style={{width:'150px'}} value={this.state.qpipmax} onChange={this.handleChange} />
									</div><br/>																			
									<div className="forinput">
										<label className="labelname">QPIP Employee Rate (%): </label>
										<input type="text" name ="qpipee" id="qpipee" style={{width:'150px'}} value={this.state.qpipee} onChange={this.handleChange} />
									</div><br/>	
									<div className="forinput">
										<label className="labelname">QPIP Employer Rate(%): </label>
										<input type="text" name ="qpiper" id="qpiper" style={{width:'150px'}} value={this.state.qpiper} onChange={this.handleChange} />
									</div><br/>
								</div>)}									
							})()}							
							{(()=>{if(this.state.managementitem !== "" && (this.state.employeeUpdate === "FD" || this.state.employeeUpdate ==='YT'|| this.state.employeeUpdate ==='NS'|| this.state.employeeUpdate ==='QC')) {return(<div>
								<div className="forinput">
										<label className="labelname">BPAF/BPAYT/BPANS Formula Nominator: </label>
										<input type="text" name ="nomiator" id="nomiator" style={{width:'150px'}} value={this.state.nomiator} onChange={this.handleChange} />
								</div><br/>	
							</div>)}})()}
							<div className="forinput">
									<label className="labelname">Non Refundable Tax Credit: </label>
									<input type="text" name ="noncredit" id="noncredit" style={{width:'150px'}} value={this.state.noncredit} onChange={this.handleChange} />
							</div><br/>	
							<h1>{this.state.Resp}</h1><br/><br/>						
						</div>)} 
					})()}					
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupTaxAmounts;