import CommonHeader from './commons/Head'
import React from "react";
import dayjs from 'dayjs';
import '../main.css'
import axios from 'axios'
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

class timeSheetForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			prog : [], proj : [], Industry:'', selectedProg : '', selectedProj : '', lumpsum:'', selectedMonth: '', selectedDay: 0,			
			//payfreq:payfreq ,
			paystatus:'',comments:'',DiffDays:'',clientName:'',
			program:'',project:'',payment:'',PayPeriods:[],startingDate:'',
			Dates1:0,Dates2:0,Dates3:0,Dates4:0,Dates5:0,Dates6:0,Dates7:0,
			Dates8:0,Dates9:0,Dates10:0,Dates11:0,Dates12:0,Dates13:0,Dates14:0,
			Dates15:0,Dates16:0,Dates17:0,Dates18:0,Dates19:0,Dates20:0,Dates21:0,
			Dates22:0,Dates23:0,Dates24:0,Dates25:0,Dates26:0,Dates27:0,Dates28:0,
			Dates29:0,Dates30:0,Dates31:0,TotalHours:0,bonusyear:'', PayDay:'', DayDiff:'', PStatus:'',
			LabelProg:'',LabelProj:'', Resp:'',valueInput:'',commprog:'',user:localStorage.getItem('tokens'),
			names:localStorage.getItem('names')};		
		this.handleProgramChange = this.handleProgramChange.bind(this);
		this.handleTextAreaChange = this.handleTextAreaChange.bind(this);
		this.handleChange=this.handleChange.bind(this);	
		this.handleLumChange=this.handleLumChange.bind(this);
		this.handleSubmit=this.handleSubmit.bind(this)		
	}

	componentDidMount() {					
		const para={user:this.state.user, names: this.state.names}				
		axios.post(process.env.REACT_APP_API_ENV+'api/payfreq.php', para)
			.then(result => {								
				this.setState({PayPeriods:result.data});									
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/progemployee.php', para)
			.then(result => {
				if(result.data[0][1]=="No") {
					this.setState({commprog:"user information is not ready, please enter your information under SETTINGS section now"})					
				} else {
					this.setState({prog:result.data})								
				}
				
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/username.php', para)
			.then(result => {				
				this.setState({clientName:result.data})								
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/findindustry.php', para)
			.then(result => {							
				this.setState({Industry:result.data})								
				this.setState({LabelProg:((result.data == 'O') ? 'Campus':'Program')})
				this.setState({LabelProj:((result.data == 'O') ? 'Program':'Project')})		
			})		
			
	}
	
	
	//The following ShowDisplay function is for example only
	ShowDisplay = (nrow,ncol) => {
		let table = []
		// Outer loop to create parent
		for (let i = 0; i < nrow; i++) {
			let children = []
			//Inner loop to create children
			for (let j = 0; j < ncol; j++) {
				children.push(<td>{`Column ${j + 1}`}</td>)
			}
			//Create the parent and add the children
			table.push(<tr>{children}</tr>)
		}
		return table
	}
	

	//to display monthly input form
	TDisplayFormat_ORIG =(DFormat, DRow, SM, MD,NameInput) =>{	
		const lines =Math.ceil(MD[SM]/7)		
		const LB=[]						
		for (let i=0;i<lines;i++) {			
			LB.push(<div key={i}><div className="label-wrap">
				<div className="label">					
					{DFormat.slice(DRow[SM]-MD[SM]+i*7,DRow[SM]-MD[SM]+((7*(i+1)>MD[SM]) ? MD[SM]:7*(i+1))).map((item, index) => {return<h2 key={index}>{item}</h2>;})} 
				</div>				
			</div>
			<div className="label-wrap">
				<div className="label">				
					{NameInput.slice(DRow[SM]-MD[SM]+i*7,DRow[SM]-MD[SM]+((7*(i+1)>MD[SM]) ? MD[SM]:7*(i+1))).map((item, index) => {return<h2 key={index}><input type="text" style={{width:'100px'}} name={item} value={this.state.value} onChange={this.handleChange}/></h2>;})}					
				</div>
			</div><br/><br/></div>)
		}
			
		return LB
	}
	TDisplayFormat =(DFormat, DiffDays, Period) =>{						
		const SecondWeekdays = DiffDays+1>13 ? 14:13
		const ThirdWeekdays  = DiffDays+1 == 15 ? 15:(DiffDays+1 == 16 ? 16 : 21)
		const FifthWeekdays  = DiffDays+1 > 30 ? 31:(DiffDays+1 > 29 ? 30 : 29)
		return(<div>
			<div className="label-wrap">
				<div className="label">					
					<h2>{dayjs(DFormat).add(1,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(2,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(3,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(4,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(5,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(6,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(7,'day').format("YYYY-MM-DD")}</h2>
				</div>				
			</div>
			<div className="label-wrap">
				<div className="label">				
					<h2><input type="text" style={{width:'100px'}} name="Dates1" value={this.state.Dates1} onChange={this.handleChange}/></h2>
					<h2><input type="text" style={{width:'100px'}} name="Dates2" value={this.state.Dates2} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates3" value={this.state.Dates3} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates4" value={this.state.Dates4} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates5" value={this.state.Dates5} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates6" value={this.state.Dates6} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates7" value={this.state.Dates7} onChange={this.handleChange}/></h2>					
				</div>
			</div><br/><br/>		
			{DiffDays+1 > 7 ? 
			<div className="label-wrap">
				<div className="label">					
					<h2>{dayjs(DFormat).add(8,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(9,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(10,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(11,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(12,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(13,'day').format("YYYY-MM-DD")}</h2>
					{DiffDays+1 > 13 ? <h2>{dayjs(DFormat).add(14,'day').format("YYYY-MM-DD")}</h2>:""}
				</div>				
			</div>:""}
			{DiffDays+1 > 7 ? 
			<div className="label-wrap">
				<div className="label">				
					<h2><input type="text" style={{width:'100px'}} name="Dates8" value={this.state.Dates8} onChange={this.handleChange}/></h2>
					<h2><input type="text" style={{width:'100px'}} name="Dates9" value={this.state.Dates9} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates10" value={this.state.Dates10} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates11" value={this.state.Dates11} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates12" value={this.state.Dates12} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates13" value={this.state.Dates13} onChange={this.handleChange}/></h2>					
					{DiffDays+1 > 13 ? <h2><input type="text" style={{width:'100px'}} name="Dates14" value={this.state.Dates14} onChange={this.handleChange}/></h2>:""}					
				</div>
			</div>:""}<br/><br/>			
			{DiffDays+1 > 14 ? 
			<div className="label-wrap">
				<div className="label">					
					<h2>{dayjs(DFormat).add(15,'day').format("YYYY-MM-DD")}</h2>
					{ThirdWeekdays>15 ? <h2>{dayjs(DFormat).add(16,'day').format("YYYY-MM-DD")}</h2>:""}
					{ThirdWeekdays>16 ? <h2>{dayjs(DFormat).add(17,'day').format("YYYY-MM-DD")}</h2>:""}
					{ThirdWeekdays>16 ? <h2>{dayjs(DFormat).add(18,'day').format("YYYY-MM-DD")}</h2>:""}
					{ThirdWeekdays>16 ? <h2>{dayjs(DFormat).add(19,'day').format("YYYY-MM-DD")}</h2>:""}
					{ThirdWeekdays>16 ? <h2>{dayjs(DFormat).add(20,'day').format("YYYY-MM-DD")}</h2>:""}
					{ThirdWeekdays>16 ? <h2>{dayjs(DFormat).add(21,'day').format("YYYY-MM-DD")}</h2>:""}
				</div>				
			</div>:""}
			{DiffDays+1 > 14 ? 
			<div className="label-wrap">
				<div className="label">				
					<h2><input type="text" style={{width:'100px'}} name="Dates15" value={this.state.Dates15} onChange={this.handleChange}/></h2>
					{ThirdWeekdays>15 ? <h2><input type="text" style={{width:'100px'}} name="Dates16" value={this.state.Dates16} onChange={this.handleChange}/></h2>:""}
					{ThirdWeekdays>16 ? <h2><input type="text" style={{width:'100px'}} name="Dates17" value={this.state.Dates17} onChange={this.handleChange}/></h2>:""}					
					{ThirdWeekdays>16 ? <h2><input type="text" style={{width:'100px'}} name="Dates18" value={this.state.Dates18} onChange={this.handleChange}/></h2>:""}				
					{ThirdWeekdays>16 ? <h2><input type="text" style={{width:'100px'}} name="Dates19" value={this.state.Dates19} onChange={this.handleChange}/></h2>:""}				
					{ThirdWeekdays>16 ? <h2><input type="text" style={{width:'100px'}} name="Dates20" value={this.state.Dates20} onChange={this.handleChange}/></h2>:""}					
					{ThirdWeekdays>16 ? <h2><input type="text" style={{width:'100px'}} name="Dates21" value={this.state.Dates21} onChange={this.handleChange}/></h2>:""}				
				</div>
			</div>:""}<br/><br/>
			{DiffDays+1 > 17 ? <div>
			<div className="label-wrap">
				<div className="label">					
					<h2>{dayjs(DFormat).add(22,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(23,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(24,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(25,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(26,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(27,'day').format("YYYY-MM-DD")}</h2>
					<h2>{dayjs(DFormat).add(28,'day').format("YYYY-MM-DD")}</h2>
				</div>				
			</div>
			<div className="label-wrap">
				<div className="label">				
					<h2><input type="text" style={{width:'100px'}} name="Dates22" value={this.state.Dates22} onChange={this.handleChange}/></h2>
					<h2><input type="text" style={{width:'100px'}} name="Dates23" value={this.state.Dates23} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates24" value={this.state.Dates24} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates25" value={this.state.Dates25} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates26" value={this.state.Dates26} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates27" value={this.state.Dates27} onChange={this.handleChange}/></h2>					
					<h2><input type="text" style={{width:'100px'}} name="Dates28" value={this.state.Dates28} onChange={this.handleChange}/></h2>					
				</div>
			</div><br/><br/></div>:""}
			{DiffDays+1 > 28 ? <div>
			<div className="label-wrap">
				<div className="label">					
					{FifthWeekdays > 28 ? <h2>{dayjs(DFormat).add(29,'day').format("YYYY-MM-DD")}</h2>:""}
					{FifthWeekdays > 29 ? <h2>{dayjs(DFormat).add(30,'day').format("YYYY-MM-DD")}</h2>:""}
					{FifthWeekdays > 30 ? <h2>{dayjs(DFormat).add(31,'day').format("YYYY-MM-DD")}</h2>:""}				
				</div>				
			</div>
			<div className="label-wrap">
				<div className="label">				
					{FifthWeekdays > 28 ? <h2><input type="text" style={{width:'100px'}} name="Dates29" value={this.state.Dates29} onChange={this.handleChange}/></h2>:""}
					{FifthWeekdays > 29 ? <h2><input type="text" style={{width:'100px'}} name="Dates30" value={this.state.Dates30} onChange={this.handleChange}/></h2>:""}					
					{FifthWeekdays > 30 ? <h2><input type="text" style={{width:'100px'}} name="Dates31" value={this.state.Dates31} onChange={this.handleChange}/></h2>:""}										
				</div>
			</div><br/><br/></div>:""}
		</div>)							
	}
		
	handleProgramChange = event => {
		this.setState({lumpsum:''})
		this.setState({selectedMonth:''})		
		this.setState({commprog:''})				
		this.setState({Resp:''})		
		this.setState({PayDay:''})				
		this.setState({PayDay:''})		
		const {name, value} = event.target
		this.setState({[name]: event.target.value})
	}
	
	handleTextAreaChange = event => {
		const {name, value} = event.target			
		this.setState({[name]: value});		
		this.setState({commprog:''})		
		this.setState({PayDay:''})				
		this.setState({PayDay:''})		
	}
	
	handleMonthChange = event => {	
		this.setState({commprog:''})		
		this.setState({Dates1:0})
		this.setState({Dates2:0})
		this.setState({Dates3:0})
		this.setState({Dates4:0})
		this.setState({Dates5:0})
		this.setState({Dates6:0})
		this.setState({Dates7:0})
		this.setState({Dates8:0})
		this.setState({Dates9:0})
		this.setState({Dates10:0})
		this.setState({Dates11:0})
		this.setState({Dates12:0})
		this.setState({Dates13:0})
		this.setState({Dates14:0})
		this.setState({Dates15:0})
		this.setState({Dates16:0})
		this.setState({Dates17:0})
		this.setState({Dates18:0})
		this.setState({Dates19:0})
		this.setState({Dates20:0})
		this.setState({Dates21:0})
		this.setState({Dates22:0})
		this.setState({Dates23:0})
		this.setState({Dates24:0})
		this.setState({Dates25:0})
		this.setState({Dates26:0})
		this.setState({Dates27:0})
		this.setState({Dates28:0})
		this.setState({Dates29:0})
		this.setState({Dates30:0})
		this.setState({Dates31:0})	
		this.setState({PayDay:''})				
		this.setState({PayDay:''})				
		this.setState({selectedMonth:''})
		this.setState({selectedMonth: event.target.value});	
		this.setState ({selectedDay: dayjs(this.state.selectedMonth).daysInMonth()},()=>{this.setState ({selectedDay: dayjs(this.state.selectedMonth).daysInMonth()})})				
		this.setState({TotalHours:0})	
		this.setState({startingDate:this.state.PayPeriods.filter(sub=>sub[1]==event.target.value).map(sub=>sub[2])})
		this.setState({DiffDays:dayjs(this.state.PayPeriods.filter(sub=>sub[1]==event.target.value).map(sub=>sub[3])).diff(this.state.PayPeriods.filter(sub=>sub[1]==event.target.value).map(sub=>sub[2]),'d')})						
		if(dayjs(this.state.PayPeriods.filter(sub=>sub[1]==event.target.value).map(sub=>sub[2])).format('MM-DD')=='10-31' && dayjs(this.state.PayPeriods.filter(sub=>sub[1]==event.target.value).map(sub=>sub[3])).diff(this.state.PayPeriods.filter(sub=>sub[1]==event.target.value).map(sub=>sub[2]),'d')==13) {
			this.setState({DiffDays:14})
		} else if(dayjs(this.state.PayPeriods.filter(sub=>sub[1]==event.target.value).map(sub=>sub[2])).format('MM-DD')=='10-31' && dayjs(this.state.PayPeriods.filter(sub=>sub[1]==event.target.value).map(sub=>sub[3])).diff(this.state.PayPeriods.filter(sub=>sub[1]==event.target.value).map(sub=>sub[2]),'d')==28) {
			this.setState({DiffDays:29})
		}		
		const userObject={				
				user:this.state.user,
				payperiod:event.target.value,				
				program:this.state.program,
				names:this.state.names
			}
		axios.post(process.env.REACT_APP_API_ENV+'api/paymentstatus.php', userObject)
			.then(result => {								
				this.setState({paystatus:result.data[0][0]})
				this.setState({comments:result.data[0][35]})								
				this.setState({PayDay:result.data[0][37]})
				this.setState({DayDiff:result.data[0][38]})
				this.setState({PStatus:result.data[0][39]})
				this.setState({valueInput:''})
				let j='Submitted Hours from ' + dayjs(result.data.filter(item=>item[34]=='k').map(sub =>sub[32]).[0]).format("YYYY-MM-DD")+' to ' + dayjs(result.data.filter(item=>item[34]=='k').map(sub =>sub[33]).[0]).format("YYYY-MM-DD") + ' =  '
				for (let i=0;i<dayjs(result.data[0][33]).diff(result.data[0][32],'d')+1;i++) {								
					//j=j+ JSON.parse(dayjs(result.data.filter(item=>item[34]=='k').map(sub =>sub[32]).[0]).add(i,'day').format("D")) 
					//this.state.valueInput.push(result.data.filter(item=>item[34]=='k').map(sub =>sub[JSON.parse(dayjs(result.data.filter(item=>item[34]=='k').map(sub =>sub[32]).[0]).add(i,'day').format("D"))]).[0])																								
					j=j + ' + ' +result.data.filter(item=>item[34]=='k').map(sub =>sub[JSON.parse(dayjs(result.data.filter(item=>item[34]=='k').map(sub =>sub[32]).[0]).add(i,'day').format("D"))]).[0]
				}
				if(result.data[0][0] == ' (Re-Submit)'){this.setState({valueInput:j + ' = ' + result.data.filter(item=>item[34]=='k').map(sub =>sub[36]) + '. Please take comments and re-submit'})} else {this.setState({valueInput:''})}
					
			})		
		this.setState({Resp:''})		
	}
	
	handleChange = event => {	
		const {name, value} = event.target		
		if(isNaN(value) || value==='') {
			this.setState({[name]: 0})		
		} else {
			this.setState({[name]: value})		
		}
		this.setState({commprog:''})	
		this.setState({PayDay:''})				
		this.setState({PayDay:''})		
		
	}
	handleLumChange = event => {
		const {name, value} = event.target		
		this.setState({[name]: value})
		this.setState({selectedMonth:''})
		this.setState({commprog:''})	
		this.setState({PayDay:''})				
		this.setState({PayDay:''})		
	}
	
	handleSubmit = event => {
		event.preventDefault()		
		const {program, project, payment, lumpsum, comments, Dates1, Dates2, Dates3, Dates4, Dates5, Dates6, Dates7, 
				Dates8, Dates9, Dates10,Dates11, Dates12, Dates13, Dates14, Dates15, Dates16, Dates17, Dates18, Dates19, 
				Dates20, Dates21,Dates22, Dates23, Dates24, Dates25, Dates26, Dates27, Dates28, Dates29, Dates30, Dates31,
				TotalHours,bonusyear,startingDate} = this.state			
		if(this.state.program == "") {
			alert('Please select your assignment for the charging')
			return false
		}		
	
		if(this.state.lumpsum == "") {
			alert('Please select regular or non regular payment')
			return false
		} else if(this.state.lumpsum == "0") {
			if(this.state.selectedMonth == "") {
				alert('Please select your payment period for entering timesheet')
				return false
			}
			if(parseFloat(this.state.Dates1) + parseFloat(this.state.Dates2) + parseFloat(this.state.Dates3) + parseFloat(this.state.Dates4) + parseFloat(this.state.Dates5) + parseFloat(this.state.Dates6) + parseFloat(this.state.Dates7) + parseFloat(this.state.Dates8) + parseFloat(this.state.Dates9) + parseFloat(this.state.Dates10) + parseFloat(this.state.Dates11) + parseFloat(this.state.Dates12) + parseFloat(this.state.Dates13) + parseFloat(this.state.Dates14) + parseFloat(this.state.Dates15) + parseFloat(this.state.Dates16) + parseFloat(this.state.Dates17) + parseFloat(this.state.Dates18) + parseFloat(this.state.Dates19) + parseFloat(this.state.Dates20) + parseFloat(this.state.Dates21) + parseFloat(this.state.Dates22) + parseFloat(this.state.Dates23) + parseFloat(this.state.Dates24) + parseFloat(this.state.Dates25) + parseFloat(this.state.Dates26) + parseFloat(this.state.Dates27) + parseFloat(this.state.Dates28) + parseFloat(this.state.Dates29) + parseFloat(this.state.Dates30) + parseFloat(this.state.Dates31)==""||
			parseFloat(this.state.Dates1) + parseFloat(this.state.Dates2) + parseFloat(this.state.Dates3) + parseFloat(this.state.Dates4) + parseFloat(this.state.Dates5) + parseFloat(this.state.Dates6) + parseFloat(this.state.Dates7) + parseFloat(this.state.Dates8) + parseFloat(this.state.Dates9) + parseFloat(this.state.Dates10) + parseFloat(this.state.Dates11) + parseFloat(this.state.Dates12) + parseFloat(this.state.Dates13) + parseFloat(this.state.Dates14) + parseFloat(this.state.Dates15) + parseFloat(this.state.Dates16) + parseFloat(this.state.Dates17) + parseFloat(this.state.Dates18) + parseFloat(this.state.Dates19) + parseFloat(this.state.Dates20) + parseFloat(this.state.Dates21) + parseFloat(this.state.Dates22) + parseFloat(this.state.Dates23) + parseFloat(this.state.Dates24) + parseFloat(this.state.Dates25) + parseFloat(this.state.Dates26) + parseFloat(this.state.Dates27) + parseFloat(this.state.Dates28) + parseFloat(this.state.Dates29) + parseFloat(this.state.Dates30) + parseFloat(this.state.Dates31) == 0) {
				alert('Are you sure your total working hour is 0? if confirmed, please skip this payment period, thank you for your cooperation!')
				return false
			}
		} else if(this.state.lumpsum == "1") {
			if(this.state.TotalHours == 0) {
				alert('Please enter your total bonus or non-periodic payment')
				return false
			}
			if(this.state.bonusyear == "") {
				alert('Please select which year payment')
				return false
			}			
		}
		const userObject={
				user:this.state.user,names:this.state.names,
				starts:dayjs(this.state.startingDate).add(1,'day').format("YYYY-MM-DD"),ends:dayjs(this.state.PayPeriods.filter(sub=>sub[1]==this.state.selectedMonth).map(sub=>sub[3])).add(1,'day').format("YYYY-MM-DD"),
				program:this.state.program,	lumpsum: this.state.lumpsum, payment: this.state.selectedMonth, lumpay: this.state.TotalHours,
				Dates1:this.state.Dates1, Dates2:this.state.Dates2, Dates3:this.state.Dates3, Dates4:this.state.Dates4, Dates5:this.state.Dates5, 
				Dates6:this.state.Dates6, Dates7:this.state.Dates7, Dates8:this.state.Dates8, Dates9:this.state.Dates9, Dates10:this.state.Dates10,
				Dates11:this.state.Dates11, Dates12:this.state.Dates12, Dates13:this.state.Dates13, Dates14:this.state.Dates14, 
				Dates15:this.state.Dates15, Dates16:this.state.Dates16, Dates17:this.state.Dates17, Dates18:this.state.Dates18, 
				Dates19:this.state.Dates19, Dates20:this.state.Dates20, Dates21:this.state.Dates21, Dates22:this.state.Dates22, 
				Dates23:this.state.Dates23, Dates24:this.state.Dates24, Dates25:this.state.Dates25, Dates26:this.state.Dates26, 
				Dates27:this.state.Dates27, Dates28:this.state.Dates28, Dates29:this.state.Dates29, Dates30:this.state.Dates30, 
				Dates31:this.state.Dates31,comments:this.state.comments,bonusyear:this.state.bonusyear
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/timehours.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});
	}
	
	render() {						
		//to sum up all input values
		const TempT=parseFloat(this.state.Dates1) + parseFloat(this.state.Dates2) + parseFloat(this.state.Dates3) + parseFloat(this.state.Dates4) + parseFloat(this.state.Dates5) + parseFloat(this.state.Dates6) + parseFloat(this.state.Dates7) + parseFloat(this.state.Dates8) + parseFloat(this.state.Dates9) + parseFloat(this.state.Dates10) + parseFloat(this.state.Dates11) + parseFloat(this.state.Dates12) + parseFloat(this.state.Dates13) + parseFloat(this.state.Dates14) + parseFloat(this.state.Dates15) + parseFloat(this.state.Dates16) + parseFloat(this.state.Dates17) + parseFloat(this.state.Dates18) + parseFloat(this.state.Dates19) + parseFloat(this.state.Dates20) + parseFloat(this.state.Dates21) + parseFloat(this.state.Dates22) + parseFloat(this.state.Dates23) + parseFloat(this.state.Dates24) + parseFloat(this.state.Dates25) + parseFloat(this.state.Dates26) + parseFloat(this.state.Dates27) + parseFloat(this.state.Dates28) + parseFloat(this.state.Dates29) + parseFloat(this.state.Dates30) + parseFloat(this.state.Dates31)						
		return (
			<div>      				
				<CommonHeader titles="Time Sheet"/>
				<form onSubmit={this.handleSubmit}>	
					<label><h2>{this.state.clientName == "" ? "your profile is not set up yet, please set up your profile first under SETTINGS section":""}</h2></label><br/>
					{(()=>{if(this.state.clientName !=="") {return (<div>
						<label><h2>{this.state.clientName} Timesheet Hours</h2></label>							
						<select name="program" id="program" style={{width:'150px'}} value={this.state.program} onChange={this.handleProgramChange}>
							<option value="">--Choose {this.state.LabelProg}--</option>												
							{this.state.commprog == '' ? this.state.prog.map((e,key)=>(<option value={e[0]} key={key}>{e[2]+': '+e[3]}</option>)):''}							
						</select><br/><small>{this.state.prog.filter(sub=>sub[0]==this.state.program).map(sub=>sub[7]) == 'Yearly' ? 'Since this is full time '+this.state.LabelProj+', you can enter any hours in any day even just one day':''}</small><br/><br/>	
						<h2 style={{width:'600px',textAlign:'left'}}>{this.state.commprog == "" ? "":"You are not assigned to "+this.state.LabelProg+" and "+this.state.LabelProj+" yet, please ask your manager to set up under MANAGEMENT section Manager Employee/Manage Assignment funcitons"}</h2><br/>						
					</div>)}})()}
					{(()=>{
						if(this.state.program !=="") {return (<div>
							<label><h2>Payment Type</h2></label>			
							<select name="lumpsum" style={{width:'150px'}} value={this.state.lumpsum} onChange={this.handleLumChange}>
								<option value="">--Select--</option>
								<option value="0">Regular Payment</option>
								{/*<option value="1">Bonus or other Non-Periodic Payment</option>*/}
							</select><br/><br/>	
							{(()=>{
								if(this.state.lumpsum =="0") {return (<div>
									<label><h2>Payment Period <b>{(this.state.selectedMonth=="") ? "":this.state.paystatus}</b></h2></label>						
									<select name="payment" style={{width:'150px'}} value={this.state.selectedMonth} onChange={this.handleMonthChange}>
										<option value="">--Choose Period--</option>											
										{this.state.PayPeriods.map((d,key)=>(<option value={d[1]} key={d[1]}>{d[2] +' ~ '+ d[3]}</option>))}	
									</select><br/><br/>	
									{(()=>{if(this.state.PStatus =="SUB") {return (<div>
										<h2>You have submitted your timesheet already and waiting for approval from your manager</h2><br/><br/>
									</div>)} else if(this.state.PStatus == "APP") {return (<div>	
										<h2>You submitted timesheet has been approved and waiting for payment process</h2><br/><br/>																				
									</div>)} else if(this.state.PStatus == "PAD" && this.state.DayDiff == "WILLPAY") {return (<div>											
										<h2>Your payment will be deposited into your banking account on {this.state.PayDay}</h2><br/>
									</div>)}})()}
									{/*<h1>After testing, please change to 3-month payment periods</h1><br/><br/>*/}
								</div>)} else if(this.state.lumpsum =="1") {return (<div>								
									<h2>Please enter total payment: </h2>
									<input type="text" name="TotalHours" value={this.state.TotalHours} style={{width:'150px'}} onChange={this.handleChange} /><br/>
									<small>Gross bonus, retroactive pay increase, vacation pay when vacation is not taken, accumulated overtime payment or other non-periodic payment</small><br/><br/>
									<label><h2>Which Year Payment</h2></label>			
									<select name="bonusyear" style={{width:'150px'}} value={this.state.bonusyear} onChange={this.handleChange}>
										<option value="">--Select--</option>
										<option value={lastyear}>{lastyear}</option>
										<option value={thisyear}>{thisyear}</option>
										<option value={nextyear}>{nextyear}</option>
									</select><br/><br/>	
								</div>)}
							})()}
					</div>)}})()}					
					{(()=>{
						if(this.state.selectedMonth !=="") {return (<div>							
							<h2>Total: <input type="text" name="TotalHours" value={TempT} style={{width:'25px'}} readOnly/><br/><br/></h2>
							<div className="label-wrap">
								<div className="label">					
									<h2>{dayjs(this.state.PayPeriods.filter(sub=>sub[1]==this.state.selectedMonth).map(sub=>sub[2])).add(1,'day').format("ddd")}</h2>
									<h2>{dayjs(this.state.PayPeriods.filter(sub=>sub[1]==this.state.selectedMonth).map(sub=>sub[2])).add(2,'day').format("ddd")}</h2>
									<h2>{dayjs(this.state.PayPeriods.filter(sub=>sub[1]==this.state.selectedMonth).map(sub=>sub[2])).add(3,'day').format("ddd")}</h2>
									<h2>{dayjs(this.state.PayPeriods.filter(sub=>sub[1]==this.state.selectedMonth).map(sub=>sub[2])).add(4,'day').format("ddd")}</h2>
									<h2>{dayjs(this.state.PayPeriods.filter(sub=>sub[1]==this.state.selectedMonth).map(sub=>sub[2])).add(5,'day').format("ddd")}</h2>
									<h2>{dayjs(this.state.PayPeriods.filter(sub=>sub[1]==this.state.selectedMonth).map(sub=>sub[2])).add(6,'day').format("ddd")}</h2>
									<h2>{dayjs(this.state.PayPeriods.filter(sub=>sub[1]==this.state.selectedMonth).map(sub=>sub[2])).add(7,'day').format("ddd")}</h2>
								</div>				
							</div>
					</div>)}})()}					
					{(()=>{if(this.state.program !=="" && this.state.selectedMonth !=="") {return (<div>
						<div>														
							{this.TDisplayFormat(this.state.startingDate, this.state.DiffDays, this.state.selectedMonth)}
							<label><h2>{this.state.valueInput}</h2></label>						
						</div><br/>	
						<label><h2>Comments </h2></label>						
						<textarea name="comments" id="comments" rows="3" value = {this.state.comments} onChange={this.handleTextAreaChange} /><br/><br/>						
					</div>)}})()}					
					<h1>{this.state.Resp}</h1><br/><br/>		
					{(()=>{if(this.state.clientName !=="") {return (<div>
						<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> &nbsp;&nbsp;
						<button type="reset" className="btn btn-primary mr-2" width="200px">&nbsp;Reset &nbsp;</button> 					
					</div>)}})()}
				</form>
			</div>
		)
	}
}

export default timeSheetForm;