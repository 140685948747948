import React from 'react'
import { useAuth } from "../context/auth";
import '../main.css'
import MenuBar from '../clients/commons/Menu'
import PrimaryFoot from '../clients/commons/PrimaryFooter'
import AdminItems from '../clients/ManagementForm'
import axios from 'axios'
import Headers from '../clients/headers'

function Admin(props) {
  const { setAuthTokens } = useAuth();

  function logOut() {
    setAuthTokens();
  }
    
    
  return ( 
    <div className="generic">
		<div className="logins" align="center">	 			
			<Headers />			
			<MenuBar /><br/><br/>			
			<h1>Admin</h1><br/><br/>			
			<AdminItems />			
			<br/><br/><PrimaryFoot />
		</div>
	</div>
  );
}

export default Admin;