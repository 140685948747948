import React, {setState} from "react";
import { useAuth } from "../context/auth";
import UCSLogin from "../RegistrationClients/UCSLogin";


function Logout(props) {
	const { setAuthTokens } = useAuth();
	setAuthTokens(); 	
	localStorage.clear(); 		
	sessionStorage.clear(); 	
	window.location.reload()
	window.location.href='/UCSLogin'    
    return false;	
}

export default Logout;