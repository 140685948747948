import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const SundayofWeek=StartDays(1);//dayjs().day(0).format("YYYY-MM-DD");
const DayofMonth=StartDays(3);//dayjs().date(1).format("YYYY-MM-DD");


function StartDays($Type){	
	let TDates=[]
	let TDays=[]
	for(let $i=0;$i<7;$i++) {
		TDates[$i]=dayjs().day(0).add($i+1,'week').format("YYYY-MM-DD");
	}
	for(let $i=0;$i<3;$i++) {
		TDays[$i]=dayjs().date(1).add($i+1,'month').format("YYYY-MM-DD");
	}
	if($Type==1||$Type==2) {
		return TDates;
	} else if($Type==3||$Type==4) {
		return TDays;
	}
	
}

class updateClientInfo extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:31, Resp:'',SubC:[], LName: '', ISIC: '', FreqG:[],paybegin:'',
				user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);				
		this.handleSubmit=this.handleSubmit.bind(this);
		this.handleCompChange=this.handleCompChange.bind(this);
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}	
		axios.post(process.env.REACT_APP_API_ENV+'api/clientUpdate.php',para)
			.then(result => {												
				this.setState({SubC:result.data})								
		})			
		axios.post(process.env.REACT_APP_API_ENV+'api/frequency.php')
			.then(result => {												
				this.setState({FreqG:result.data})								
		})				
	}	
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	handleCompChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
		const CID={CID:event.target.value}
		axios.post(process.env.REACT_APP_API_ENV+'api/clientinformationUpdate.php', CID)
			.then(result => {																						
				this.setState({SName:result.data[1]})	
				this.setState({ISIC:result.data[2]})	
		})			
	}
		
	handleSubmit = event => {		
		event.preventDefault()		
		const {adminupdate, LName, SName, ISIC,paybegin} = this.state					
		if(!LName) {
			alert('Please select client for the updating')
			return false
		}		
		if(!SName) {
			alert('Please enter client new legal name')
			return false
		}		
		if(!ISIC) {
			alert('Please select client salary payment frequency')
			return false
		}			
		const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				LName: this.state.LName,
				SName: this.state.SName,
				ISIC:  this.state.ISIC,
				paybegin: this.state.paybegin
		}
		axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
			.then((res) => {						
				this.setState({Resp:res.data});					
			}).catch((error) => {
				console.log(error)
			});		 
	}
	
	render() {						
		return (		
			<div>    
				<CommonHeader titles="Client"/>
				<form onSubmit={this.handleSubmit}>															
					<h2>Choose Client</h2>
					<select id="LName" name="LName" value = {this.state.LName} style={{width:'160px'}} onChange={this.handleCompChange}>
						<option value="">--Select--</option>									
						{this.state.SubC.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
					</select><br/><br/>
					<h2>New Legal Name</h2>
					<input type="text" name="SName" id="SName" value={this.state.SName} onChange={this.handleChange} />
					<br/><br/>			
					<h2>Salary Payment Frequency</h2>
					<select id="ISIC" name="ISIC" value = {this.state.ISIC} style={{width:'160px'}} onChange={this.handleChange}>
						<option value="">--Select--</option>									
						{this.state.FreqG.map(item => (<option value={item[0]} key={item[0]}>{item[1]}</option>))}			
					</select><br/><br/>			
					<h2>When to Start New Payment Frequency</h2>
					<select id="paybegin" name="paybegin" value = {this.state.paybegin} onChange={this.handleChange}>
						<option value="">--Select Start Date--</option>						
						{this.state.ISIC==1||this.state.ISIC==2 ? SundayofWeek.map((item, index) => {return<option value={item} key={index}>{item}</option>;}):DayofMonth.map((item, index) => {return<option value={item} key={index}>{item}</option>;})}					
					</select><br/>If client pay before working hours, please go to section "Set up Payment Periods" to update pay information<br/>			
					<h1>{this.state.Resp}</h1><br/><br/>																	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default updateClientInfo;