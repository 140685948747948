import React from "react";
import dayjs from 'dayjs';
import { useAuth } from "../context/auth";
import '../main.css'
import MenuBar from '../clients/commons/Menu'
import PrimaryFoot from '../clients/commons/PrimaryFooter'
import ApprovalPage from '../clients/ApprovalPage'
import ApprovalAbsent from '../clients/ApprovalAbsent'
import Headers from '../clients/headers'
import RootDir from '../common/apiaddress.json'


function Admin(props) {
  const { setAuthTokens } = useAuth();
  
  function logOut() {
    setAuthTokens();
  }
    
    
  return ( 
    <div className="generic">
		<div className="logins" align="center">	 			
			<Headers />
			<MenuBar /><br/><br/>
			<h1>Assign Approver During My Absent</h1><br/><br/>							
			<ApprovalAbsent /><br/><br/>
			<h1>Approve Time Sheet (<small>{dayjs().format("YYYY-MM-DD")}</small>)</h1><br/><br/>							
			<ApprovalPage />			
			<br/><br/><PrimaryFoot />
		</div>
	</div>
  );
}

export default Admin;