import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import CommonHeader from '../clients/commons/Head'

class profileForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {tts:[],
			firstname:'', lastname:'', SIN1:'', SIN2:'', SIN3:'', address:'', birth1:'', birth2:'', birth3:'', postcode1:'',
			postcode2:'', basicamount:'', basicamount1:'', caregive:'', ageamount:'', pensionamount:'', tution:'', disabilityamount:'', spouseamount:'',
			dependamount:'', caregivedepend:'', caregivedepend18:'', transferredAmount:'', transferredfromdepend:'',TotalA:'',
			Pro:[],Banks:[],formtype:'',year:2020,netincome:0,statename:'',criAMT:'',TD1Year:'', basicAMT:'',
			currentProfile:[],EID:'',countries:[],countryname:'',onemoreemployer:false,lessthanclaim:false,percent90yes:false,percent90no:false,
			prescribedzoon:'',additionaldeduction:'',caregiveamt:'', agetd1amount:'',agethreshold:'',ageup:'',pensionamt:'',disaamt:'',
			amt18u:'',careupamt:'',up18lessamt:'',up18threshold:'', AMTSPOUSE:'', SPOUSETH:'', SPOUSEUP:'',HOMECARE65UP:'',INFIRMDEPEND18UP:'',
			H65UPTH:'', H65UPEX:'', INFIRM18UPTH:'', INFIRM18UPEX:'',EDUPART:'', EDUFULL:'',CAREREL:'',CARETH:'',CAREUP:'',AMTSENIOR:'',
			AMTCHILD:'',pcombined:'',tutioncomment:'', TEXTFULL:'', TEXTPART:'', CEA:'', TLINES:'', BPA:'', CAREGIVE18D:'', AGE:'', 
			PENSION:'', TUTIONCOMM:'', DISABILITY:'', SPOUSE:'', DEPEND:'',	CAREGIVEDEPEND:'', CAREGIVE18UP:'', TRANSSPOUSE:'', 
			TRANSDEPEND:'', TOTALCLAIM:'', SUPP1:'', SUPP2:'', SUPP3:'',FDLINE:'',nsexceed:'',additionalcredit:'',nssumm:'',AGESUP:'',
			amtsupp1:'',amtsupp2:'',amtsupp3:'',viewcomments:'',TotalAmount:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
				
		this.handleStateChange=this.handleStateChange.bind(this)
	}

	componentDidMount() {
		const para={user:this.state.user,names:this.state.names}
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {						
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});		
		axios.post(process.env.REACT_APP_API_ENV+'api/Formwording.php')
			.then(result => {						
				this.setState({tts:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});	
		axios.post(process.env.REACT_APP_API_ENV+'api/bank.php')
			.then(result => {		
				this.setState({Banks:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});	
		axios.post(process.env.REACT_APP_API_ENV+'api/currentProfile.php', para)
			.then(result => {										
				this.setState({currentProfile:result.data})			
				this.setState({firstname:result.data[0]})			
				this.setState({lastname:result.data[1]})							
				this.setState({address:result.data[4]})			
				this.setState({city:result.data[5]})			
				this.setState({province:result.data[6]})			
				this.setState({bankname:result.data[14]})			
				this.setState({branch:result.data[10]})			
				this.setState({checknumber:result.data[12]})		
				this.setState({EID:result.data[15]})	
				if(result.data[2]){
					this.setState({SIN1:(result.data[2]).substring(0,3)})		
					this.setState({SIN2:(result.data[2]).substring(4,7)})		
					this.setState({SIN3:(result.data[2]).substring(8,12)})		
					this.setState({postcode1:(result.data[7]).substring(0,3)})		
					this.setState({postcode2:(result.data[7]).substring(4,7)})							
					this.setState({phone1:(result.data[3]).substring(0,3)})		
					this.setState({phone2:(result.data[3]).substring(4,7)})		
					this.setState({phone3:(result.data[3]).substring(8,12)})		
				}
				
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/taxyear.php')
			.then(result => {							
				this.setState({year:result.data})			
		})		
		
	}	

	handleStateChange = event => {	
		this.setState({viewcomments:''})
		this.setState({criAMT:''})
		this.setState({basicAMT:''})
		this.setState({basicamount:''})
		this.setState({caregive:''})
		this.setState({ageamount:''})
		this.setState({pensionamount:''})
		this.setState({tution:''})
		this.setState({disabilityamount:''})
		this.setState({spouseamount:''})
		this.setState({dependamount:''})
		this.setState({caregivedepend:''})
		this.setState({caregivedepend18:''})
		this.setState({transferredAmount:''})
		this.setState({transferredfromdepend:''})				
		this.setState({onemoreemployer:false})
		this.setState({lessthanclaim:false})
		this.setState({percent90yes:false})
		this.setState({percent90no:false})
		this.setState({prescribedzoon:''})
		this.setState({additionaldeduction:''})
		this.setState({TotalAmount:''})
		this.setState({TD1Year:''})
		const {name, value} = event.target				
		this.setState({[name]: value})	
		const userObject={
				user:this.state.user,
				names:this.state.names,
				state: event.target.value
			}
		axios.post(process.env.REACT_APP_API_ENV+'api/taxbasicinformation.php',userObject)
			.then(result => {			
				if(result.data[0] !='No' && result.data[0] !='NOS') {
					//this.setState({basicamount:(result.data[2] == 0 || result.data[2]=='') ? result.data[2]:result.data[2].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({basicamount:result.data[2]})				
					//this.setState({basicamount1:(result.data[2] == 0 || result.data[2]=='') ? result.data[2]:result.data[2].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})				
					this.setState({basicamount1:result.data[2]})				
					this.setState({netincome:result.data[3]})					
					this.setState({statename:result.data[14]})
					this.setState({caregiveamt:result.data[15]})
					this.setState({agetd1amount:result.data[16]})
					this.setState({agethreshold:result.data[17]})
					this.setState({ageup:result.data[18]})
					this.setState({pensionamt:result.data[19]})
					this.setState({disaamt:result.data[20]})
					this.setState({amt18u:result.data[21]})
					this.setState({up18threshold:result.data[22]})
					this.setState({up18lessamt:result.data[23]})
					this.setState({careupamt:result.data[24]})				
					this.setState({AMTSPOUSE:result.data[25]})				
					this.setState({SPOUSETH:result.data[26]})				
					this.setState({SPOUSEUP:result.data[27]})				
					this.setState({HOMECARE65UP:result.data[28]})				
					this.setState({H65UPTH:result.data[29]})
					this.setState({H65UPEX:result.data[30]})				
					this.setState({INFIRMDEPEND18UP:result.data[31]})				
					this.setState({INFIRM18UPTH:result.data[32]})				
					this.setState({INFIRM18UPEX:result.data[33]})				
					this.setState({EDUPART:result.data[34]})				
					this.setState({EDUFULL:result.data[35]})				
					this.setState({CAREREL:result.data[36]})				
					this.setState({CARETH:result.data[37]})				
					this.setState({CAREUP:result.data[38]})				
					this.setState({AMTSENIOR:result.data[39]})				
					this.setState({AMTCHILD:result.data[40]})				
					this.setState({TEXTFULL:result.data[41]})				
					this.setState({TEXTPART:result.data[42]})				
					this.setState({CEA:result.data[43]})											
					this.setState({TLINES:result.data[4]})							
					this.setState({BPA:result.data[5]})							
					this.setState({CAREGIVE18D:result.data[6]})							
					this.setState({AGE:result.data[7]})							
					this.setState({PENSION:result.data[8]})							
					this.setState({TUTIONCOMM:result.data[9]})							
					this.setState({DISABILITY:result.data[10]})							
					this.setState({SPOUSE:result.data[11]})							
					this.setState({DEPEND:result.data[12]})							
					this.setState({CAREGIVEDEPEND:result.data[13]})					
					this.setState({CAREGIVE18UP:result.data[44]})							
					this.setState({TRANSSPOUSE:result.data[45]})							
					this.setState({TRANSDEPEND:result.data[46]})							
					this.setState({TOTALCLAIM:result.data[47]})							
					this.setState({SUPP1:result.data[48]})							
					this.setState({SUPP2:result.data[49]})							
					this.setState({SUPP3:result.data[50]})							
					this.setState({FDLINE:result.data[51]})
					this.setState({nsexceed:result.data[52]})				
					this.setState({additionalcredit:result.data[53]})							
					this.setState({nssumm:result.data[54]})							
					this.setState({AGESUP:result.data[55]})		
					this.setState({basicAMT:'Yes'})							
				} else if(result.data[0] == 'No') {
					this.setState({basicAMT:'NO'})	
				} else if(result.data[0] =='NOS') {
					this.setState({basicAMT:'NOS'})	
				}
			})			
		axios.post(process.env.REACT_APP_API_ENV+'api/viewtd1form.php', userObject)
			.then(result => {					
				if(result.data[0] == 'No') {
					this.setState({viewcomments: 'You do not have TD1 Form yet for the past years, you can set up TD1 Form for year ' + this.state.year+  ' or you can let system decide the tax deduction for you'})										
					this.setState({basicamount:result.data[7]})					
					this.setState({criAMT:'A'})					
				} else if(result.data[0] != 'No') {
					if(result.data[0] != this.state.year) {
						this.setState({viewcomments:'Please do not file a new '+this.state.year +' TD1 Form If you have nothing to change and the system will take care of this for you'})					
					} 
					this.setState({TD1Year:result.data[0]})			
					this.setState({birthday:result.data[5]})			
					this.setState({countryname:result.data[6]})								
					this.setState({caregive:result.data[8]})									
					this.setState({ageamount:result.data[9]})									
					this.setState({pensionamount:result.data[10]})									
					this.setState({tution:result.data[11]})									
					this.setState({disabilityamount:result.data[12]})									
					this.setState({spouseamount:result.data[13]})									
					this.setState({dependamount:result.data[14]})									
					this.setState({caregivedepend:result.data[15]})									
					this.setState({caregivedepend18:result.data[16]})									
					this.setState({transferredAmount:result.data[17]})				
					this.setState({transferredfromdepend:result.data[18]})				
					this.setState({TotalAmount:result.data[19]})				
					this.setState({amtsupp1:result.data[20]})				
					this.setState({amtsupp2:result.data[21]})									
					this.setState({amtsupp3:result.data[22]})				
					this.setState({amtsupp3:result.data[22]})	
					this.setState({basicamount:result.data[7]})		
					this.setState({criAMT:'B'})	
				} 
		})
		
	}
	
	render() {
		const TTAmount=parseFloat(this.state.basicamount==''? 0:this.state.basicamount)+parseFloat(this.state.caregive==''? 0:this.state.caregive)+
						parseFloat(this.state.ageamount==''? 0:this.state.ageamount)+parseFloat(this.state.pensionamount==''? 0: this.state.pensionamount)+
						parseFloat(this.state.tution==''? 0:this.state.tution)+parseFloat(this.state.disabilityamount==''? 0:this.state.disabilityamount)+
						parseFloat(this.state.spouseamount==''?0:this.state.spouseamount)+parseFloat(this.state.dependamount==''?0:this.state.dependamount)+
						parseFloat(this.state.caregivedepend==''?0:this.state.caregivedepend)+parseFloat(this.state.caregivedepend18==''?0:this.state.caregivedepend18)+
						parseFloat(this.state.transferredAmount==''?0:this.state.transferredAmount)+parseFloat(this.state.transferredfromdepend==''?0:this.state.transferredfromdepend)+						
						parseFloat(this.state.amtsupp1==''?0:this.state.amtsupp1)+parseFloat(this.state.amtsupp2==''?0:this.state.amtsupp2)+parseFloat(this.state.amtsupp3==''?0:this.state.amtsupp3)						
		return (
			<div>      	
					<CommonHeader titles="TD1 Form"/>
					<label><h2>{this.state.TD1Year !="" ? this.state.TD1Year: this.state.year} Personal Tax Credit Return - TD1 Form</h2></label>							
					<select name="formtype" id="formtype" style={{width:'150px'}} value={this.state.formtype} onChange={this.handleStateChange}>
									<option value="">--Select TD1 Form--</option>														
									<option value="FD">Federal</option>															
									{this.state.Pro.filter(item=>item[0] == this.state.province).map(item=>(<option value={item[0]}>{item[1]}</option>))}
					</select><br/><br/>				
					{(()=>{if(this.state.formtype != '' && (this.state.criAMT =='A' || this.state.basicAMT == 'NO')) {return (<div>	
						<h1 style={{textAlign:'left'}}>{this.state.viewcomments}</h1><br/><br/>
					</div>)}})()} 
					{(()=>{if(this.state.formtype != '' && this.state.basicAMT == 'NOS') {return (<div>	
						<h1 style={{textAlign:'left'}}>Sorry, system TD1 Form data is not setup yet</h1><br/><br/>
					</div>)}})()} 
					{(()=>{if(this.state.formtype != '' && this.state.criAMT !='A' && this.state.basicAMT == 'Yes') {return (<div>	
						<h1 style={{textAlign:'left'}}>{this.state.viewcomments}</h1><br/><br/>
						<h1 style={{textAlign:'left',color:'black',fontWeight:'bold'}}><a href={"../files/TD1 Form_"+this.state.formtype+".pdf"} target="_blank">Personal Tax Credits Return -- TD1{this.state.formtype=='FD'?'':this.state.formtype} Form </a></h1>							
						<p style={{textAlign:'left'}}>Fill out this form based on the best estimate of your circumstances.
						{(this.state.formtype=="FD"||this.state.formtype=="YT") ? " If you do not fill out this form, your tax deductions will only include the basic personal amount,estimated by your employer or payer based on the income they pay you.":""} 
						</p><br/><br/>												
						<div className="forinput">						
							<label className="labelname"><h2><b>First Name and Initials </b>: {this.state.firstname} & {this.state.firstname.substr(0,1)}{this.state.lastname.substr(0,1)}</h2></label>						
							<label className="labelname"><h2><b>Last Name</b>: {this.state.lastname}</h2></label>						
						</div><br/>							
						<div className="forinput">						
							<label className="labelname"><h2><b>Employee Number</b>:{this.state.EID}</h2></label>						
							<label className="labelname"><h2><b>Address</b>: {this.state.address+', '+this.state.city+', '+this.state.province}</h2></label>						
						</div><br/>
						<div className="forinput">						
							<label className="labelname"><h2><b>Post Code</b>: {this.state.postcode1+'-'+this.state.postcode2}</h2></label>						
							<label className="labelname"><h2><b>Social Insurance Number</b>: {this.state.SIN1+'-'+this.state.SIN2+'-'+this.state.SIN3}</h2></label>						
						</div><br/>
						<div className="forinput">
							<label className="labelname"><h2><b>Date of Birth</b>:{this.state.birthday} </h2></label>
							<label className="labelname"><h2><b>Country of Perment Residence</b>: {this.state.countryname}</h2></label>														
						</div><br/>	
						{/*Federal*/}
						{(()=>{if(this.state.formtype === 'FD') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Caregive Amount for FD and YT*/}						
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Canada Caregive Amount</b>:</label><label className="labelname"><h2>${this.state.caregive}</h2></label>								
							</div><br/>	
							{/*Age amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Tuition</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>	
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>	
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'13. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*Ontario*/}						
						</div>)} else if(this.state.formtype === 'ON') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>							
							{/*Age amount*/}
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>							
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>	
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*Yukon*/}
						</div>)} else if(this.state.formtype === 'YT') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Caregive Amount for FD and YT*/}						
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Canada Caregive Amount</b>:</label><label className="labelname"><h2>${this.state.caregive}</h2></label>								
							</div><br/>	
							{/*Age amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Tuition</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</li>
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==5).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>	
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>	
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'13. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*Alberta*/}
						</div>)} else if(this.state.formtype === 'AB') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>	
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
							</ul><br/>
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>	
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>	
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*BC*/}
						</div>)} else if(this.state.formtype === 'BC') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Tuition</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>								
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>																				
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>																				
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>	
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*Manitoba*/}
						</div>)} else if(this.state.formtype === 'MB') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname"><b>Tuition</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>								
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>							
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>		
							{/*Family Tax Benefit*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Family Tax Benefit</b>:</label><label className="labelname"><h2>${this.state.caregive}</h2></label>								
							</div><br/>	
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'13. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*New Brunswidk*/}
						</div>)} else if(this.state.formtype === 'NB') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>		
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Tuition</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>						
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>									
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>							
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*Newfoundland and Labrador */}
						</div>)} else if(this.state.formtype === 'NL') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname"><b>Tuition</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>	
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>	
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>			
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>								
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*Nova Scotia*/}
						</div>)} else if(this.state.formtype === 'NS') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Age amount supplement*/}	
							<p style={{textAlign:'left'}}><b>{'2.1 '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).filter(sub=>sub[4]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).filter(sub=>sub[4]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age amount supplement</b>:</label><label className="labelname"><h2>${this.state.amtsupp1}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>		
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname"><b>Tuition</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>	
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>	
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/* Spouse or common-law partner supplement*/}
							<p style={{textAlign:'left'}}><b>{'6.1 '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==5).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==6).map(sub=>sub[6])}</li>									
							</ul><br/>								
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==7).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Spouse or common-law partner supplement</b>:</label><label className="labelname"><h2>${this.state.amtsupp2}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>			
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>
							{/*Amount for an eligible dependant supplement*/}
							<p style={{textAlign:'left'}}><b>{'7.1 '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==6).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==7).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==8).map(sub=>sub[6])}</li>									
							</ul><br/>			
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==9).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Eligble Dependant Supplement</b>:</label><label className="labelname"><h2>${this.state.amtsupp3}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>									
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>								
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*Northwest Territories*/}
						</div>)} else if(this.state.formtype === 'NT') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname"><b>Tuition</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>	
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>	
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
							</ul><br/>								
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</li>									
							</ul><br/>								
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>								
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/* Nunavut*/}
						</div>)} else if(this.state.formtype === 'NU') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname"><b>Tuition</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>	
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>	
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>
							</ul><br/>								
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>								
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>	
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>								
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*Prince Edward Island*/}
						</div>)} else if(this.state.formtype === 'PE') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>	
							{/*Tuition amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<div className="forinput">
								<label className="labelname"><b>Tuition</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>	
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>	
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>			
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>								
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>	
							{/*Saskatchewan*/}						
						</div>)} else if(this.state.formtype === 'SK') {return (<div>
							{/*Basic personal amount*/}
							<p style={{textAlign:'left'}}><b>{'1. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==1).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Basic Personal Amount</b>:</label><label className="labelname"><h2>${this.state.basicamount}</h2></label>								
							</div><br/>	
							{/*Age amount*/}					
							<p style={{textAlign:'left'}}><b>{'2. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==2).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Age Amount</b>:</label><label className="labelname"><h2>${this.state.ageamount}</h2></label>								
							</div><br/>	
							{/*Senior Supplementary amount*/}
							<p style={{textAlign:'left'}}><b>{'3. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==3).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Senior Supplementary amount</b>:</label><label className="labelname"><h2>${this.state.amtsupp1}</h2></label>								
							</div><br/>	
							{/*Pension amount*/}
							<p style={{textAlign:'left'}}><b>{'4. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==4).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Pension Income Amount</b>:</label><label className="labelname"><h2>${this.state.pensionamount}</h2></label>								
							</div><br/>														
							{/*disability amount*/}
							<p style={{textAlign:'left'}}><b>{'5. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==5).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Disability Amount</b>:</label><label className="labelname"><h2>${this.state.disabilityamount}</h2></label>								
							</div><br/>							
							{/*spouse or common lawer partner amount*/}
							<p style={{textAlign:'left'}}><b>{'6. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==6).filter(sub=>sub[4]==3).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Spouse/Common Law Partner Amount</b>:</label><label className="labelname"><h2>${this.state.spouseamount}</h2></label>								
							</div><br/>	
							{/*amount for eligble dependant*/}
							<p style={{textAlign:'left'}}><b>{'7. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==7).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.dependamount}</h2></label>								
							</div><br/>	
							{/*Child amount*/}
							<p style={{textAlign:'left'}}><b>{'8. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==8).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Child Amount</b>:</label><label className="labelname"><h2>${this.state.tution}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant, spouse or Comment lawer*/}
							<p style={{textAlign:'left'}}><b>{'9. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>																	
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==9).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant</b>:</label><label className="labelname"><h2>${this.state.caregivedepend}</h2></label>								
							</div><br/>	
							{/*caregiver amount for dependant age 18 or older*/}
							<p style={{textAlign:'left'}}><b>{'10. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==0).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==0).map(sub=>sub[6])}</p><br/>
							<ul style={{textAlign:'left',paddingLeft:'30px'}}>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==1).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==2).map(sub=>sub[6])}</li>
								<li>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==3).map(sub=>sub[6])}</li>									
							</ul><br/>
							<p style={{textAlign:'left'}}>{this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==10).filter(sub=>sub[4]==4).map(sub=>sub[6])}</p><br/>					
							<div className="forinput">
								<label className="labelname"><b>Caregiver Amount for Dependant Age 18+</b>:</label><label className="labelname"><h2>${this.state.caregivedepend18}</h2></label>								
							</div><br/>								
							{/*Amount transferred from spouse or common lawer*/}
							<p style={{textAlign:'left'}}><b>{'11. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==11).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Spouse/Common-law Partner</b>:</label><label className="labelname"><h2>${this.state.transferredAmount}</h2></label>								
							</div><br/>	
							{/*Amount transferred from Dependant*/}
							<p style={{textAlign:'left'}}><b>{'12. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==12).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Amount From Dependant</b>:</label><label className="labelname"><h2>${this.state.transferredfromdepend}</h2></label>								
							</div><br/>							
							{/*Total Amount*/}
							<p style={{textAlign:'left'}}><b>{'13. '+this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[5])}</b> – {this.state.tts.filter(sub=>sub[2]==this.state.formtype).filter(sub=>sub[3]==13).map(sub=>sub[6])}</p><br/>
							<div className="forinput">
								<label className="labelname"><b>Total Claim Amount</b>:</label><label className="labelname"><h2>${this.state.TotalAmount}</h2></label>								
							</div><br/>									
						</div>)}
						})()} 
					</div>)}})()} 
			</div>
		)
	}
}

export default profileForm;