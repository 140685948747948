import {PDFDocument} from 'pdf-lib';
import { saveAs } from 'file-saver';
import CommonHeader from '../clients/commons/Head'
import React  from "react";
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')


class reportForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {BranchList:[], EmployeeList:[],
						paystub:'',report:1,payDetails:[], branch:'', BranchName:[], CID:'',BID:'',payPeriods:[],
						payperiod:'',start:'',end:'', pid:'', comments:'', chargingfee:[], t4comments:'',t4year:'',
						T4T:[],T4TS:[],T4TA:[],T4TAComments:'',T4TComments:'',T4TAS:[], Taxdays:'', T4Ready:'',T4Aready:'',
						downloadsA:'',downloads:'',user:localStorage.getItem('tokens'),names:localStorage.getItem('names'),
						Client:'',approvedpayment:[],
						starting:'',ending:'', clientfee:[]};		
		this.handleChange=this.handleChange.bind(this);		
		this.handleSubChange=this.handleSubChange.bind(this);	
		this.handlePeriodChange=this.handlePeriodChange.bind(this);		
		this.handleSubmit=this.handleSubmit.bind(this);
		this.handleT4Change=this.handleT4Change.bind(this);
		this.handleEndChange=this.handleEndChange.bind(this);
	}

	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}	
		axios.post(process.env.REACT_APP_API_ENV+'api/branchupdates.php',para)
			.then(result => {						
				this.setState({BranchList:result.data})			
			})		
		axios.post(process.env.REACT_APP_API_ENV+'api/taxseasondays.php', para)
			.then(result => {												
				this.setState({Taxdays:result.data})								
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/findbranch.php', para)
			.then(result => {
				if(result.data[0][3]=="No") {
					this.setState({comments:"No payment has been processed yet"})
				} else {
					this.setState({BranchName:result.data})					
				}
			})
		axios.post(process.env.REACT_APP_API_ENV+'api/paymentperiod.php', para)
			.then(result => {
				this.setState({payPeriods:result.data})									
			})
		const userObject={				
					user:this.state.user,
					year:thisyear-1
				}		
		axios.post(process.env.REACT_APP_API_ENV+'api/employert4tax.php', userObject)
			.then(result => {
				if(result.data[0][0] !="No") {					
					this.setState({T4T:result.data})
					this.setState({T4Ready:result.data[0][66]})
				} else {
					this.setState({T4TComments:'No'})					
				}
		})	
		axios.post(process.env.REACT_APP_API_ENV+'api/employert4atax.php', userObject)
			.then(result => {
				if(result.data[0][0] !="No") {					
					this.setState({T4TA:result.data})	
					this.setState({T4AReady:result.data[0][66]})
				} else {
					this.setstT4TAComments({T4TAComments:'No'})					
				}
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/employert4summtax.php', userObject)
			.then(result => {
				if(result.data[0][0] !="No") {					
					this.setState({T4TS:result.data})	
				}
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/employert4asummtax.php', userObject)
			.then(result => {
				if(result.data[0][0] !="No") {					
					this.setState({T4TAS:result.data})	
				}
		})
		axios.post(process.env.REACT_APP_API_ENV+'api/clientfees.php', para)
			.then(result => {
				this.setState({approvedpayment:result.data})									
			})
	}
						
	handleChange = event => {
		this.setState({payperiod:''})
		this.setState({t4comments:''})
		this.setState({T4TAComments:''})
		this.setState({T4TComments:''})
		const {name, value} = event.target				
		this.setState({[name]: value})			
	}
	
	handleEndChange = event => {
		this.setState({payperiod:''})
		this.setState({t4comments:''})
		this.setState({T4TAComments:''})
		this.setState({T4TComments:''})
		const {name, value} = event.target				
		this.setState({[name]: value})			
		if(event.target.value !="") {
			const userObject={				
					user:this.state.user,
					names:this.state.names,
					starting:this.state.starting,
					ending:event.target.value				
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/clientchargefee.php', userObject)
				.then(result => {							
					this.setState({clientfee:result.data})											
				})		
		}
	}
		
	handleT4Change = event => {
		this.setState({payperiod:''})
		this.setState({t4comments:''})
		this.setState({T4TAComments:''})
		this.setState({T4TComments:''})
		const {name, value} = event.target				
		this.setState({[name]: value})				
	}
	
	handlePeriodChange = event => {		
		this.setState({payperiod:''})	
		this.setState({t4comments:''})
		this.setState({T4TAComments:''})
		this.setState({T4TComments:''})
		const {name, value} = event.target				
		this.setState({[name]: value})	
		if(event.target.value !="" && this.state.paystub==1) {
			const userObject={				
					user:this.state.user,
					names:this.state.names,
					branch:this.state.branch,
					period:event.target.value				
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/withholding.php', userObject)
				.then(result => {				console.log(result.data)			
					this.setState({payDetails:result.data})											
				})		
		} else if(event.target.value !="" && this.state.paystub==2) {
			const userObject={				
					user:this.state.user,
					names:this.state.names,
					branch:this.state.branch,
					period:event.target.value				
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/accountingfee.php', userObject)
				.then(result => {			
					this.setState({chargingfee:result.data})											
				})		
		}
	}

	handleSubChange = event => {				
		const {name, value} = event.target				
		this.setState({[name]: value})	
		const userObject={				
					user:this.state.user,
					names:this.state.names,
					BID:event.target.value					
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/findEmployees.php', userObject)
				.then(result => {			
					this.setState({EmployeeList:result.data})											
				})		
	}	
	
	TDisplayFormat =(DFormat, DRow) =>{		
		const LB=[]							
		for (let i=0;i<DRow;i++) {			
			LB.push(<div key={i}>
			<div className="label-wrap" style={{textAlign:'center'}}>
				<div className="label">					
					{DFormat.slice(i*10,10*(i+1)).map((item, index) => {return<h2 style={{width:'95px'}} key={index}>{item}</h2>;})} 
				</div>				
			</div><br/><br/>
			</div>)
		}			
		return LB
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {t4year} = this.state		
		if(this.state.paystub==3) {
			const userObject={				
					user:this.state.user,	
					names:this.state.names,
					t4year:this.state.t4year				
				}			
			axios.post(process.env.REACT_APP_API_ENV+'api/t4taxrelease.php', userObject)
				.then(result => {							
					this.setState({t4comments:result.data})											
				})		
		} 
	}
	
	render() {				
		return (
			<div>      
				<CommonHeader titles="Accounting"/>				
				<h2>Choose Branch Company</h2>					
				<select name="paystub" style={{width:'150px'}} value={this.state.paystub} onChange={this.handleSubChange}>
					<option value="">--Branch--</option>
					<option value="0">--Whole Employees--</option>						
					{this.state.BranchList.map((sub,index) => (<option value={sub[0]} key={index}>{sub[3]}</option>))}						
				</select><br/><br/>										
				{(()=>{if(this.state.paystub != "") {return (<div>
					<h2 style={{textAlign:'left'}}><b>Employee List</b></h2><br/>
					<div className="label-wrap" style={{textAlign:'center'}}>
						<div className="label">	
							<table>
								<thead>
									<tr>											
										<th style={{width:'100px'}}>Sequence</th>
										<th style={{width:'150px'}}>First Name</th>
										<th style={{width:'150px'}}>Last Name</th>
										<th style={{width:'150px'}}>Phone</th>
										<th style={{width:'150px'}}>Email</th>
										<th style={{width:'150px'}}>Province</th>											
										<th style={{width:'150px'}}>Branch</th>
									</tr>
								</thead>
								<tbody>																			
								{this.state.EmployeeList.slice(0, this.state.EmployeeList.length).map((item, index) => {
									return (<tr><td>{item[7]}</td>
										<td>{item[1]}</td>
										<td>{item[2]}</td>
										<td>{item[3]}</td>											
										<td>{item[4]}</td>
										<td>{item[5]}</td>
										<td>{item[6]}</td>
									  </tr>
									  )})}
								</tbody>
							</table>
						</div>				
					</div><br/>						
				</div>)}})()}							
			</div>
		)
	}
}




export default reportForm;

