import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
import CommonHeader from '../clients/commons/Head'

class setupBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {
			adminitem:24,program:'', COMP:[], ProgName:'', ProjName:'', Resp:'', Nodata:'',freqs:[],selectedLName:'',
			LName: '', BName: '', defaultpay:'',payfreqs:'',starting:'',payperiod:[], currfreq:'',payday:'', beforeafter:'2',
			payday2:'',testdate:'',dupfreq:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);		
		this.handleCampusChange=this.handleCampusChange.bind(this);
		this.handleSubmit=this.handleSubmit.bind(this);
		this.handleFREQChange=this.handleFREQChange.bind(this)
	}

	componentDidMount() {	
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/frequency.php', para)
			.then(result => {												
				this.setState({freqs:result.data})								
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/deletepaymentperiod.php')
			.then(result => {				
				this.setState({Nodata:result.data})
				this.setState({payperiod:result.data})										
		})		
		axios.post(process.env.REACT_APP_API_ENV+'api/testdate.php')
			.then(result => {				
				this.setState({testdate:result.data})									
		})	
	}	
		
	handleBankChange = event => {		
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({payfreqs:''})
		
		if(event.target.value!=="") {
			axios.post(process.env.REACT_APP_API_ENV+'api/lastpaymentdate.php?CID='+ event.target.value)
				.then(result => {									
					this.setState({ProgName:result.data[1]})												
					this.setState({LName:result.data[2]})					
					this.setState({ProjName:result.data[3]})	
					this.setState({currfreq:result.data[4]})
					this.setState({dupfreq:result.data[4]})
				})
		}		
		this.setState({Resp:""});
	}
	
	handleCampusChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({BName:''})
		
		if(event.target.value !=="") {
			axios.post(process.env.REACT_APP_API_ENV+'api/findregisteredcompany.php')
				.then(result => {	
					if(result.data[0][0] != "No") {
						this.setState({Nodata:result.data})
						this.setState({COMP:result.data})					
					} 
				})			
		} 
		this.setState({Resp:""});
	}	
	
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
	
	handleFREQChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		if(event.target.value == ''){
			this.setState({currfreq:this.state.dupfreq})
		} else {
			this.setState({currfreq:event.target.value});
		}
	}

	StartingPoint =(Freq) =>{			
		const LB=[]	
		if(Freq==1||Freq==2) {			
			for(let i=0;i<10;i++) {
				LB[i]=<option value={dayjs().day(0).add(i+1,'week').format("YYYY-MM-DD")}>{dayjs().day(0).add(i+1,'week').format("YYYY-MM-DD")}</option>;
			}
		} else if(Freq==3||Freq==4) {
			for(let i=0;i<10;i++) {
				LB[i]=<option value={dayjs().date(1).add(i+1,'month').format("YYYY-MM-DD")}>{dayjs().date(1).add(i+1,'month').format("YYYY-MM-DD")}</option>
			}
		} 			
		return LB
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {program,ProgName, ProjName, LName, BName, defaultpay,payfreqs,starting,currfreq,payday,payday2} = this.state					
		if (this.state.program == "") {
				alert('Please select manage payment period type, Add, Update or Delete?')
				return false
		}
		if(this.state.program == "1")  {			
			if(!BName) {
				alert('Please select company')
				return false
			}
			if(!defaultpay) {
				alert('Please select how many years')
				return false
			}					
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				LName: this.state.selectedLName,
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				BName: this.state.BName,
				defaultpay:this.state.defaultpay,
				currfreq:this.state.currfreq,
				payday:this.state.payday,
				payday2:this.state.payday2,
				beforeafter:this.state.beforeafter
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.program == "2")  {			
			if(!BName) {
				alert('Please select company')
				return false
			}
			if(!payfreqs) {
				alert('please select payment frequency')
				return false
			}		
			if(!starting) {
				alert('Please select starting date for the updating')
				return false
			}
			if(!defaultpay) {
				alert('Please select how many years')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				LName: this.state.selectedLName,
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				BName: this.state.BName,
				defaultpay:this.state.defaultpay,
				payfreqs:this.state.payfreqs,
				starting:this.state.starting,
				currfreq:this.state.currfreq,
				payday:this.state.payday,
				payday2:this.state.payday2,
				beforeafter:this.state.beforeafter
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.program == "3")  {			
			if(!BName) {
				alert('Please select company')
				return false
			}				
			if(!starting) {
				alert('Please select starting date for the updating')
				return false
			}			
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				LName: this.state.selectedLName,
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				BName: this.state.BName,
				starting:this.state.starting
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} 
	}
	
	render() {				
		return (		
			<div>      
				<CommonHeader titles="Payroll Dates"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Payment Periods {this.state.testdate}</h2></label>							
						<select name="program" id="program" style={{width:'150px'}} value={this.state.program} onChange={this.handleCampusChange}>
							<option value="">--Manage Payment Periods--</option>
							<option value="1">Set Up New Payment Periods</option>						
							<option value="2">Update Payment Periods</option>												
							<option value="3">Delete Payment Periods</option>												
						</select><br/><br/>							
					</div>
					{(()=>{if(this.state.program !== "") {return(<div>
							<label><h2>Choose Company</h2></label>
							<select id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} onChange={this.handleBankChange}>
								<option value="">--Select Company--</option>									
								{this.state.COMP.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
							</select><br/><br/>	
							{(()=>{if(this.state.BName !== "") {return(<div>
								<label><h2>Current Last Payment Beginning Date</h2></label>
								<input id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} readOnly />
								<br/><br/>								
								<label><h2>Current Last Payment Ending Date</h2></label>
								<input id="LName" name="LName" value = {this.state.LName} style={{width:'160px'}} readOnly />
								<br/><br/>	
								<label><h2>Current Last Payment Period</h2></label>
								<input id="ProjName" name="ProjName" value = {this.state.ProjName} style={{width:'160px'}} readOnly />
								<br/><br/>	
								<label><h2>Current Payment Frequency</h2></label>
								<input id="currfreq" name="currfreq" value = {this.state.currfreq} style={{width:'160px'}} readOnly />
								<br/><br/>	
								{(()=>{if(this.state.program == "2") {return(<div>
									<label><h2>Payment Frequncy</h2></label>
									<select id="payfreqs" name="payfreqs" value = {this.state.payfreqs} style={{width:'160px'}} onChange={this.handleFREQChange}>
										<option value="">--Select Frequncy --</option>																			
										{this.state.freqs.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}																			
									</select><br/><br/>										
								</div>)}})()}
								{(()=>{if(this.state.program == "2" && this.state.payfreqs !== "") {return(<div>
										<label><h2>Starting From</h2></label>										
										<select id="starting" name="starting" value = {this.state.starting} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--select date--</option>
											{this.StartingPoint(this.state.payfreqs)}
										</select><br/><br/>	
								</div>)}})()}
								{(()=>{if(this.state.program == "3") {return(<div>
										<label><h2>Delete Periods Starting From</h2></label>										
										<select id="starting" name="starting" value = {this.state.starting} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--select date--</option>											
											{this.state.payperiod.filter(sub=>sub[0]==this.state.BName).map((sub,index) => (<option value={sub[3]} key={index}>{sub[1]+' ~ '+sub[2]}</option>))}			
										</select><br/><br/>	
								</div>)}})()}								
								{(()=>{if(this.state.program == "1"||(this.state.program == "2" && this.state.payfreqs != "")) {return(<div>
									<label><h2>Extend Years</h2></label>
									<select id="defaultpay" name="defaultpay" value = {this.state.defaultpay} style={{width:'160px'}} onChange={this.handleChange}>
										<option value="">--Select years --</option>									
										<option value="1">--One Year --</option>									
										<option value="2">--Two Years --</option>									
										<option value="3">--Three Years --</option>									
										<option value="4">--Four Years --</option>									
										<option value="5">--Five Years --</option>									
										<option value="6">--Over Five Years --</option>																	
									</select><br/><br/>									
								</div>)}})()}	

								
								{(()=>{if((this.state.program == "1" || this.state.program == "2") && this.state.BName !== "" && (this.state.currfreq == 1 || this.state.currfreq ==2) && this.state.defaultpay != "") {return(<div>
										<label><h2>Choose Weekly/Bi-Weekly Payment Day</h2></label>	
										<select id="payday" name="payday" value = {this.state.payday} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--Select--</option>
											<option value="1">--Monday--</option>
											<option value="2">--Tuesday--</option>
											<option value="3">--Wednesday--</option>
											<option value="4">--Thursday--</option>
											<option value="5">--Friday--</option>											
										</select><br/><br/>	
								</div>)}})()}
								{(()=>{if((this.state.program == "1" || this.state.program == "2") && this.state.BName !== "" && this.state.currfreq == 3 && this.state.defaultpay != "") {return(<div>
										<label><h2>Choose Semi-Monthly First Payment Day</h2></label>										
										<select id="payday" name="payday" value = {this.state.payday} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--Select--</option>											
											<option value="16">--16--</option>											
											<option value="17">--17--</option>
											<option value="18">--18--</option>
											<option value="19">--19--</option>
											<option value="20">--20--</option>
											<option value="21">--21--</option>
											<option value="22">--22--</option>
											<option value="23">--23--</option>
											<option value="24">--24--</option>											
											<option value="25">--25--</option>											
											<option value="26">--26--</option>											
											<option value="27">--27--</option>											
											<option value="28">--28--</option>											
											<option value="29">--29--</option>											
											<option value="30">--30--</option>											
											<option value="31">--Month End--</option>											
										</select><br/>
										{(()=>{if(this.state.payday < "23" && this.state.payday != "") {return(<div>
											<p>The payment schedule will be next month on {this.state.payday}. {this.state.payday == 15 ? 
											"Semi-monthly second payment day should be next month end.":""} If this day falls on weekend or holiday, the payment schedule will be the next business day</p>
										</div>)} else if(this.state.payday == "31") {return(<div>
											<p>The payment schedule will be current month end and the semi-monthly second payment should be next month 15. If this day falls on weekend or holiday, the payment schedule will be the next business day</p>
										</div>)} else if(this.state.payday > "22" && this.state.payday !="") {return(<div>
											<p>The payment schedule will be this month on {this.state.payday}. If this day falls on weekend or holiday, the payment schedule will be the next business day</p>
										</div>)}})()}<br/><br/>
										<label><h2>Choose Semi-Monthly Second Payment Day</h2></label>										
										<select id="payday2" name="payday2" value = {this.state.payday2} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--Select--</option>																						
												<option value="1">--1--</option>
												<option value="2">--2--</option>
												<option value="3">--3--</option>
												<option value="4">--4--</option>
												<option value="5">--5--</option>
												<option value="6">--6--</option>
												<option value="7">--7--</option>
												<option value="8">--8--</option>
												<option value="9">--9--</option>											
												<option value="10">--10--</option>											
												<option value="11">--11--</option>											
												<option value="12">--12--</option>											
												<option value="13">--13--</option>											
												<option value="14">--14--</option>											
												<option value="15">--15--</option>											
												<option value="31">--Month End--</option>																						
										</select><br/>
										{(()=>{if(this.state.payday2 == "31" && this.state.payday2 != "") {return(<div>
											<p>The payment schedule will be next month end. If this day falls on weekend or holiday, the payment schedule will be the next business day</p>
										</div>)} else if(this.state.payday2 != "") {return(<div>
											<p>The payment schedule will be next month on {this.state.payday2}. If this day falls on weekend or holiday, the payment schedule will be the next business day</p>
										</div>)}})()}<br/><br/>
								</div>)}})()}
								{(()=>{if((this.state.program == "1" || this.state.program == "2") && this.state.BName !== "" && this.state.currfreq == 4 && this.state.defaultpay != "") {return(<div>
										<label><h2>Choose Monthly Payment Day</h2></label>										
										<select id="payday" name="payday" value = {this.state.payday} style={{width:'160px'}} onChange={this.handleChange}>
											<option value="">--Select--</option>											
											<option value="1">--1--</option>
											<option value="2">--2--</option>
											<option value="3">--3--</option>
											<option value="4">--4--</option>
											<option value="5">--5--</option>
											<option value="6">--6--</option>
											<option value="7">--7--</option>
											<option value="8">--8--</option>
											<option value="9">--9--</option>											
											<option value="10">--10--</option>											
											<option value="11">--11--</option>											
											<option value="12">--12--</option>											
											<option value="13">--13--</option>											
											<option value="14">--14--</option>																						
											<option value="15">--15--</option>											
											<option value="31">--Month End--</option>																							
										</select><br/><br/>	
										{(()=>{if(this.state.payday !== "") {return(<div>
											<label><h2>Payment Schedule Before or After Pay Period</h2></label>										
											<select id="beforeafter" name="beforeafter" value = {this.state.beforeafter} style={{width:'160px'}} onChange={this.handleChange}>
												<option value="">--Select--</option>
												<option value="1">--Before Pay Period--</option>
												<option value="2">--After Pay Period--</option>
											</select><br/>Normally salary payment is after working, only a few companys pay before working. Therefore we set default vlaue as after<br/><br/>
										</div>)}})()}
								</div>)}})()}
							</div>)}})()}
							<h1>{this.state.Resp}</h1><br/><br/>						
						</div>)}})()}						
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupBanking;