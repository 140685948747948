import React from 'react'


export default function BusinessBanner(){
	return (
		<div className="banner">
			<h1>Streamline your business</h1>
			<h2>Enhance your efficiency</h2>
		</div>
	)
}