import React from 'react'
import {Link} from "react-router-dom";

class regSub extends React.Component {
	render() {    		
		return (<div>
			<h2><b>Registration Not Completed</b></h2>			
			<br/>
			<p>Sorry, you have not registered in our system yet</p><br/>
			<p>Please go to <Link to = "/Registration" style={{color:'blue'}}><b>Registration Page</b></Link> to register in the system</p><br/>			
			<p>Thank you for your cooperation!</p><br/>					
		</div>)
	}
}

export default regSub