import React from 'react'
import axios from 'axios';


class BusinessName extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}

	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data})													
			})		
	}
	render() {				
		return(
			<div className="header">
				<div className="logo">
					<h1><span>{this.state.CompName[0]} Payroll Service</span></h1>
				</div>
			</div>
		)
	}
}

export default BusinessName