/* eslint-disable import/first */
import React from 'react'
import '../main.css'
import icon1 from '../images/icon1.jpg'
import axios from 'axios';
import MenuBar from '../common/Menu'
import HomeContent from '../common/HomeContent'
import LoginPage from '../admin/quoteform'
import PrimaryFooter from '../common/PrimaryFooter'
import BusinessName from '../common/BusinessName'
import BusinessBanner from '../common/BusinessBanner'


class SearchImage extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}

	componentDidMount() {				
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data})													
			})		
	}
	render() {				
		return(
			<div className="title"><span><img src={icon1} alt="icon" /></span>
				<h1> {this.state.CompName[0]} solutions</h1>
			</div>
		)
	}
}


class HomeTitle extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {HM_Title:''};				
	}

	componentDidMount() {				
		const userObject={
				Titles:"HOME"				
			}
		axios.post(process.env.REACT_APP_API_ENV+'api/FindTitle.php', userObject)
			.then(result => {
				this.setState({HM_Title:result.data})													
			})		
	}
	render() {				
		return(
			<div className="title">
				<h1>{this.state.HM_Title}</h1>
			</div>
		)
	}
}

function Primary() {
	return(
		<div className="primary-col">
			<div className="generic">
				<div className="panel">	  
					<HomeTitle />										
					<HomeContent />
				</div>
			</div>
		</div>
	)
}

function LoginTitle(){
	return(
		<div className="title">
			<h1>Get Quote here</h1>
		</div>
	)
}


function LoginTool(){
	return(
		<div className="search">
			<LoginTitle />
			<LoginPage />			
		</div>
	)
}

function SearchCon() {
	return(		
        <ul>
          <li>Anytime</li>
		  <li>Anywhere</li>
          <li>Online Input</li>
		  <li>Easiest-to-use</li>
          <li>Real time calculation</li>
          <li>Cost saving</li>
          <li>Efficiency</li>
		  <li>Compliance</li>
		  <li>Accuracy</li>
		  <li>Accessibility</li>
        </ul>     
	)
}

function SearchPanel(){
	return(
		<div className="panel">
			<SearchImage />
			<div className="content">
				<SearchCon />
			</div>
		</div>
	)
}

function SideBar() {
	return(
		<div className="side-bar">
			<LoginTool />
			<SearchPanel />			
		</div>
	)
}

function HomePage(){
	return(
		<div className="page">
			<Primary />
			<SideBar />
		</div>		
	)
}

export default function Home() {
	return(
		<div>
			<MenuBar />
			<BusinessName />			
			<BusinessBanner />			
			<HomePage />		
			<PrimaryFooter />
		</div>		
	)
}

	