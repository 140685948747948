import React from 'react'
import dayjs from 'dayjs';
import '../main.css'
import axios from 'axios'
import Logo from '../clients/images/banner-image.jpg'


class Headers extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {prog:'',role:'', industry:'',assignment:'',FileExt:'', imgsrc:'', PWDTimes:'', PWDDATE:'',NMonth:'',
						RoleID:'',DiffDay:'', ASS:'', BK:'',user:localStorage.getItem('tokens'),
						rrsp:'', uniong:'', LogoID:'',names:localStorage.getItem('names'),
						pensiong:'', uniondue:'', Deductions:'',payrate:'',grouppension:'',groupunion:''}
	}
	componentDidMount() {
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/countprog.php',para)
			.then(result => {
				this.setState({prog:result.data[1]});
				this.setState({role:result.data[0]});
				this.setState({industry:result.data[2]});
				this.setState({assignment:result.data[3]});				
				this.setState({FileExt:result.data[4]});											
				this.setState({Deductions:result.data[6]});
				this.setState({payrate:result.data[7]});
				if(result.data[4] !=' ') {							
					this.setState({imgsrc:process.env.REACT_APP_API_ENV+'/static/media/logo_'+result.data[5]+'.'+result.data[4]});
					localStorage.setItem("LOGOCID", process.env.REACT_APP_API_ENV+'/static/media/logo_'+result.data[5]+'.'+result.data[4]);
				}
		}).catch((error) => {
			console.log('error ' + error);   
		});
		axios.post(process.env.REACT_APP_API_ENV+'api/userinformation.php', para)
			.then(result => {				
				this.setState({clientName:result.data[1]})																
				this.setState({subName:result.data[2]})																
				this.setState({PWDTimes:result.data[12]})		
				this.setState({PWDDATE:result.data[13]})		
				this.setState({NMonth:result.data[15]})
				this.setState({RoleID:result.data[14]})
				this.setState({ASS:result.data[16]})
				this.setState({BK:result.data[17]})
				this.setState({rrsp:result.data[18]})
				this.setState({uniong:result.data[19]})
				this.setState({pensiong:result.data[20]})
				this.setState({uniondue:result.data[21]})
				this.setState({grouppension:result.data[23]})
				this.setState({groupunion:result.data[24]})
				this.setState({LogoID:result.data[22]})
				this.setState({DiffDay:dayjs(result.data[13]).add(result.data[15], 'month').diff(dayjs(),'day')})				
			})
	}
	render() {
		return(
			<div className="title">
				<div align="center">					
					<br/><br/>				
					<h1>{(this.state.FileExt != ' ' && this.state.FileExt !='') ? <img src={this.state.imgsrc} alt="icon" />:<img src={Logo} alt="icon" />}</h1>					
					<h2>Welcome {this.state.clientName} to {this.state.subName} Payroll System</h2><br/>
					<ol style={{textAlign:'left' ,paddingLeft:'25px'}}>
						{(()=>{if(this.state.clientName == " ") {return(<div>
							<li>Please set up your profile first under SETTINGS section!</li>
						</div>)}})()}
						{(()=>{if(this.state.payrate == "0") {return(<div>
							<li>Please ask your manager to set up your rate under MANAGEMENT section Manage Employee function!</li>
						</div>)}})()}
						{(()=>{if(this.state.BK == "0") {return(<div>
							<li>Please set up your company bank account</li>
						</div>)} else if(this.state.BK =="TEST") {return(<div>
							<li>Please update your company bank account under MANAGEMENT section</li>
						</div>)}})()}
						{(()=>{if(this.state.PWDTimes == 1) {return(<div>
							<li>Please change your password immediately under SETTINGS section! After PWD changed, you have to log out and log in again</li>
							<li>Please update your TD1 Form under SETTINGS section if you want to deduct more values from your payroll, otherwise the system will default your payroll deduction!</li>
						</div>)}})()}					
						{(()=>{if(this.state.DiffDay < 7 && this.state.DiffDay >=0) {return(<div>
							<li>Please change your password under SETTINGS section since your password is going to be expired in {this.state.DiffDay} day(s)}</li>
						</div>)} else if(this.state.DiffDay < 0) {return(<div>
							<li>Please change your password immediately under SETTINGS section since your password is expired already</li>
						</div>)}})()}
						{(()=>{if(this.state.grouppension != 0) {return(<div>
							<li>Please update your group pension plan information under Update Your Profile function in SETTINGS section if your company has pension plan and you want to join, otherwise you can leave it alone</li>							
						</div>)}})()}
						{(()=>{if(this.state.groupunion != 0) {return(<div>							
							<li>Please update your union due information under Update Your Profile function in SETTINGS section if your company has pension plan and you want to join, otherwise you can leave it alone</li>
						</div>)}})()}
						{(()=>{if((this.state.RoleID == 1 || this.state.RoleID == 2) && this.state.Deductions==0) {return(<div>
							<li>Please determine whether your company has group pension plan to set up pension deduction rate under MANAGEMENT section Manage Payroll Deduction Items part</li>
							<li>Please determine whether your company has union organization to set up union due rate under MANAGEMENT section Manage Payroll Deduction Items part</li>
						</div>)}})()}
						{(()=>{if((this.state.RoleID == 1 || this.state.RoleID == 2) && this.state.prog==0) {return(<div>
							<li>If you have more than one branch companies, please add/update your new branch and corresponding bank accounts under MANAGEMENT section!</li>
							<li>Please set up your program and cost center under MANAGEMENT section!</li>
							<li>If you have more than one employees, please add/update employee information under MANAGEMENT section!</li>
						</div>)}})()}
						{(()=>{if((this.state.RoleID == 1 || this.state.RoleID == 2) && this.state.ASS==0) {return(<div>							
							<li>Please assign your employee assignment under MANAGEMENT section!</li>							
						</div>)}})()}
						{(()=>{if((this.state.RoleID == 1 || this.state.RoleID == 2) && this.state.ASS==0 && this.state.LogoID != 1) {return(<div>	
							<li>If you want to replace logo with your own, please upload your logo under MANAGEMENT section!</li>
						</div>)}})()}
						{(()=>{if(this.state.ASS==0) {return(<div>
							<li>Once you enter your basic information and refresh, then you can start using the system!</li>							
						</div>)}})()}
					</ol><br/>
				</div>			
			</div>
		)
	}
} 

export default Headers;