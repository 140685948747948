import React from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";
import CommonHeader from '../common/Head'


class Homes extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {CompName:''};				
	}

	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/FindCompanyName.php')
			.then(result => {				
				this.setState({CompName:result.data})													
			})		
	}
	render() {				
		return (<div className="content">	
			<CommonHeader titles="Home"/>
			<h2>Why Choose {this.state.CompName[0]}. </h2>
			<p> <b>{this.state.CompName[0]}</b> is Canada payroll specialist helping Canadian companies excel in customer satisfaction and customer support. 
				We are building our vision and proud of being trusted by Canadian companies from coast-to-coast ranging from one employee to as many as employees.
				Any company looking to reduce payroll administration and increase overall efficiency would benefit from using <b>{this.state.CompName[0]}</b> Payroll service</p>
			<p><b>{this.state.CompName[0]}</b> provides employee time management and payroll services, and we only charge payroll service fee to greatly reduce your company operational cost, such as banking cheque fees, payroll staff fees, etc</p>
			<p><b>{this.state.CompName[0]}</b> takes care of our customers and love serving our customers by delivering a personal and consistent experience to each one of our customers.
				Generally, Payroll processing is seen as complicated and time-consuming, but</p>
			<p><b>{this.state.CompName[0]}</b> module is feature-rich and will meet all of your payroll processing needs. We design the Payroll service from the gound up and offer 
				online input and provide real time calculation. That's why our customers say it's the easiest-to-use system </p>
			<p><b>{this.state.CompName[0]}</b> brings together your data from across all modules to process your payroll efficiently and accurately. </p>
			<p><b>{this.state.CompName[0]}</b> is the perfect solution to :</p>
			<p>
				<li>Reduce your Payroll cost -- Cut banking cheque fees</li>
				<li>Increase your Payroll efficiency -- Deposit payment directly</li>
				<li>Ensure your Payroll compliance -- Apply current on ever-changing tax rules and regulations</li>
				<li>Increase your Payroll accuracy -- Calculate payroll automatically and correctly and no manual works</li>		
				<li>Print Pay stubs and T4/T4A forms from your desk -- Eliminate courier fees</li>				
				<li>Unlimited technical support -- No hidden and extra cost</li>				
			</p>			  
			<p><b>{this.state.CompName[0]}</b> is designed for small and medium sized companies. We develop our software to help companies grow from just a few employees, to hundreds and thousands. </p>
			<p><Link to = "/Registration"><b>Register your company</b></Link> now</p>
        </div>)
	}	
}

export default Homes
