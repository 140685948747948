import React from 'react'
import {Link} from "react-router-dom";

class regSub extends React.Component {
	render() {    		
		return (<div>
			<h2><b>Registration Completed</b></h2>			
			<br/>
			<p>Thank you for your registration in our system.</p><br/>
			<p>You have completed your registration in the system.</p><br/>
			<p>Now you can <Link to = "/Login" style={{color:'blue'}}><b>Login </b></Link> to the system and set up basic and necessary informtion for your school</p><br/>
			<p>Then you can use our system with your comfort.</p><br/>
			<p>You streamline your business</p><br/>
			<p>You enhance your business efficient</p><br/>
			
		</div>)
	}
}


export default regSub