import React,{useState} from 'react'
import '../main.css'
import axios from 'axios'
import CommonHeader from '../clients/commons/Head'


class setupBanking extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:27,program:'', ProgName:'', ProjName:'', Resp:'', Nodata:'',hours:[],LName: '', 
					BName: '',user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};					
		this.handleChange=this.handleChange.bind(this);				
		this.handleSubmit=this.handleSubmit.bind(this)			
	}

	componentDidMount() {		
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/workinghours.php')
			.then(result => {												
				this.setState({program:result.data[0]})								
				this.setState({BName:result.data[1]})								
				this.setState({ProgName:result.data[2]})								
				this.setState({LName:result.data[3]})								
				this.setState({ProjName:result.data[4]})								
		})		
	}	
		
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
	}
		
	handleSubmit = event => {		
		event.preventDefault()		
		const {program,ProgName, ProjName, LName, BName} = this.state					
		if (this.state.program == "") {
			alert('Please end business hour starting time')
			return false
		}
		if(!BName) {
			alert('Please enter business hour ending time')
			return false
		}
		if(!ProgName) {
			alert('Please enter contact email address')
			return false
		}					
		if(!LName) {
			alert('Please enter contact phone number')
			return false
		}									
		if(!ProjName) {
			alert('Please enter company address')
			return false
		}					
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,
				program:this.state.program,
				LName: this.state.LName,
				ProgName:this.state.ProgName,
				ProjName:this.state.ProjName,
				BName: this.state.BName						
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});				
	}
	
	render() {				
		return (		
			<div>      		
				<CommonHeader titles="Contact"/>
				<form onSubmit={this.handleSubmit}>										
					<label><h2>Start Time</h2></label>							
					<input name="program" id="program" style={{width:'150px'}} value={this.state.program} onChange={this.handleChange} /><br/><br/>							
					<label><h2>End Time</h2></label>
					<input id="BName" name="BName" value = {this.state.BName} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>								
					<label><h2>Email Address</h2></label>
					<input id="ProgName" name="ProgName" value = {this.state.ProgName} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>								
					<label><h2>Phone</h2></label>
					<input id="LName" name="LName" value = {this.state.LName} style={{width:'160px'}} onChange={this.handleChange} /><br/><br/>
					<label><h2>Company Address</h2></label>
					<input id="ProjName" name="ProjName" value = {this.state.ProjName} style={{width:'250px'}} onChange={this.handleChange}/><br/><br/>
					<h1>{this.state.Resp}</h1><br/><br/>			
					<h2><small>Please follow the above foramt to update the values</small></h2><br/>
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupBanking;