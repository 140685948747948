import CommonHeader from '../clients/commons/Head'
import React  from "react";
import axios from 'axios'
import dayjs from 'dayjs';


class reportForm extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {years:'', reYears:[], clients:[],user:localStorage.getItem('tokens'),
					names:localStorage.getItem('names')};		
	}
							
	componentDidMount() {				
		const para={user:this.state.user,names:this.state.names}	
		axios.post(process.env.REACT_APP_API_ENV+'api/registeredyears.php',para)
			.then(result => {						
				this.setState({reYears:result.data})			
			})				
	}
	
	handleChange = event => {	
		const {name, value} = event.target				
		this.setState({[name]: value})		
		const userObject={				
				user:this.state.user,
				names:this.state.names,				
				years:event.target.value				
			}			
		axios.post(process.env.REACT_APP_API_ENV+'api/shownclients.php', userObject)
			.then(result => {							
				this.setState({clients:result.data})											
			})			
	}
	
	render() {	console.log(this.state.clients)			
		return (
			<div>      
				<CommonHeader titles="Registered Company"/>
				<form >
					<h2> Registered Company</h2>
					<label><h2>Starting Date</h2></label>							
						<select id="years" name="years" value = {this.state.years} style={{width:'160px'}} onChange={this.handleChange}>
							<option value="">--Select Year--</option>									
							{this.state.reYears.map((sub,index) => (<option value={sub[0]} key={index}>{sub[0]}</option>))}			
						</select>
					<br/><br/>	
					<h2>{this.state.years == "9999" ? "We don't have registered company yet, just wait":""}</h2><br/><br/>
					{(()=>{if(this.state.years < "9999" && this.state.years != "") {return(<div>
						<div className="label-wrap" style={{textAlign:'center'}}>
							<div className="label">	
								<table>
									<thead>
										<tr>											
											<th style={{width:'200px'}}>Company</th>
											<th style={{width:'200px'}}>Subsidiary</th>
											<th style={{width:'200px'}}>Contact</th>
											<th style={{width:'200px'}}>Contact Phone</th>
											<th style={{width:'200px'}}>Contact Email</th>
											<th style={{width:'200px'}}>City</th>
											<th style={{width:'200px'}}>Employee</th>
										</tr>
									</thead><br/>
									<tbody>																			
									{this.state.clients.slice(0, this.state.clients.length).map((item, index) => {
										return (
											<tr><td>{item[0]}</td>
												<td>{item[1]}</td>
												<td>{item[2]}</td>
												<td>{item[3]}</td>											
												<td>{item[4]}</td>
												<td>{item[5]}</td>
												<td>{item[6]}</td>
											</tr>
										  )})}
									</tbody>
								</table>
							</div>				
						</div><br/>		
					</div>)}})()}
				</form>			
			</div>
		)
	}
}

export default reportForm;

